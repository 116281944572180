import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class Merchant extends Component {
  constructor(props) {
    super(props);
    this.state = { userId: "" };
  }
  componentDidMount() {
    let user_id = localStorage.getItem("data");
    this.setState({ userId: user_id });
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };

  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text1 w80 txtJus">
                  <span className="header2 font20 fontfamTM">
                    Connecting Crypto Peers{" "}
                  </span>
                  <span className="font16" style={{ color: "black" }}>
                    by bringing individual & merchants from all around the globe
                    to extend your horizons by accepting payments in bitcoins...
                  </span>
                  <div>
                    {!this.state.userId ? (
                      <button
                        type="button"
                        className="btn btnWhite  mt15"
                        onClick={() => this.props.history.push("/signup")}
                      >
                        Sign Up Now <i className="fa fa-chevron-right" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btnWhite  mt15"
                        onClick={() => this.props.history.push("/Wallet")}
                      >
                        Create Invoice <i className="fa fa-chevron-right" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="posRel marAuto width55"
                  style={{ background: "white" }}
                >
                  <img
                    className="merIm1"
                    src={require("../assets/newImages/merchnt_img_1.png")}
                    alt=""
                  />
                  {/* <img className="mer_logo" src={require("../assets/newImages/merchnt_logo.png")} alt="" /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pb30"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row rowSpace4 flexRev">
              <div className="col-md-6">
                <div className="merImgLft">
                  <img
                    src={require("../assets/newImages/merchnt_img_2.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text2">
                  <span className="header2 font18 fontfamTM">
                    Generate invoice{" "}
                  </span>
                  <span className="font16">
                    , start accepting payments in bitcoins, ask your customers
                    to scan the QR code or share your wallet through various
                    social media apps
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace4">
              <div className="col-md-6">
                <div className="text3">
                  <span className="header2 font18 fontfamTM">Coinbaazar </span>
                  <span className="font16">
                    provides a payment solution for everyone who wants to accept
                    bitcoin for their{" "}
                  </span>
                  <span className="header2 font18 fontfamTM">
                    goods & services{" "}
                  </span>
                  <span className="font16">
                    in the most simple & secure manner
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="merImgRyt">
                  <img
                    src={require("../assets/newImages/merchnt_img_3.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row">
              <div className="col-md-12">
                <h4 className="header2 font30 fontfamTM text-center">
                  For any assistance reach our Live support
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
