import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class GoodsAndServices extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text2">
                  <p className="font18" style={{ color: "black" }}>
                    <span className="orangeColor fontfamQB">Coinbaazar</span> is
                    the best marketplace around the Globe to buy and sell,
                    products and services, with{" "}
                    <span className="orangeColor fontfamQB">Bitcoin</span>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="giftImg">
                  <img
                    src={require("../assets/newImages/woman-doing-virtual-shopping.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="goodsLayer">
          <div className="container1440">
            <div className="refHeader">
              <h2 className="goodsTitle">Now bring a change in your life</h2>
            </div>
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="width85">
                  <img
                    src={require("../assets/newImages/online-shopping-discount.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text2">
                  {/* <h2 className="header2 fontfamTM">Download Google Authenticator App </h2> */}
                  {/* <h2 className="bnkImgTitle" style={{textAlign:"left"}}>Always Ensure to</h2> */}
                  <p className="font16 txtJus fontfamQM">
                    Start accepting{" "}
                    <span className="orangeColor fontfamQB">
                      Bitcoin payments
                    </span>{" "}
                    worldwide by selling your products such as electronics,
                    household items, clothing, and possibly anything, and expand
                    your horizons
                  </p>
                  <p className="font16 txtJus fontfamQM">
                    If you are a professional in any field, be it social media
                    influencer such as Youtube, Instagram, or Twitter, or you
                    are into providing other online services such as developing
                    websites, content writer, designer, video making and much
                    more, you can always accept bitcoin as payment method.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="text2">
                  <p
                    className="dBlueColor fontfamTM font18"
                    style={{ textAlign: "left" }}
                  >
                    Ways in which you can accept Bitcoin Payments
                  </p>
                  <p className="font16 txtJus fontfamQM">
                    One way is to create offers/ads on Coinbaazar from one to
                    many depending on the number of products and services you
                    want to sell. This way you are protected by our escrow
                    service..
                  </p>
                  <p className="font16 txtJus fontfamQM">
                    The other way is to create an invoice and share it directly
                    with your clients via WhatsApp, Telegram, or any other
                    sharing app. So that they can send bitcoin payment in your
                    wallet by scanning the QR code. The invoice contains all the
                    details.
                    <br />
                    <span className="orangeColor">
                      Note: This is an off escrow/direct payment wherein you
                      accept bitcoins directly from your clients
                    </span>
                  </p>
                  <p className="font16 txtJus fontfamQM">
                    Once your client makes the payment, they click on the paid
                    button, and afterwards both the buyer and seller can
                    download the receipt for future reference.
                    <br />
                    <span className="orangeColor">
                      Note: Each invoice expires in 15 mins, ensure your client
                      pays in time or else you will have to create a new invoice
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="goodsImg">
                  <img
                    src={require("../assets/newImages/woman-working-on-laptop-sitting-at-desks-with-cup-work.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
