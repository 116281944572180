import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class BankTransfer extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text2">
                  <p className="font18" style={{ color: "black" }}>
                    Trade Bitcoins in simplest and secure way on{" "}
                    <span className="orangeColor fontfamQB">Coinbaazar</span>{" "}
                    with bank transfers and Other online services, Find the most
                    attractive deals to Buy and Sell Bitcoins instantly.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="giftImg">
                  <img
                    src={require("../assets/newImages/online-money-transfer.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="refHeader">
              <h2 className="giftPad2">
                <span className="orangeColor fontfamQB">Coinbaazar</span> escrow
                system provides the safety of funds for both buyer and seller
              </h2>
            </div>
          </div>
        </section>
        <section
          className="pb30"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="text2" style={{ color: "var(--text-grey)" }}>
                  {/* <h2 className="header2 fontfamTM">Download Google Authenticator App </h2> */}
                  <span className="font16 txtJus">
                    Be a vendor or find an offer for buying or selling Bitcoins
                    Globally with{" "}
                    <span className="orangeColor fontfamQB">
                      Payment Methods
                    </span>{" "}
                    such as Bank Transfer, Cash App, Zelle, Paypal, GooglePay,
                    Mobile Money, Vinmo, Paytm, Remitly, WeChat Pay and Many
                    More..
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="width75">
                  <img
                    src={require("../assets/newImages/Untitled.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bankLayer"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="refHeader">
              <h2 className="giftPad2">
                Lowest Transaction Fee for Advertisers{" "}
                <span className="orangeColor fontfamQB">|</span> No Fee For
                Traders
              </h2>
            </div>
            <div className="centerSection">
              <div className="bankImg">
                <img
                  src={require("../assets/newImages/man-transfering-bitcoin-using-application.png")}
                  alt=""
                />
              </div>

              <h2 className="bnkImgTitle">Increase your Savings</h2>

              <div className="bnkTxt" style={{ color: "black" }}>
                <p style={{ color: "var(--text-grey)" }}>
                  Negotiate the price with Vendors on trade chat window
                </p>
              </div>
              <div className="bnkTxt" style={{ color: "black" }}>
                <p style={{ color: "var(--text-grey)" }}>
                  Professional Dispute Resolution in case of any issue while in
                  a trade
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440 pb20">
            <div className="row">
              <div className="col-md-12">
                <h4 className="header2 fontsize fontfamTM text-center">
                  For any help/assistance, talk to our Live support agents
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
