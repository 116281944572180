import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import $ from "jquery";
/*      Modules       */
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "rc-pagination";
import { CSVLink } from "react-csv";

/* date picker */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
let data2 = [];
export default class HistoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "receive-btc",
      loading: false,
      result: "",
      qrCodeUrlAddress: "",
      Address: "",
      created_at: "",
      total: "",
      BTCAddress: "",
      BTCAmount: "",
      Description: "",
      Amount: "",
      currency: "INR",
      BTCError: "",
      BTCAmountError: "",
      G2FA: false,
      auth_code: "",
      addr: "",
      fromdate: "",
      startDate: new Date(),
      utcZone: "Europe/London",
      TotalPages: 1,
      currentPage: 1,
      transaction_H: true,
    };
  }

  componentDidMount() {
    let getUtc = localStorage.getItem("_utc_");
    if (getUtc == undefined || getUtc == "") {
      this.setState({ utcZone: this.state.utcZone });
    } else {
      this.setState({ utcZone: getUtc });
    }

    // use socket to resmove this bug
    // window.location.reload()

    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    let selectCoin = localStorage.getItem("selectCurrency");
    if (selectCoin == "btc") {
      this.setState({ transaction_H: true });
    } else {
      this.setState({ transaction_H: false });
    }
    setTimeout(() => {
      this.setState({ _id: id, token: token, addr: addr }, () =>
        this._loadData()
      );
    }, 250);
    setTimeout(() => {
      Api("user/getUser", "GET", {}, this.state.token, this.state._id).then(
        (resp) => {
          try {
            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");
                this.props.history.push("/");
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              }
            }
          } catch (ex) {}
        }
      );
    }, 100);
  }
  handleChange1 = (date) => {
    this.setState({
      startDate1: date,
    });
  };
  handleChange2 = (date) => {
    this.setState({
      startDate2: date,
    });
  };
  openWeb = (e) => {
    e.preventDefault();
    // alert(e.target.id)
    window.open(
      `https://live.blockcypher.com/btc-testnet/tx/${e.target.id}/`,
      "_blank"
    );
  };
  openMGTWeb = (e) => {
    e.preventDefault();

    // window.open(`https://live.blockcypher.com/btc-testnet/btc/tx/${e.target.id}`, '_blank')
    window.open(`https://explore.magnetocoin.com/tx/${e.target.id}`, "_blank");
  };

  historyData = (FromDate, ToDate, page) => {
    var pageToOpen = 1;
    if (page != undefined) pageToOpen = page;
    console.log("rrrrrrPage to open====>", pageToOpen);
    var parameter = {
      startDate: FromDate ? FromDate : "",
      endDate: ToDate ? ToDate : "",
      userId: this.state._id,
      pageNumber: pageToOpen,
    };
    console.log("rrrrrrPage to params====>", parameter);
    //alert(ToDate)
    this.setState({ loading: true });
    Api(
      "user/transactionList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              whole_data1: resp.data.result.docs,
              TotalPages: resp.data.result.total,
              currentPage: resp.data.result.page,
            });
            console.log(
              "rrrrrrrrrrrrrrrrrrrrrest====>",
              this.state.TotalPages,
              this.state.currentPage
            );
          } else {
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };

  CSVDownload2 = (type) => {
    if (this.state.whole_data1.length == 0) {
      return;
    }

    let header2 = [
      { label: "#", key: "S" },
      { label: "Created At", key: "createdAt" },
      { label: "Transaction Id", key: "transaction_hash" },
      { label: "Description", key: "remark" },
      { label: "Send BTC", key: "send_amount" },
      { label: "Receive BTC", key: "recieve_amount" },
    ];
    data2 = [];

    for (let j = 0; j < this.state.whole_data1.length; j++) {
      var _a = {
        S: `${j + 1}`,
        createdAt: `${this.state.whole_data1[j]["created_At"]
          .replace("T", " ( ")
          .replace("Z", "")
          .split(".")[0] + " )"}`,
          transaction_hash:`${this.state.whole_data1[j]["transaction_hash"]!=undefined?this.state.whole_data1[j]["transaction_hash"]:"-"}`,
          remark: `${this.state.whole_data1[j]["remark"]!=undefined?this.state.whole_data1[j]["remark"]:"-"}`,
          send_amount: `${this.state.whole_data1[j]["send_amount"]!=undefined?this.state.whole_data1[j]["send_amount"]:"-"}`,
          recieve_amount: `${this.state.whole_data1[j]["recieve_amount"]!=undefined?this.state.whole_data1[j]["recieve_amount"]:"-"}`,
      };

      data2.push(_a);
    }
  };

  populateView1 = () => {
    this.CSVDownload2();
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (var i = 0; i < this.state.whole_data1.length; i++) {
      if (this.state.whole_data1[i]["transaction_hash"] == "") {
        continue;
      }
      // alert()
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          this.state.whole_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width">{`${new Date(
                  this.state.whole_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 1) {
          this.state.whole_data1[i]["transaction_hash"] !== undefined
            ? children.push(
                <td>
                  <a
                    className="anchor-styling"
                    id={`${this.state.whole_data1[i]["transaction_hash"]}`}
                    onClick={(e) => this.openWeb(e)}
                  >{`${this.state.whole_data1[i]["transaction_hash"]}`}</a>
                </td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 2) {
          this.state.whole_data1[i]["remark"] !== undefined
            ? children.push(<td>{`${this.state.whole_data1[i]["remark"]}`}</td>)
            : children.push(<td>{null}</td>);
        } else if (j == 3) {
          this.state.whole_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 4) {
          this.state.whole_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_data1[i]["recieve_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };

  //mgt---------------

  MGT_historyData = (FromDate, ToDate, page) => {
    //this.setState({ loading: true })
    Api(
      "user/mgt_transactionList",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      console.log("MGT history============>>>", resp);
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            // console.log("mgt_json======>"+JSON.stringify(resp.data.result))
            this.setState({ whole_MGT_data1: resp.data.result });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  populateMGT = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_MGT_data1.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_MGT_data1.length < 5
        ? this.state.whole_MGT_data1.length
        : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        // alert(' j is here',j)
        if (j == 0) {
          this.state.whole_MGT_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width">{`${new Date(
                  this.state.whole_MGT_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              )
            : //  children.push(<td className="DtaeField-width">{`${new Date(this.state.whole_MGT_data1[i]["createdAt"]).toLocaleString("en-US", {timeZone: this.state.utcZone})}`}</td>)
              children.push(<td>{null}</td>);
        } else if (j == 1) {
          this.state.whole_MGT_data1[i]["transaction_hash"] !== undefined
            ? children.push(
                <td>
                  <a
                    className="anchor-styling"
                    id={`${this.state.whole_MGT_data1[i]["transaction_hash"]}`}
                    onClick={(e) => this.openMGTWeb(e)}
                  >{`${this.state.whole_MGT_data1[i]["transaction_hash"]}`}</a>
                </td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 2) {
          this.state.whole_MGT_data1[i]["remark"] !== undefined
            ? children.push(
                <td className="maxwidthField">{`${
                  this.state.whole_MGT_data1[i]["remark"]
                }`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 3) {
          this.state.whole_MGT_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 4) {
          this.state.whole_MGT_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["recieve_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populateMGTRecently = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_MGT_data1.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_MGT_data1.length < 5
        ? this.state.whole_MGT_data1.length
        : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          this.state.whole_MGT_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width">{`${new Date(
                  this.state.whole_MGT_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 1) {
          this.state.whole_MGT_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["recieve_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 2) {
          this.state.whole_MGT_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 3) {
          this.state.whole_MGT_data1[i]["toAddress"] !== undefined
            ? this.state.whole_MGT_data1[i]["toAddress"] !== null
              ? children.push(
                  <td>{`${this.state.whole_MGT_data1[i]["toAddress"]}`}</td>
                )
              : children.push(<td>{null}</td>)
            : children.push(<td>{null}</td>);
        } else if (j == 4) {
          this.state.whole_MGT_data1[i]["remark"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["remark"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 5) {
          this.state.whole_MGT_data1[i]["withdraw_fee"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["withdraw_fee"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };

  _loadData() {
    this.setState({ loading: true });

    var parameter = {
      _id: this.state._id,
    };

    Api(
      "user/userProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              total: resp.data.result.btc.total,
              created_at: resp.data.result.btc.addresses[0].created_at,
              Address: resp.data.result.btc.addresses[0].addr,
              qrCodeUrlAddress: resp.data.result.qrCodeUrlAddress,
            });
          } else toast_func("error", resp.data.responseMessage);
        } else
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.historyData();
    this.MGT_historyData();
  }

  setDate = (e) => {
    this.setState({ fromdate: $("#from").val() });
    if (e.target.id == "from") {
      var Fromdate_year = $("#from")
        .val()
        .split("/")[2];
      var Fromdate_month = $("#from")
        .val()
        .split("/")[0];
      var Fromdate_day = $("#from")
        .val()
        .split("/")[1];

      if ($("#from").val() == "" || $("#from").val() == undefined)
        this.setState({ FromDate: "" });
      else
        this.setState({
          FromDate:
            Fromdate_year +
            "-" +
            Fromdate_month +
            "-" +
            Fromdate_day +
            "T00:00:00.000Z",
        });
    } else if (e.target.id == "to") {
      var Todate_year = $("#to")
        .val()
        .split("/")[2];
      var Todate_month = $("#to")
        .val()
        .split("/")[0];
      var Todate_day = $("#to")
        .val()
        .split("/")[1];

      if ($("#to").val() == "" || $("#to").val() == undefined)
        this.setState({ ToDate: "" });
      else {
        this.setState({
          ToDate:
            Todate_year +
            "-" +
            Todate_month +
            "-" +
            Todate_day +
            "T23:59:00.000Z",
        });
      }
    }
  };

  fetchAmount() {
    var parameter = {
      localCurrency: this.state.currency,
      btc: this.state.BTCAmount,
    };
    Api(
      "user/priceEquation",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})
          this.setState({ Amount: resp.data.result["price"] });
        }
      }
    });
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}

        <ToastContainer />
        <Modal
          isOpen={this.state.G2FA}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
          }}
        >
          <div className="cardBox max-WT-550 center-box">
            <div className="sectionHead mb15 borderBottom pb10 text-center">
              <h2 className="textInitial">Verify Authenticator</h2>
            </div>
            <p>
              if you setup 2 step verification, you can use the google
              authenticator app to receive codes even if you don't have an
              internet connection or mobile service for trades & transactions
            </p>
            <form className="mb0">
              <div className="formBox  max-WT-400 center-box">
                <div className="form-group">
                  {/* <div className="max-WT-120 center-box">
                          <a> <img src={`${this.state.image}`} alt="Images" /></a>
                        </div> */}
                </div>

                <div className="form-group">
                  {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                  <label className="control-label">Authenticate code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="auth_code"
                    id="auth_cade"
                    maxLength={6}
                    value={this.state.auth_code}
                    onChange={this.handlecase}
                  />
                </div>
                {this.state.auth_codeError !== "" ? (
                  <label className="error">{this.state.auth_codeError}</label>
                ) : null}
                <button
                  type="submit"
                  onClick={(e) => this.verify(e)}
                  className="btn btnGreen p10 pb10 w-100"
                >
                  VERIFY
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle walletSection">
            <div className="container">
              <div
                id="tab3"
                className={`tab-pane  radius4 ${
                  this.state.tab === "transaction" ? "active" : ""
                } `}
              >
                <div className="txn_csv">
                <div className="mb20">
                  <h4 className="dBlueColor">Wallet Transactions</h4>
                  {/* <h2>Wallet Transactions</h2> */}
                </div>
                <div className="csv_b"> 
                <CSVLink
                        filename={"all_trades.csv"}
                        className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"
                        data={data2 || []}
                        headers={this.state.header1}
                      >
                        <i className="fas fa-download" />
                        CSV File
                      </CSVLink>
                      </div>
                </div>
                <div className="cardBox">
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap pt15">
                      <h4 className="mb15 font20">History</h4>
                      <div className="tableFilter2 ml-auto d-flex flex-wrap  align-items-center mb15">
                        <div className="datepickerField d-flex  align-items-center">
                          <label className="dBlueColor">From</label>
                          <DatePicker
                            className="form-control radius6"
                            selected={this.state.startDate1}
                            onChange={this.handleChange1}
                            placeholderText="mm/dd/yyyy"
                            maxDate={new Date()}
                          />
                        </div>
                        <div className="datepickerField d-flex align-items-center">
                          <label className="dBlueColor">To</label>
                          <DatePicker
                            className="form-control radius6"
                            selected={this.state.startDate2}
                            onChange={this.handleChange2}
                            placeholderText="mm/dd/yyyy"
                            minDate={this.state.startDate1}
                            maxDate={new Date()}
                          />
                        </div>

                        {/* <div className="datepickerField d-flex  align-items-center">
                                  <label>From</label>
                                  <input type="text" id="from" className="form-control datepicker" onChange={(e)=>{if($('#from').val() == "") this.setState({FromDate:""})}} onFocus={(e)=>this.setDate(e) } /> 
                                </div>
                                <div className="datepickerField d-flex align-items-center">
                                  <label>To</label>
                                  <input type="text" id="to" className="form-control datepicker" onChange={(e)=>{if($('#to').val() == "") this.setState({ToDate:""})}}  onFocus={(e)=>this.setDate(e)} />
                                </div> */}
                        {this.state.transaction_H == true ? (
                          <button
                            type="button"
                            className="btn btnGreen minWidth80"
                            onClick={() =>
                              this.historyData(
                                this.state.startDate1,
                                this.state.startDate2
                              )
                            }
                          >
                            Search
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btnGreen minWidth80"
                            onClick={() =>
                              this.MGT_historyData(
                                this.state.startDate1,
                                this.state.startDate2
                              )
                            }
                          >
                            Search
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="tableBoxBody">
                      <div className="table-responsive">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead>
                            {this.state.transaction_H == true ? (
                              <tr>
                                <th>Date</th>
                                <th>Txn. Id</th>
                                <th>Description</th>
                                <th>Sent BTC</th>
                                <th>Received BTC</th>
                              </tr>
                            ) : (
                              <tr>
                                <th>Date</th>
                                <th>Txn. Id</th>
                                <th>Description</th>
                                <th>Sent MGTC</th>
                                <th>Received MGTC</th>
                              </tr>
                            )}
                          </thead>
                          {this.state.transaction_H == true ? (
                            <tbody>
                              {this.state.whole_data1 != undefined
                                ? this.populateView1()
                                : null}
                              {/* <tr>
                                      <td>11/11/2018</td>
                                      <td></td>
                                      <td>.0025</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>11/11/2018</td>
                                      <td></td>
                                      <td>.0025</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>12/11/2018</td>
                                      <td></td>
                                      <td></td>
                                      <td>00.25</td>
                                    </tr> */}
                            </tbody>
                          ) : (
                            <tbody>
                              {this.state.whole_MGT_data1 != undefined
                                ? this.populateMGT()
                                : null}
                              {/* <tr>
                                      <td>11/11/2018</td>
                                      <td></td>
                                      <td>.0025</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>11/11/2018</td>
                                      <td></td>
                                      <td>.0025</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>12/11/2018</td>
                                      <td></td>
                                      <td></td>
                                      <td>00.25</td>
                                    </tr> */}
                            </tbody>
                          )}
                        </table>
                        {this.state.TotalPages ? (
                          this.state.TotalPages > 10 ? (
                            <Pagination
                              className="mt10"
                              defaultCurrent={1}
                              total={this.state.TotalPages}
                              onChange={(value) => {
                                this.setState({ currentPage: value });
                                this.historyData(
                                  this.state.startDate1,
                                  this.state.startDate2,
                                  value
                                );
                              }}
                            />
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* {this.state.whole_data1 != undefined ?
                           
                          <div className="globalPagination">
                            <ul className="pagination">
                              <li className="page-item">
                                <a className="page-link" href={"#1"} aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                  <span className="sr-only">Previous</span>
                                </a>
                              </li>
                              <li className="page-item"><a className="page-link" href={"#1"}>1</a></li>
                              <li className="page-item"><a className="page-link" href={"#2"}>2</a></li>
                              <li className="page-item">
                                <a className="page-link" href={"#2"} aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                  <span className="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          :null
                          } */}
                </div>
              </div>

              {/* <!-- tab3 End --> */}

              {/* <!-- Tab-Content Start --> */}
            </div>
          </section>
        </main>

        {/* // <!-- createAccountSection Start --> */}
        {/* <section className="createAccountSection bgGreen">
          <div className="container-fluid">
            <div className="createAccountBox">
              <span className="wColor">Getting started is easy. Create your account now.</span>
              <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
            </div>
          </div>
        </section> */}
        <Footer {...this.props} />
      </div>
    );
  }
}
