import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class Security extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6 mobvw">
                <div className="text2">
                  <span className="header2 socailTxt fontfamTB">
                    Coinbaazar{" "}
                  </span>
                  <span className="font16" style={{ color: "black" }}>
                    is embedded with the latest security features, which you
                    must follow to ensure the safety of your account.
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="width85">
                  <img
                    src={require("../assets/newImages/security1.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="refHeader">
              <h2 className="txt2">Lets Get Started</h2>
            </div>
          </div>
        </section>
        <section
          className="pb30"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="text2">
                  <h2 className="header2 fontfamTM">
                    Download Google Authenticator App{" "}
                  </h2>
                  <span className="font16 txtJus">
                    Find security option on coinbaazar, click on button which
                    shows disabled authentication. Once you click, it will show
                    you a barcode which you can scan to activate your Google
                    Authenticator For assistance reach us on live chat
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mobImg">
                  <img
                    src={require("../assets/newImages/security2.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2 flexRev">
              <div className="col-md-6">
                <div className="refImgLft">
                  <img
                    src={require("../assets/newImages/security3.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="refTxt3">
                  <h2 className="header2 fontfamTB">2FA OTP Authentication</h2>
                  <span
                    className="font16"
                    style={{ color: "var(--text-grey)" }}
                  >
                    This security features can be enabled after you verify
                    mobile number from profile page. Once it is verified you can
                    enable this feature, which allows you to enter in your
                    account once you logout and login again with mobile OTP
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace">
              <div className="col-md-6">
                <div className="text2">
                  <h2 className="header2 fontfamTM">
                    Email OTP Authentication{" "}
                  </h2>
                  <span className="font16 txtJus">
                    This security features is self enabled,the moment you sign
                    up on coinbaazar platform.Everytime you send the bitcoins
                    out of your coinbaazar wallet, you will recieve to unique
                    OTP on your email to authenticate the outgoing
                    transaction.So always ensure to keep your email and account
                    secure.
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="refImg">
                  <img
                    src={require("../assets/newImages/security4.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2 flexRev">
              <div className="col-md-6">
                <div className="merImgLft">
                  <img
                    src={require("../assets/newImages/security5.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="refTxt3">
                  <h2 className="header2 fontfamTB">Login and Logout</h2>
                  <span className="font16 txtJus">
                    This security features allows the user to login from one
                    system or browser.If Coinbaazar system detects the same user
                    has been logged in from other system or browser, it will
                    logout the user from the previous system or browser.So
                    always ensure to keep your username and password secure.If
                    it's not you,please sign in again and change your password
                    immediately or contact coinbaazar support
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
