import React, { Component } from "react";
import Headers from "../components/header";
import Footer from "../components/footer";
import { Api, socket, getCurrency } from "../services/webservices";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";

export default class CheckBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "check",
      cardNumber: "",
      btcAmount: 0,
      price: 0.0,
      tab: "check",
      cardStatus: "unredeem",
    };
  }

  componentDidMount = async () => {
    var id = await localStorage.getItem("data");
    var token = await localStorage.getItem("token");
    await this.setState({
      _id: id,
      token: token,
    });
  };

  checkbalance = (e) => {
    e.preventDefault();
    if (this.state.cardNumber.length == 12) {
      var parameter = { giftCardCode: this.state.cardNumber.toUpperCase() };

      console.log(
        "=====parameter========>",
        "gift",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      );
      Api(
        "gift/check_balance",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((Response) => {
        console.log("=============>" + JSON.stringify(Response));
        if (Response.status == 200) {
          if (Response.status == 200) {
            if (Response.data.responseCode == 200 && Response.data.succ) {
              var amount = Response.data.succ.btcAmount;
              this.setState(
                {
                  btcAmount: amount,
                  cardStatus: Response.data.succ.cardStatus,
                },
                () => this.fetchAmount()
              );
              // toast_func("success", Response.data.responseMessage);
              console.log("=====>" + JSON.stringify(Response));
            } else {
              // toast_func("error", Response.data.message);
              console.log("=====>>>error", Response.data.message);
              this.setState({
                cardErr: "Please enter the correct gift card code",
              });
            }
          }
        }
      });
    } else {
      this.setState({
        cardErr: "Please enter the correct gift card code",
      });
    }
  };

  fetchAmount() {
    console.log("okay ----->", this.state.currency);
    var parameter = { localCurrency: "USD", btc: this.state.btcAmount };
    this.setState({ loader1: true });
    Api(
      "user/priceEquation",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });
      console.log("fatchAmount==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var num = Number(resp.data.result["price"]);
          var n = num.toFixed(2);
          // var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // var n2 = n.split(".")[1]
          // var price =n1+"."+n2
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})
          // console.log("working fine--->",price)
          this.setState({ price: n });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  }

  selecttab(e, selectTab) {
    console.log("=====parameter========>", e, selectTab);
  }
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Headers
          {...this.props}
          reload={true}
          parentCallback1={this.handleCallback}
        />
        <main className="mainContent">
          <section className="commonSectionStyle3">
            <div className="container1440">
              <div className="buySellBox2 pt0">
                <div className="sectionHead">
                  <h2 className="textInitial header2 mb30">
                    Crypto Gift Card for your Loved One's
                  </h2>
                  <div className="tabList2 mb30">
                    <div
                      className={`${
                        this.state.tab === "create" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "create");
                        this.props.history.push(`/create-gift-card`);
                      }}
                    >
                      <a>Create</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "check" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "check");
                        this.props.history.push(`/check-balance`);
                      }}
                    >
                      <a>Check balance</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "redeem" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "redeem");
                        this.props.history.push(`/redeem-card`);
                      }}
                    >
                      <a>Redeem</a>
                    </div>
                  </div>
                </div>
                <div className="cardHeader">
                  <h1>Check your Coinbaazar Gift Card Balance</h1>
                  {/* <p className="mb5 rcvCoin">Redeem your Code here</p> */}
                </div>
                <div className="max-WT-500 center-box">
                  <div className="balanceImg">
                    {/* <img src={require("../assets/newImages/checkBal.png")}></img> */}
                    <img
                      src={require("../assets/newImages/bitcoin-server.gif")}
                    />
                  </div>
                </div>
                <div className="cardBox p30 max-WT-500 center-box radius4 formBox">
                  <form className="mb0">
                    <div className="form-group">
                      <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                        Enter your Code
                      </h4>

                      <div className="w-100 align-items-center load-img">
                        <input
                          type="text"
                          className="form-control giftCd"
                          placeholder="Code #"
                          value={this.state.cardNumber}
                          onChange={(e) =>
                            this.setState({
                              cardNumber: e.target.value.toUpperCase(),
                              cardErr: "",
                            })
                          }
                          maxLength={12}
                          name="cardNumber"
                        />
                      </div>

                      {this.state.cardErr ? (
                        <label className="error">{this.state.cardErr}</label>
                      ) : null}
                      <div className="balnce">
                        {this.state.cardStatus == "redeemed" ? (
                          <div className="cardBal mt15 txtLeft">
                            Gift Card already redeemed.
                          </div>
                        ) : (
                          <div className="cardBal mt15 txtLeft">
                            BTC Amount: {this.state.btcAmount}
                          </div>
                        )}

                        <div className="cardBal mt15 txtLeft">
                          Amount: {this.state.price} USD
                        </div>
                      </div>
                      {/* <div className="cardBal mt15 txtLeft">This amount will be added to your wallet</div> */}
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={(e) => this.checkbalance(e)}
                        className="btn btnGrey w-100 pt10 pb10 radius10"
                      >
                        Check Balance
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
