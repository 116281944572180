import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { socket } from "../src/services/webservices";
import $ from "jquery";

import "./App.css";
import createHistory from "history/createBrowserHistory";
import Dashboard from "./screens/dashboard";
import Login from "./screens/login";
import Signup from "./screens/signup";
import ForgotPassword from "./screens/forgotpassword";
import Aboutus from "./screens/aboutus";
import BuyCoinDetail from "./screens/buycoindetail";
import Cointab from "./screens/cointab";
import Cointab1 from "./screens/cointab1";
import Contactus from "./screens/contactus";
import CreateAdvertise from "./screens/createadvertise";
import FAQ from "./screens/faq";
import Privacypolicy from "./screens/privacypolicy";
import SellBitcoinDetail from "./screens/sellbitcoindetail";
import Terms from "./screens/terms";
import Profile from "./screens/profile";
import EditProfile from "./screens/editProfile";
import Wallet from "./screens/wallet";
import enableAuthentication from "./screens/enableAuthentication";
import Authentication from "./screens/Authentication";
import ValidateWithdraw from "./screens/validateWithdraw";
// import Authentication1 from "./screens/Authentication1";
import TwofactorAuthentication from "./screens/twoFactorAuth";
import Resetpassword from "./screens/resetpassword";
import EmailVerification from "./screens/email-verification";
import ResetPassword from "./screens/reset-password";
import MainDashboard from "./screens/maindashboard";
import EditAdvertise from "./screens/editadvertise";
import HistoryList from "./screens/HistoryList";
import UserDetails from "./screens/user-details";
import SendBuyRequest from "./screens/send-buy-request";
import Feedback from "./screens/feedback";
import NotFound from "./screens/notFound";
import WalletAddress from "./screens/Wallet_Address";
import Merchant from "./screens/merchant";
import Referral from "./screens/referral";
import Security from "./screens/security";
import Socialmedia from "./screens/social_media";
import ReferFriend from "./screens/referFriend";
import GenerateInvoice from "./screens/generateInvoice";
import GiftCard from "./screens/giftcard";
import BankTransfer from "./screens/banktransfer";
import AltCoin from "./screens/altcoin";
import CashDeposit from "./screens/cashdeposit";
import GoodsAndServices from "./screens/goods&services";
import Salary from "./screens/salary";
import SalaryTransaction from "./screens/salary-transaction";
import CreateCard from "./screens/create-gift-card";
import RedeemCard from "./screens/redeem-card";
import CheckBalance from "./screens/check-balance";

/*              Sound effect            */

import Sound from "react-sound";

import { Api } from "./services/webservices";

const history = createHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0);
  // handleCallback = (childData) => {
  //   alert(childData);
  // };
});
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("data1") != null ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready_detail: false,
      token: "",
      _id: "",
      addr: "",
    };
    // status_ok:false,
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount = () => {
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      if (addr !== null) {
        this.setState({
          _id: id,
          token: token,
          addr: addr,
        });
      }
    }, 300);

    Api("admin/configData", "POST", {}, this.state.token, this.state._id).then(
      (resp) => {
        try {
          this.setState({
            loading: false,
          });
          if ((resp.status = 200)) {
            if (resp.data.Data.docs[0].maintainanceProcess) {
              this.setState({
                status_ok: false,
              });
            } else {
              this.setState({
                status_ok: true,
              });
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
        } catch (ex) {}
      }
    );

    // if( resp.data.Data.docs[0].maintainanceProcess === undefined || resp.data.Data.docs[0].maintainanceProcess  )
    // {
    //   this.setState({status_ok:false})
    // }
    // else{
    //   this.setState({status_ok:true})
    // }

    let arr = [
      "maindashboard",
      "reset-password",
      "email-verification",
      "resetpassword",
      "twofactor",
      "Authentication",
      "enableAuthentication",
      "Wallet",
      "Wallet_Address",
      "editprofile",
      "profile",
      "terms",
      "sellbitcoindetail",
      "privacypolicy",
      "faq",
      "create-offer",
      "contactus",
      "cointab",
      "buycoindetail",
      "login",
      "dashboard",
      "edit-offer",
      "HistoryList",
      "user-details",
      "send-buy-request",
      "feedback",
      "signup",
      "login",
      "forgotpassword",
      "aboutus",
      "contactus",
      "faq",
      "privacypolicy",
      "terms",
      "email-verification",
      "reset-password",
      "buy-bitcoins",
      "sell-bitcoins",
      "bitcoins",
      "buy-bitcoin-instantly",
      "sell-bitcoins-instantly",
      "merchant",
      "referral",
      "security",
      "social_media",
      "referFriend",
      "generateInvoice",
      "giftcard",
      "banktransfer",
      "altcoin",
      "cashdeposit",
      "goods&services",
      "salary",
      "salary-transaction",
      "create-gift-card",
      "redeem-card",
      "check-balance",
      "checkWithdraw",
    ];
    let index = arr.findIndex(
      (x) => x == history.location.pathname.split("/")[1]
    );
    if (index == -1) {
      this.setState({ ready_detail: true });
      history.push("/");
    }

    if (localStorage.getItem("data1") == null) {
      let arr = [
        "signup",
        "login",
        "referral",
        "merchant",
        "social_media",
        "security",
        "forgotpassword",
        "Wallet_Address",
        "generateInvoice",
        "aboutus",
        "contactus",
        "faq",
        "privacypolicy",
        "terms",
        "email-verification",
        "reset-password",
        "buy-bitcoins",
        "buy-bitcoin-instantly",
        "sell-bitcoins-instantly",
        "feedback",
        "giftcard",
        "banktransfer",
        "altcoin",
        "create-offer",
        "cashdeposit",
        "goods&services",
        "checkWithdraw",
      ];
      let index = arr.findIndex(
        (x) => x == history.location.pathname.split("/")[1]
      );
      if (index == -1) {
        history.push("/");
      } else {
        // if(arr[index] === "authentication"){ history.push("/login");}
        // else if (arr[index] === "emailVerification"){ history.push("/signup");}
        // else if(arr[index] === "resetPassword"){history.push("/resetPassword/Id")}
        // else {history.push("/"+arr[index]);}
      }
      this.setState({ ready_detail: true });
    } else {
      if (history.location.pathname.split("/")[1] == "NotFound") {
        this.setState({ ready_detail: true });
        history.push("/");
      } else
        this.setState({
          ready_detail: true,
        });
    }

    this.gettingUTCTimeZone();
    this.stabilishSocketConnection();
  };

  gettingUTCTimeZone() {
    if (localStorage.getItem("utcTimeZone") == null) {
      localStorage.setItem("utcTimeZone", "America/Antigua");
    } else {
      var zone = localStorage.getItem("utcTimeZone");
    }
  }
  stabilishSocketConnection = () => {
    var userID = localStorage.getItem("data");

    if (localStorage.getItem("data") !== null) {
      socket.on("connect", () => console.log(""));
      socket.emit("initChat", { userId: userID });
    } else {
      socket.on("connect", () => console.log(""));
    }
  };

  render() {
    return this.state.status_ok || this.state.status_ok === undefined ? (
      this.state.ready_detail ? (
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/bitcoins/:method" component={Dashboard} />
            <PrivateRoute path="/login" component={Login} />
            <Route exact path="/resetpassword" component={Resetpassword} />
            <PrivateRoute path="/signup" component={Signup} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route
              exact
              path="/email-verification/"
              component={EmailVerification}
            />
            <Route exact path="/aboutus" component={Aboutus} />
            <Route
              exact
              path="/buy-bitcoin-instantly/:method/:buy_id/"
              component={BuyCoinDetail}
            />
            <Route exact path="/cointab/:type" component={Cointab} />
            <Route exact path="/buy-bitcoins" component={Cointab1} />
            <Route exact path="/sell-bitcoins" component={Cointab1} />
            <Route exact path="/buy-bitcoins/:method" component={Cointab1} />
            <Route exact path="/sell-bitcoins/:method" component={Cointab1} />
            <Route exact path="/contactus" component={Contactus} />
            <Route exact path="/create-offer" component={CreateAdvertise} />
            <Route exact path="/edit-offer/:_id" component={EditAdvertise} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/privacypolicy" component={Privacypolicy} />
            <Route
              exact
              path="/sell-bitcoins-instantly/:method/:sell_id/"
              component={SellBitcoinDetail}
            />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/editprofile" component={EditProfile} />
            <Route exact path="/wallet" component={Wallet} />
            <Route
              exact
              path="/Wallet_Address/:coin/:userId"
              component={WalletAddress}
            />
            <Route exact path="/enableAuth" component={enableAuthentication} />
            <Route exact path="/authentication" component={Authentication} />
            <Route
              exact
              path="/twofactor"
              component={TwofactorAuthentication}
            />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/dashboard" component={MainDashboard} />
            <Route exact path="/HistoryList" component={HistoryList} />
            <Route exact path="/user-details/:_id" component={UserDetails} />
            <Route exact path="/Feedback/:userID" component={Feedback} />
            <Route
              exact
              path="/send-buy-request/:trade_id"
              component={SendBuyRequest}
            />
            <Route exact path="/notFound" component={NotFound} />
            <Route exact path="/merchant" component={Merchant} />
            <Route exact path="/referral" component={Referral} />
            <Route exact path="/security" component={Security} />
            <Route exact path="/social_media" component={Socialmedia} />
            <Route exact path="/referFriend" component={ReferFriend} />
            <Route
              exact
              path="/generateInvoice/:invoiceId"
              component={GenerateInvoice}
            />
            <Route exact path="/giftcard" component={GiftCard} />
            <Route exact path="/banktransfer" component={BankTransfer} />
            <Route exact path="/altcoin" component={AltCoin} />
            <Route exact path="/cashdeposit" component={CashDeposit} />
            <Route exact path="/goods&services" component={GoodsAndServices} />
            <Route exact path="/salary" component={Salary} />
            <Route
              exact
              path="/salary-transaction"
              component={SalaryTransaction}
            />
            <Route exact path="/create-gift-card" component={CreateCard} />
            <Route exact path="/redeem-card" component={RedeemCard} />
            <Route exact path="/check-balance" component={CheckBalance} />
            <Route
              exact
              path="/checkWithdraw/:withdrawId"
              component={ValidateWithdraw}
            />
          </Switch>
        </Router>
      ) : (
        ""
      )
    ) : (
      <NotFound />
    );
  }
}
