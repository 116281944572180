import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  validateUserName,
  validateEmail,
  validatePassword,
  validateMobileNo,
  validateConfirmPassword,
} from "../utils/validation";
import { Link } from "react-router-dom";
import { ReactModal } from "../services/globals";
import { Api } from "../services/webservices";
import Header from "../components/header";
import Footer from "../components/footer";
import Recaptcha from "react-recaptcha";
import _country from "../JSON/country.json";
import { Loader } from "react-overlay-loader";
import Fingerprint from "fingerprintjs";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import StopSignUp from "./stopSignUp";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: "",
      nameStatus: false,
      refferalError: "",
      refferalStatus: false,
      email: "",
      emailError: "",
      emailStatus: false,
      website: "",
      websiteError: "",
      websiteStatus: false,
      password: "",
      passwordError: "",
      passwordStatus: false,
      confirmPassword: "",
      confirmPasswordError: "",
      confirmPasswordStatus: false,
      mobileNo: "",
      mobileNoError: "",
      mobileNoStatus: false,
      countryCodeError: "",
      modalIsOpen: false,
      isChecked: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp1Status: false,
      otp2Status: false,
      otp3Status: false,
      otp4Status: false,
      _id: "",
      isVerified: null, // isVerified:null,
      result: "",
      countryCode: "",
      loading: false,
      isVerifiedError: "",
      captch_error: false,
      _countryCode: "",
      modalIsOpen1: false,
      message: "",
      otpMessage:
        " You have successfully signed up and confirmation link & otp has been sent to your registerd email ",
      isChecked2: false,
      acceptTPError: "",
      status_ok1: false,
      refreshed: true,
      userType: "user",
      isCompany: false,
      signUpOtp: true,
      togglePswrd: true,
      toggleCnfrmPswrd: true,
      refferalid: "",
      isRegister: false,
    };
  }

  UNSAFE_componentWillMount() {
    // var addr = localStorage.getItem("Address")
    // var id = localStorage.getItem("data")
    // var token = localStorage.getItem("token")
    // setTimeout(() => {
    //   if (addr !== null) {
    //     this.setState({ _id: id, token: token, addr: addr })
    //   }},300)

    Api("admin/configData", "POST", {}, this.state.token, this.state._id).then(
      (resp) => {
        console.log("pankaj ====>>>> ", resp);
        if (resp.data != undefined) {
          if (resp.data.Data != undefined) {
            if (resp.data.Data.docs[0].registrationOff) {
              this.setState({
                status_ok1: false,
                refreshed: false,
              });
            } else {
              this.setState({
                status_ok1: true,
                refreshed: false,
              });
            }
          }
        }
      }
    );
  }

  componentDidMount() {
    setTimeout(() => {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      ctx.beginPath();
      ctx.arc(45, 40, 60, 0, 2 * Math.PI);
      ctx.fillStyle = "#fff";
      ctx.fill();
      ctx.strokeStyle = "#fff";
      ctx.stroke();
      var canvas = document.getElementById("myCanvas"),
        context = canvas.getContext("2d");
      make_base();
      function make_base() {
        var base_image = new Image();
        base_image.src =
          "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
        //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
        //   base_image.src = '../assets/newImages/logo.png';
        base_image.onload = function() {
          context.drawImage(base_image, 10, 15, 80, 60);
        };
      }
    }, 1000);

    let rafrel = this.props.location.search;
    // let ref = queryString.parse(rafrel)

    let ref = new URLSearchParams(rafrel);
    var refrel = ref.get("referral");
    // console.log("==>>",)
    if (rafrel) {
      this.setState({ refferalid: refrel });
    }
    // else{
    //     this.setState({refferalid:refrel})
    // }

    window.scrollTo(0, 0);

    var id = localStorage.getItem("data");

    this.setState({ _id: id });
    fetch(
      "https://api.ipdata.co?api-key=d414acaf85b37373a2b36bbb52079ab7f86bdb6cc969b71fd9b31b56"
    )
      .then((resp) => resp.json())
      .then((result) => {
        this.setState({ result: result });
      });
  }
  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(
      "target name =>>>",
      e.target.name,
      "target value=>>>",
      e.target.value
    );
    this.setState({ [name]: value });

    if (name == "otp1") {
      this.setState({ [name]: value });
      if (!this.state.otp1Status) {
        if (!isNaN(value) && value != " ") {
          // this.setState({ [name]: value })
          this.setState({ otp1Status: true });
          this.secoundInput.focus();
        }
      } else {
        if (value == "") {
          // this.setState({ [name]: value })
          this.setState({ otp1Status: false });
          // this.secoundInput.focus()
        }
        this.firstInput.focus();
      }
    } else if (name == "otp2") {
      this.setState({ [name]: value });
      if (!this.state.otp2Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ otp2Status: true });
          this.thirdInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ otp2Status: false });
          //  this.thirdInput.focus()
        }
        this.firstInput.focus();
      }
    } else if (name == "otp3") {
      this.setState({ [name]: value });
      if (!this.state.otp3Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ otp3Status: true });
          this.fourthInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ otp3Status: false });
        }
        this.secoundInput.focus();
      }
    } else if (name == "otp4") {
      this.setState({ [name]: value });
      if (!this.state.otp4Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ otp4Status: true });
        }
      } else {
        if (value == "") {
          this.setState({ otp4Status: false });
        }
        this.thirdInput.focus();
      }
    }
  };
  Populate_codes_1 = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(
        <option>
          {_country[i].name + "      (" + _country[i].dial_code + ")"}
        </option>
      );
    }
    return __array;
  };
  Populate_codes_2 = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option>{_country[i].name}</option>);
    }
    return __array;
  };
  recapctchaLoaded() {}
  _search_1 = (e) => {
    e.preventDefault();
    var _value = e.target.value;
    var _index = _country.findIndex((x) => x.dial_code == value);

    // var value = value.substr(1, value.indexOf(' '));
    // var value=value.trim()
    var value = _value.split(" ");
    var _length = value.length;
    var _newvar = value[_length - 1];
    var _newvar1 = _newvar.trim();
    var _newvar2 = _newvar1.slice(1, _newvar1.length - 1);

    this.setState({
      _countryCode: _newvar2,
      countryCode: _value,
      countryCodeError: "",
    });
    // this.setState({ countryCode: value, countryCodeError: "", countryCodeName: _country[_index].name })
  };
  _search_2 = (e) => {
    e.preventDefault();
    var value = e.target.value;
    var _index = _country.findIndex((x) => x.name == value);

    this.setState({
      countryCode: value,
      countryCodeError: "",
      countryCode: _country[_index].dial_code,
    });
  };
  toggleBox() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  toggleBox1() {
    this.setState({ isChecked2: !this.state.isChecked2 }, () => {
      if (this.state.isChecked2) {
        this.setState({ acceptTPError: "" });
      }
    });
  }
  handleUserInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    console.log("=====>", value);
    this.setState({ [name]: value });
    this.validate(name, value);
    // if (name === 'mobileNo') {
    //     if (isNaN(value) != true) {
    //         this.setState({ [name]: value }, () => {
    //             this.validate(name, value)
    //         })
    //     }
    // }
  };
  handleRefrelInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (isNaN(value) != true) {
      // console.log("=============================",value)
      this.setState({ [name]: value }, () => {
        this.validate(name, value);
      });
    }
  };
  validate = (name, value) => {
    if (name === "name") {
      // console.log("iiiiii=====>",value)
      if (validateUserName(value).status) {
        this.setState({
          nameError: "",
          nameStatus: true,
        });
      } else {
        this.setState({
          nameStatus: false,
          nameError: validateUserName(this.state.name).error,
        });
      }
    }
    // else if (name === "refferalid") {
    //     if (validateEmail(this.state.refferalid).status) {
    //         this.setState({ refferalStatus: true, refferalError: "" })
    //     } else {
    //         this.setState({ refferalStatus: false, refferalError: validateEmail(this.state.refferalid).error })
    //     }
    // }
    else if (name === "email") {
      if (validateEmail(value).status) {
        this.setState({
          emailStatus: true,
          emailError: "",
        });
      } else {
        this.setState({
          emailStatus: false,
          emailError: validateEmail(this.state.email).error,
        });
      }
    } else if (name === "password") {
      if (validatePassword(value).status) {
        this.setState({
          passwordError: "",
          passwordStatus: true,
        });
      } else {
        this.setState({
          passwordError: validatePassword(this.state.password).error,
          passwordStatus: false,
        });
      }
    } else if (name === "confirmPassword") {
      if (value !== this.state.password) {
        this.setState({
          confirmPasswordError: "*Password does not match",
        });
      } else {
        this.setState({
          confirmPasswordError: "",
        });
      }
    } else if (name === "mobileNo") {
      if (validateMobileNo(value).status) {
        this.setState({
          mobileNoError: "",
          mobileNoStatus: true,
        });
      } else {
        this.setState({
          mobileNoError: validateMobileNo(this.state.mobileNo).error,
          mobileNoStatus: false,
        });
      }
    } else if (
      this.state.countryCode == "" ||
      this.state.countryCode == "Select Country Code"
    ) {
      this.setState({
        countryCodeError: "*Please enter valid country code",
      });
    } else if (this.state.isVerified != false) {
      // toast_func("failed", "errrrrrr")
    } else {
      this.setState({
        isVerifiedError: "*Please, verify you are not a robot",
      });
    }
  };
  _register = (e) => {
    e.preventDefault();
    this.setState({ isRegister: true });
    if (validateUserName(this.state.name).status === true) {
      this.setState({
        nameStatus: true,
        nameError: "",
      });
    } else {
      this.setState({
        nameError: validateUserName(this.state.name).error,
        isRegister: false,
      });
    }

    if (validateEmail(this.state.email).status === true) {
      this.setState({
        emailStatus: true,
        emailError: "",
      });
    } else {
      this.setState({
        emailError: validateEmail(this.state.email).error,
        isRegister: false,
      });
    }

    if (validatePassword(this.state.password).status === true) {
      this.setState({
        passwordStatus: true,
        passwordError: "",
      });
    } else {
      this.setState({
        passwordError: validatePassword(this.state.password).error,
        isRegister: false,
      });
    }

    if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        confirmPasswordError: "*Password does not match",
        isRegister: false,
      });
    } else {
      this.setState({ confirmPasswordError: "" });
    }

    if (this.state.isChecked) {
      if (
        this.state.countryCode === "" ||
        this.state.countryCode === "Select Country Code"
      ) {
        this.setState({
          countryCodeError: "*Please enter valid country code",
          isRegister: false,
        });
      } else {
        this.setState({
          countryCodeError: "",
          isRegister: false,
        });
      }

      if (validateMobileNo(this.state.mobileNo).status == true) {
        this.setState({
          mobileNoStatus: true,
          passwordError: "",
        });
      } else {
        this.setState({
          mobileNoError: validateMobileNo(this.state.mobileNo).error,
          isRegister: false,
        });
      }
    }

    if (this.state.isVerified != true) {
      this.setState({
        isVerifiedError: "*Please, verify you are not a robot.",
        isRegister: false,
      });
    } else {
      this.setState({ isVerifiedError: "" });
    }

    if (!this.state.isChecked2) {
      this.setState({
        acceptTPError: "* Please accept T&C and privacy policy",
        isRegister: false,
      });
      return;
    }

    setTimeout(() => {
      if (
        this.state.isVerified &&
        this.state.passwordStatus &&
        this.state.emailStatus &&
        this.state.nameStatus
      ) {
        if (this.state.isChecked) {
          if (this.state.mobileNoStatus && this.state.countryCodeError == "") {
            this._SignUp();
          }
        } else {
          this._SignUp();
        }
      }
    }, 100);
  };
  _SignUp = () => {
    this.setState({ loading: true });
    // setTimeout(() => {
    //     if (this.state.loading == true) {
    //         this.setState({ loading: false })
    //         setTimeout(() => {
    //             toast_func("error", "Unable to reach server please try again")
    //         }, 100);
    //     }
    // }, 10000);
    if (this.state.isChecked) {
      var parameter = {
        user_name: this.state.name,
        email: this.state.email,
        website: this.state.website,
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
        opt_key: this.state.isChecked ? "true" : "false",
        system_ip: this.state.result.ip,
        phone_number: this.state.mobileNo,
        country_code: this.state._countryCode,
        referred_by: this.state.refferalid,
      };
    } else {
      var parameter = {
        user_name: this.state.name,
        email: this.state.email,
        website: this.state.website,
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
        opt_key: this.state.isChecked ? "true" : "false",
        system_ip: this.state.result.ip,
        referred_by: this.state.refferalid,
      };
    }

    try {
      Api("user/signup", "POST", parameter).then((resp) => {
        console.log("check-==>>>>", resp);
        this.setState({ loading: false });
        if (resp.status == 200) {
          this.setState({ isRegister: false });
          if (resp.data.responseCode == 200) {
            //this.props.history.push("/")
            localStorage.setItem("data", resp.data.data._id);

            if (this.state.isChecked) {
              this.setState({
                modalIsOpen: false,
              });
            } else {
              this.setState({
                name: "",
                email: "",
                password: "",
                website: "",
                confirmPassword: "",
                mobileNo: "",
                countryCode: "",
              });
              toast_func("success", resp.data.responseMessage);
              // this.setState({ message: resp.data.responseMessage })
              setTimeout(() => {
                this.setState({
                  modalIsOpen1: true,
                });
                // this.props.history.push("/")
              }, 2500);
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          this.setState({ loading: false });
          toast_func(
            "error",
            "Some problem occured please contact the administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  };
  modalfunc = (e) => {
    e.preventDefault();
    setTimeout(() => {
      this.props.history.push("/");
    }, 1000);

    this.setState({ modalIsOpen1: false });
  };
  resentOTP() {
    var __id = localStorage.getItem("data");
    var parameter = { _id: __id };
    this.setState({ loading: true });
    Api("user/sent_phone_otp", "POST", parameter).then((resp) => {
      if (resp.status == 200) {
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }

  submitVerifyOtp = (e) => {
    e.preventDefault();
    var fingerprint = new Fingerprint().get();
    var searchUrl = localStorage.getItem("data");
    let OTP =
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;

    console.log("user-id==>>>>", localStorage.getItem("data"));
    this.setState({ modalIsOpen1: false });
    var parameter = { _id: searchUrl, browser_id: fingerprint, otp: OTP };
    Api("user/verify_email_via_otp", "POST", parameter).then((resp) => {
      console.log("otpRespppp--==>>>", resp);
      if (resp.status == 200) {
        // toast_func("success", resp.data.responseMessage)
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
          setTimeout(() => {
            this.props.history.push("/login");
          }, 3000);
          this.setState({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
          });
        } else if (resp.data.responseCode == 400) {
          this.props.history.push("NotFound");
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func("error", "Please contact to administritor ");
      }
    });
  };

  submitOtp = (e) => {
    e.preventDefault();
    if (
      this.state.otp1Status &&
      this.state.otp2Status &&
      this.state.otp3Status &&
      this.state.otp4Status
    ) {
      var id = localStorage.getItem("data");
      setTimeout(() => {
        var parameter = {
          _id: id,
          phoneOtp:
            this.state.otp1 +
            this.state.otp2 +
            this.state.otp3 +
            this.state.otp4,
        };
        this.setState({ loading: true });
        Api("user/verify", "POST", parameter).then((resp) => {
          this.setState({ loading: false });
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              this.setState({
                message: resp.data.responseMessage,
              });
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              setTimeout(() => {
                this.setState({
                  modalIsOpen1: true,
                  modalIsOpen: false,
                });
                // this.props.history.push("/")
              }, 2500);
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        });
      }, 200);
    } else {
      toast_func("error", "Please enter OTP");
    }
  };

  onClick_userDrop(e) {
    //alert(e.target.value)
    this.setState({ userType: e.target.value });
    if (e.target.value == "user")
      this.setState({
        isCompany: false,
      });
    else this.setState({ isCompany: true });
  }

  signUpOtp() {
    this.setState({ signUpOtp: false });
  }
  showPswrd(val) {
    if (val == "show") {
      this.setState({ togglePswrd: false });
      var x = document.getElementById("myPswrd");
      x.type = "text";
    } else {
      this.setState({ togglePswrd: true });
      var x = document.getElementById("myPswrd");
      x.type = "password";
    }
  }
  handleCnfrmPswrd(val) {
    if (val == "show") {
      this.setState({ toggleCnfrmPswrd: false });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "text";
    } else {
      this.setState({ toggleCnfrmPswrd: true });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "password";
    }
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    if (this.state.refreshed)
      return (
        <div className="loader">
          {" "}
          <img
            className=""
            style={{ height: 100, width: 100 }}
            src={require("../assets/lg.gif")}
          />{" "}
        </div>
      );
    return (
      <div>
        {/* <Header {...this.props} />
                {this.state.loading ? <div className='loader'> <img style={{ height: 100, width: 100 }} style={{ height: 100, width: 100 }} className="" src={require('../assets/lg.gif')} /> </div>
                    : null
                } */}
        <ReactModal visible={this.state.modalIsOpen1}>
          <div id="verificationModal" tabindex="-1" role="dialog">
            <div
              className="modal-dialog max-WT-500 verification_modal"
              role="document"
            >
              <form className="mb0">
                {this.state.signUpOtp ? (
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{this.state.otpMessage}</h5>
                      {/* <button onClick={() => this.setState({ modalIsOpen1: false })} type="button" className="close" data-dismiss="modal">&times;</button> */}
                    </div>

                    <div className="modal-footer">
                      <div className="w-100 text-center">
                        <span>VERIFY WITH CONFIRMATION LINK</span>
                      </div>
                    </div>
                    <div className=" or">
                      <label> OR </label>
                    </div>
                    <div
                      className="w-100 text-center "
                      onClick={() => this.signUpOtp()}
                    >
                      <button type="submit" className="btn btnGreen WT-80 mr15">
                        VERIFY WITH OTP
                      </button>
                    </div>
                    <span className="formLinksInfo mt10">
                      If you verified by link go to?
                      <Link to={"/login"}>
                        <u color="#30b5d1">Login</u>
                      </Link>
                    </span>
                  </div>
                ) : (
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">E-Mail verification</h4>
                      <button
                        onClick={() => this.setState({ signUpOtp: true })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                      <div className="w-100">
                        <div className="sectionHead text-center">
                          <h2>Enter Verification Code</h2>
                          <p>A text message with code was sent to your Email</p>
                        </div>
                        <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                          <input
                            type="text"
                            maxlength="1"
                            name="otp1"
                            value={this.state.otp1}
                            ref={(secondIndex) =>
                              (this.firstInput = secondIndex)
                            }
                            onChange={(e) => this.handleOtp(e)}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            name="otp2"
                            value={this.state.otp2}
                            ref={(thirdIndex) =>
                              (this.secoundInput = thirdIndex)
                            }
                            onChange={(e) => this.handleOtp(e)}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            name="otp3"
                            value={this.state.otp3}
                            ref={(fourthIndex) =>
                              (this.thirdInput = fourthIndex)
                            }
                            onChange={(e) => this.handleOtp(e)}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            name="otp4"
                            value={this.state.otp4}
                            ref={(fifthIndex) =>
                              (this.fourthInput = fifthIndex)
                            }
                            onChange={(e) => this.handleOtp(e)}
                            className="form-control radius6 text-center"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="pt10 w-100 text-right">
                        <button
                          type="submit"
                          onClick={(e) => this.submitVerifyOtp(e)}
                          className="btn btnGreen max-WT-120 mr15"
                        >
                          SUBMIT
                        </button>
                        <button
                          type="button"
                          onClick={this.resentOTP}
                          className="btn btnGreen max-WT-120 ml5"
                        >
                          RESEND
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </ReactModal>

        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle bgColour">
            <div className="container">
              {this.state.status_ok1 || this.state.status_ok1 === undefined ? (
                <div className="signupbox">
                  <div className="box_1">
                    <a href="/">
                      <canvas id="myCanvas" className="canvas" />
                    </a>
                    <div className="pdbox2">
                      <img src={require("../assets/newImages/signup.png")} />
                    </div>
                  </div>
                  <div className="box_2">
                    <form>
                      <div className="formBox max-WT-400 center-box">
                        <div>
                          <h2 className="header2">Register</h2>
                          <p
                            className="mb8"
                            style={{ color: "var(--text-grey)" }}
                          >
                            Create an Account and start using your Wallet.
                          </p>
                        </div>
                        <div className="form-group1">
                          <label>User</label>
                          <select
                            name="location"
                            value={this.state.userType}
                            onChange={(e) => this.onClick_userDrop(e)}
                            className="form-control select-style1 radius10"
                          >
                            <option value="user">Individual</option>
                            <option value="company">Company</option>
                          </select>
                        </div>

                        <div className="form-group1">
                          <label>Username</label>
                          <input
                            type="text"
                            value={this.state.name}
                            name="name"
                            maxLength={32}
                            onChange={this.handleUserInput}
                            className="form-control radius10"
                            placeholder={
                              this.state.isCompany
                                ? "Company Name"
                                : "User Name"
                            }
                            required
                          />
                          {this.state.nameError != "" ? (
                            <label className="error">
                              {this.state.nameError}
                            </label>
                          ) : null}
                        </div>

                        <div className="form-group1">
                          <label>Email ID</label>
                          <input
                            type="email"
                            value={this.state.email}
                            name="email"
                            onChange={this.handleUserInput}
                            className="form-control radius10"
                            placeholder={
                              this.state.isCompany
                                ? "Company Email"
                                : "Email ID"
                            }
                            required
                          />
                          {this.state.emailError != "" ? (
                            <label className="error">
                              {this.state.emailError}
                            </label>
                          ) : null}
                        </div>
                        {this.state.isCompany && (
                          <div className="form-group1">
                            <label>Website</label>
                            <input
                              type="text"
                              value={this.state.website}
                              name="website"
                              onChange={this.handleUserInput}
                              className="form-control radius10"
                              placeholder="Optional"
                              required
                            />
                            {this.state.websiteError != "" ? (
                              <label className="error">
                                {this.state.websiteError}
                              </label>
                            ) : null}
                          </div>
                        )}
                        <div className="form-group1 posRel">
                          <label>Password</label>
                          <input
                            type="password"
                            id="myPswrd"
                            value={this.state.password}
                            name="password"
                            onChange={this.handleUserInput}
                            className="form-control radius10"
                            placeholder="Password"
                            required
                          />
                          {this.state.togglePswrd == true ? (
                            <span
                              title="Hide"
                              className="fa fa-eye-slash"
                              onClick={() => this.showPswrd("show")}
                            />
                          ) : (
                            <span
                              title="Show"
                              className="fa fa-eye"
                              onClick={() => this.showPswrd("hide")}
                            />
                          )}

                          {this.state.passwordError != "" ? (
                            <label className="error">
                              {this.state.passwordError}
                            </label>
                          ) : null}
                        </div>
                        <div className="form-group1 posRel">
                          <label>Confirm</label>
                          <input
                            type="password"
                            id="cnfrmPswrd"
                            value={this.state.confirmPassword}
                            name="confirmPassword"
                            onChange={this.handleUserInput}
                            className="form-control radius10"
                            placeholder="Confirm Password"
                          />
                          {this.state.toggleCnfrmPswrd == true ? (
                            <span
                              title="Hide"
                              className="fa fa-eye-slash"
                              onClick={() => this.handleCnfrmPswrd("show")}
                            />
                          ) : (
                            <span
                              title="Show"
                              className="fa fa-eye"
                              onClick={() => this.handleCnfrmPswrd("hide")}
                            />
                          )}

                          {this.state.confirmPasswordError != "" ? (
                            <label className="error">
                              {this.state.confirmPasswordError}
                            </label>
                          ) : null}
                        </div>
                        <div className="form-group1">
                          <label>Referral ID </label>
                          <input
                            type="text"
                            value={this.state.refferalid}
                            name="refferalid"
                            maxLength={32}
                            onChange={this.handleUserInput}
                            className="form-control radius10"
                            placeholder="Optional"
                            required
                          />
                          {/* {this.state.refferalStatus != "" ?
                                                    <label className="error">{this.state.refferalStatus}</label>
                                                    : null} */}
                        </div>
                        {/* <div className="form-group1 checkbox-block clearfix mb5">
                                            <label className="checkbox-design">
                                                <input type="checkbox" name="" value={this.state.isChecked} onChange={() => this.toggleBox()} />
                                                <span>
                                                </span>Verify mobile number (Optional)
                                        </label>
                                        </div> */}
                        {this.state.isChecked ? (
                          <div>
                            <div className="form-group1">
                              <select
                                className="form-control select-style3 radius10"
                                value={this.state.countryCode}
                                onChange={(e) => this._search_1(e)}
                              >
                                <option>Select by Country Code</option>
                                {this.Populate_codes_1()}
                              </select>
                              {/*                                             
                                                    <div className=" col-md-6 sub-rowclass2">
                                                  <select className="form-control select-style3" value={this.state.countryCodeName} onChange={(e) => this._search_2(e)}>
                                                <option>Select by Country Name</option>
                                                {this.Populate_codes_2()}
                                            </select></div> */}

                              {this.state.countryCodeError != "" ? (
                                <label className="error">
                                  {this.state.countryCodeError}
                                </label>
                              ) : null}
                            </div>
                            <div className="form-group1">
                              <input
                                type="text"
                                inputMode="numeric"
                                maxLength="14"
                                value={this.state.mobileNo}
                                name="mobileNo"
                                onChange={this.handleUserInput}
                                className="form-control radius10"
                                placeholder="Mobile No."
                              />
                              {this.state.mobileNoError != "" ? (
                                <label className="error">
                                  {this.state.mobileNoError}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                        {/* <div className="form-group1 pl20">
                                                <label className="control-label  mb5 weight500">Please, verify you are not a robot</label>
                                                <div className="captchImg">
                                                    <Recaptcha
                                                        sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                                                        render="explicit"
                                                        onloadCallback={this.recapctchaLoaded}
                                                        verifyCallback={() => this.setState({ isVerified: true, isVerifiedError: "" })}
                                                    />
                                                    {this.state.isVerifiedError !== "" ?
                                                        <label className="error">{this.state.isVerifiedError}</label>
                                                        : null}
                                                </div>
                                            </div> */}
                        <div className="form-group1 captaMar">
                          {/* <label>Please, verify you are not a robot</label>    */}
                          <Recaptcha
                            sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                            render="explicit"
                            onloadCallback={this.recapctchaLoaded}
                            verifyCallback={() =>
                              this.setState({
                                isVerified: true,
                                isVerifiedError: "",
                              })
                            }
                          />
                          {this.state.isVerified != true ? (
                            <label className="error">
                              {this.state.isVerifiedError}
                            </label>
                          ) : null}
                        </div>
                        <div className="form-group1 d-flex mt15">
                          <div className="checkbox-design-block posRel">
                            <label
                              className="checkbox-design mb0 fontfamQR"
                              style={{ color: "var(--text-grey)" }}
                            >
                              <input
                                type="checkbox"
                                name="accept"
                                value={this.state.isChecked2}
                                onChange={() => this.toggleBox1()}
                              />
                              <span /> &nbsp; &nbsp; I accept the{" "}
                              <a href={`/terms`} target="_blank">
                                T&C
                              </a>{" "}
                              and{" "}
                              <a href={`/privacypolicy`} target="_blank">
                                Privacy policy
                              </a>
                            </label>
                          </div>
                        </div>

                        {/* <div className="form-group1 checkbox-block clearfix mb5">
                                               
                                                <label className="checkbox-design fontfamQR">
                                                    <input type="checkbox" name="" value={this.state.isChecked2} onChange={() => this.toggleBox1()} />
                                                    <span>
                                                    </span>I accept the <a href={`/terms`} target="_blank">T&C</a> and <a href={`/privacypolicy`} target="_blank"
                                                        >Privacy policy</a>
                                                </label>
                                            </div> */}
                        {this.state.acceptTPError !== "" ? (
                          <label className="error">
                            {this.state.acceptTPError}
                          </label>
                        ) : null}
                        <div className="text-center">
                          <button
                            type="submit"
                            onClick={(e) => this._register(e)}
                            disabled={this.state.isRegister}
                            className="btn btnGrey w-100 radius4"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                      <p className="formLinksInfo mt10">
                        Already have an account?
                        <Link to={"/login"}>Login</Link>
                      </p>
                    </form>
                  </div>
                </div> // <div className="cardBox max-WT-550 center-box">
              ) : (
                //     <div className="sectionHead borderBottom pb10 text-center">
                //         <h2>Register</h2>
                //         <p>Create an Account and start using your Wallet.</p>
                //     </div>

                //     <form className="w-100 main-form">
                //         <div className="formBox max-WT-400 center-box">
                //             <div className="form-group">
                //                 <select name="location" value={this.state.userType} onChange={(e) => this.onClick_userDrop(e)} className="form-control select-style1">
                //                     <option value="user">User</option>
                //                     <option value="company">Company</option>
                //                 </select>
                //             </div>

                //             <div className="form-group">
                //                 <input type="text" value={this.state.name} name="name" maxLength={32} onChange={this.handleUserInput}
                //                     className="form-control radius4" placeholder={this.state.isCompany ? "Company Name" : "User Name"} required></input>
                //                 {this.state.nameError != "" ?
                //                     <label className="error">{this.state.nameError}</label>
                //                     : null}
                //             </div>

                //             <div className="form-group">
                //                 <input type="email" value={this.state.email} name="email" onChange={this.handleUserInput}
                //                     className="form-control radius4" placeholder={this.state.isCompany ? "Company Email" : "Email ID"} required></input>
                //                 {this.state.emailError != "" ?
                //                     <label className="error">{this.state.emailError}</label>
                //                     : null}
                //             </div>
                //             {this.state.isCompany && (
                //                 <div className="form-group">
                //                     <input type="text" value={this.state.website} name="website" onChange={this.handleUserInput}
                //                         className="form-control radius4" placeholder="Website" required></input>
                //                     {this.state.websiteError != "" ?
                //                         <label className="error">{this.state.websiteError}</label>
                //                     : null}
                //                 </div>
                //             )}
                //             <div className="form-group">
                //                 <input type="password" value={this.state.password} name="password" onChange={this.handleUserInput}
                //                     className="form-control radius4" placeholder="Password" required></input>
                //                 {this.state.passwordError != "" ?
                //                     <label className="error">{this.state.passwordError}</label>
                //                     : null}
                //             </div>
                //             <div className="form-group">
                //                 <input type="password" value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleUserInput} className="form-control radius4" placeholder="Confirm Password" ></input>
                //                 {this.state.confirmPasswordError != "" ?
                //                     <label className="error">{this.state.confirmPasswordError}</label>
                //                     : null}
                //             </div>
                //             {/* <div className="form-group checkbox-block clearfix mb5">
                //             <label className="checkbox-design">
                //                 <input type="checkbox" name="" value={this.state.isChecked} onChange={() => this.toggleBox()} />
                //                 <span>
                //                 </span>Verify mobile number (Optional)
                //         </label>
                //         </div> */}
                //             {this.state.isChecked ?
                //                 <div>
                //                     <div className="form-group"
                //                     >

                //                         <select className="form-control select-style3" value={this.state.countryCode} onChange={(e) => this._search_1(e)}>
                //                             <option >Select by Country Code</option>
                //                             {this.Populate_codes_1()}
                //                         </select>
                //                         {/*
                //                     <div className=" col-md-6 sub-rowclass2">
                //                   <select className="form-control select-style3" value={this.state.countryCodeName} onChange={(e) => this._search_2(e)}>
                //                 <option>Select by Country Name</option>
                //                 {this.Populate_codes_2()}
                //             </select></div> */}

                //                         {this.state.countryCodeError != "" ?
                //                             <label className="error">{this.state.countryCodeError}</label>
                //                             : null}
                //                     </div>
                //                     <div className="form-group">
                //                         <input type="text" inputMode="numeric" maxLength="14" value={this.state.mobileNo} name="mobileNo" onChange={this.handleUserInput} className="form-control radius4" placeholder="Mobile No." ></input>
                //                         {this.state.mobileNoError != "" ?
                //                             <label className="error">{this.state.mobileNoError}</label>
                //                             : null}
                //                     </div>
                //                 </div>
                //                 :
                //                 null}
                //             <div className="form-group pl20">
                //                 <label className="control-label  mb5 weight500">Please, verify you are not a robot</label>
                //                 <div className="captchImg">
                //                     <Recaptcha
                //                         sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                //                         render="explicit"
                //                         onloadCallback={this.recapctchaLoaded}
                //                         verifyCallback={() => this.setState({ isVerified: true, isVerifiedError: "" })}
                //                     />
                //                     {this.state.isVerifiedError !== "" ?
                //                         <label className="error">{this.state.isVerifiedError}</label>
                //                         : null}
                //                 </div>
                //             </div>
                //             <div className="form-group checkbox-block clearfix mb5">
                //                 <label className="checkbox-design">
                //                     <input type="checkbox" name="" value={this.state.isChecked2} onChange={() => this.toggleBox1()} />
                //                     <span>
                //                     </span>I accept the <a href={`/terms`} target="_blank" className="anchor-styling">T&C</a> and <a href={`/privacypolicy`} target="_blank"
                //                         className="anchor-styling">Privacy policy</a>
                //                 </label>
                //             </div>
                //             {this.state.acceptTPError !== '' ?
                //                 <label className="error">{this.state.acceptTPError}</label>
                //                 : null}
                //             <div className="text-center">
                //                 <button type="submit" onClick={(e) => this._register(e)} className="btn btnGreen w-100 pt10 pb10 radius4">REGISTER</button>
                //             </div>
                //         </div>
                //         <p className="formLinksInfo mt15">Already have an account?<Link to={"/login"}>LOGIN</Link></p>
                //     </form>
                //  </div>
                <StopSignUp />
              )}
            </div>
            <ReactModal visible={this.state.modalIsOpen}>
              <div id="verificationModal" tabindex="-1" role="dialog">
                <div className="modal-dialog max-WT-500" role="document">
                  <form className="mb0">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">
                          Phone number verification
                        </h4>
                        <button
                          onClick={() =>
                            this.setState({
                              modalIsOpen: false,
                            })
                          }
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                        <div className="w-100">
                          <div className="sectionHead text-center">
                            <h2>Enter Verification Code</h2>
                            <p>
                              A text message with code was sent to your phone
                            </p>
                          </div>
                          <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                            <input
                              type="text"
                              name="otp1"
                              value={this.state.otp1}
                              ref={(secondIndex) =>
                                (this.firstInput = secondIndex)
                              }
                              onChange={this.handleOtp}
                              className="form-control radius6 text-center"
                            />
                            <input
                              type="text"
                              name="otp2"
                              value={this.state.otp2}
                              ref={(thirdIndex) =>
                                (this.secoundInput = thirdIndex)
                              }
                              onChange={this.handleOtp}
                              className="form-control radius6 text-center"
                            />
                            <input
                              type="text"
                              name="otp3"
                              value={this.state.otp3}
                              ref={(fourthIndex) =>
                                (this.thirdInput = fourthIndex)
                              }
                              onChange={this.handleOtp}
                              className="form-control radius6 text-center"
                            />
                            <input
                              type="text"
                              name="otp4"
                              value={this.state.otp4}
                              ref={(fifthIndex) =>
                                (this.fourthInput = fifthIndex)
                              }
                              onChange={this.handleOtp}
                              className="form-control radius6 text-center"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="pt10 w-100 text-right">
                          <button
                            type="submit"
                            onClick={(e) => this.submitOtp(e)}
                            className="btn btnGreen max-WT-120 mr15"
                          >
                            SUBMIT
                          </button>
                          <button
                            type="button"
                            onClick={this.resentOTP}
                            className="btn btnGreen max-WT-120 ml5"
                          >
                            RESEND
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ReactModal>
          </section>
          {/* <section className="createAccountSection bgGreen">
                        <div className="container-fluid">
                            <div className="createAccountBox">
                                <span className="wColor">Getting started is easy. Create your account now.</span>
                                <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                            </div>
                        </div>
                    </section> */}
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
