import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class CashDeposit extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text2">
                  <p className="font18" style={{ color: "black" }}>
                    On
                    <span className="orangeColor fontfamQB">
                      {" "}
                      Coinbaazar
                    </span>{" "}
                    you can buy and sell your bitcoins with cash deposit
                    services, such as Western Union, Moneygram, Xoom and many
                    other available in your region
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="giftImg">
                  <img
                    src={require("../assets/newImages/successful-investor (1).gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cashLayer">
          <div className="container1440">
            <div className="cashSection">
              <div className="bankImg">
                <img
                  src={require("../assets/newImages/man-withdrawing-money-from-smartphone.png")}
                  alt=""
                />
              </div>

              <div className="bnkTxt">
                <p>
                  Do not worry if you are not using your bank account or don't
                  have one, just create your offer or find a vendor at{" "}
                  <span className="orangeColor fontfamQB">Coinbaazar</span>. Our
                  escrow ensures the protection to both buyer and seller.
                </p>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="cashImg">
                  <img
                    src={require("../assets/newImages/man-using-atm-machine.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text2">
                  {/* <h2 className="header2 fontfamTM">Download Google Authenticator App </h2> */}
                  <h2 className="bnkImgTitle" style={{ textAlign: "left" }}>
                    Always Ensure
                  </h2>
                  <p className="font16 txtJus fontfamQM">
                    Verify all the details with the vendor that you require to
                    receive/transfer funds
                  </p>
                  <p className="font16 txtJus fontfamQM">
                    Make sure to keep/ask for a transfer receipt thereby enable
                    both parties to verify the transaction
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440 pb20">
            <div className="row">
              <div className="col-md-12">
                <div className="bnkTxt">
                  <span>
                    Professional Dispute Resolution in case of any issues
                  </span>
                </div>
                <h4 className="header2 fontsize fontfamTM text-center">
                  For any help/assistance, talk to our Live support agents
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
