import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import { toast_func } from "../components/toaster";
import { ToastContainer, toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import renderHTML from "react-render-html";
export default class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = { Address: "ffffffffffff", coinView: "" };
  }
  componentDidMount() {
    // console.log('param checking===>>>',this.props.match.params.userId)//
    this.getQrUrl();
  }

  getQrUrl = () => {
    //this.setState({ loading: true })
    let id = this.props.match.params.userId;
    let selectCoin = this.props.match.params.coin;

    console.log("=======>>>>>>>>>", typeof selectCoin);
    Api(`user/getWallet_address/${id}`, "GET", {}, "", id).then((resp) => {
      this.setState({ loading: false });
      console.log("or resp========t=====>>>>>>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          if (selectCoin == "btc") {
            this.setState({
              qrImageUrl: resp.data.data.qrCodeUrlAddress,
              walletAddress: resp.data.data.walletAddress,
              coinView: "btc",
            });
          } else {
            this.setState({
              walletAddress: resp.data.data.mgtAddress,
              qrImageUrl: resp.data.data.mgtWalletQR_code,
              coinView: "mgt",
            });
          }
        }
        //console.log(this.state.qrImageUrl)
      }
    });
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div className="swd">
        <div className="cardBox swd_main">
          <div className="center-box">
            <div className="swd_img">
              <Link to={"/"}>
                <img
                  className="swd_logo"
                  src={require("../assets/images/lOGO.jpg")}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="swd_qrImg mt10">
              <img className="swd_qr" src={this.state.qrImageUrl} />
            </div>
            <div className="coinAddress; text-align: center mt20 ">
              {!this.state.Address ? (
                <span>
                  <img
                    src={require("../assets/ajax-loader.gif")}
                    style={{
                      height: "25px",
                      width: "25px",
                      alignSelf: "center",
                    }}
                    alt=""
                  />
                </span>
              ) : null}
              <CopyToClipboard
                onCopy={() => this.setState({ copied: true })}
                text={this.state.walletAddress}
              >
                <a href="#">
                  <i className=" btn btnGreen  w-100 fas fa-copy ">
                    {" "}
                    {this.state.copied == true ? "Copied" : "Copy"}
                  </i>
                </a>
              </CopyToClipboard>
              {/* {this.state.copied == true ?
                    <text style={{ position: "absolute", right: 0, color: "green" }} className="error ">copied</text>
                    : null
                  } */}
            </div>
          </div>
          <div className="swd_lb">
            <span className="qrTitle">Scan this QR code to send</span>
            {this.state.coinView == "btc" ? (
              <p>bitcoins to coinbaazar wallet</p>
            ) : (
              <p>Magnetocoin to coinbaazar wallet</p>
            )}
          </div>
          <div className="appIcon w-100">
            <div className="smallAddressBox">
              <a
                href="https://play.google.com/store/apps/details?id=com.nobillio.coinbaazar"
                target="_blank"
              >
                <img
                  src={require("../assets/images/playIcon.png")}
                  alt="icon"
                />
              </a>
            </div>
            <div className="smallAddressBox ">
              <a
                href="https://apps.apple.com/in/app/coinbaazar-buy-sell-bitcoins/id1548921926"
                target="_blank"
              >
                <img
                  src={require("../assets/images/appleIcon.png")}
                  alt="icon"
                />
              </a>
            </div>
          </div>
          <div className="text w-100">
            <span>Download Mobile App</span>
          </div>
        </div>
      </div>
    );
  }
}
