import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import $ from "jquery";
import { ToastContainer } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
export default class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      auth_code: "",
      auth_codeError: "",
      activeState: null,
      SecretKey: "",
    };
  }
  componentDidMount() {
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      this.setState({ _id: id, token: token, addr: addr }, () =>
        this.loadData()
      );
    }, 300);
  }
  loadData = () => {
    var parameter = { _id: this.state._id };
    this.setState({ loading: true });
    try {
      Api(
        "user/userProfile",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        if (resp.status === 200) {
          if (resp.data.responseCode === 200) {
            this.setState({
              activeState: resp.data.result.googleTwofactorLink,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
        }
      });
    } catch (ex) {}
    this.authdata();
  };

  activate = (e) => {
    e.preventDefault();

    var _value = this.state.auth_code.trim();

    if (_value.length === 6) {
      if (this.state.activeState === true) {
        var parameter = {
          _id: this.state._id,
          removeAuth: true,
          otp: this.state.auth_code,
        };
      } else {
        var parameter = { _id: this.state._id, otp: this.state.auth_code };
      }

      this.setState({ loading: true });

      Api(
        "user/twoFactorOtpVerification",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              setTimeout(() => {
                this.props.history.push("/twofactor");
              }, 2500);
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    } else {
      this.setState({
        auth_codeError: "Please enter the valid token number",
      });
    }
  };
  handlecase = (e) => {
    let value = e.target.value;

    //alert(">>"+value+"<<")
    var _value = this.state.auth_code.trim();

    if (!isNaN(value) && value !== " ") {
      this.setState({ auth_code: value }, () => this.setdata());
    }
  };
  setdata = () => {
    var _value = this.state.auth_code.trim();

    if (_value.length !== 6) {
      this.setState({
        auth_codeError: "Please enter the valid token number",
      });
    } else {
      this.setState({ auth_codeError: "" });
    }
  };
  authdata = (e) => {
    //  e.preventDefault();
    var parameter = { _id: this.state._id };
    this.setState({ loading: true });
    Api(
      "user/enableGoogleTwoFactor",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status === 200) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode === 200) {
            this.setState({
              image: resp.data.data.qrCodeUrl,
              SecretKey: resp.data.data.secret,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
        } else {
          toast_func("success", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  };
  stateApis = () => {
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    if (this.state.activeState == false) {
      return (
        <div>
          <Header {...this.props} parentCallback1={this.handleCallback} />
          <ToastContainer />
          {this.state.loading ? (
            <div className="loader">
              {" "}
              <img
                className=""
                style={{ height: 100, width: 100 }}
                src={require("../assets/lg.gif")}
              />{" "}
            </div>
          ) : null}

          <main className="mainContent">
            <section className="staticContentSection commonSectionStyle">
              <div className="container1440">
                <div className="cardBox max-WT-550 center-box">
                  <div className="cardHead mb15 pb10 text-center">
                    <h4 className="font18 mt0 mb5 text-center">
                      Enable Authenticator
                    </h4>
                  </div>
                  <p className="rcvCoin pl15 pr15">
                    If you setup 2 step verification, you can use the google
                    authenticator app to receive codes even if you don't have an
                    internet connection or mobile service for trades &
                    transactions
                  </p>
                  <form className="mb0">
                    <div className="formBox  max-WT-400 center-box">
                      <div className="form-group">
                        <div className="max-WT-120 center-box">
                          <a>
                            {" "}
                            <img src={`${this.state.image}`} alt="Images" />
                          </a>
                        </div>
                      </div>

                      <div className="form-group">
                        <p>SecretKey: {this.state.SecretKey}</p>
                        <label className="control-label">
                          Authenticate code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="auth_code"
                          id="auth_cade"
                          maxLength={6}
                          value={this.state.auth_code}
                          onChange={this.handlecase}
                        />
                      </div>
                      {this.state.auth_codeError !== "" ? (
                        <label className="error">
                          {this.state.auth_codeError}
                        </label>
                      ) : null}
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={(e) => this.activate(e)}
                          className="btn btnGreen w-100 radius4 mb20"
                        >
                          Activate
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
            {/* <!-- createAccountSection Start --> */}
            {/* <section className="createAccountSection bgGreen">
               <div className="container-fluid">
                  <div className="createAccountBox">
                     <span className="wColor">Getting started is easy. Create your account now.</span>
                     <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                  </div>
               </div>
            </section> */}
          </main>
          <Footer {...this.props} />
        </div>
      );
    } else if (this.state.activeState == true) {
      return (
        <div>
          <Header {...this.props} parentCallback1={this.handleCallback} />
          <ToastContainer />
          {this.state.loading ? (
            <div className="loader">
              {" "}
              <img
                className=""
                style={{ height: 100, width: 100 }}
                src={require("../assets/lg.gif")}
              />{" "}
            </div>
          ) : null}

          <main className="mainContent">
            <section className="staticContentSection commonSectionStyle">
              <div className="container1440">
                <div className="cardBox max-WT-550 center-box">
                  <div className="cardHead mb15 pb10 text-center">
                    <h4 className="font18 mt0 mb5 text-center">
                      Disable Authenticator
                    </h4>
                    {/* <h2 className="textInitial">Disable Authenticator</h2> */}
                  </div>
                  <p className="rcvCoin pl15 pr15">
                    If you setup 2 step verification, you can use the google
                    authenticator app to receive codes even if you don't have an
                    internet connection or mobile service for trades &
                    transactions
                  </p>
                  <form className="mb0">
                    <div className="formBox  max-WT-400 center-box">
                      <div className="form-group">
                        <div className="max-WT-120 center-box" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Authenticate code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="auth_code"
                          id="auth_cade"
                          maxLength={6}
                          value={this.state.auth_code}
                          onChange={this.handlecase}
                        />
                      </div>
                      {this.state.auth_codeError !== "" ? (
                        <label className="error">
                          {this.state.auth_codeError}
                        </label>
                      ) : null}
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={(e) => this.activate(e)}
                          className="btn btnGreen w-100 radius4 mb20"
                        >
                          Deactivate
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </main>
          <Footer {...this.props} />
        </div>
      );
    } else {
      return <div />;
    }
  }
}
