import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class GiftCard extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text4">
                  <span className="header2 socailTxt fontfamTM">
                    Gift card with Bitcoin{" "}
                  </span>
                  <p className="font18 fontfamQM">
                    Exchange any gift card across the globe within minutes at
                    coinbaazar
                  </p>
                  <p className="font16">
                    <span className="orangeColor fontfamQB">Coinbaazar</span> is
                    the best marketplace to buy and sell Bitcoin in exchange of
                    gift card. If you have a bunch of Gift Cards that you aren’t
                    using, convert them instantly to Bitcoin to monetize and
                    maximize your profits. It’s simple and convenient, for both
                    Advertiser or Trader and works with almost all popular gift
                    cards including the one’s which are not so popular. Now is
                    the time to convert your unused Gift Cards to Bitcoin !
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="giftImg">
                  <img
                    src={require("../assets/newImages/gift-card.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="refHeader">
              <h2 className="giftPad">
                Here’s how to get started with Coinbaazar and buy Bitcoin
              </h2>
            </div>
          </div>
        </section>
        <section
          className="pb30"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row rowSpace2 flexRev">
              <div className="col-md-6">
                <div className="width85">
                  <img
                    src={require("../assets/newImages/woman-received-gift-card.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="refTxt3">
                  <h2 className="header2 fontfamTM">Gift Card Seller</h2>
                  <ul className="pl25 stepList mb0">
                    <li>Sign-Up</li>
                    <li>Locate a vendor</li>
                    <li>Read terms of trade</li>
                    <li>Begin the transaction</li>
                    <li>Make payment & get Btc</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="gftTxt">
              <p className="mb0">
                <span className="orangeColor fontfamQB">Advertisers</span> can
                create offers to buy gift cards with the
              </p>
              <p>
                <span className="orangeColor fontfamQB">Lowest Fee</span> of
                0.5% for any gift card
              </p>
            </div>
            <div className="gftTxt">
              <p>
                <span className="orangeColor fontfamQB">Traders</span> there is
                absolutely <span className="orangeColor fontfamQB">No Fee</span>{" "}
                for selling gift cards in exchange for bitcoins.
              </p>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="text2">
                  <h2 className="header2 fontfamTM">Gift Card Buyer</h2>
                  <ul className="pl25 stepList">
                    <li>Sign-Up</li>
                    <li>Create offer / advertisement</li>
                    <li>Click on transfer bond button on dashboard</li>
                    <li>Fund you bitcoin wallet</li>
                    <li>Enable your offer / advertisement</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="width80">
                  <img
                    src={require("../assets/newImages/woman-getting-attractive-cashback.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container1440">
            <div className="gftTxt2">
              <p>
                All that is left for you, is to start trading Gift Cards with
                Bitcoins, You can also watch our detailed video tour on{" "}
                <span className="orangeColor fontfamQB">
                  how to buy, sell or store Bitcoins on Coinbaazar
                </span>
              </p>
            </div>
            <div className="gftTxt2">
              <p>
                If you have any querries, please contact our support team by
                clicking the chat icon in the bottom right corner of the page.
                We’re available around the clock !!{" "}
              </p>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
