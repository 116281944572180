import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { Api } from "../services/webservices";
import renderHTML from "react-render-html";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
export default class FAQ extends Component {
  state = { loading: false };

  componentDidMount() {
    this.setState({ loading: true });
    var parameter = { type: "FAQ" };
    try {
      Api("static/staticContent", "POST", parameter).then((resp) => {
        if (resp.status == 200) {
          this.setState({ loading: false });
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
          if (resp.data.responseCode == 200) {
            let arrTrade = [];
            let arrSecurity = [];
            let arrFeedback = [];
            let arrTransfer = [];
            if (resp.data.obj["trade"].length > 0) {
              for (let i = 0; i < resp.data.obj["trade"].length; i++) {
                arrTrade.push(
                  <div className="card">
                    <div className="card-header" id={`heading1${i + 1}`}>
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#collapse1${i + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse1${i + 1}`}
                      >
                        {resp.data.obj["trade"][i]["question"]}
                      </button>
                      <span className="qno">
                        Q.
                        {i + 1}
                      </span>
                    </div>
                    <div
                      id={`collapse1${i + 1}`}
                      className="collapse"
                      aria-labelledby={`heading1${i + 1}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <label className="ans">Answer</label>
                        <p>
                          {renderHTML(`${resp.data.obj["trade"][i]["answer"]}`)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              this.setState({
                AllTrade: arrTrade,
              });
            }
            if (resp.data.obj["security"].length > 0) {
              for (let i = 0; i < resp.data.obj["security"].length; i++) {
                arrSecurity.push(
                  <div className="card">
                    <div className="card-header" id={`heading2${i + 1}`}>
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#collapse2${i + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse2${i + 1}`}
                      >
                        {resp.data.obj["security"][i]["question"]}
                      </button>
                      <span className="qno">
                        Q.
                        {i + 1}
                      </span>
                    </div>
                    <div
                      id={`collapse2${i + 1}`}
                      className="collapse"
                      aria-labelledby={`heading2${i + 1}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <label className="ans">Answer</label>
                        <p>
                          {renderHTML(
                            `${resp.data.obj["security"][i]["answer"]}`
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              this.setState({
                AllSecurity: arrSecurity,
              });
            }
            if (resp.data.obj["feedback"].length > 0) {
              for (let i = 0; i < resp.data.obj["feedback"].length; i++) {
                arrFeedback.push(
                  <div className="card">
                    <div className="card-header" id={`heading3${i + 1}`}>
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#collapse3${i + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse3${i + 1}`}
                      >
                        {resp.data.obj["feedback"][i]["question"]}
                      </button>
                      <span className="qno">
                        Q.
                        {i + 1}
                      </span>
                    </div>
                    <div
                      id={`collapse3${i + 1}`}
                      className="collapse"
                      aria-labelledby={`heading3${i + 1}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <label className="ans">Answer</label>
                        <p>
                          {renderHTML(
                            `${resp.data.obj["feedback"][i]["answer"]}`
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              this.setState({
                AllFeedback: arrFeedback,
              });
            }
            if (resp.data.obj["transfer"].length > 0) {
              for (let i = 0; i < resp.data.obj["transfer"].length; i++) {
                arrTransfer.push(
                  <div className="card">
                    <div className="card-header" id={`heading4${i + 1}`}>
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#collapse4${i + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse4${i + 1}`}
                      >
                        {resp.data.obj["transfer"][i]["question"]}
                      </button>
                      <span className="qno">
                        Q.
                        {i + 1}
                      </span>
                    </div>
                    <div
                      id={`collapse4${i + 1}`}
                      className="collapse"
                      aria-labelledby={`heading4${i + 1}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <label className="ans">Answer</label>
                        <p>
                          {renderHTML(
                            `${resp.data.obj["transfer"][i]["answer"]}`
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              this.setState({
                AllTransfer: arrTransfer,
              });
            }
          }
        }
      });
    } catch (ex) {}
  }

  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <ToastContainer />
        <Header {...this.props} parentCallback1={this.handleCallback} />

        <main className="mainContent">
          <section className="staticContentSection  buySellBox2 mb50">
            <div className="container1440">
              <div className="cardBox createAdv pb30">
                <div className="sectionHead">
                  <h2 className="font18 textInitial fontfamTB">
                    FREQUENTLY ASKED QUESTIONS
                  </h2>
                </div>

                <div className="accordianBox">
                  <h3 className="accordBoxHead">
                    <img
                      src={require("../assets/images/globe-icon.png")}
                      alt="icon"
                    />
                    Trade
                  </h3>
                  <div id="accordion" className="globalAccordian">
                    {this.state.AllTrade ? this.state.AllTrade : null}
                  </div>
                </div>

                <div className="accordianBox">
                  <h3 className="accordBoxHead">
                    <img
                      src={require("../assets/images/sheild-icon.png")}
                      alt="icon"
                    />
                    Security
                  </h3>
                  <div id="accordion2" className="globalAccordian">
                    {this.state.AllSecurity ? this.state.AllSecurity : null}
                  </div>
                </div>

                <div className="accordianBox">
                  <h3 className="accordBoxHead">
                    <img
                      src={require("../assets/images/feedback-icon.png")}
                      alt="icon"
                    />
                    Feedback
                  </h3>
                  <div id="accordion3" className="globalAccordian">
                    {this.state.AllTransfer ? this.state.AllTransfer : null}
                  </div>
                </div>

                <div className="accordianBox">
                  <h3 className="accordBoxHead">
                    <img
                      src={require("../assets/images/transfer-icon.png")}
                      alt="icon"
                    />
                    Transfer
                  </h3>
                  <div id="accordion4" className="globalAccordian">
                    {this.state.AllFeedback ? this.state.AllFeedback : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {isloggedIn !== "true" ? (
          <section className="mt35">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mar_auto">
                  <div className="createAccountBox">
                    <p className="">
                      Getting started is easy. Create your account now.
                    </p>
                    <Link to={"/signup"}>
                      <a className="btn btnBlack max-WT-190 wColor p10 pb10">
                        Create an Account Now
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <Footer {...this.props} />
      </div>
    );
  }
}
