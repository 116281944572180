import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = "" + parseFloat(navigator.appVersion);
var majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;

export default class TwofactorAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "two-factor",
      _id: "",
      TwofactorAuthentication: null,
      loginGuard: null,
      array_length: 0,
      system_ip: "",
      loading: false,
      _id: "",
      token: "",
      browser_details: "",
      majorVersion: "",
      GoogleTwofactorAuthentication: null,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp1Status: false,
      otp2Status: false,
      otp3Status: false,
      otp4Status: false,
      modalIsOpen: false,
      utcZone: "Europe/London",
      dataTime: new Date(),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    let getUtc = localStorage.getItem("_utc_");
    if (getUtc == undefined || getUtc == "") {
      this.setState({ utcZone: this.state.utcZone });
    } else {
      this.setState({ utcZone: getUtc });
    }

    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      this.setState({ _id: id, token: token, addr: addr }, () => this.submit());
    }, 1000);
    this.browserDetails();
    return fetch(
      "https://api.ipdata.co?api-key=58734e0865c4677d4c7b3d23728cc9aed0eb032d772006af3e3bae57"
    )
      .then((resp) => resp.json())
      .then((result) => {
        this.setState({ system_ip: result.ip });

        var progressbar = $("#progress_bar,#progress_bar2");
        var max = progressbar.attr("max");
        var time = (1000 / max) * 5;
        var value = progressbar.val();

        var loading = function() {
          value += 1;
          var addValue = progressbar.val(value);

          $(".progress-value").html(value + "%");
          var $ppc = $(".progress-pie-chart"),
            deg = (360 * value) / 100;
          if (value > 50) {
            $ppc.addClass("gt-50");
          }

          $(".ppc-progress-fill").css("transform", "rotate(" + deg + "deg)");
          $(".ppc-percents span").html(value + "%");

          if (value == max) {
            clearInterval(animate);
          }
        };

        var animate = setInterval(function() {
          loading();
        }, time);
      });
  }
  stateApis = () => {
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "otp1") {
      if (!this.state.otp1Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: true });
          this.secoundInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: false });
          // this.secoundInput.focus()
        }
      }
    } else if (name == "otp2") {
      if (!this.state.otp2Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: true });
          this.thirdInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: false });
          //  this.thirdInput.focus()
        }
      }
    } else if (name == "otp3") {
      if (!this.state.otp3Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: true });
          this.fourthInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: false });
          //this.fourthInput.focus()
        }
      }
    } else if (name == "otp4") {
      if (!this.state.otp4Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: true });
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: false });
        }
      }
    }
  };
  resentOTP() {
    var __id = localStorage.getItem("data");
    var parameter = {
      _id: __id,
    };
    this.setState({ loading: true });
    Api("user/sent_phone_otp", "POST", parameter).then((resp) => {
      if (resp.status == 200) {
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }
  submitOtp = (e) => {
    e.preventDefault();
    if (
      this.state.otp1Status &&
      this.state.otp2Status &&
      this.state.otp3Status &&
      this.state.otp4Status
    ) {
      var id = localStorage.getItem("data");

      setTimeout(() => {
        var parameter = {
          _id: id,
          phoneOtp:
            this.state.otp1 +
            this.state.otp2 +
            this.state.otp3 +
            this.state.otp4,
        };
        this.setState({ loading: true });
        Api("user/verify", "POST", parameter).then((resp) => {
          this.setState({ loading: false });
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              this.setState({ GoogleTwofactorAuthentication: true });
              this.setState({ modalIsOpen: false });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              toast_func("error", resp.data.responseMessage);
              this.setState({ modalIsOpen: false });
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        });
      }, 200);
    } else {
      toast_func("error", "Please enter OTP");
    }
  };
  browserDetails() {
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    this.setState({ browser_details: browserName });
    this.setState({ majorVersion: majorVersion });
    //        document.write(''
    //    +'Browser name  = '+browserName+'<br>'
    //    +'Full version  = '+fullVersion+'<br>'
    //    +'Major version = '+majorVersion+'<br>'
    //    +'navigator.appName = '+navigator.appName+'<br>'
    //    +'navigator.userAgent = '+navigator.userAgent+'<br>'
    //   )
  }
  polulate_view = () => {
    let table = [];
    // Outer loop to create parent
    for (let i = 0; i < this.state.array_length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < 3; j++) {
        if (j == 0) {
          // new Date(this.state.whole_data[i]["createdAt"]).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>)
          children.push(
            <td key={j}>{`${new Date(
              this.state.whole_data[i]["login_date"]
            ).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>
          );

          // children.push(<td>{`$  new Date(this.state.whole_data[i]["login_date"]).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>)

          // children.push(<td>{`${this.state.whole_data[i]["login_date"].replace("T", " --  ").replace("Z", "").split(".")[0]}`}</td>)
          // children.push(<td>{`${this.state.whole_data[i]["login_date"].replace("T", " --  ").replace("Z", "").split(".")[0]}`}</td>)
        } else if (j == 1) {
          children.push(
            <td key={j}>{`${this.state.whole_data[i]["browser"]}`}</td>
          );
        } else if (j == 2) {
          children.push(
            <td key={j}>{`${this.state.whole_data[i]["system_ip"].replace(
              "::ffff:",
              ""
            )}`}</td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  update_Auth = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    let type = e.target.name;
    if (type == "disable_Auth") {
      var parameter = {
        _id: this.state._id,
        two_factor_auth: "true",
        Two_FA_verification: "true",
      };
    } else if (type == "enable_Auth") {
      var parameter = {
        _id: this.state._id,
        two_factor_auth: "false",
        Two_FA_verification: "false",
      };
    }
    Api(
      "user/two_factor_auth",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      if ((resp.status = 200)) {
        if (
          resp.data.responseCode == 409 ||
          resp.data.responseCode == 410 ||
          resp.data.responseCode == 411
        ) {
          localStorage.removeItem("data");
          localStorage.removeItem("isLoggedin");
          localStorage.removeItem("data1");
          localStorage.removeItem("token");
          // toast_func("error", resp.data.responseMessage);
          // setTimeout(() => {
          this.props.history.push("/");
          // }, 2500);
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else if (resp.data.responseCode == 201) {
          toast_func("err", resp.data.responseMessage);
        } else if (resp.data.responseCode == 200) {
          this.setState({
            TwofactorAuthentication: resp.data.result.two_factor_auth,
          });
        }
      }
    });
  };
  update_Guard = (e) => {
    this.setState({ loading: true });
    let type = e.target.name;
    if (type == "disable_Auth") {
      var parameter = {
        _id: this.state._id,
        browser_id: this.state.system_ip,
        loginGuard: "true",
      };
    } else if (type == "enable_Auth") {
      var parameter = {
        _id: this.state._id,
        browser_id: this.state.system_ip,
        loginGuard: "false",
      };
    }
    Api(
      "user/loginGuard",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            this.setState({ loginGuard: resp.data.result.loginGuard });
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };

  submit() {
    this.setState({ loading: true });

    Api("user/getUser", "GET", {}, this.state.token, this.state._id).then(
      (resp) => {
        this.setState({ loading: false });
        try {
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              // localStorage.removeItem("data");
              // localStorage.removeItem("isLoggedin");
              // localStorage.removeItem("data1");
              // localStorage.removeItem("token");
              // this.props.history.push("/")
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            }
          } else {
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      }
    );

    var parameter = {
      _id: this.state._id,
    };
    this.setState({ loading: true });
    try {
      setTimeout(() => {
        Api(
          "user/userProfile",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          try {
            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");

                this.props.history.push("/");
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              } else if (resp.data.responseCode == 200) {
                var accCreated = new Date(resp.data.result.updatedAt);
                var accCreatedTimeStamp = accCreated.getTime();
                var getTS = accCreatedTimeStamp;
                var updatedCreatedAt = new Date(getTS);
                var dd = new Date(updatedCreatedAt).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                });
                this.setState({ dataTime: dd });

                var accCreatedDate =
                  updatedCreatedAt.getFullYear() +
                  "-" +
                  parseInt(updatedCreatedAt.getMonth() + 1) +
                  "-" +
                  updatedCreatedAt.getDate();
                var accCreatedTime =
                  updatedCreatedAt.getHours() +
                  ":" +
                  updatedCreatedAt.getMinutes() +
                  ":" +
                  updatedCreatedAt.getSeconds();

                this.setState({
                  accCreatedDate: accCreatedDate,
                  accCreatedTime: accCreatedTime,
                });

                this.setState({
                  TwofactorAuthentication: resp.data.result.two_factor_auth,
                  loginGuard: resp.data.result.loginGuard,
                  GoogleTwofactorAuthentication:
                    resp.data.result.googleTwofactorLink,
                });

                this.setState({
                  array_length: resp.data.result.login_history.length,
                  whole_data: resp.data.result.login_history,
                });
              }
            }
          } catch (ex) {
            toast_func(
              "error",
              "Unable to reach server please contact administrator"
            );
          }
        });
      }, 1000);
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }

    this.stateApis();
  }
  update_Auth1 = (e) => {
    e.preventDefault();
    this.props.history.push("/authentication");
  };
  update_Auth2 = (e) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />

        <ToastContainer />
        <Modal isOpen={this.state.modalIsOpen}>
          <ModalBody>
            <div id="verificationModal" tabindex="-1" role="dialog">
              <div className="modal-dialog max-WT-500" role="document">
                <form className="mb0">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Phone number verification</h4>
                      <button
                        onClick={() =>
                          this.setState({
                            modalIsOpen: false,
                          })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                      <div className="w-100">
                        <div className="sectionHead text-center">
                          <h2>Enter Verification Code</h2>
                          <p>A text message with code was sent to your phone</p>
                        </div>
                        <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                          <input
                            type="text"
                            name="otp1"
                            value={this.state.otp1}
                            ref={(secondIndex) =>
                              (this.firstInput = secondIndex)
                            }
                            onChange={this.handleOtp}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            name="otp2"
                            value={this.state.otp2}
                            ref={(thirdIndex) =>
                              (this.secoundInput = thirdIndex)
                            }
                            onChange={this.handleOtp}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            name="otp3"
                            value={this.state.otp3}
                            ref={(fourthIndex) =>
                              (this.thirdInput = fourthIndex)
                            }
                            onChange={this.handleOtp}
                            className="form-control radius6 text-center"
                          />
                          <input
                            type="text"
                            name="otp4"
                            value={this.state.otp4}
                            ref={(fifthIndex) =>
                              (this.fourthInput = fifthIndex)
                            }
                            onChange={this.handleOtp}
                            className="form-control radius6 text-center"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="pt10 w-100 text-right">
                        <button
                          type="submit"
                          onClick={(e) => this.submitOtp(e)}
                          className="btn btnGreen max-WT-120 mr15"
                        >
                          SUBMIT
                        </button>
                        <button
                          type="button"
                          onClick={this.resentOTP}
                          className="btn btnGreen max-WT-120 ml5"
                        >
                          RESEND
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle walletSection">
            {this.state.loading ? (
              <div className="loader">
                {" "}
                <img
                  className=""
                  style={{ height: 100, width: 100 }}
                  src={require("../assets/lg.gif")}
                />{" "}
              </div>
            ) : null}
            <div className="container">
              <div className="tabList3 mb30 mt30">
                <div
                  className={`${
                    this.state.tab === "two-factor" ? "taboxActive3" : "tabox3"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "two-factor" });
                  }}
                >
                  <a data-toggle="tab" href="#tab1">
                    Two-Factor Authentication
                  </a>
                </div>
                {/* <div className={`${this.state.tab === "login-guard" ? "taboxActive3 pad10" : "tabox3 pad10"}`} onClick={() => { this.setState({ tab: "login-guard" }) }}>
								<a  data-toggle="tab" href="#tab2">Login Guard</a>
								</div> */}
                <div
                  className={`${
                    this.state.tab === "login-history"
                      ? "taboxActive3 pad10"
                      : "tabox3 pad10"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "login-history" });
                  }}
                >
                  <a data-toggle="tab" href="#tab3">
                    Login History
                  </a>
                </div>
              </div>

              {/* <ul className="nav nav-tabs globalTab w-100  mb30" role="tablist">
                                <li className="nav-item max-WT-33Per">
                                    <a className={`nav-link ${this.state.tab === "two-factor" ? "active" : ""}`} data-toggle="tab" href="#tab1" onClick={() => { this.setState({ tab: "two-factor" }) }}>TWO-FACTOR AUTHENTICATION</a>
                                </li>
                                <li className="nav-item max-WT-33Per">
                                    <a className={`nav-link ${this.state.tab === "login-guard" ? "active" : ""}`} data-toggle="tab" href="#tab2" onClick={() => { this.setState({ tab: "login-guard" }) }}>LOGIN GUARD</a>
                                </li>
                                <li className="nav-item max-WT-33Per">
                                    <a className={`nav-link ${this.state.tab === "login-history" ? "active" : ""}`} data-toggle="tab" href="#tab3" onClick={() => { this.setState({ tab: "login-history" }) }}>LOGIN HISTORY</a>
                                </li>
                            </ul> */}

              <div className="tab-content mb15">
                {this.state.tab == "two-factor" ? (
                  <div
                    id="tab1"
                    className={`nav-link ${
                      this.state.tab === "two-factor" ? "active" : ""
                    }`}
                  >
                    <div className="row cardBox p30">
                      <div className="col-md-6  d-flex colSpace dBlock">
                        <p
                          className="font14 mar_auto2"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Mobile 2FA Authentication
                        </p>

                        <div className="">
                          {this.state.TwofactorAuthentication ? (
                            <button
                              type="button"
                              name="enable_Auth"
                              onClick={(e) => this.update_Auth(e)}
                              className="btn btnGreen  w-100 font16 weight500"
                            >
                              Enabled Authenticator
                            </button>
                          ) : (
                            <button
                              type="button"
                              name="disable_Auth"
                              onClick={(e) => this.update_Auth(e)}
                              className="btn btnRed w-100 font16 weight500"
                              style={{ color: "var(--text-grey)" }}
                            >
                              Disabled Authenticator
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6  d-flex colSpace dBlock">
                        <p
                          className="font14 mar_auto2"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Google 2FA Authentication
                        </p>

                        <div className="">
                          {this.state.GoogleTwofactorAuthentication ? (
                            <button
                              type="button"
                              name="enable_Auth"
                              onClick={(e) => this.update_Auth1(e)}
                              className="btn btnGreen  w-100 font16 weight500"
                            >
                              Enabled Authenticator
                            </button>
                          ) : (
                            <button
                              type="button"
                              name="disable_Auth"
                              onClick={(e) => this.update_Auth1(e)}
                              className="btn btnRed w-100 font16 weight500"
                            >
                              Disabled Authenticator
                            </button>
                          )}
                        </div>
                      </div>

                      {/* <div className="d-flex flex-wrap mb15">
                                                    <h4 className="mt0 font14 pr15">OTP Two-factor authentication is</h4>
                                                    <div className=" max-WT-300">
                                                        {
                                                            this.state.TwofactorAuthentication ?
                                                                <button type="button" name="enable_Auth" onClick={(e)=>this.update_Auth(e)} className="btn btnGreen  w-100 font16 weight500">ENABLED AUTHENTICATOR</button>
                                                                :
                                                                <button type="button" name="disable_Auth" onClick={(e)=>this.update_Auth(e)} className="btn btnRed w-100 font16 weight500">DISABLED AUTHENTICATOR</button>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap mb15">
                                                    <h4 className="mt0 font16 pr15">Google Two-factor authentication is</h4>
                                                    <div className=" max-WT-300">
                                                        {
                                                            this.state.GoogleTwofactorAuthentication ?
                                                                <button type="button" name="enable_Auth" onClick={(e)=>this.update_Auth1(e)} className="btn btnGreen  w-100 font16 weight500">ENABLED AUTHENTICATOR</button>
                                                                :
                                                                <button type="button" name="disable_Auth" onClick={(e)=>this.update_Auth1(e)} className="btn btnRed w-100 font16 weight500">DISABLED AUTHENTICATOR</button>
                                                        }

                                                    </div>
                                                </div> */}
                      {/* </div> */}
                      {/* <div className="col-md-5">
                                                <div id="pbar" className="progress-pie-chart" data-percent="0">
                                                    <div className="ppc-progress">
                                                        <div className="ppc-progress-fill"></div>
                                                    </div>
                                                    <div className="ppc-percents">
                                                        <div className="pcc-percents-wrapper">
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <progress id="progress_bar" value="0" max="99"></progress>
                                            </div> */}
                    </div>
                  </div>
                ) : this.state.tab == "login-guard" ? (
                  <div
                    id="tab2"
                    className={`nav-link ${
                      this.state.tab === "login-guard" ? "active" : ""
                    }`}
                  >
                    <h4 className="mt0 mb15 header2">Login Guard</h4>
                    <div className="row cardBox p30 rowMar">
                      <div className="col-md-7">
                        <div className="d-flex flex-wrap mb15">
                          <p className="mt0 font16 pr15">
                            Prevent logins from unauthorized browser:
                          </p>
                          <div className=" max-WT-300">
                            {this.state.loginGuard !== false ? (
                              <button
                                type="button"
                                name="enable_Auth"
                                onClick={this.update_Guard}
                                className="btn btnGreen w-100 font16 weight500"
                              >
                                Enabled Authenticator
                              </button>
                            ) : (
                              <button
                                type="button"
                                name="disable_Auth"
                                onClick={this.update_Guard}
                                className="btn btnRed  w-100 font16 weight500"
                              >
                                Disabled Authenticator
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div
                          id="pbar2"
                          className="progress-pie-chart"
                          data-percent="0"
                        >
                          <div className="ppc-progress">
                            <div className="ppc-progress-fill" />
                          </div>
                          <div className="ppc-percents">
                            <div className="pcc-percents-wrapper">
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                        <progress id="progress_bar2" value="0" max="99" />
                      </div>
                    </div>

                    <h4 className="mt15 mb15 header2">Authorized browser</h4>
                    <div className="tableBox">
                      <div className="tableBoxBody">
                        <div className="table-responsive tbl">
                          <table className="table  tableAlignMiddle globalTable2 minWidth600 tableLayoutFixed">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>User Agent</th>
                                {/* <th>Total Deposit Fee BTC</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* new Date(this.state.ClosedTradeList[i]["createdAt"]).toLocaleString("en-US", { timeZone: this.state.utcZone }) */}

                                {/* <td>{new Date(this.state.accCreatedDate.toLocaleString("en-US", { timeZone: this.state.utcZone }))} </td> */}
                                <td>{this.state.dataTime}</td>
                                <td>
                                  {this.state.browser_details} Version (
                                  {this.state.majorVersion})
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : this.state.tab == "login-history" ? (
                  <div
                    id="tab3"
                    className={`nav-link ${
                      this.state.tab === "tab-pane" ? "active" : ""
                    }`}
                  >
                    {/* <!-- tableBox Start --> */}
                    <div className="tableBox">
                      <div className="tableBoxBody">
                        <div className="table-responsive tbl">
                          <table className="table  tableAlignMiddle globalTable2 minWidth600 tableLayoutFixed tableonhover table-bordered">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Browser</th>
                                <th>IP Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.polulate_view()}
                              {/* <tr>
                                          <td>02/21/2018 13:34</td>
                                          <td>Successfully logged in</td>
                                          <td>111.91.225.14</td>
                                       </tr>
                                       <tr>
                                          <td>02/21/2018 13:34</td>
                                          <td>Successfully logged in</td>
                                          <td>111.91.225.14</td>
                                       </tr>
                                       <tr>
                                          <td>02/21/2018 13:34</td>
                                          <td>Successfully logged in</td>
                                          <td>111.91.225.14</td>
                                       </tr>
                                       <tr>
                                          <td>02/21/2018 13:34</td>
                                          <td>Successfully logged in</td>
                                          <td>111.91.225.14</td>
                                       </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <!-- tableBox End --> */}
                  </div>
                ) : null}
              </div>
              {/* <!-- Tab-Content Start --> */}
            </div>
          </section>
          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
                        <div className="container-fluid">
                            <div className="createAccountBox">
                                <span className="wColor">Getting started is easy. Create your account now.</span>
                                <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                            </div>
                        </div>
                    </section> */}

          {/* <!-- createAccountSection Start -->	    */}
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
