//Modules
import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { ReactModal } from "../services/globals";
import Fingerprint from "fingerprintjs";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
//Components
import {
  validateName,
  validateEmail,
  validatePassword,
  validateMobileNo,
  validateNameorEmail,
} from "../utils/validation";
import { Api, socket } from "../services/webservices";
import Header from "../components/header";
import Footer from "../components/footer";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

import { setData } from "../services/globals";

var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = "" + parseFloat(navigator.appVersion);
var majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;
// var loginCredentials1
// var loginCredentials2

const Greet = ({ name }) => <div>Hello {name}</div>;
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      emailStatus: false,
      password: "",
      passwordError: "",
      passwordStatus: false,
      // isVerified: null,
      isVerified: true,
      modalIsOpen: false,
      country_name: "",
      browserName: "",
      fingerprint: "",
      system_ip: "",
      loading: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp1Status: false,
      otp2Status: false,
      otp3Status: false,
      otp4Status: false,
      modalIsOpen1: false,
      modalIsOpen2: false,
      phoneVerified: false,
      isChecked: false,
      isTrue: false,
      togglePswrd: true,
    };
  }
  componentDidMount() {
    //console.log("dddd",this.refs.ref)
    // localStorage.setItem("loginCredentials1", this.state.email)
    // localStorage.setItem("loginCredentials2", this.state.password)
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(45, 40, 60, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    var canvas = document.getElementById("myCanvas"),
      context = canvas.getContext("2d");

    make_base();
    function make_base() {
      var base_image = new Image();
      base_image.src =
        "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
      //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
      //   base_image.src = '../assets/newImages/logo.png';
      base_image.onload = function() {
        context.drawImage(base_image, 10, 15, 80, 60);
      };
    }

    window.scrollTo(0, 0);

    var loginCredentials1 = localStorage.getItem("loginCredentials1");
    var loginCredentials2 = localStorage.getItem("loginCredentials2");
    // console.log("credentials===>",loginCredentials1,loginCredentials2)

    setTimeout(() => {
      if (loginCredentials1 != null) {
        this.setState({
          email: loginCredentials1,
          password: loginCredentials2,
          phoneVerified: true,
        });
        // console.log("email+pass===>",this.state.email,this.state.password)
      }
    }, 200);
    var fingerprint = new Fingerprint().get();
    this.setState({ fingerprint: fingerprint });
    this.browserDetails();
    // var captcha = sliderCaptcha({
    //     id: 'captcha',
    //     repeatIcon: 'fa fa-times',
    //     onSuccess: function () {
    //         var handler = setTimeout(function () {
    //             window.clearTimeout(handler);
    //             captcha.reset();
    //         }, 500);
    //     }
    // });
    // return fetch("https://api.ipdata.co?api-key=83e48ebb5d4693b8d6a4979112e250423504120d2fb840b3fdc541dd")
    //     .then((resp) => resp.json())
    //     .then((result => {

    //         this.setState({ country_name: result.country_name, system_ip: result.ip })
    //     }))
  }

  browserDetails() {
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    this.setState({ browserName: browserName });
    //        document.write(''
    //    +'Browser name  = '+browserName+'<br>'
    //    +'Full version  = '+fullVersion+'<br>'
    //    +'Major version = '+majorVersion+'<br>'
    //    +'navigator.appName = '+navigator.appName+'<br>'
    //    +'navigator.userAgent = '+navigator.userAgent+'<br>'
    //   )
  }
  recapctchaLoaded() {}
  handleUserInput = (e) => {
    e.preventDefault();

    let type = e.target.name;
    let value = e.target.value;
    // console.log("inputchecking==>",value,type)
    this.setState({ [type]: value }, () => this.submit(type, value, e));

    setTimeout(() => {
      if (type === "email") {
        if (validateNameorEmail(this.state.email).status) {
          this.setState({
            emailStatus: true,
            emailError: "",
          });
        } else {
          this.setState({
            emailError: validateNameorEmail(this.state.email).error,
            emailStatus: false,
          });
        }
      } else if (type === "password") {
        if (this.state.password == "") {
          this.setState({
            passwordStatus: false,
            passwordError: validatePassword(this.state.password).error,
          });
        } else {
          this.setState({
            passwordStatus: true,
            passwordError: "",
          });
        }
      }
    }, 100);
  };
  recapctchaLoaded() {}
  verifyCallback = (e) => {
    e.persist();
  };
  submit(type, value, e) {
    e.preventDefault();

    if (type == "password") {
      if (validatePassword(this.state.password).status) {
        this.setState({ passwordStatus: true });
      } else
        this.setState({
          passwordStatus: false,
        });
    } else if (type == "email") {
      if (validateEmail(this.state.email).status) {
        this.setState({ emailStatus: true });
      } else
        this.setState({
          emailStatus: false,
        });
    }
  }
  _resentEmail = (e) => {
    this.setState({ loading: true });
    var parameter = { email: this.state.email };
    e.preventDefault();
    try {
      Api("user/sent_activation_link", "POST", parameter).then((resp) => {
        this.setState({ loading: false });
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 2000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 401) {
            this.setState({
              modalIsOpen: true,
            });
          } else if (resp.data.responseCode == 202) {
            this.setState({
              modalIsOpen1: true,
            });
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Some problem occured please contact the administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  };
  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "otp1") {
      if (!this.state.otp1Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: true });
          this.secoundInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: false });
        }
      }
    } else if (name == "otp2") {
      if (!this.state.otp2Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: true });
          this.thirdInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: false });
        }
      }
    } else if (name == "otp3") {
      if (!this.state.otp3Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: true });
          this.fourthInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: false });
        }
      }
    } else if (name == "otp4") {
      if (!this.state.otp4Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: true });
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: false });
        }
      }
    }
  };
  _login = (e) => {
    e.preventDefault();
    // console.log("headerRef", this.refs.headerRef)
    this.forceUpdate();

    // alert(this.state.isVerified)
    // return

    if (validateNameorEmail(this.state.email).status) {
      this.setState({
        emailStatus: true,
        emailError: "",
      });
    } else {
      this.setState({
        emailError: validateNameorEmail(this.state.email).error,
        emailStatus: false,
      });
    }

    if (this.state.password != "") {
      this.setState({
        passwordStatus: true,
        passwordError: "",
      });
    } else {
      this.setState({
        passwordError: validatePassword(this.state.password).error,
        passwordStatus: false,
      });
    }
    if (this.state.isVerified != true) {
      this.setState({
        captcha_error: "Please, verify you are not a robot",
      });
      return;
    }

    if (
      this.state.emailStatus == true &&
      this.state.passwordStatus == true &&
      this.state.isVerified == true
    ) {
      this.setState({ loading: true });

      var parameter = {
        email: this.state.email,
        password: this.state.password,
        browser_id: this.state.fingerprint,
        browser: this.state.browserName,
        location: this.state.country_name,
        system_ip: this.state.system_ip,
      };
      try {
        Api("user/userLogin", "POST", parameter).then((resp) => {
          console.log("response===>login", JSON.stringify(resp));
          this.setState({
            loading: false,
            email: "",
            password: "",
          });

          if (resp.status == 200) {
            if (resp.data.responseCode == 401) {
              this.setState({
                modalIsOpen: true,
                user_ID: resp.data.data._id,
              }); //email modal
            } else if (resp.data.responseCode == 202) {
              //OTP

              localStorage.setItem("data", resp.data.data._id);
              toast_func("success", resp.data.responseMessage);
              setTimeout(() => {
                this.setState({
                  modalIsOpen1: true,
                });
              }, 2500);
            } else if (resp.data.responseCode == 200) {
              this.forceUpdate();
              //console.log("dddd",this.refs.ref)
              localStorage.setItem("loginCredentials1", this.state.email);
              localStorage.setItem("loginCredentials2", this.state.password);

              toast_func("success", "login successful");
              localStorage.setItem("isLoggedin", "true");
              localStorage.setItem("data1", "true");
              var key = {
                _id: resp.data.data._id,
                token: resp.data.data.token,
              };
              var loginCredentials = {
                email: this.state.email,
                password: this.state.password,
              };
              console.log("Login=========>", loginCredentials);
              if (this.state.phoneVerified) {
                localStorage.setItem(
                  "Address",
                  resp.data.data.address.addresses[0].addr
                );
                localStorage.setItem(
                  "loginCredentials1",
                  loginCredentials.email
                );
                localStorage.setItem(
                  "loginCredentials2",
                  loginCredentials.password
                );
                //localStorage.setItem("loginCredentials3", loginCredentials.isChecked)
              } else {
                localStorage.setItem(
                  "Address",
                  resp.data.data.address.addresses[0].addr
                );
                localStorage.removeItem("loginCredentials1");
                localStorage.removeItem("loginCredentials2");
                // localStorage.removeItem("loginCredentials3")
              }

              // localStorage.setItem('selectCurrency','btc')

              socket.emit("initChat", {
                userId: resp.data.data._id,
              });

              localStorage.setItem("data", key._id);
              localStorage.setItem("token", key.token);
              setData(key._id);

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact the administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  };
  resentOTP() {
    var __id = localStorage.getItem("data");
    var parameter = { _id: __id };
    try {
      Api("user/sent_phone_otp", "POST", parameter).then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  }
  rememberMe() {
    this.setState({
      phoneVerified: !this.state.phoneVerified,
    });
  }
  submitVerifyOtp = (e) => {
    e.preventDefault();
    var fingerprint = new Fingerprint().get();
    var searchUrl = localStorage.getItem("data");
    let OTP =
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;

    console.log("user-id==>>>>", localStorage.getItem("data"));

    var parameter = {
      _id: this.state.user_ID,
      browser_id: fingerprint,
      otp: OTP,
    };
    Api("user/verify_email_via_otp", "POST", parameter).then((resp) => {
      console.log("otpRespppp--==>>>", resp);
      if (resp.status == 200) {
        // toast_func("success", resp.data.responseMessage)
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
          this.setState({
            modalIsOpen2: false,
          });
          setTimeout(() => {
            this.props.history.push("/login");
          }, 3000);
          // this.props.history.push("/login")
        } else if (resp.data.responseCode == 400) {
          this.props.history.push("NotFound");
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func("error", "Please contact to administritor ");
      }
    });
  };

  submitOtp = (e) => {
    e.preventDefault();

    var id = localStorage.getItem("data");
    // alert(id)
    // return

    console.log("==--==>", id);
    if (
      this.state.otp1Status &&
      this.state.otp2Status &&
      this.state.otp3Status &&
      this.state.otp4Status
    ) {
      setTimeout(() => {
        var parameter = {
          _id: id,
          phoneOtp:
            this.state.otp1 +
            this.state.otp2 +
            this.state.otp3 +
            this.state.otp4,
        };
        this.setState({ loading: true });
        try {
          Api("user/verify", "POST", parameter).then((resp) => {
            this.setState({ loading: false });

            if (resp.status == 200) {
              if (resp.data.responseCode == 200) {
                this.setState({
                  modalIsOpen1: false,
                });

                if (
                  resp.data.result.loginGuard &&
                  resp.data.result.browser_id != this.state.fingerprint
                ) {
                  this.props.history.push("/login");
                } else {
                  toast_func("success", "login successful");
                  localStorage.setItem("isLoggedin", "true");
                  localStorage.setItem("data1", "true");
                  if (this.state.phoneVerified) {
                    localStorage.setItem("loginCredentials1", this.state.email);
                    localStorage.setItem(
                      "loginCredentials2",
                      this.state.password
                    );
                  } else {
                    localStorage.removeItem("loginCredentials1");
                    localStorage.removeItem("loginCredentials2");
                  }
                  var key = {
                    _id: resp.data.result._id,
                    token: resp.data.result.token,
                  };

                  localStorage.setItem("data", key._id);
                  setData(resp.data.result._id);
                  // console.log("llllll ", resp.data.result._id);

                  localStorage.setItem("token", key.token);
                  socket.emit("initChat", {
                    userId: resp.data.result._id,
                  });
                  setTimeout(() => {
                    this.props.history.push("/");
                  }, 2500);
                }
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              } else {
                toast_func("error", resp.data.responseMessage);
              }
            } else {
              toast_func(
                "error",
                "Some problem occured please contact administrator"
              );
            }
          });
        } catch (ex) {
          toast_func(
            "error",
            "Unable to reach server please contact administrator"
          );
        }
      }, 200);
    } else {
      toast_func("error", "Please enter OTP");
    }
  };
  //     myFunction(e){
  //         var x = document.getElementById("myInput");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }
  //     }
  showPswrd(val) {
    if (val == "show") {
      this.setState({ togglePswrd: false });
      var x = document.getElementById("myPswrd");
      x.type = "text";
    } else {
      this.setState({ togglePswrd: true });
      var x = document.getElementById("myPswrd");
      x.type = "password";
    }
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div className="wrapper">
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        {/* <Header {...this.props }   /> */}

        {/* <Loader fullVersion loading={this.state.loading} /> */}
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle bgColour">
            <div className="container">
              <div className="loginbox hytAuto">
                <div className="box_4">
                  <a href="/">
                    <canvas id="myCanvas" className="canvas" />
                  </a>
                  <div className="pdBox">
                    <img src={require("../assets/newImages/login_img.png")} />
                  </div>
                </div>
                <div className="box_2" style={{ border: "1px solid white" }}>
                  <form>
                    <div className="formBox max-WT-400 center-box">
                      <div>
                        <h2 className="header2">Login</h2>
                      </div>
                      <label>Username or Email</label>
                      <div className="form-group">
                        <input
                          type="email"
                          maxLength={35}
                          name="email"
                          onChange={this.handleUserInput}
                          autocomplete="off"
                          value={this.state.email}
                          className="form-control radius10"
                          placeholder="Enter Your Email or Username"
                        />
                        {/* {this.state.emailError != "" ?
                                                <label className="error">{this.state.emailError}</label>
                                                : null} */}
                      </div>
                      <label>Password</label>
                      <div className="form-group posRel">
                        <input
                          type="password"
                          id="myPswrd"
                          maxLength={16}
                          name="password"
                          autocomplete="off"
                          onChange={this.handleUserInput}
                          value={this.state.password}
                          className="form-control radius10"
                          placeholder="Enter Your Password"
                        />
                        {this.state.togglePswrd == true ? (
                          <span
                            title="Hide"
                            className="fa fa-eye-slash setPos"
                            onClick={() => this.showPswrd("show")}
                          />
                        ) : (
                          <span
                            title="Show"
                            className="fa fa-eye setPos"
                            onClick={() => this.showPswrd("hide")}
                          />
                        )}
                        {/* {this.state.passwordError != "" ?
                                                <label className="error">{this.state.passwordError}</label>
                                                : null} */}
                      </div>
                      <div className="form-group captaMar">
                        {/* <label>Please, verify you are not a robot</label> */}
                        {/* <div class="container-fluid">
           <div class="form-row">
            <div class="col-12">
                <div class="slidercaptcha card">
                    <div class="card-header">
                        <span>Complete the security check</span>
                    </div>
                    <div class="card-body">
                        <div id="captcha"></div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
                        <Recaptcha
                          sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                          render="explicit"
                          onloadCallback={this.recapctchaLoaded}
                          verifyCallback={() =>
                            this.setState({
                              isVerified: true,
                              captcha_error: "",
                            })
                          }
                        />
                        {this.state.isVerified != true ? (
                          <label className="error">
                            {this.state.captcha_error}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group d-flex mt15">
                        <div className="checkbox-design-block">
                          <label className="checkbox-design mb0">
                            <input
                              type="checkbox"
                              name="rememberMe"
                              id="rememberMe"
                              value={this.state.phoneVerified}
                              onClick={() => this.rememberMe()}
                            />
                            <span /> &nbsp; &nbsp; Remember me
                          </label>
                        </div>
                        <Link
                          className="forgotpass ml-auto"
                          to={"/forgotpassword"}
                        >
                          <a className="forgotpass ml-auto">Forgot Password?</a>
                        </Link>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={(e) => this._login(e)}
                          className="btn btnGrey w-100 pt10 pb10 radius10"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="formLinksInfo mt15">
                    Don’t have an account? <Link to={"/signup/"}>Sign Up</Link>
                  </p>
                </div>
              </div>
              {/* <div className="cardBox max-WT-550 center-box">
                                <div className="sectionHead borderBottom pb10 text-center">
                                    <h2>LOGIN</h2>
                                </div> */}

              {/* <div style={{backgroundColor:"transparent",justifyContent:"center"}}> */}
              {/* <div style={{padding:"20px",height:"auto",width:"auto",justifyContent:"center",display:"flex",flexDirection:"row"}}> */}
              {/* <ClipLoader
                                        sizeUnit={"px"}
                                        size={35}
                                        loading={this.state.loading}
                                        />
                                    <label style={{marginLeft:"20%",marginTop:"10px"}}> Loading...</label> */}

              {/* browser_id: 3380385078
otp: "6714"
_id: "61f7f8ca6571b4494e05c7cb"

browser_id: 3380385078 
otp: "6989" 
_id: "61f800d86571b4494e05c7cc"

https://uat.coinbaazar.com/api/v1/user/verify_email_via_otp */}

              {/* </div>  */}
              {/* </div>  */}

              {/* <form>
                                    <div className="formBox max-WT-400 center-box">
                                        <div className="form-group">
                                            <input type="email" maxLength={35} name="email" onChange={this.handleUserInput} value={this.state.email} className="form-control radius4" placeholder="Enter Username or Email" />
                                            {this.state.emailError != "" ?
                                                <label className="error">{this.state.emailError}</label>
                                                : null}
                                        </div>
                                        <div className="form-group">
                                            <input type="password" id="myInput" maxLength={16} name="password" onChange={this.handleUserInput} value={this.state.password} className="form-control radius4" placeholder="Enter Password" />
                                            {this.state.passwordError != "" ?
                                                <label className="error">{this.state.passwordError}</label>
                                                : null}
                                                
                                        </div>
                                        <div className="form-group">
                                        <span><input type="checkbox" className="shwPas" onClick={(e) => this.myFunction(e)} /></span> <span className="shwLabel">Show Password</span>
                                        </div>

                                        <div className="form-group pl20">
                                            <label className="control-label  mb5 weight500">Please, verify you are not a robot</label>
                                            <div className="captchImg">
                                                <Recaptcha
                                                    sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                                                    render="explicit"
                                                    onloadCallback={this.recapctchaLoaded}
                                                    verifyCallback={() => this.setState({ isVerified: true, captcha_error: "" })}
                                                />
                                                */}
              <ReactModal visible={this.state.modalIsOpen}>
                <div id="verificationModal" tabindex="-1" role="dialog">
                  <div className="modal-dialog max-WT-500" role="document">
                    <form className="mb0">
                      <div className="modal-content">
                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                          <h4 className="modal-title">Email verification</h4>
                          <button
                            onClick={() =>
                              this.setState({
                                modalIsOpen: false,
                              })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                          <div className="w-100">
                            <div className="sectionHead text-center">
                              <h2>
                                Verification link has been send on your email
                                please verify it first.
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="pt10 w-100 text-right">
                            <button
                              type="button"
                              onClick={(e) =>
                                this.setState({
                                  modalIsOpen2: true,
                                  modalIsOpen: false,
                                })
                              }
                              className="btn btnGreen max-WT-auto mr50"
                            >
                              Verify with otp
                            </button>
                          </div>
                          <div className="pt10 w-20 text-right">
                            <button
                              type="button"
                              onClick={(e) => this._resentEmail(e)}
                              className="btn btnGreen max-WT-auto mr50"
                            >
                              RESEND
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>
              <ReactModal visible={this.state.modalIsOpen1}>
                <div id="verificationModal" tabindex="-1" role="dialog">
                  <div className="modal-dialog max-WT-500" role="document">
                    <form className="mb0">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Phone number verification
                          </h4>
                          <button
                            onClick={() =>
                              this.setState({
                                modalIsOpen1: false,
                              })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                          <div className="w-100">
                            <div className="sectionHead text-center">
                              <h2>Enter Verification Code</h2>
                              <p>
                                A text message with code was sent to your phone
                              </p>
                            </div>
                            <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                              <input
                                type="text"
                                name="otp1"
                                value={this.state.otp1}
                                ref={(secondIndex) =>
                                  (this.firstInput = secondIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp2"
                                value={this.state.otp2}
                                ref={(thirdIndex) =>
                                  (this.secoundInput = thirdIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp3"
                                value={this.state.otp3}
                                ref={(fourthIndex) =>
                                  (this.thirdInput = fourthIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp4"
                                value={this.state.otp4}
                                ref={(fifthIndex) =>
                                  (this.fourthInput = fifthIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="pt10 borderTop w-100 text-right">
                            <button
                              type="submit"
                              onClick={(e) => this.submitOtp(e)}
                              className="btn btnGreen max-WT-120 mr15"
                            >
                              SUBMIT
                            </button>
                            <button
                              type="button"
                              onClick={this.resentOTP}
                              className="btn btnGreen max-WT-120 ml5"
                            >
                              RESEND
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>

              <ReactModal visible={this.state.modalIsOpen2}>
                <div id="verificationModal" tabindex="-1" role="dialog">
                  <div className="modal-dialog max-WT-500" role="document">
                    <form className="mb0">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            onClick={() =>
                              this.setState({
                                modalIsOpen2: false,
                                modalIsOpen: true,
                              })
                            }
                            type="button"
                            className="backBtn fa"
                            data-dismiss="modal"
                          >
                            &#xf060;
                          </button>
                          <h4 className="modal-title mod_tit">
                            Email verification
                          </h4>
                        </div>
                        <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                          <div className="w-100">
                            <div className="sectionHead text-center">
                              <h2>Enter Verification Code</h2>
                              <p>
                                A text message with code was sent to your email
                              </p>
                            </div>
                            <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                              <input
                                type="text"
                                name="otp1"
                                value={this.state.otp1}
                                ref={(secondIndex) =>
                                  (this.firstInput = secondIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp2"
                                value={this.state.otp2}
                                ref={(thirdIndex) =>
                                  (this.secoundInput = thirdIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp3"
                                value={this.state.otp3}
                                ref={(fourthIndex) =>
                                  (this.thirdInput = fourthIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                              <input
                                type="text"
                                name="otp4"
                                value={this.state.otp4}
                                ref={(fifthIndex) =>
                                  (this.fourthInput = fifthIndex)
                                }
                                onChange={this.handleOtp}
                                className="form-control radius6 text-center"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="pt10 borderTop w-100 text-right">
                            <button
                              type="submit"
                              onClick={(e) => this.submitVerifyOtp(e)}
                              className="btn btnGreen max-WT-120 mr15"
                            >
                              SUBMIT
                            </button>
                            <button
                              type="button"
                              onClick={this._resentEmail}
                              className="btn btnGreen max-WT-120 ml5"
                            >
                              RESEND
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactModal>
              {/*
                                            </div>
                                        </div>
                                        {this.state.captcha_error != "" ? <label className="error"> {this.state.captcha_error}</label> : null}
                                        <div className="form-group clearfix d-flex">
                                            <div className="checkbox-design-block">
                                                <label className="checkbox-design mb0">
                                                    <input type="checkbox" name="rememberMe" id="rememberMe" value={this.state.phoneVerified} checked={this.state.phoneVerified} onClick={() => this.rememberMe()} />
                                                    <span></span>Remember me
                              </label>

                                            </div>
                                            <Link className="forgotpass ml-auto" to={"/forgotpassword"}><a className="forgotpass ml-auto">Forgot-Password?</a></Link>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" onClick={(e) => this._login(e)} className="btn btnGreen w-100 pt10 pb10 radius4">LOGIN</button>
                                        </div>
                                    </div>
                                </form>
                                <p className="formLinksInfo mt15">Don’t have an account?  <Link to={"/signup"}>REGISTER NOW</Link></p>

                            </div> */}
            </div>
          </section>
          {/* {isloggedIn !== "true" ?
                        <section className="createAccountSection bgGreen">
                            <div className="container-fluid">
                                <div className="createAccountBox">
                                    <span className="wColor">Getting started is easy. Create your account now.</span>
                                    <Link to={"/signup"}>
                                        <a className="btn btnBlack max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                                    </Link>
                                </div>
                            </div>
                        </section>
                        : null} */}
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
