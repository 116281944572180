import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import { Api, socket } from "../services/webservices";
import { base32 } from "../services/Decrypt";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  OKShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  FacebookMessengerShareButton,
  PocketShareButton,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";

export default class ReferFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: "www.coinbaazar.com",
      loading: false,
      result: "",
      moreIcon: "",
      referralList: [],
      refrelId: null,
    };
  }
  componentDidMount() {
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(45, 40, 60, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    var canvas = document.getElementById("myCanvas"),
      context = canvas.getContext("2d");
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      this.setState({ _id: id, token: token, addr: addr }, () =>
        this.loadData()
      );
    }, 300);
    this.showReferralList();
    // console.log("=>",token)
    var shareUrl = window.location.origin + "/signup/" + this.state.refrelId;
    this.setState({ shareUrl: shareUrl });
    make_base();
    function make_base() {
      var base_image = new Image();
      base_image.src =
        "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
      //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
      //   base_image.src = '../assets/newImages/logo.png';
      base_image.onload = function() {
        context.drawImage(base_image, 10, 15, 80, 60);
      };
    }
  }

  showReferralList() {
    let id = localStorage.getItem("data");
    var parameter = { userId: id };
    // console.log('huhuhuhuhuh===>>>>>')
    Api(
      "admin/viewDetail",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("viewDetail#####===>", resp);
      try {
        this.setState({
          loading: false,
          referralList: resp.data.data.referrals,
        });
      } catch (err) {}
    });
  }
  createRefId(e) {
    e.preventDefault();
    Api(
      "user/createReferralId",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("-===-Done=-=-=-=>", resp);
      if (resp.data.responseCode == 200) {
        this.loadData();
      }
    });
  }
  viewDetail = (e, userId) => {
    e.preventDefault();
    var encryptData = base32.encode(userId);
    this.props.history.push(`/user-details/${encryptData}`);
  };

  loadData() {
    //this.setState({ loading: true })

    var parameter = { _id: this.state._id };
    // console.log('huhuhuhuhuh===>>>>>')
    Api(
      "user/userProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      // console.log('nowOutttttt===>')
      try {
        console.log("adress==>>>", resp);
        console.log("qradress==>>>", resp.data.result);
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              refrelId: resp.data.result.referral_id,
            });
            this.setState({
              refrelAmnt: resp.data.result.btc.btc_referral_amt,
            });
            if (
              resp.data.result.referral_id == "" ||
              resp.data.result.referral_id == undefined
            ) {
              var shareUrl = window.location.origin + "/signup/";
              this.setState({
                shareUrl: shareUrl,
              });
            } else {
              var shareUrl =
                window.location.origin +
                "/signup?referral=" +
                resp.data.result.referral_id;
              this.setState({
                shareUrl: shareUrl,
              });
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  }

  allIcon() {
    this.setState({ moreIcon: "All" });
  }
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        <main className="mainContent">
          <section
            className="commonSectionStyle"
            style={{ color: "var(--white-color)" }}
          >
            <div className="container">
              <div className="loginbox2 posRel">
                {/* <span className="crossBtn"><i className="fa fa-times"></i></span> */}
                <div className="box_lft">
                  <a href="/">
                    <canvas id="myCanvas" className="canvas" />
                  </a>

                  <div className="pdBox3">
                    <img
                      className="rf_img"
                      src={require("../assets/newImages/IMG-20220311-WA0000.jpg")}
                    />
                  </div>
                </div>
                <div className="box_ryt">
                  <form>
                    <div className="formBox max-WT-400 marAuto">
                      <div>
                        <h2 className="header2 text-center">Refer a Friend</h2>
                      </div>
                      <div>
                        <div className="profImg">
                          <img
                            src={require("../assets/images/user-icon.png")}
                          />
                        </div>
                        {this.state.refrelId == "" ? (
                          <div className="d-flex colCenter">
                            <button
                              className="btn btnGreen"
                              onClick={(e) => this.createRefId(e)}
                            >
                              Create Referral Id
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex colCenter">
                            <label>Referral Id: </label>
                            <span
                              className="font14 ml5"
                              style={{ color: "var(--black-color)" }}
                            >
                              {" "}
                              {this.state.refrelId}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="text-center mt10">
                        <label
                          className="btnGrey w-100 pt10 pb10 radius10 "
                          style={{ color: "#fff" }}
                        >
                          Refer Friend
                        </label>
                      </div>
                      <div
                        className=" btnGreen pt20 w-100 d-flex align-items-center flex-wrap text-center"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/* <div> */}
                        {/* {this.state.moreIcon=='All' && ( */}
                        <div className="align-items-center w-100">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <FacebookShareButton
                              url={`${this.state.shareUrl}`}
                              quote={" SignUp with this rafrel id "}
                              className="Demo__some-network__share-button pl10"
                            >
                              <FacebookIcon size={40} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id   "
                              className="pl10"
                            >
                              <WhatsappIcon size={40} round />
                            </WhatsappShareButton>
                            <TelegramShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              className="pl10"
                            >
                              <TelegramIcon size={40} round />
                            </TelegramShareButton>
                            <FacebookMessengerShareButton
                              url={`${this.state.shareUrl}`}
                              appId="4556366847784258"
                              className="pl10"
                            >
                              <FacebookMessengerIcon size={40} round />
                            </FacebookMessengerShareButton>
                            {/* {this.state.moreIcon=="All" &&( */}
                            <TwitterShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id  "
                              via="Coinbaazar"
                              className="pl10"
                            >
                              <TwitterIcon size={40} round />
                            </TwitterShareButton>
                            {/* )} */}

                            <VKShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              className="pl10"
                            >
                              {/* {`${this.state.qrCodeUrlAddress}`} */}
                              <VKIcon size={40} round />
                            </VKShareButton>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <PinterestShareButton
                              url={`${this.state.shareUrl}`}
                              description="SignUp with this referral id "
                              className="pl10"
                            >
                              {/* {`${this.state.qrCodeUrlAddress}`} */}
                              <PinterestIcon size={40} round />
                            </PinterestShareButton>
                            <OKShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              className="pl10"
                            >
                              {" "}
                              {/* // image=
                              {`${this.state.qrCodeUrlAddress}`} */}
                              <OKIcon size={40} round />
                            </OKShareButton>
                            <LinkedinShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              summary="Hello user you should SignUp with this refrel ID"
                              className="pl10"
                            >
                              <LinkedinIcon size={40} round />
                            </LinkedinShareButton>
                            <RedditShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              className="pl10"
                            >
                              <RedditIcon size={40} round />
                            </RedditShareButton>

                            <PocketShareButton
                              url={`${this.state.shareUrl}`}
                              title="SignUp with this referral id "
                              className="pl10"
                            >
                              <PocketIcon size={40} round />
                            </PocketShareButton>
                            <WorkplaceShareButton
                              url={`${this.state.shareUrl}`}
                              quote="SignUp with this referral id "
                              className="pl10"
                            >
                              <WorkplaceIcon size={40} round />
                            </WorkplaceShareButton>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {this.state.referralList.length != 0 ? (
                <div className="referlist">
                  <h4 className="header2">
                    Referral List
                    <button
                      className="btn btnGrey  btcrefAmnt"
                      onClick={() => this.props.history.push("/HistoryList")}
                    >
                      My Referral Income :{" "}
                      {this.state.refrelAmnt
                        ? this.state.refrelAmnt.toFixed(8)
                        : this.state.refrelAmnt}
                    </button>
                  </h4>
                  {this.state.referralList.map((item, index) => {
                    return (
                      <div key={index} className="listItem">
                        <div className="itemImg">
                          {" "}
                          <img
                            src={
                              item.profilePic
                                ? item.profilePic
                                : require("../assets/images/user-icon.png")
                            }
                          />
                        </div>
                        <div className="itemInfo">
                          <p>{item.user_name}</p>
                          <span className="id">
                            Referral_id: {item.referral_id}
                          </span>
                          <span
                            className="icon"
                            onClick={(e) => this.viewDetail(e, item._id)}
                          >
                            <i className="fa fa-chevron-right" />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </section>
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
