import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class AltCoin extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text2">
                  <p className="font18" style={{ color: "black" }}>
                    Now exchange your{" "}
                    <span className="orangeColor fontfamQB">
                      Altcoins or Tokens
                    </span>{" "}
                    such as ETH, BCH, MGTC, USDT & many more with bitcoins on{" "}
                    <span className="orangeColor fontfamQB">Coinbaazar</span>{" "}
                    peer to peer Marketplace
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="giftImg">
                  <img
                    src={require("../assets/newImages/altcoin (1).gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="refHeader">
              <h2 className="giftPad2">
                <span className="orangeColor fontfamQB">"Altcoin"</span> refers
                to any type of cryptocurrency other than{" "}
                <span className="orangeColor fontfamQB">Bitcoin</span>
              </h2>
            </div>
          </div>
        </section>

        <section className="altLayer">
          <div className="container1440">
            <div className="altSection">
              <div className="bankImg">
                <img
                  src={require("../assets/newImages/cryptocurrency-exchange.png")}
                  alt=""
                />
              </div>

              <div className="bnkTxt" style={{ color: "var(--text-grey)" }}>
                <p className="font18" style={{ color: "black" }}>
                  Many altcoins and tokens are somehow hard to cash as they are
                  not easily convertible to Fiat on many exchanges...
                </p>
              </div>
              <div className="bnkTxt" style={{ color: "var(--text-grey)" }}>
                <p className="font18" style={{ color: "black" }}>
                  <span className="orangeColor fontfamQB">Coinbaazar</span>{" "}
                  facilitates both buyer and seller, to convert altcoins to
                  bitcoins without paying huge fee
                </p>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-6">
                <div className="text2" style={{marginBottom:120}}>
                  {/* <h2 className="header2 fontfamTM">Download Google Authenticator App </h2> */}
                  <h2 className="bnkImgTitle" style={{ textAlign: "left" }}>
                    Create your offer or find a{" "}
                    <span className="orangeColor fontfamQB">Vendor</span>
                  </h2>
                  <p className="font16 txtJus"  style={{ color: "black" }}>
                    <i className="fa fa-circle bullet" />
                    Check the amount of bitcoins you want to exchange before
                    creating an offer or before opening a trade.
                  </p>
                  <p className="font16 txtJus" style={{ color: "black" }}>
                    <i className="fa fa-circle bullet" />
                    Check the current price of altcoin or tokens from Google or
                    any other reliable source and share it on trade window.
                  </p>
                  <p className="font16 txtJus" style={{ color: "black" }}>
                    <i className="fa fa-circle bullet" />
                    Once both buyer and seller agree, send your altcoin or Token
                    Wallet in the chat window.
                  </p>
                  <p className="font16 txtJus" style={{ color: "black" }}>
                    <i className="fa fa-circle bullet" />
                    Once you have transferred the{" "}
                    <span className="orangeColor fontfamQB">altcoins</span>,
                    make sure you send the transaction Hash in the chat window.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="width75">
                  <img
                    src={require("../assets/newImages/cryptocurrency-wallet.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440 pb20">
            <div className="row">
              <div className="col-md-12">
                <div className="bnkTxt">
                  <span>
                    Professional Dispute Resolution in case of any issues
                  </span>
                </div>
                <h4 className="header2 fontsize fontfamTM text-center">
                  For any help/assistance, talk to our Live support agents
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
