import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class Socialmedia extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="socailTxt">
                  {/* <h2 className="header2 fontfamTB">EARN consistent income through referrals </h2>
                            <p className="font16">
                           Coinbaazar referral program lets you earn bitcoins on every successful trade by your referrals</p> */}
                  <span className="fontfamTM dBlueColor">Follow</span>
                  <span className="fontfamTB dBlueColor ml10 mr10">
                    Coinbaazar
                  </span>
                  <span className="fontfamTM dBlueColor">
                    to get connected with other Crypto Peers....
                  </span>
                  {/* <button type="button" className="btn btnWhite  mt15" >Sign Up Now <i className="fa fa-chevron-right"></i></button> */}
                </div>
                <div className="mt30">
                  <span className="font16 fontfamTM ml5 mr5">We Welcome</span>
                  <span className="font16">
                    you to join us on various Social Media Platforms, where you
                    can get connected with other crypto enthusiasts.
                  </span>
                  <br />
                  <br />
                  <span className="font16 mt20">
                    Get yourself updated with the latest offers and
                    announcements about
                  </span>
                  <span className="font16 fontfamTM ml5 mr5">Coinbaazar</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="socialImg">
                  <img
                    src={require("../assets/newImages/social_media1.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="text-center">
              <h2 className="header2 fontfamTB">Find Us @</h2>
            </div>
            <div className="refTxt">
              <p>Join our community on various social platforms</p>
            </div>
            <div className="socMedGrp">
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/officialcoinbaazar/",
                    "_blank"
                  )
                }
              >
                <div className="socialIcon">
                  <img src={require("../assets/newImages/fb.png")} />
                </div>
              </div>
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open("https://x.com/coin_baazar", "_blank")
                }
              >
                <div className="socialTweet">
                  <img src={require("../assets/newImages/x.png")} />
                </div>
              </div>
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCcdU1yxj8meIjRbDmh14djQ",
                    "_blank"
                  )
                }
              >
                <div className="socialTweet">
                  <img src={require("../assets/newImages/youTube logo.png")} />
                </div>
              </div>
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open("https://t.me/Coinbaazar_Exchange", "_blank")
                }
              >
                <div className="socialIcon">
                  <img src={require("../assets/newImages/telegram logo.png")} />
                </div>
              </div>
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open("https://www.reddit.com/r/coinbaazar/", "_blank")
                }
              >
                <div className="socialIcon">
                  <img src={require("../assets/newImages/reddit.png")} />
                </div>
              </div>
              <div
                className="card_22 w15"
                onClick={() =>
                  window.open(
                    "https://discord.com/channels/968046535130615838/969931379099975690",
                    "_blank"
                  )
                }
              >
                <div className="socialIcon">
                  <img src={require("../assets/newImages/discord.png")} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="row rowSpace">
              <div className="col-md-7">
                <div className="text2">
                  <h2 className="header2 fontfamTB">Chat Room </h2>
                  <span className="font16 txtJus">
                    A dedicated live chat room for all users to communicate with
                    one another, where one can discuss the latest news, subjects
                    , exchange ideas, suggestions, and other crypto-related
                    experiences.
                  </span>
                  <div className="footerApp mt20">
                    <div className="googleApp appBtn">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.nobillio.coinbaazar"
                        target="_blank"
                      >
                        <img
                          src={require("../assets/newImages/googlePlay.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                    <div className="appBtn ml15">
                      <a
                        href="https://apps.apple.com/in/app/coinbaazar-buy-sell-bitcoins/id1548921926"
                        target="_blank"
                      >
                        <img
                          src={require("../assets/newImages/appstr.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="imgGrp0">
                  <img
                    src={require("../assets/newImages/socialMed2.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="refBnr p0 refhyt"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row buySellBox2">
              <div className="col-md-6">
                <div className="">
                  <div className="imgGrp1">
                    <img
                      src={require("../assets/newImages/socialMed4.png")}
                      alt=""
                    />
                  </div>
                  <div className="imgGrp2">
                    <img
                      src={require("../assets/newImages/socialMed3.png")}
                      alt=""
                    />
                  </div>
                  {/* <div className="refImg">
                    <img src={require("../assets/newImages/socialTalk.jpg")} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 imgGrpTxt">
                <h2 className="header2 fontfamTB">TalkOnCrypto</h2>
                <a href="https://talkoncrypto.com/" target="_blank">
                  {" "}
                  <span className="font16 fontfamTM mr5 dBlueColor">
                    talkoncrypto.com
                  </span>
                </a>
                <span className="font16 txtJus" style={{ color: "black" }}>
                  is a cryptocurrency forum where all the crypto enthusiasts can
                  chat , share & discuss the variety of topics related to the
                  world of cryptocurrency starting from annoucements, industry
                  developments, new launches of Apps to latest topics and many
                  more on a single platform. Coinbaazar aims to bring crypto
                  people together to share their ideas for people who are
                  venturing in to crypto space
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="" style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="row rowSpace2">
              <div className="col-md-7 imgGrpTxt">
                <h2 className="header2 fontfamTB">Cryptoplo News</h2>
                <a href="https://cryptoplo.com/" target="_blank">
                  {" "}
                  <span className="font16 fontfamTM mr5 dBlueColor">
                    Cryptoplo.com
                  </span>
                </a>
                <span className="font16 txtJus">
                  is a site dedicated to crypto news around the globe. Any
                  crypto sites, bloggers, Exchanges, and ICOs can publish their
                  press releases/news without any fee just by mailing at{" "}
                  <span className="font16 fontfamTM mr5">
                    info@cryptoplo.com
                  </span>
                  . A venture by Coinbaazar LLC, Cryptoplo promises to be a fair
                  and objective portal, where readers can find the best
                  information and latest crypto currency news
                </span>
              </div>
              <div className="col-md-5">
                <div className="">
                  <div className="imgGrp3">
                    <img
                      src={require("../assets/newImages/socialmed5.png")}
                      alt=""
                    />
                  </div>
                  <div className="imgGrp4">
                    <img
                      src={require("../assets/newImages/socialmed6.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
