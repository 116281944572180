import React, { Component } from "react";
import { Api, socket } from "../services/webservices";
import { toast_func } from "../components/toaster";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ValidateWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      otpError: false,
      paymentDone: false,
      auth_otp: "",
    };
  }

  // componentDidMount=()=>{
  //     console.log("propsss=======>",)
  // }

  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "auth_otp") {
      if (value.length !== 7) {
        this.setState({ [name]: value });
      } else {
        //this.setState({otpError:true})
      }
    } else {
      this.setState({ otpError: true });
    }
  };

  verifyWithdraw = (e) => {
    let id = this.props.match.params.withdrawId;
    e.preventDefault();
    // alert(this.state.auth_otp)

    if (this.state.auth_otp.length < 4) return;
    var parameter = { id: id, otp: this.state.auth_otp };

    this.setState({ loading: true });
    Api(
      "user/validate",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({
        loading: false,
        auth_otp: "",
      });
      try {
        console.log("withdrawwww======>" + JSON.stringify(resp));
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.message);
            this.setState({
              BTCAmount: "",
              BTCAddress: "",
              Description: "",
              Amount: "",
              paymentDone: true,
            });
            // setTimeout(() => {
            //   this.props.history.push('/')
            // }, 2000);
            //   this.componentDidMount();
            //   window.location.reload();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.message);
          }
        } else {
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div style={{ marginTop: "130px" }}>
        <ToastContainer />
        {this.state.paymentDone == false ? (
          <div className="cardBox max-WT-550 center-box">
            <div className="cardHead mb15 pb10 text-center">
              <h4 className="font18 mt0 mb5 text-center">Please Verify</h4>
            </div>
            <p className="rcvCoin pl15 pr15">
              To withdraw your funds, please enter authentication code that you
              have received on your registered email address.
            </p>
            <form className="mb0">
              <div className="formBox  max-WT-400 center-box">
                <div className="form-group">
                  {/* <div className="max-WT-120 center-box">
                                                <a> <img src={`${this.state.image}`} alt="Images" /></a>
                                            </div> */}
                </div>

                <div className="form-group">
                  {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                  <label className="control-label">Submit Email OTP</label>
                  <input
                    type="number"
                    className="form-control"
                    name="auth_otp"
                    id="auth_cade"
                    maxLength="6"
                    value={this.state.auth_otp}
                    onChange={this.handleOtp}
                  />
                </div>
                {this.state.otpError == true && (
                  <label className="error">Please enter correct OTP</label>
                )}
                {this.state.auth_codeError !== "" ? (
                  <label className="error">{this.state.auth_codeError}</label>
                ) : null}
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={(e) => this.verifyWithdraw(e)}
                    className="btn btnGreen w-100 radius4 mb20"
                  >
                    OK
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="cardBox max-WT-550 center-box">
            <div className="cardHead mb15 pb10 text-center">
              <h4 className="font18 mt0 mb5 text-center">
                Payment request approved successfully.
              </h4>
            </div>
          </div>
        )}
      </div>
    );
  }
}
