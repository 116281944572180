import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Api } from "../services/webservices";
import { toast_func } from "../components/toaster";

export default class GenerateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Address: "ffffffffffff",
      coinView: "",
      copied: false,
      qr: "",
    };
  }
  componentDidMount() {
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(45, 40, 60, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    var canvas = document.getElementById("myCanvas"),
      context = canvas.getContext("2d");
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    make_base();
    function make_base() {
      var base_image = new Image();
      base_image.src =
        "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
      //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
      //   base_image.src = '../assets/newImages/logo.png';
      base_image.onload = function() {
        context.drawImage(base_image, 10, 15, 80, 60);
      };
    }

    this.getDetail();
  }
  counterFun(time) {
    console.log("timeeeeee======>" + time);
    let t = time;
    var myInterval = setInterval(() => {
      t--;
      this.setState({ expireTime: t });
      if (t == 0) {
        clearInterval(myInterval);
        this.getDetail();
      }
    }, 60000);
  }

  getDetail() {
    let invoiceId = this.props.match.params.invoiceId;
    Api(`user/getInvoice/${invoiceId}`, "GET", {}, "", "").then((resp) => {
      this.setState({ loader2: false });
      if (resp.status == 200) {
        console.log("-===-Done=-=-=-=>", resp);
        if (resp.data.code == 200) {
          if (resp.data.succ.expireTime != 0) {
            this.counterFun(resp.data.succ.expireTime);
          }
          let currAmt =
            resp.data.succ.currencyAmount +
            resp.data.succ.currencyAmount * (resp.data.succ.tax / 100);

          this.setState({
            orderNo: resp.data.succ.orderNo,
            item: resp.data.succ.paymentMethod,
            itemDescription: resp.data.succ.itemDescription,
            amount: resp.data.succ.amount,
            expireTime: resp.data.succ.expireTime,
            qr: resp.data.succ.userId.btc.addresses[0].addr,
            qrCodeUrlAddress: resp.data.succ.userId.qrCodeUrlAddress,
            status: resp.data.succ.status,
            receipt: resp.data.succ.receipt,
            currencyAmt: currAmt,
            currency: resp.data.succ.currency,
          });
        } else if (resp.data.responseCode == 400) {
        }
      }
    });
  }

  markPaid(e) {
    e.preventDefault();
    let invoiceId = this.props.match.params.invoiceId;
    Api(`user/payInvoice/${invoiceId}`, "GET", {}, "", "").then((resp) => {
      this.setState({ loader2: false });
      if (resp.status == 200) {
        console.log("-===-payInvoice=-=-=-=>", resp);
        if (resp.data.code == 200) {
          this.getDetail();
        } else if (resp.data.responseCode == 400) {
        }
      }
    });
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle bgColour">
            <div className="container">
              <div className="loginbox posRel boxHyt">
                {/* <span className="crossBtn"><i className="fa fa-times"></i></span> */}
                <div className="box_4">
                  <a href="/">
                    {" "}
                    <canvas id="myCanvas" className="canvas" />
                  </a>
                  <div className="pdBox w100Per">
                    <div className="colCenter">
                      <label className="font18 wColor">
                        Your order number is:{" "}
                      </label>
                      <p className="font18 wColor">{this.state.orderNo}</p>
                      <div className="radius12 p20 bgWhite">
                        <div className="row mb10">
                          <div className="col-md-6 txtLeft fontfamQM dBlueColor">
                            Item
                          </div>
                          <div className="col-md-6 txtRyt">
                            {this.state.item}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 txtLeft fontfamQM dBlueColor">
                            Item description
                          </div>
                          <div className="col-md-6 txtRyt">
                            {this.state.itemDescription}
                          </div>
                        </div>
                      </div>
                      <div className="radius12 p20 bgWhite mt20 mb15">
                        <div className="row">
                          <div className="col-md-6 txtLeft fontfamQM dBlueColor">
                            Total Amount{" "}
                          </div>
                          <div className="col-md-6 txtRyt weight600">
                            {this.state.amount} BTC
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 txtLeft fontfamQM dBlueColor">
                            (Including Tax/vat){" "}
                          </div>
                          <div className="col-md-6 txtRyt weight600">
                            ({this.state.currencyAmt} {this.state.currency})
                          </div>
                        </div>
                      </div>
                      <a className="howPay font16">
                        <i className="fa fa-question-circle ml5" /> How do I pay
                        this?
                      </a>
                    </div>
                    <p className="txtJus" style={{ color: "#ffffff" }}>
                      {" "}
                      Note: The invoice contains a Bitcoin wallet address in
                      both QR code and text format. Scan the QR code or copy and
                      paste the address into your wallet. Keep in mind there is
                      NO Fee if you are using coinbaazar wallet, but in case of
                      any other wallet there is likely a charge on BTC
                      withdrawal fee, so take this into account when sending to
                      ensure you do not underpay. Transaction Time with
                      Coinbaazar Wallet are Instant whereas with Other Wallet it
                      May take some time depending on Blockchain Network
                    </p>
                  </div>
                </div>
                <div className="box_2 pdTop">
                  {/* <form> */}
                  <div className="formBox">
                    {/* <div>
                                    <h2 className="header2 text-center">Refer a Friend</h2>
                                    </div> */}
                    <div className="btcTab text-center mb15">
                      ₿
                      {/* <button className="btn btnWhite minWidth100 mb15">₿</button> */}
                    </div>
                    {this.state.status == "expired" && (
                      <div className="d-flex colCenter">
                        <label>
                          Invoice Expired, Ask the merchant to share a new
                          invoice to make payment
                        </label>
                      </div>
                    )}
                    {this.state.status == "pending" && (
                      <div className="d-flex colCenter">
                        <span className="mr5 ">Payment expires in: </span>
                        <div id="timerId" className="">
                          <span className="weight600">
                            {this.state.expireTime} Minute
                          </span>
                          {/* <span className="weight600">{setTimeout(()=>{
                                              this.counterFun(10,true)
                                            }, 100)}</span> */}
                        </div>
                      </div>
                    )}
                    {this.state.status == "paid" && (
                      <div className="d-flex colCenter">
                        <label>
                          Invoice marked as Paid, You can view and download your
                          receipt.
                        </label>
                      </div>
                    )}
                    <div>
                      <p className="invoiceTxt mb0">
                        Make sure you transfer only Bitcoins to this Wallet.
                      </p>
                      <div className="qrCode max-WT-500">
                        {this.state.status == "expired" ? (
                          <a>
                            {" "}
                            <img
                              src={require("../assets/images/expired.png")}
                              width="200px"
                              alt="QR Code"
                              style={{ padding: "16px", marginLeft: "-18px" }}
                            />
                          </a>
                        ) : (
                          <a>
                            {" "}
                            <img
                              src={this.state.qrCodeUrlAddress}
                              alt="QR Code"
                            />
                          </a>
                        )}
                        {/* <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px",alignSelf: "center", marginLeft: "50%", marginTop: "10%" }} alt="" /></span> */}
                      </div>
                      <div className="coinAddress">
                        <input
                          type="text"
                          name="qr"
                          className="form-control"
                          value={this.state.qr}
                          readOnly
                        />
                        {/* <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px",alignSelf:"center" }} alt="" /></span> */}

                        <CopyToClipboard
                          onCopy={() => this.setState({ copied: true })}
                          text={this.state.qr}
                        >
                          <i className="fas fa-copy" />
                        </CopyToClipboard>
                        {this.state.copied == true ? (
                          <text
                            style={{
                              position: "absolute",
                              right: 0,
                              color: "green",
                            }}
                            className="error "
                          >
                            copied
                          </text>
                        ) : null}
                      </div>
                      <div className="text-center">
                        {this.state.status == "pending" && (
                          <button
                            className="btn btnGray minWidth80"
                            onClick={(e) => this.markPaid(e)}
                          >
                            I have Paid
                          </button>
                        )}
                        {/* {this.state.status=='paid' ?
                                  <button className="btn btnGray minWidth80">Paid</button>
                                  :null
                                } */}
                        {this.state.status == "paid" ? (
                          <a
                            className="btn btnGray minWidth80"
                            style={{ "margin-left": "5px" }}
                            href={this.state.receipt}
                            target="_blank"
                            download
                          >
                            <i
                              class="fa fa-download"
                              style={{ color: "#fff" }}
                            />
                          </a>
                        ) : null}
                        <p className="mt15 txtJus">
                          <span className="weight600">Disclaimer:</span> This is
                          an Off Escrow/Direct Payment, Make sure you trust the
                          person and check the details mentioned in the invoice
                          before transferring the funds. Coinbaazar LLC would
                          not be liable in case of any Dispute. Click on the
                          paid button once you make the payment.
                        </p>
                      </div>
                    </div>

                    {/* <div  className="text-center mt10">
                                            <label className="btnGrey w-100 pt10 pb10 radius10 " style={{color:"#fff"}}>Refer Friend</label>
                                        </div> */}
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
