import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import $, { data } from "jquery";
import { Api, socket, getCurrency } from "../services/webservices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import currencyJson from "../JSON/currency.json"
/*      Modules       */
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import { Link, withRouter } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { validateBTCAddress, validateMGTAddress } from "../utils/validation";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  OKShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  FacebookMessengerShareButton,
  PocketShareButton,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";

let currencyJson = [];

export default class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "receive-btc",
      loading: false,
      result: "",
      qrCodeUrlAddress: "",
      Address: "",
      mgtqrAddress: "",
      mgtAddress: "",
      created_at: "",
      total: "",
      BTCAddress: "",
      BTCAmount: "",
      Description: "",
      currency: "USD",
      BTCError: "",
      BTCAmountError: "",
      MGTError: "",
      MGTAmount: "",
      MGTAmountError: "",
      amountError: "",
      G2FA: false,
      withdrawVri: false,
      auth_code: "",
      auth_otp: "",
      addr: "",
      name: "Nobillio",
      utcZone: "Europe/London",
      reloadHeader: false,
      moreIcon: "",
      dropdownOpen: false,
      showModal: false,
      coinView: "btc",
      oldAcnt: false,
      otpError: false,
      invoice: false,
      InvoiceCurrency: "USD",
      BTCAmount: "",
      transactionFee: 0,
      Total: 0,
      generateInvoice: true,
      itemError: false,
      amountError: false,
      btcError: false,
      getInvoiceList: [],
      getLocalInvoiceList: [],
      dateInput: "",
    };
  }

  componentWillMount = async () => {
    let selectCoin = localStorage.getItem("selectCurrency");
    if (selectCoin) {
      this.setState({
        coinView: selectCoin,
        reloadHeader: true,
      });
    }
    let selectTab = localStorage.getItem("tab");
    if (selectTab) {
      this.setState({
        tab: selectTab || "receive-btc",
      });
    }
  };
  componentDidMount = async () => {
    localStorage.setItem("selectCurrency", "btc");
    window.scrollTo(0, 0);
    currencyJson = await getCurrency();
    let selectCoin = localStorage.getItem("selectCurrency");

    let getUtc = localStorage.getItem("_utc_");
    if (getUtc == undefined || getUtc == "") {
      this.setState({
        utcZone: this.state.utcZone,
      });
    } else {
      this.setState({ utcZone: getUtc });
    }

    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");

    var shareUrl =
      window.location.origin + "/Wallet_Address/" + selectCoin + "/" + id;

    // console.log("url======>>>>",shareUrl)
    setTimeout(() => {
      this.setState(
        {
          _id: id,
          token: token,
          addr: addr,
          shareUrl: shareUrl,
        },
        () => this._loadData()
      );
    }, 250);

    socket.on("getUserBalance", (resp) => {
      // var num = Number(resp.balance);
      // var n = num.toFixed(8);
      // this.setState({ totalBalance: n })
      this.setState({ reloadHeader: true });
    });

    setTimeout(() => {
      socket.emit("notificationList", {
        userId: this.state._id,
      });
    }, 300);

    setTimeout(() => {
      Api("user/getUser", "GET", {}, this.state.token, this.state._id).then(
        (resp) => {
          try {
            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                // localStorage.removeItem("data");
                // localStorage.removeItem("isLoggedin");
                // localStorage.removeItem("data1");
                // localStorage.removeItem("token");
                // this.props.history.push("/")
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              }
            }
          } catch (ex) {}
        }
      );
    }, 100);
  };

  toggle(type) {
    if (type === "dropdown") {
      this.state.dropdownOpen
        ? this.setState({
            dropdownOpen: false,
          })
        : this.setState({
            dropdownOpen: true,
          });
    } else {
      this.state.showModal
        ? this.setState({
            showModal: false,
          })
        : this.setState({ showModal: true });
    }
  }

  allIcon() {
    this.setState({ moreIcon: "All" });
  }

  historyData = () => {
    let selectCoin = localStorage.getItem("selectCurrency");
    var parameter = {
      startDate: "",
      endDate: "",
      userId: this.state._id,
      coin: selectCoin,
    };
    //this.setState({ loading: true })
    Api(
      "user/transactionList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("btc-=====>>", resp);
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              whole_data1: resp.data.result.docs,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.TotalCoin();
  };

  TotalCoin = () => {
    //this.setState({ loading: true })
    Api(
      `user/countData/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      // console.log('hellooooooooo=====>>',resp)
      this.setState({ loading: false });
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          if (resp.data.result.length) {
            var num = resp.data.result[0].reciveAmount;
            var n = num.toFixed(8);
            var num1 = resp.data.result[0].sendAmount;
            var n1 = num1.toFixed(8);

            this.setState({
              reciveAmount: n,
              sendAmount: n1,
              withdrawAmount: resp.data.result[0].withdrawAmount,
            });
          }
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }

      //this.setState({whole_data1:resp.data.result.docs})
    });
    this.stateApis();
  };
  stateApis = () => {
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  openWeb = (e) => {
    e.preventDefault();

    // window.open(`https://live.blockcypher.com/btc-testnet/btc/tx/${e.target.id}`, '_blank')
    window.open(`https://live.blockcypher.com/btc/tx/${e.target.id}`, "_blank");
  };

  populateView1 = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_data1.length < 5 ? this.state.whole_data1.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          this.state.whole_data1[i]["created_At"] !== undefined
            ? children.push(
                <td key={j} className="DtaeField-width">{`${new Date(
                  this.state.whole_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              ) //  children.push(<td className="DtaeField-width">{`${new Date(this.state.whole_data1[i]["createdAt"]).toLocaleString("en-US", {timeZone: this.state.utcZone})}`}</td>)
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 1) {
          this.state.whole_data1[i]["transaction_hash"] !== undefined
            ? children.push(
                <td key={j}>
                  <a
                    className="anchor-styling"
                    id={`${this.state.whole_data1[i]["transaction_hash"]}`}
                    onClick={(e) => this.openWeb(e)}
                  >{`${this.state.whole_data1[i]["transaction_hash"]}`}</a>
                </td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 2) {
          this.state.whole_data1[i]["remark"] !== undefined
            ? children.push(
                <td key={j} className="maxwidthField">{`${
                  this.state.whole_data1[i]["remark"]
                }`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 3) {
          this.state.whole_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td key={j}>{`${this.state.whole_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 4) {
          this.state.whole_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td key={j}>{`${
                  this.state.whole_data1[i]["recieve_amount"]
                }`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populateView1Recently = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_data1.length < 5 ? this.state.whole_data1.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          this.state.whole_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width" key={j}>{`${new Date(
                  this.state.whole_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 1) {
          this.state.whole_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td key={j}>{`${
                  this.state.whole_data1[i]["recieve_amount"]
                }`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 2) {
          this.state.whole_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td key={j}>{`${this.state.whole_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 3) {
          this.state.whole_data1[i]["toAddress"] !== undefined
            ? this.state.whole_data1[i]["toAddress"] !== null
              ? children.push(
                  <td key={j}>{`${this.state.whole_data1[i]["toAddress"]}`}</td>
                )
              : children.push(<td key={j}>{null}</td>)
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 4) {
          this.state.whole_data1[i]["remark"] !== undefined
            ? children.push(
                <td key={j}>{`${this.state.whole_data1[i]["remark"]}`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        } else if (j == 5) {
          this.state.whole_data1[i]["withdraw_fee"] !== undefined
            ? children.push(
                <td key={j}>{`${
                  this.state.whole_data1[i]["withdraw_fee"]
                }`}</td>
              )
            : children.push(<td key={j}>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  _loadData() {
    //this.setState({ loading: true })

    var parameter = { _id: this.state._id };
    // console.log('huhuhuhuhuh===>>>>>')
    Api(
      "user/userProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      // console.log('nowOutttttt===>')
      try {
        console.log("adress==>>>", resp);
        // console.log('qradress==>>>',resp.data.result.mgt.mgtAddr_QR_img)
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            var num = Number(resp.data.result.btc.total);

            var n = num.toFixed(8);
            if (resp.data.result.mgt.address === undefined) {
              this.setState({ oldAcnt: true });
            }
            this.setState({
              total: n,
              created_at: resp.data.result.btc.addresses[0].created_at,
              Address: resp.data.result.btc.addresses[0].addr,
              qrCodeUrlAddress: resp.data.result.qrCodeUrlAddress,
              mgtAddress: resp.data.result.mgt.address,
              mgtqrAddress: resp.data.result.mgt.mgtAddr_QR_img,
              totalMgt: resp.data.result.mgt.total,
              username: resp.data.result.user_name,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.historyData();
    this.MGT_historyData();
    this.getInvoiceList();
  }
  sendBTC = () => {
    if (validateBTCAddress(this.state.BTCAddress).status) {
      this.setState({ BTCError: "" });
    } else
      this.setState({
        BTCError: validateBTCAddress(this.state.BTCAddress).error,
      });

    if (this.state.BTCAmount !== "") {
      var value = this.state.BTCAmount;
      if (!isNaN(value) || value == ".") {
        var value2 = value.split("");
        if (value2[0] == ".") {
          this.setState({
            BTCAmount: ".",
            BTCAmountError: "* Please enter valid BTC amount",
          });
        } else if (this.state.BTCAmount == 0) {
          this.setState({
            BTCAmount: 0,
            BTCAmountError: "* Please enter BTC amount more then zero",
          });
        }
      }
      //this.setState({ BTCAmountError: "" })
    } else
      this.setState({
        BTCAmountError: "* Please enter BTC amount",
      });

    if (this.state.BTCAddress == "") {
      this.setState({
        BTCError: "* Please enter BTC Address",
      });
    }

    setTimeout(() => {
      if (this.state.BTCError == "" && this.state.BTCAmountError == "") {
        //this.BTCSend()
        if (this.state.BTCAmount > 0) {
          if (this.state.priceWithoutRefine > 0) {
            if (this.state.addr != this.state.BTCAddress) {
              this.verify2FA();
            } else
              this.setState({
                BTCAddress: "",
                BTCError: "* You can not enter your own Address!",
              });
          } else
            this.setState({
              amountError: "* Please enter amount more then zero",
            });
        } else
          this.setState({
            BTCAmountError: "* Please enter BTC amount more then zero",
          });
      }
    }, 200);
  };

  //MGT send functionality\\---------------------------------------------MGT

  getMgtVal = (e) => {
    // console.log(e.target.name+"==mgt_send====>"+e.target)
    let temp = {};
    temp[e.target.name] = e.target.value;
    this.setState(temp);
    if (e.target.value !== "")
      this.setState({
        MGTAmountError: "",
      });

    if (e.target.value !== "")
      this.setState({
        MGTError: "",
      });
  };

  MGTsend = () => {
    if (this.state.MGTAddress != "") {
      if (validateMGTAddress(this.state.MGTAddress).status) {
        this.setState({ MGTError: "" });
      } else
        this.setState({
          MGTError: validateMGTAddress(this.state.MGTAddress).error,
        });
    } else {
      this.setState({
        MGTError: "Please Enter an MGT address",
      });
    }

    if (this.state.MGTAmount != "") {
      // var value = this.state.MGTAmount
      if (this.state.MGTAmount == "0") {
        // console.log('ach=====aaa');
        this.setState({
          MGTAmount: 0,
          MGTAmountError: "* Please enter MGT amount more then zero",
        });
      } else {
        setTimeout(() => {
          if (this.state.MGTError == "" && this.state.MGTAmountError == "") {
            //this.MGTSend()

            if (this.state.MGTAmount < 0) {
              //  if(this.state.priceWithoutRefine>0){
              if (this.state.addr != this.state.MGTAddress) {
                this.MGT_withdraw_otp();
              } else
                this.setState({
                  MGTAddress: "",
                  MGTError: "* You can not enter your own Address!",
                });
            } else
              this.setState({
                amountError: "* Please enter amount more then zero",
              });
          }
        }, 200);
      }
    } else {
      this.setState({
        MGTAmountError: "* Please enter MGT amount",
      });
    }
  };

  MGT_withdraw_otp = () => {
    var parameter = { amount: this.state.MGTAmount };
    this.setState({ loading: true });
    Api(
      "user/sendMgt_Request",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("MGTOTP response ===>>", resp);
      this.setState({ loading: false });
      if (resp.status == 200) {
        if (resp.data.responseCode == 301) {
          toast_func("success", resp.data.message);
          this.setState({ withdrawVri: true });
        } else {
          toast_func("error", resp.data.message);
        }
      } else {
        toast_func("error", resp.data.message);
      }
    });
  };
  sendMGT = (e) => {
    e.preventDefault();

    var parameter = {
      toAddress: this.state.MGTAddress,
      amount: this.state.MGTAmount, // "user_name": this.state.username,
      otp: this.state.auth_otp,
      description: this.state.Description,
    };
    this.setState({ loading: true });
    Api(
      "user/send_mgt_coin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("MGT response ===>>", resp);
      this.setState({ loading: false });
      if (resp.status == 200) {
        console.log("==>>==", resp.data);
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.message);
          this.setState({
            MGTAmount: "",
            MGTAddress: "",
            Description: "",
            Amount: "",
            withdrawVri: false,
            auth_otp: "",
          });
          setTimeout(() => {
            // window.location.reload();
          }, 2000);
        } else if (resp.data.responseCode == 201) {
          toast_func("error", resp.data.message);
        } else if (resp.data.responseCode == 400) {
          toast_func("error", resp.data.message);
        } else {
          toast_func("error", resp.data.message);
        }
      }
    });
  };
  openMGTWeb = (e) => {
    e.preventDefault();

    // window.open(`https://live.blockcypher.com/btc-testnet/btc/tx/${e.target.id}`, '_blank')
    window.open(`https://explore.magnetocoin.com/tx/${e.target.id}`, "_blank");
  };
  MGT_historyData = () => {
    //this.setState({ loading: true })
    Api(
      "user/mgt_transactionList",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      console.log("MGT history============>>>", resp);
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            // console.log("mgt_json======>"+JSON.stringify(resp.data.result))
            this.setState({
              whole_MGT_data1: resp.data.result,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  populateMGT = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_MGT_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_MGT_data1.length < 5
        ? this.state.whole_MGT_data1.length
        : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        // alert(' j is here',j)
        if (j == 0) {
          this.state.whole_MGT_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width">{`${new Date(
                  this.state.whole_MGT_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              ) //  children.push(<td className="DtaeField-width">{`${new Date(this.state.whole_MGT_data1[i]["createdAt"]).toLocaleString("en-US", {timeZone: this.state.utcZone})}`}</td>)
            : children.push(<td>{null}</td>);
        } else if (j == 1) {
          this.state.whole_MGT_data1[i]["transaction_hash"] !== undefined
            ? children.push(
                <td>
                  <a
                    className="anchor-styling"
                    id={`${this.state.whole_MGT_data1[i]["transaction_hash"]}`}
                    onClick={(e) => this.openMGTWeb(e)}
                  >{`${this.state.whole_MGT_data1[i]["transaction_hash"]}`}</a>
                </td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 2) {
          this.state.whole_MGT_data1[i]["remark"] !== undefined
            ? children.push(
                <td className="maxwidthField">{`${
                  this.state.whole_MGT_data1[i]["remark"]
                }`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 3) {
          this.state.whole_MGT_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 4) {
          this.state.whole_MGT_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["recieve_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populateMGTRecently = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.whole_MGT_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">No record found.</label>
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.whole_MGT_data1.length < 5
        ? this.state.whole_MGT_data1.length
        : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          this.state.whole_MGT_data1[i]["created_At"] !== undefined
            ? children.push(
                <td className="DtaeField-width">{`${new Date(
                  this.state.whole_MGT_data1[i]["created_At"]
                ).toLocaleString("en-US", {
                  timeZone: this.state.utcZone,
                })}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 1) {
          this.state.whole_MGT_data1[i]["recieve_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["recieve_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 2) {
          this.state.whole_MGT_data1[i]["send_amount"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["send_amount"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 3) {
          this.state.whole_MGT_data1[i]["toAddress"] !== undefined
            ? this.state.whole_MGT_data1[i]["toAddress"] !== null
              ? children.push(
                  <td>{`${this.state.whole_MGT_data1[i]["toAddress"]}`}</td>
                )
              : children.push(<td>{null}</td>)
            : children.push(<td>{null}</td>);
        } else if (j == 4) {
          this.state.whole_MGT_data1[i]["remark"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["remark"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        } else if (j == 5) {
          this.state.whole_MGT_data1[i]["withdraw_fee"] !== undefined
            ? children.push(
                <td>{`${this.state.whole_MGT_data1[i]["withdraw_fee"]}`}</td>
              )
            : children.push(<td>{null}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  creatMGTwllet = (e) => {
    Api(
      `user/create_Wallet/${this.state.username}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      // console.log('creat ===========>>>',resp)
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({ oldAcnt: false });
            toast_func("success", resp.data.message);
            this._loadData();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.message);
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  verify2FA = () => {
    this.setState({ loading: true });

    Api(
      `user/checkGoogle2FA/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      if (resp.status == 200) {
        if (resp.data.responseCode == 201) {
          // toast_func("success", resp.data.responseMessage)
          // setTimeout(() => {
          //   this.props.history.push("/twofactor")
          // }, 2500);
          this.BTCSend();
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else if (resp.data.responseCode == 200) {
          this.setState({
            G2FA: true,
            image: resp.data.result.qrCodeUrlAddress,
          });
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      }
    });
  };
  BTCSend = () => {
    var parameter = {
      sendTo: this.state.BTCAddress,
      amount: this.state.BTCAmount,
      userId: this.state._id,
      remark: this.state.Description,
    };
    this.setState({ loading: true });
    Api(
      "user/withdraw_amount",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.message);
            this.setState({
              BTCAmount: "",
              BTCAddress: "",
              Description: "",
              Amount: "",
              reloadHeader: true,
            });
            socket.emit("getUserBalance", {
              userId: this.state._id,
            });
            // setTimeout(() => {
            //   this.props.history.push('/')
            // }, 2000);
            this.componentDidMount();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 301) {
            this.setState({
              withdrawVri: true,
            });
          } else {
            toast_func("error", resp.data.message);
          }
        } else {
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  onClick_currency = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ currency: value }, () => this.fetchAmount());
  };
  Populate_currency = () => {
    let currencyArray = [];
    if (currencyJson.fiat) {
      let currArr = Object.keys(currencyJson.fiat);
      for (var i = 0; i < currArr.length; i++) {
        currencyArray.push(
          <option key={i} value={currArr[i]}>
            {currArr[i]}
          </option>
        );
      }
    }
    // for (var i = 0; i < currencyJson.length; i++) {
    //   currencyArray.push(<option value={currencyJson[i].code} key={i} >{currencyJson[i].code}</option>)
    // }
    return currencyArray;
  };
  handlecase = (e) => {
    let value = e.target.value;

    //alert(">>"+value+"<<")
    var _value = this.state.auth_code.trim();

    if (!isNaN(value) && value !== " " && value !== ".") {
      this.setState({ auth_code: value }, () => this.setdata());
    }
  };
  setdata = () => {
    var _value = this.state.auth_code.trim();

    if (_value.length !== 6) {
      this.setState({
        auth_codeError: "Please enter the valid token number",
      });
    } else {
      this.setState({ auth_codeError: "" });
    }
  };
  KnowAddress = () => {
    Api(
      `user/chnageTransactionAmount/${this.state.BTCAddress}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})

          this.setState(
            {
              transactionFee: resp.data.result,
            },
            () => this.reciverAmount()
          );
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  };
  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "auth_otp") {
      if (value.length !== 7) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ otpError: true });
    }
  };
  verifyWithdraw = (e) => {
    e.preventDefault();
    // alert(this.state.auth_otp)

    if (this.state.auth_otp.length < 4) return;
    var parameter = {
      sendTo: this.state.BTCAddress,
      amount: this.state.BTCAmount,
      userId: this.state._id,
      remark: this.state.Description,
      otp: this.state.auth_otp,
    };

    this.setState({ loading: true });
    Api(
      "user/withdrawVerification",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({
        loading: false,
        auth_otp: "",
      });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.message);
            this.setState({
              BTCAmount: "",
              BTCAddress: "",
              Description: "",
              Amount: "",
              withdrawVri: false,
            });
            // setTimeout(() => {
            //   this.props.history.push('/')
            // }, 2000);
            this.componentDidMount();
            window.location.reload();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.message);
          }
        } else {
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
    if (name == "BTCAddress") {
      if (value != " " && validateBTCAddress(value).status) {
        if (this.state.addr != value)
          this.setState({ [name]: value, BTCError: "" }, () =>
            this.KnowAddress()
          );
        else
          this.setState({
            [name]: "",
            BTCError: "* You can not enter your own Address!",
          });
      } else if (value == "") {
        this.setState(
          {
            [name]: "",
            BTCError: "* Please enter BTC Address.",
          },
          () => this.KnowAddress()
        );
      }
    }
    if (name == "BTCAmount") {
      var test = value.split("");
      if (test[test.length - 1] !== " ") {
        if (!isNaN(value) || value == ".") {
          var value2 = value.split("");
          if (value2[0] == ".") {
            this.setState({
              [name]: ".",
              BTCAmountError: "* Please enter valid BTC amount",
            });
          }
          // else if(value2[0] == 0)
          // {
          //   this.setState({ [name]: 0, BTCAmountError: "* Please enter BTC amount more then zero" })
          // }
          else {
            this.setState({ [name]: value }, () => this.reciverAmount());
            if (value[0] == "-") {
              value = value.substring(1, value.length);
              this.setState({ [name]: value });
              this.setState(
                { BTCAmountError: "" },
                () => this.fetchAmount(),
                () => this.reciverAmount(value)
              );
            } else
              this.setState(
                { BTCAmountError: "" },
                () => this.fetchAmount(),
                () => this.reciverAmount(value)
              );
          }
        }
      } else if (value == "") {
        this.setState(
          {
            [name]: "",
            BTCAmountError: "* Please enter BTC amount",
          },
          () => this.fetchAmount()
        );
      }
    }

    if (name == "Amount") {
      var test = value.split("");
      if (test[test.length - 1] !== " ") {
        if (!isNaN(value)) {
          // console.log("rrrrrrrr im on change from amount")
          if (value[0] == "-") {
            value = value.substring(1, value.length);
            this.setState(
              {
                price: value,
                priceWithoutRefine: value,
                amountError: "",
              },
              () => this.fetchBTC()
            );
          } else
            this.setState(
              {
                price: value,
                priceWithoutRefine: value,
                amountError: "",
              },
              () => this.fetchBTC()
            );
          // console.log("rrrrrrrr amount after setState", this.state.price);
        }
      }
    }
  };

  reciverAmount(value) {
    let tFee = this.state.transactionFee;
    let btc = this.state.BTCAmount;
    let transFee = 0;
    if (btc) {
      transFee = btc - tFee;
    }

    console.log("======.................", transFee);
    if (this.state.BTCAmount != "") {
      this.setState({
        reciverAmount: transFee.toFixed(6),
      });
      if (this.state.total < transFee) {
        this.setState({
          BTCAmountError: "You don't have enough Bitcoin",
        });
      } else if (tFee > this.state.BTCAmount) {
        this.setState({
          BTCAmountError: "Bitcoin amount should be more than transaction fee",
        });
      } else {
        this.setState({ BTCAmountError: "" });
      }
    } else {
      this.setState({
        reciverAmount: this.state.transactionFee,
      });
    }
  }

  verify = (e) => {
    e.preventDefault();

    var _value = this.state.auth_code.trim();

    if (_value.length == 6) {
      if (this.state.activeState == true) {
        var parameter = {
          _id: this.state._id,
          removeAuth: true,
          otp: this.state.auth_code,
        };
      } else {
        var parameter = { _id: this.state._id, otp: this.state.auth_code };
      }
      // this.setState({ loading: true })

      Api(
        "user/twoFactorOtpVerification",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              //toast_func("success", resp.data.responseMessage)
              this.setState({ G2FA: false });
              setTimeout(() => {
                this.BTCSend();
              }, 1000);
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              this.setState({ G2FA: false });
              toast_func("error", resp.data.responseMessage);
              this.setState({ auth_code: "" });
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    } else {
      this.setState({
        auth_codeError: "Please enter the valid token number",
      });
    }
  };

  fetchAmount() {
    console.log("okay ----->", this.state.currency);
    var parameter = {
      localCurrency: this.state.currency,
      btc: this.state.BTCAmount,
    };
    this.setState({ loader1: true });
    Api(
      "user/priceEquation",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });
      console.log("fatchAmount==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var num = Number(resp.data.result["price"]);
          var n = num.toFixed(2);
          // var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // var n2 = n.split(".")[1]
          // var price =n1+"."+n2
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})
          // console.log("working fine--->",price)
          this.setState({
            price: n,
            priceWithoutRefine: n,
          });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  }
  fetchBTC() {
    var parameter = { localCurrency: this.state.currency, margin: "" };
    this.setState({ loader2: true });
    Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader2: false });
      console.log("fatchBTC==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          console.log("rrrrrrrr", resp.data);
          var priceNow = resp.data.result.price;
          console.log("rrrrrrr price now===>", priceNow);
          console.log("rrrrrrr amount entered===>", this.state.price);
          var btc =
            (this.state.tax ? this.state.Total : this.state.price) / priceNow;
          console.log("rrrrrrr price now===>", btc);
          this.setState({ BTCAmount: btc.toFixed(8) }, () =>
            this.reciverAmount()
          );
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
          this.setState({ loader2: false });
        }
      }
    });
  }
  selecttab = (tabval) => {
    localStorage.setItem("tab", tabval);
    this.setState({ tab: tabval });
    this.getInvoiceList();
  };
  selectCoin = async (e) => {
    this.setState({ reloadHeader: true });
    console.log("yehh====>>", e.target);
    if (e.target.value == "btc") {
      this.setState({
        coinView: "btc",
        reloadHeader: true,
      });
      window.location.reload();
    } else if (e.target.value) {
      this.setState({
        coinView: "mgt",
        reloadHeader: true,
      });
      // window.location.reload(); //ttttttttttttttttttttttttttttttt
    }

    localStorage.setItem("selectCurrency", e.target.value);
  };
  activate = (e) => {
    e.preventDefault();

    var _value = this.state.auth_code.trim();

    if (_value.length == 6) {
      if (this.state.activeState == true) {
        var parameter = {
          _id: this.state._id,
          removeAuth: true,
          otp: this.state.auth_code,
        };
      } else {
        var parameter = { _id: this.state._id, otp: this.state.auth_code };
      }

      //  this.setState({ loading: true })

      Api(
        "user/twoFactorOtpVerification",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              setTimeout(() => {
                this.props.history.push("/twofactor");
              }, 2500);
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    } else {
      this.setState({
        auth_codeError: "Please enter the valid token number",
      });
    }
  };
  createInvoice() {
    this.setState({ invoice: true });
  }
  handleInvoice = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // this.setState({ [name]: value,})

    if (name == "tax") {
      var test = value.split("");
      if (value < 100) {
        this.setState({ [name]: value });

        var taxx = 0;
        if (value) {
          taxx = Number(value) / 100;
        }
        console.log(this.state.price + "=====", taxx);
        var fTax = parseFloat(this.state.price) * taxx;
        console.log("2=====", fTax);
        var total = parseFloat(this.state.price) + parseFloat(fTax);
        // console.log("=====",total)
        this.setState({ Total: total.toFixed(2) }, () => this.fetchBTC());
      }
    } else if (name == "InvoiceItem") {
      this.setState({
        [name]: value,
        itemError: false,
      });
    } else if (name == "InvoiceItemDis") {
      // alert(name+"===="+value)
      this.setState({ [name]: value });
    } else if (name == "BTCAmount") {
      if (value) {
        var test = value.split("");
        if (test[test.length - 1] !== " ") {
          if (!isNaN(value) || value == ".") {
            var value2 = value.split("");
            if (value2[0] == ".") {
              this.setState({
                [name]: ".",
                BTCAmountError: "* Please enter valid BTC amount",
              });
            }
            // else if(value2[0] == 0)
            // {
            //   this.setState({ [name]: 0, BTCAmountError: "* Please enter BTC amount more then zero" })
            // }
            else {
              this.setState(
                {
                  BTCAmount: value,
                  btcError: false,
                },
                () => this.fetchAmount()
              );
              //  this.fetchAmount();
              // this.setState({BTCAmountError: ""}, () => this.fetchAmount())
              // this.setState({BTCAmountError: ""}, () => this.fetchAmount())
            }
          }
        }
      } else {
        this.setState({
          BTCAmount: "",
          tax: "",
          price: "",
          Total: 0,
        });
      }
    }

    if (name == "price") {
      var test = value.split("");
      this.setState({ [name]: value });
      if (value) {
        if (test[test.length - 1] !== " ") {
          if (!isNaN(value)) {
            // console.log("rrrrrrrr im on change from amount")
            if (value[0] == "-") {
              value = value.substring(1, value.length);
              this.setState({
                price: value,
                Total: value,
                priceWithoutRefine: value,
                amountError: false,
              });
              this.fetchBTC();
            } else
              this.setState({
                price: value,
                Total: value,
                priceWithoutRefine: value,
                amountError: false,
              });
            this.fetchBTC();
            // console.log("rrrrrrrr amount after setState", this.state.price);
          }
        }
      } else {
        this.setState({
          Total: 0,
          BTCAmount: 0,
          tax: "",
        });
      }
    }
  };
  // fetchInvoiceAmount(btcAmt) {
  //   var parameter = {
  //     "localCurrency": this.state.InvoiceCurrency,
  //     "btc": btcAmt
  //   }
  //   this.setState({loader1:true})
  //   Api("user/priceEquation", "POST", parameter, this.state.token, this.state._id)
  //     .then((resp) => {
  //       this.setState({loader1:false});
  //       console.log('fatchAmount==>>',resp);
  //       if (resp.status == 200) {
  //         if (resp.data.responseCode == 200) {
  //           var num = Number(resp.data.result["price"]);
  //           var n = num.toFixed(2);
  //           var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //           var n2 = n.split(".")[1]
  //           var price =n1+"."+n2
  //           //alert(JSON.stringify(resp.data.result["price"]))
  //           //this.setState({Amount})
  //           this.setState({ calAmount: price, priceWithoutRefine:n})
  //         }
  //         else if(resp.data.responseCode == 502)
  //         {
  //             this.props.history.push("NotFound")

  //         }
  //       }
  //     })
  // }
  // fetchInvoiceBTC(){

  //   var parameter = {
  //     "localCurrency": this.state.InvoiceCurrency,
  //     "margin": ""
  //   }
  //   this.setState({loader2:true})
  //   Api("user/priceEquationWithMargin", "POST", parameter, this.state.token, this.state._id)
  //     .then((resp) => {
  //       this.setState({loader2:false})
  //       console.log('fatchBTC==>>',resp)
  //       if (resp.status == 200) {
  //         if (resp.data.responseCode == 200) {
  //           console.log('rrrrrrrr', resp.data);
  //           var priceNow = resp.data.result.price;
  //           console.log('rrrrrrr price now===>', priceNow);
  //           console.log('rrrrrrr amount entered===>', this.state.price);
  //           var btc = this.state.Total/priceNow;
  //           console.log('rrrrrrr price now===>', btc);
  //           this.setState({BTCAmount:(btc).toFixed(8)});
  //         }
  //         else if(resp.data.responseCode == 502)
  //         {
  //             this.props.history.push("NotFound");
  //             this.setState({loader2:false})

  //         }
  //       }
  //     })
  // }
  invoiceModal() {
    if (this.state.generateInvoice == true) {
      this.setState({
        invoice: false,
        BTCAmount: "",
        InvoiceItem: "",
        InvoiceItemDis: "",
        price: "",
        tax: "",
        Total: 0,
      });
    } else if (this.state.generateInvoice == false) {
      this.setState({ generateInvoice: true });
    }
  }
  create() {
    // alert(this.state.InvoiceItem)
    if (this.state.InvoiceItem) {
      if (this.state.price) {
        if (this.state.BTCAmount) {
          var parameter = {
            amount: this.state.BTCAmount,
            paymentMethod: this.state.InvoiceItem,
            itemDescription: this.state.InvoiceItemDis,
            currencyAmount: this.state.price,
            currency: this.state.currency,
            tax: this.state.tax,
            createdAt: new Date()
              .toLocaleString("en-US", {
                timeZone: this.state.utcZone,
              })
              .split(",")[0],
          }; //new Date().getDate()+'/'+new Date().getMonth()+1+'/'+new Date().getFullYear()
          this.setState({ loader2: true });
          Api(
            "user/generateInvoice",
            "POST",
            parameter,
            this.state.token,
            this.state._id
          ).then((resp) => {
            this.setState({ loader2: false });
            if (resp.status == 200) {
              console.log("-===-=-=-=-=>", resp);
              if (resp.data.code == 200) {
                toast_func("success", resp.data.message);
                var inVoiceUrl =
                  window.location.origin +
                  "/generateInvoice/" +
                  resp.data.succ._id;
                this.setState({
                  generateInvoice: false,
                  inVoiceUrl: inVoiceUrl,
                  tax: "",
                  InvoiceItemDis: "",
                  BTCAmount: "",
                  InvoiceItem: "",
                  Total: 0,
                });
              } else if (resp.data.code == 400) {
                toast_func("error", resp.data.message);
              }
            }
          });
        } else {
          this.setState({ btcError: true });
        }
      } else {
        this.setState({ amountError: true });
      }
    } else {
      this.setState({ itemError: true });
    }
  }

  getInvoiceList() {
    Api(
      "user/getInvoiceList/",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("-===-Done=-=-=-=>", resp);
      this.setState({ loader2: false });
      if (resp.status == 200) {
        console.log("-===-Done=-=-=-=>", resp);
        if (resp.data.code == 200) {
          this.setState({
            getInvoiceList: resp.data.succ,
            getLocalInvoiceList: resp.data.succ,
          });
        } else if (resp.data.responseCode == 400) {
        }
      }
    });
  }

  clearFilter = () => {
    this.setState({
      getInvoiceList: this.state.getLocalInvoiceList,
      dateInput: "",
    });
  };

  handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      this.searchByDate();
    }
  };

  searchByDate = () => {
    // const [year, month, day] = this.state.dateInput.split("-");
    // const indianDateObject = new Date(`${month}/${day}/${year}`);
    // const usMonth = (indianDateObject.getMonth() + 1).toString();
    // const usDay = indianDateObject.getDate().toString();
    // const usYear = indianDateObject.getFullYear();
    let usDate = this.state.dateInput.toLocaleDateString();
    let filterData = this.state.getLocalInvoiceList.filter((item, i) => {
      return usDate == new Date(item.updatedAt).toLocaleDateString();
    });
    console.log(usDate);
    this.setState({ getInvoiceList: filterData });
  };

  handleChange1 = (date) => {
    this.setState({ dateInput: date });
  };

  maxValue() {
    var total = this.state.total;
    var Total = total - 0.00000001;
    this.setState({ BTCAmount: Total.toFixed(8) }, () => this.reciverAmount());

    setTimeout(() => {
      this.fetchAmount();
    }, 1000);
  }
  handleCallback1 = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    const { dateInput } = this.state;
    return (
      <div>
        <Header
          {...this.props}
          walletCallback={this.handleCallback}
          parentCallback1={this.handleCallback1}
        />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        "<ToastContainer />
        <Modal
          isOpen={this.state.G2FA}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
          }}
        >
          <div className="cardBox max-WT-550 center-box">
            <div className="cardHead center-box pb10 text-center posRel">
              <span
                className="crossBtn"
                onClick={() =>
                  this.setState({
                    G2FA: false,
                  })
                }
              >
                <i className="fa fa-times" />
              </span>

              <h4 className="font18 mt0 mb5 text-center">
                Verify Authenticator
              </h4>

              {/* <h2 className="textInitial">Verify Authenticator</h2> */}
            </div>
            <p className="rcvCoin pl15 pr15">
              if you setup 2 step verification, you can use the google
              authenticator app to receive codes even if you don't have an
              internet connection or mobile service for trades & transactions
            </p>
            <form className="mb0">
              <div className="formBox  max-WT-400 center-box">
                <div className="form-group">
                  {/* <div className="max-WT-120 cente r-box">
                            <a> <img src={`${this.state.image}`} alt="Images" /></a>
                          </div> */}
                </div>

                <div className="form-group">
                  {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                  <label className="control-label">Authenticate code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="auth_code"
                    id="auth_cade"
                    maxLength="6"
                    value={this.state.auth_code}
                    onChange={this.handlecase}
                  />
                </div>

                {this.state.auth_codeError !== "" ? (
                  <label className="error">{this.state.auth_codeError}</label>
                ) : null}
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={(e) => this.verify(e)}
                    className="btn btnGreen w-100 radius4 mb20"
                  >
                    Verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
        {/* vdffogogk */}
        <Modal
          isOpen={this.state.withdrawVri}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
          }}
        >
          <div className="cardBox max-WT-550 center-box" style={{ backgroundColor: "var(--primary-color)" }}>
            <div className="cardHead mb15 pb10 text-center">
              <button
                onClick={() =>
                  this.setState({
                    withdrawVri: false,
                  })
                }
                type="button"
                className="close"
                data-dismiss="modal"
                style={{ fontSize: 30 }}
              >
                &times;
              </button>
              {/* <h2 className="textInitial">Please Verify</h2> */}
              <h4 className="font18 mt0 mb5 text-center">Please Verify</h4>
            </div>
            <p className="rcvCoin pl15 pr15" >
              To withdraw your funds, please enter authentication code that you
              have received on your registered email address.
            </p>
            <form className="mb0">
              <div className="formBox  max-WT-400 center-box">
                <div className="form-group">
                  {/* <div className="max-WT-120 center-box">
                            <a> <img src={`${this.state.image}`} alt="Images" /></a>
                          </div> */}
                </div>

                <div className="form-group">
                  {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                  <label className="control-label">Submit Email OTP</label>
                  <input
                    type="number"
                    className="form-control"
                    name="auth_otp"
                    id="auth_cade"
                    maxLength="6"
                    value={this.state.auth_otp}
                    onChange={this.handleOtp}
                  />
                </div>
                {this.state.otpError == true && (
                  <label className="error">Please enter correct OTP</label>
                )}
                {this.state.auth_codeError !== "" ? (
                  <label className="error">{this.state.auth_codeError}</label>
                ) : null}
                <div className="text-center">
                  {this.state.coinView == "btc" ? (
                    <button
                      type="submit"
                      onClick={(e) => this.verifyWithdraw(e)}
                      className="btn btnGreen w-100 radius4 mb20"
                    >
                      OK
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => this.sendMGT(e)}
                      className="btn btnGreen w-100 radius4 mb20"
                    >
                      OK
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.invoice}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
          }}
        >
          <div
            className="cardBox max-WT-550 center-box"
            style={{ backgroundColor: "var(--white-color)" }}
          >
            <button
              onClick={() => this.invoiceModal()}
              type="button"
              className="close"
              data-dismiss="modal"
            >
              &times;
            </button>

            <div className="cardHeadModal sectionHead pb15 borderBottom pb10 text-center">
              <h4 className="textInitial">Invoice</h4>
            </div>
            <p className="invoicePad">
              {" "}
              Define parameters for Item, price, and optional details or tax.
              Share it to your customer to receive payments.
            </p>
            {this.state.generateInvoice == true ? (
              <form className="mb0">
                <div className="formBox  max-WT-400 center-box">
                  <div className="form-group">
                    {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                    <label className="control-label">Item </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Item Name "
                      name="InvoiceItem"
                      id="auth_cade"
                      maxLength="20"
                      value={this.state.InvoiceItem}
                      onChange={(e) => this.handleInvoice(e)}
                    />
                  </div>
                  {this.state.itemError == true && (
                    <label style={{ color: "red" }}>Please Enter Item</label>
                  )}
                  <div className="form-group">
                    {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                    <label className="control-label">Item Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Item Discription (Optional)"
                      name="InvoiceItemDis"
                      id="auth_cade"
                      maxLength="40"
                      value={this.state.InvoiceItemDis}
                      onChange={(e) => this.handleInvoice(e)}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      {" "}
                      {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                      <label className="control-label">Invoice Amount</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control radius-TR0 radius-BR0"
                          placeholder=" Invoice Amount"
                          id="auth_cade"
                          maxLength="8"
                          name="price"
                          value={this.state.price}
                          onChange={(e) => this.handleInvoice(e)}
                        />
                        <div className="input-group-append Width80">
                          <select
                            className="form-control select-style1 radius-TL0 radius-BL0"
                            name="currency"
                            onChange={(e) => this.onClick_currency(e)}
                          >
                            <option>{this.state.currency}</option>
                            {this.Populate_currency()}
                          </select>
                        </div>
                      </div>
                      {/* <div className='d-flex'>
                      <input type="text" className="form-control radius12 borderRight0 radius-TR0 radius-BR0" placeholder=" Select Currency"  id="auth_cade" maxLength="8"  name="calAmount" value={this.state.calAmount} onChange={(e)=> this.handleInvoice(e)} />
                      <select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0 minHeight45" name="InvoiceCurrency"   onChange={(e) => this.onClick_currency(e)}>
															<option>{this.state.InvoiceCurrency}</option>
															{this.Populate_currency()}
														</select>
                      </div> */}
                    </div>
                  </div>
                  {this.state.amountError == true && (
                    <label style={{ color: "red" }}>Please Enter Amount</label>
                  )}
                  <div className="form-group">
                    {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                    <label className="control-label">Tax/Vat in % if Any</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Optional"
                      name="tax"
                      id="auth_cade"
                      maxLength="4"
                      value={this.state.tax}
                      onChange={(e) => this.handleInvoice(e)}
                    />
                  </div>
                  <div className="form-group flex-row">
                    {/* <p>SecretKey: {this.state.SecretKey}</p> */}
                    <div>
                      <label className="control-label">Total</label>
                      {/* <label className="form-control" name="Total">
                        {this.state.Total}
                      </label> */}
                       <input
                        className="form-control"
                        placeholder={this.state.Total}
                      />
                    </div>
                    <div>
                      <label className="control-label">Amount in Bitcoin</label>
                      <input
                        className="form-control"
                        placeholder="Bitcoin"
                        id="BTCAmount"
                        pattern="[0-9]*"
                        name="BTCAmount"
                        maxLength="10"
                        value={this.state.BTCAmount}
                        onChange={(e) => this.handleInvoice(e)}
                      />
                    </div>
                    {this.state.btcError == true && (
                      <label style={{ color: "red" }}>
                        Please Enter Bitcoin
                      </label>
                    )}
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="button"
                      className="btn btnGreen radius4"
                      onClick={() => this.create()}
                    >
                      Create Invoice
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div
                className=" btnGreen pt20 w-100 d-flex align-items-center flex-wrap"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div>
                  <FacebookShareButton
                    url={`${this.state.inVoiceUrl}`}
                    quote={" Payment Invoice"}
                    className="Demo__some-network__share-button pl10"
                  >
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url={`${this.state.inVoiceUrl}`}
                    title="Payment Invoice  "
                    className="pl10"
                  >
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={`${this.state.inVoiceUrl}`}
                    title="Payment Invoice"
                    className="pl10"
                  >
                    <TelegramIcon size={40} round />
                  </TelegramShareButton>
                  {/* <EmailShareButton
                                          url={`${this.state.inVoiceUrl}`}
                                          subject="Coinbaazar"
                                          body={"Payment Invoice " + <a href="www.example.com">Link</a>}
                                          className="pl10">
                                            <EmailIcon
                                              size={40}
                                              round />
                                      </EmailShareButton> */}
                  <FacebookMessengerShareButton
                    url={`${this.state.inVoiceUrl}`}
                    appId="4556366847784258"
                    className="pl10"
                  >
                    <FacebookMessengerIcon size={40} round />
                  </FacebookMessengerShareButton>
                  {this.state.moreIcon == "All" && (
                    <TwitterShareButton
                      url={`${this.state.inVoiceUrl}`}
                      title="Payment Invoice "
                      via="Coinbaazar"
                      className="pl10"
                    >
                      <TwitterIcon size={40} round />
                    </TwitterShareButton>
                  )}
                </div>
                {this.state.moreIcon == "All" && (
                  <div>
                    <VKShareButton
                      url={`${this.state.inVoiceUrl}`}
                      title="Payment Invoice"
                      image={`${this.state.qrCodeUrlAddress}`}
                      className="pl10"
                    >
                      <VKIcon size={40} round />
                    </VKShareButton>
                    <PinterestShareButton
                      url={`${this.state.inVoiceUrl}`}
                      media={`${this.state.qrCodeUrlAddress}`}
                      description="Payment Invoice"
                      className="pl10"
                    >
                      <PinterestIcon size={40} round />
                    </PinterestShareButton>
                    <OKShareButton
                      url={`${this.state.inVoiceUrl}`}
                      image={`${this.state.qrCodeUrlAddress}`}
                      title="Payment Invoice"
                      className="pl10"
                    >
                      <OKIcon size={40} round />
                    </OKShareButton>
                    <LinkedinShareButton
                      url={`${this.state.inVoiceUrl}`}
                      title="Payment Invoice"
                      summary="Hello user you should use Coinbaazar for buy and sell BTC"
                      className="pl10"
                    >
                      <LinkedinIcon size={40} round />
                    </LinkedinShareButton>
                    <RedditShareButton
                      url={`${this.state.inVoiceUrl}`}
                      title="Payment Invoice"
                      className="pl10"
                    >
                      <RedditIcon size={40} round />
                    </RedditShareButton>

                    <PocketShareButton
                      url={`${this.state.inVoiceUrl}`}
                      title="Payment Invoice"
                      className="pl10"
                    >
                      <PocketIcon size={40} round />
                    </PocketShareButton>
                    <WorkplaceShareButton
                      url={`${this.state.inVoiceUrl}`}
                      quote="Payment Invoice"
                      className="pl10"
                    >
                      <WorkplaceIcon size={40} round />
                    </WorkplaceShareButton>
                  </div>
                )}
                {this.state.moreIcon == "" && (
                  <button
                    onClick={() => this.allIcon()}
                    className="btn mb10 ml10"
                    style={{
                      backgroundColor: "red",
                      height: 40,
                      width: 120,
                      borderRadius: 20,
                    }}
                  >
                    <p className="font12 mt5 mr40" style={{ color: "#fff" }}>
                      More
                    </p>
                  </button>
                )}
                <p className="mt15">Share Invoice to receive BTC</p>
              </div>
            )}
          </div>
        </Modal>
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle walletSection">
            <div className="container">
              <div className="d-grid">
                <select
                  name="coinView"
                  value={this.state.coinView}
                  onChange={(e) => this.selectCoin(e)}
                  className="form-control select-style1 selectTab"
                >
                  <option value="btc">Bitcoin</option>
                  <option value="mgt">Magnetocoin</option>
                </select>
                {/* <ul className="nav nav-tabs globalTab mb30" role="tablist">
                        <li className="nav-item max-WT-33Per">
                          <a className={`nav-link ${this.state.tab === "receive-btc" ? "active" : ""}`} data-toggle="tab" href="#tab1" onClick={() => this.selecttab("receive-btc")} >Receive BTC</a>
                        </li>
                        <li className="nav-item max-WT-33Per">
                          <a className={`nav-link ${this.state.tab === "send-btc" ? "active" : ""} `} data-toggle="tab" href="#tab2" onClick={() => this.selecttab("send-btc")} >Send BTC</a>
                        </li>
                        <li className="nav-item max-WT-33Per">
                          <a className={`nav-link ${this.state.tab === "transaction" ? "active" : ""} `} data-toggle="tab" href="#tab3" onClick={() => this.selecttab("transaction")} >Transaction</a>
                        </li>
                      </ul> */}
                <div className="tabList2 mb30">
                  <div
                    className={`${
                      this.state.tab === "receive-btc"
                        ? "taboxActive2"
                        : "tabox2"
                    }`}
                    data-toggle="tab"
                    href="#tab1"
                    onClick={() => this.selecttab("receive-btc")}
                  >
                    {this.state.coinView == "btc" ? (
                      <a>Receive BTC</a>
                    ) : (
                      <a>Receive MGTC</a>
                    )}
                  </div>
                  <div
                    className={`${
                      this.state.tab === "send-btc" ? "taboxActive2" : "tabox2"
                    }`}
                    data-toggle="tab"
                    href="#tab2"
                    onClick={() => this.selecttab("send-btc")}
                  >
                    {this.state.coinView == "btc" ? (
                      <a>Send BTC</a>
                    ) : (
                      <a>Send MGTC</a>
                    )}
                  </div>

                  <div
                    className={`${
                      this.state.tab === "transaction"
                        ? "taboxActive2"
                        : "tabox2"
                    }`}
                    data-toggle="tab"
                    href="#tab3"
                    onClick={() => this.selecttab("transaction")}
                  >
                    <a>Transaction</a>
                  </div>
                  <div
                    className={`${
                      this.state.tab === "invoice" ? "taboxActive2" : "tabox2"
                    }`}
                    data-toggle="tab"
                    href="#tab4"
                    onClick={() => this.selecttab("invoice")}
                  >
                    <a>Invoice</a>
                  </div>
                  <div
                    className={`${
                      this.state.tab === "salary" ? "taboxActive2" : "tabox2"
                    }`}
                    title="Pay Salaries in Bitcoin, Coming Soon..."
                    data-toggle="tab"
                    href="#tab5"
                    onClick={() => this.selecttab("salary")}
                  >
                    <a>Salary</a>
                  </div>
                </div>
              </div>
            </div>
            {this.state.coinView == "btc" && (
              <div className="container">
                <div className="tab-content">
                  {this.state.tab == "receive-btc" ? (
                    <div id="tab1" className="tab-pane active">
                      <div className="cardBox max-WT-500 center-box radius12">
                        <div className="cardHead">
                          <h4 className="font18 mt0 mb5 text-center">
                            Receive Bitcoins
                          </h4>
                        </div>
                        <div>
                          <p className="mb5 rcvCoin">
                            Give out the Bitcoin address below to receive
                            Bitcoins.
                          </p>
                          <div className="coinAddress">
                            <input
                              type="text"
                              className="form-control"
                              name="Address"
                              value={this.state.Address}
                              readOnly
                            />
                            {!this.state.Address ? (
                              <span>
                                <img
                                  src={require("../assets/ajax-loader.gif")}
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    alignSelf: "center",
                                  }}
                                  alt=""
                                />
                              </span>
                            ) : null}
                            <CopyToClipboard
                              onCopy={() =>
                                this.setState({
                                  copied: true,
                                })
                              }
                              text={this.state.Address}
                            >
                              <a href="#">
                                <i className="fas fa-copy" />
                              </a>
                            </CopyToClipboard>
                            {this.state.copied == true ? (
                              <text
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  color: "green",
                                }}
                                className="error "
                              >
                                copied
                              </text>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="mb30">
                                <h4 className="font18 mt0 mb5">Incoming Transaction and Fees</h4>
                                <p className="mb5">Current deposite fee for incoming transaction: 0.005 BTC</p>
                                <a href="javascript:void(0);" className="btn btnGreen w-100 pt10 pb10 radius4">Show your incoming bitcoin transaction</a>
                              </div>
                              <div className="mb30">
                                <h4 className="font18 mt0 mb5">Old Address</h4>
                                <div className="d-flex align-items-center flex-wrap">
                                  <label className="weight600 mb0">Created at</label>
                                  {this.state.created_at != "" ?
                                  <span className="ml-auto">Date : {this.state.created_at.replace("T" ,  "  Time : ").replace("Z","")}</span>
                                  :null}
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                  <label className="weight600 mb0">Address</label>
                                  {this.state.Address != "" ?
                                  <span className="ml-auto">{this.state.Address}</span>
                                  :null}
                                </div>
                              </div> */}
                        <div className="">
                          <h4
                            className="font14 mt15 text-center fontfamQB mb5"
                            style={{ color: "var(--text-grey)" }}
                          >
                            QR Code for mobile
                          </h4>
                          <div className="qrCode max-WT-500 text-center">
                            {this.state.qrCodeUrlAddress != "" ? (
                              <img
                                src={`${this.state.qrCodeUrlAddress}`}
                                alt="QR Code"
                              />
                            ) : (
                              <span>
                                <img
                                  src={require("../assets/ajax-loader.gif")}
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    alignSelf: "center",
                                    marginLeft: "50%",
                                    marginTop: "10%",
                                  }}
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                          <div
                            className=" btnGreen pt20 w-100 d-flex align-items-center flex-wrap"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <FacebookShareButton
                                url={`${this.state.shareUrl}`}
                                quote={" Coinbaazar BTC Wallet"}
                                className="Demo__some-network__share-button pl10"
                              >
                                <FacebookIcon size={40} round />
                              </FacebookShareButton>
                              <WhatsappShareButton
                                url={`${this.state.shareUrl}`}
                                title="Coinbaazar BTC Wallet  "
                                className="pl10"
                              >
                                <WhatsappIcon size={40} round />
                              </WhatsappShareButton>
                              <TelegramShareButton
                                url={`${this.state.shareUrl}`}
                                title="Coinbaazar BTC Wallet"
                                className="pl10"
                              >
                                <TelegramIcon size={40} round />
                              </TelegramShareButton>
                              {/* <EmailShareButton
                                        url={`${this.state.shareUrl}`}
                                        subject="Coinbaazar"
                                        body={"Coinbaazar BTC Wallet " + <a href="www.example.com">Link</a>}
                                        className="pl10">
                                          <EmailIcon
                                            size={40}
                                            round />
                                    </EmailShareButton> */}
                              <FacebookMessengerShareButton
                                url={`${this.state.shareUrl}`}
                                appId="4556366847784258"
                                className="pl10"
                              >
                                <FacebookMessengerIcon size={40} round />
                              </FacebookMessengerShareButton>
                              {this.state.moreIcon == "All" && (
                                <TwitterShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar BTC Wallet "
                                  via="Coinbaazar"
                                  className="pl10"
                                >
                                  <TwitterIcon size={40} round />
                                </TwitterShareButton>
                              )}
                            </div>
                            {this.state.moreIcon == "All" && (
                              <div>
                                <VKShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar BTC Wallet"
                                  image={`${this.state.qrCodeUrlAddress}`}
                                  className="pl10"
                                >
                                  <VKIcon size={40} round />
                                </VKShareButton>
                                <PinterestShareButton
                                  url={`${this.state.shareUrl}`}
                                  media={`${this.state.qrCodeUrlAddress}`}
                                  description="Coinbaazar BTC Wallet"
                                  className="pl10"
                                >
                                  <PinterestIcon size={40} round />
                                </PinterestShareButton>
                                <OKShareButton
                                  url={`${this.state.shareUrl}`}
                                  image={`${this.state.qrCodeUrlAddress}`}
                                  title="Coinbaazar BTC Wallet"
                                  className="pl10"
                                >
                                  <OKIcon size={40} round />
                                </OKShareButton>
                                <LinkedinShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar BTC Wallet"
                                  summary="Hello user you should use Coinbaazar for buy and sell BTC"
                                  className="pl10"
                                >
                                  <LinkedinIcon size={40} round />
                                </LinkedinShareButton>
                                <RedditShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar BTC Wallet"
                                  className="pl10"
                                >
                                  <RedditIcon size={40} round />
                                </RedditShareButton>

                                <PocketShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar BTC Wallet"
                                  className="pl10"
                                >
                                  <PocketIcon size={40} round />
                                </PocketShareButton>
                                <WorkplaceShareButton
                                  url={`${this.state.shareUrl}`}
                                  quote="Coinbaazar BTC Wallet"
                                  className="pl10"
                                >
                                  <WorkplaceIcon size={40} round />
                                </WorkplaceShareButton>
                              </div>
                            )}
                            {this.state.moreIcon == "" && (
                              <button
                                onClick={() => this.allIcon()}
                                className="btn mb10 ml10"
                                style={{
                                  backgroundColor: "red",
                                  height: 40,
                                  width: 120,
                                  borderRadius: 20,
                                }}
                              >
                                <p
                                  className="font12 mt5 mr40"
                                  style={{ color: "#fff" }}
                                >
                                  More
                                </p>
                              </button>
                            )}
                            <p
                              className="mt15"
                              style={{ color: "var(--text-grey)" }}
                            >
                              Share Wallet to receive BTC
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.tab == "send-btc" ? (
                    <div
                      id="tab2"
                      className={`tab-pane ${
                        this.state.tab === "send-btc" ? "active" : ""
                      } `}
                    >
                      <div className="cardBox p30 max-WT-500 center-box radius10 formBox">
                        <form className="mb0">
                          <div className="form-group">
                            <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                              Send Bitcoins
                            </h4>
                            <div
                              className=" border radius10 bgGray p10"
                              style={{ color: "var(--text-grey)" }}
                            >
                              <div className="d-flex align-items-center flex-wrap">
                                <label className="weight600 mb0">
                                  In your wallet:
                                </label>

                                <span className="ml-auto w-50">
                                  {this.state.total} BTC
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap mt10">
                                <label className="weight600 mb0">
                                  Transaction Fee:
                                </label>
                                <span
                                  className="ml-auto w-50"
                                  onChange={this.handleOnChange}
                                >
                                  {this.state.transactionFee} BTC
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-wrap mt10">
                                <label className="weight600 mb0">
                                  You are sending:
                                </label>
                                <span className="ml-auto w-50">
                                  {this.state.reciverAmount} BTC
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                              Receiving Bitcoin address
                            </h4>
                            <div className="">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter bitcoin address"
                                name="BTCAddress"
                                value={this.state.BTCAddress}
                                onChange={(e) => this.handleOnChange(e)}
                              />
                            </div>
                            {this.state.BTCError != "" ? (
                              <label className="error">
                                {this.state.BTCError}
                              </label>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                              Amount in Bitcoins
                            </h4>
                            <div className="d-flex">
                              <div className="w-100 align-items-center load-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0.000BTC"
                                  maxLength={10}
                                  name="BTCAmount"
                                  value={this.state.BTCAmount}
                                  onChange={(e) => this.handleOnChange(e)}
                                />
                                {this.state.loader2 ? (
                                  <span>
                                    <img
                                      src={require("../assets/ajax-loader.gif")}
                                      style={{ height: "25px", width: "25px" }}
                                      alt=""
                                    />
                                  </span>
                                ) : null}
                              </div>
                              <button
                                type="button"
                                className="btn btnGreen radius4 ml10"
                                data-toggle="modal"
                                onClick={() => this.maxValue()}
                                data-target="#deleteAccountModal"
                              >
                                Max
                              </button>
                            </div>
                            {this.state.BTCAmountError != "" ? (
                              <label className="error">
                                {this.state.BTCAmountError}
                              </label>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                              Description
                            </h4>
                            <div className="">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Appears in the transaction list"
                                maxLength={200}
                                name="Description"
                                value={this.state.Description}
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                              Amount
                            </h4>
                            <div className="d-flex">
                              <div className="w-100 align-items-center load-img">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Appears in the transaction list"
                                  name="Amount"
                                  value={this.state.price}
                                  onChange={(e) => this.handleOnChange(e)}
                                />
                                {this.state.loader1 ? (
                                  <span>
                                    <img
                                      src={require("../assets/ajax-loader.gif")}
                                      style={{ height: "25px", width: "25px" }}
                                      alt=""
                                    />
                                  </span>
                                ) : null}
                              </div>

                              <div className="minWidth100 pl10">
                                <select
                                  className="form-control select-style1"
                                  name="currency"
                                  value={this.state.currency}
                                  onChange={(e) => this.onClick_currency(e)}
                                >
                                  <option>{this.state.currency}</option>
                                  {this.Populate_currency()}
                                </select>
                              </div>
                            </div>
                            {this.state.amountError != "" ? (
                              <label className="error">
                                {this.state.amountError}
                              </label>
                            ) : null}
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btnGreen w-100 radius4"
                              data-toggle="modal"
                              onClick={this.sendBTC}
                              data-target="#deleteAccountModal"
                            >
                              Continue
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : this.state.tab == "transaction" ? (
                    <div
                      id="tab3"
                      className={`tab-pane  radius4 ${
                        this.state.tab === "transaction" ? "active" : ""
                      } `}
                    >
                      <div className="mb20">
                        <h4 className="dBlueColor">Wallet Transactions</h4>
                      </div>

                      <div className="cardBox">
                        <div className="tableBox">
                          <div className="tableBoxHead d-flex align-items-center flex-wrap">
                            <h4 className="mb15 font20 pt15">Recently</h4>
                          </div>
                          <div className="tableBoxBody">
                            <div className="table-responsive">
                              <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Received BTC</th>
                                    <th>Sent BTC</th>
                                    <th>Address</th>
                                    <th>Description</th>
                                    <th>Withdrawal Fee</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.whole_data1 != undefined
                                    ? this.populateView1Recently()
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="tableBox">
                          <div className="tableBoxBody">
                            <div className="table-responsive">
                              <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                <thead>
                                  <tr>
                                    <th>Total Received</th>
                                    <th>Total Sent</th>
                                    <th>Total Withdrawal Fee</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.reciveAmount != undefined ? (
                                    <tr>
                                      <td>{this.state.reciveAmount}</td>
                                      <td>{this.state.sendAmount}</td>
                                      <td>{this.state.withdrawAmount}</td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td colSpan="6" align="center">
                                        {" "}
                                        <label className="error text-center">
                                          No record found.
                                        </label>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        {/* <div className="tableBox">
                                    <div className="tableBoxHead d-flex align-items-center flex-wrap">
                                      <h4 className="mb15 font20">Earlier Month</h4>
                                    </div>
                                  </div> */}

                        <div className="tableBox">
                          {/* <div className="tableBoxHead d-flex align-items-center flex-wrap">
                                      <h4 className="mb15 font20">History</h4>
                                      </div> */}
                          {/* <div className="tableFilter2 ml-auto d-flex flex-wrap  align-items-center mb15">
                                        <div className="datepickerField d-flex  align-items-center">
                                          <label>From</label>
                                          <input type="text" className="form-control datepicker" />
                                        </div>
                                        <div className="datepickerField d-flex align-items-center">
                                          <label>To</label>
                                          <input type="text" className="form-control datepicker" />
                                        </div>
                                        <button type="button" className="btn btnGreen minWidth80">Resend</button>
                                      </div> */}

                          <div className="tableBoxHead d-flex align-items-center flex-wrap">
                            <h4 className="mb15">History</h4>
                            <button
                              onClick={() =>
                                this.props.history.push("/Historylist")
                              }
                              className="showMore btn ml-auto mb15 minWidth120"
                            >
                              Show More...
                            </button>
                          </div>
                          <div className="tableBoxBody">
                            <div className="table-responsive">
                              <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Txn. Id</th>
                                    <th>Description</th>
                                    <th>Sent BTC</th>
                                    <th>Received BTC</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.whole_data1 != undefined
                                    ? this.populateView1()
                                    : null}
                                  {/* <tr>
                                              <td>11/11/2018</td>
                                              <td></td>
                                              <td>.0025</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>11/11/2018</td>
                                              <td></td>
                                              <td>.0025</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>12/11/2018</td>
                                              <td></td>
                                              <td></td>
                                              <td>00.25</td>
                                            </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* <div className="globalPagination">
                                    <ul className="pagination">
                                      <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">&laquo;</span>
                                          <span className="sr-only">Previous</span>
                                        </a>
                                      </li>
                                      <li className="page-item"><a className="page-link" href="#">1</a></li>
                                      <li className="page-item"><a className="page-link" href="/HistoryList">2</a></li>
                                      <li className="page-item">
                                        <a className="page-link" href="/HistoryList" aria-label="Next">
                                          <span aria-hidden="true">&raquo;</span>
                                          <span className="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div> */}
                      </div>
                    </div>
                  ) : this.state.tab == "invoice" ? (
                    <div
                      id="tab4"
                      className={`tab-pane  radius4 ${
                        this.state.tab === "invoice" ? "active" : ""
                      } `}
                    >
                      <div className="">
                        <h4 className="dBlueColor">Invoices</h4>
                        <p style={{ color: "var(--text-grey)" }}>
                          Coinbaazar Bitcoin Invoice is a payment request that
                          is specifically denominated in Bitcoin. It is a means
                          for product sellers or service providers to request
                          payment from buyers using Bitcoin as the preferred
                          method of transaction. Bitcoin invoices contain
                          relevant information such as the amount to be paid,
                          the seller's Bitcoin address, and any additional
                          details necessary for completing the payment.You can
                          share your Bitcoin Invoice to your customers to
                          receive bitcoins.
                        </p>
                      </div>
                      <div className="invoiceTab">
                        <button
                          type="button"
                          className="btn btnGreen radius4"
                          onClick={() => this.createInvoice()}
                        >
                          Create Invoice
                        </button>

                        <div
                          className="input-per"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 5,
                          }}
                        >
                          <DatePicker
                            className="form-control radius6"
                            value={
                              this.state.dateInput // selected={this.state.dateInput}
                            }
                            selected={this.state.dateInput}
                            onChange={this.handleChange1}
                            onKeyDown={this.handleInputKeyDown} // onChange={(e) =>
                            //   this.setState({ dateInput: e.target.value })
                            // }
                            placeholderText="MM/DD/YYYY"
                            maxDate={new Date()}
                          />

                          <div
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#30b5d1",
                              width: 45,
                              display: "grid",
                              placeItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <i
                              className="fa fa-search"
                              style={{ fontSize: 25, color: "white" }}
                              onClick={this.searchByDate}
                            />
                          </div>
                          <button
                            className="btn btnGreen radius4"
                            onClick={this.clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      {this.state.invoice == true && <div />}

                      <div className="tableBox">
                        <div className="tableBoxBody">
                          <div className="table-responsive tbl">
                            <table className="table tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                              <thead className="tbl_hd">
                                <tr>
                                  <th>Date</th>
                                  <th>Order Id</th>
                                  <th>Description</th>
                                  <th>Status</th>
                                  <th>Tax</th>
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.getInvoiceList.map((item, i) => {
                                  return (
                                    // <div>
                                    <tr key={i}>
                                      <td>
                                        {new Date(
                                          item.updatedAt
                                        ).toLocaleString("en-US", {
                                          timeZone: this.state.utcZone,
                                        })}
                                      </td>
                                      <td>{item.orderNo}</td>
                                      <td>{item.itemDescription}</td>
                                      <td>{item.status}</td>
                                      <td>{item.tax}%</td>
                                      <td>
                                        {item.amount}
                                        (BTC)
                                      </td>
                                      <td>
                                        {item.status == "paid" ? (
                                          <a
                                            href={item.receipt}
                                            target="_blank"
                                            className="mr10"
                                            download
                                          >
                                            <i class="fa fa-download dBlueColor" />
                                          </a>
                                        ) : null}
                                        <i
                                          className="fa fa-arrow-right dBlueColor"
                                          onClick={() =>
                                            this.setState({
                                              tab: "transaction",
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>
                                    // </div>
                                  );
                                })}
                                {/* <tr>
                                     <td>25-03-2022</td>
                                     <td>hgsyut7628</td>
                                     <td>rytywtyuyiui</td>
                                     <td>paid</td>
                                     <td>$2.00(USD)</td>
                                     <td><i className='fa fa-arrow-right dBlueColor'></i></td>
                                   </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.tab == "salary" ? (
                    <div id="tab5" className="tab-pane active">
                      <div className="cardBox max-WT-500 center-box radius12">
                        <div
                          className="cardHead"
                          style={{ "border-radius": "12px" }}
                        >
                          <h4 className="font18 mt0 mb5 text-center">
                            Coming Soon
                          </h4>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <!-- tab3 End --> */}
                </div>
                {/* <!-- Tab-Content Start --> */}
              </div>
            )}
            {this.state.coinView == "mgt" && (
              <div className="container">
                {/* <div className="col-md-8 selectTab">
                            
                      </div> */}
                {/* <ul className="nav nav-tabs globalTab w-100  mb30" role="tablist">
                          <li className="nav-item max-WT-33Per">
                            <a className={`nav-link ${this.state.tab === "receive-btc" ? "active" : ""}`} data-toggle="tab" href="#tab1" onClick={() => this.selecttab("receive-btc")} >Receive MGTC</a>
                          </li>
                          <li className="nav-item max-WT-33Per">
                            <a className={`nav-link ${this.state.tab === "send-btc" ? "active" : ""} `} data-toggle="tab" href="#tab2" onClick={() => this.selecttab("send-btc")} >Send MGTC</a>
                          </li>
                          <li className="nav-item max-WT-33Per">
                            <a className={`nav-link ${this.state.tab === "transaction" ? "active" : ""} `} data-toggle="tab" href="#tab3" onClick={() => this.selecttab("transaction")} >Transaction</a>
                          </li>
                        </ul> */}
                {this.state.oldAcnt == false ? (
                  <div className="tab-content">
                    {this.state.tab == "receive-btc" ? (
                      <div id="tab1" className="tab-pane active">
                        <div className="cardBox max-WT-500 center-box radius12">
                          <div className="cardHead">
                            <h4 className="font18 mt0 mb5 text-center">
                              Receive Magneto coins
                            </h4>
                          </div>
                          <div className="">
                            <p className="mb5 rcvCoin">
                              Give out the Magnetocoin address below to receive
                              Magneto coins.
                            </p>
                            <div className="coinAddress">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.mgtAddress}
                              />
                              {!this.state.mgtAddress ? (
                                <span>
                                  {" "}
                                  // mmmmmmmmmmmmmmmmmmmm
                                  <img
                                    src={require("../assets/ajax-loader.gif")}
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      alignSelf: "center",
                                    }}
                                    alt=""
                                  />
                                </span>
                              ) : null}
                              <CopyToClipboard
                                onCopy={() =>
                                  this.setState({
                                    copied: true,
                                  })
                                }
                                text={this.state.mgtAddress}
                              >
                                <a href="#">
                                  <i className="fas fa-copy" />
                                </a>
                              </CopyToClipboard>
                              {this.state.copied == true ? (
                                <text
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    color: "green",
                                  }}
                                  className="error "
                                >
                                  copied
                                </text>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="mb30">
                                  <h4 className="font18 mt0 mb5">Incoming Transaction and Fees</h4>
                                  <p className="mb5">Current deposite fee for incoming transaction: 0.005 BTC</p>
                                  <a href="javascript:void(0);" className="btn btnGreen w-100 pt10 pb10 radius4">Show your incoming bitcoin transaction</a>
                                </div>
                                <div className="mb30">
                                  <h4 className="font18 mt0 mb5">Old Address</h4>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <label className="weight600 mb0">Created at</label>
                                    {this.state.created_at != "" ?
                                    <span className="ml-auto">Date : {this.state.created_at.replace("T" ,  "  Time : ").replace("Z","")}</span>
                                    :null}
                                  </div>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <label className="weight600 mb0">Address</label>
                                    {this.state.Address != "" ?
                                    <span className="ml-auto">{this.state.Address}</span>
                                    :null}
                                  </div>
                                </div> */}
                          <div className="">
                            <h4 className="font14 mt15 text-center fontfamQB mb5">
                              QR Code for mobile
                            </h4>
                            <div className="qrCode max-WT-500">
                              {this.state.mgtqrAddress != "" ? (
                                <a
                                  style={{
                                    alignSelf: "center",
                                    marginLeft: "35%",
                                    marginTop: "10%",
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={`${this.state.mgtqrAddress}`}
                                    alt="QR Code"
                                  />
                                </a>
                              ) : (
                                <span>
                                  <img
                                    src={require("../assets/ajax-loader.gif")}
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      alignSelf: "center",
                                      marginLeft: "50%",
                                      marginTop: "10%",
                                    }}
                                    alt=""
                                  />
                                </span>
                              )}
                            </div>
                            <div
                              className="btnGreen p10 pt20 pb10 w-100 d-flex align-items-center flex-wrap"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <div>
                                <FacebookShareButton
                                  url={`${this.state.shareUrl}`}
                                  quote={" Coinbaazar MGTC Wallet"}
                                  className="Demo__some-network__share-button pl10"
                                >
                                  <FacebookIcon size={40} round />
                                </FacebookShareButton>
                                <WhatsappShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar MGTC Wallet "
                                  className="pl10"
                                >
                                  <WhatsappIcon size={40} round />
                                </WhatsappShareButton>
                                <TelegramShareButton
                                  url={`${this.state.shareUrl}`}
                                  title="Coinbaazar MGTC Wallet"
                                  className="pl10"
                                >
                                  <TelegramIcon size={40} round />
                                </TelegramShareButton>
                                {/* <EmailShareButton
                                          url={`${this.state.shareUrl}`}
                                          subject="Coinbaazar"
                                          body={"Coinbaazar MGT Wallet " + <a href="www.example.com">Link</a>}
                                          className="pl10">
                                            <EmailIcon
                                              size={40}
                                              round />
                                      </EmailShareButton> */}
                                <FacebookMessengerShareButton
                                  url={`${this.state.shareUrl}`}
                                  appId="4556366847784258"
                                  className="pl10"
                                >
                                  <FacebookMessengerIcon size={40} round />
                                </FacebookMessengerShareButton>
                                {this.state.moreIcon == "All" && (
                                  <TwitterShareButton
                                    url={`${this.state.shareUrl}`}
                                    title="Coinbaazar MGTC Wallet "
                                    via="Coinbaazar"
                                    className="pl10"
                                  >
                                    <TwitterIcon size={40} round />
                                  </TwitterShareButton>
                                )}
                              </div>
                              {this.state.moreIcon == "All" && (
                                <div>
                                  <VKShareButton
                                    url={`${this.state.shareUrl}`}
                                    title="Coinbaazar MGTC Wallet"
                                    image={`${this.state.qrCodeUrlAddress}`}
                                    className="pl10"
                                  >
                                    <VKIcon size={40} round />
                                  </VKShareButton>
                                  <PinterestShareButton
                                    url={`${this.state.shareUrl}`}
                                    media={`${this.state.qrCodeUrlAddress}`}
                                    description="Coinbaazar MGTC Wallet"
                                    className="pl10"
                                  >
                                    <PinterestIcon size={40} round />
                                  </PinterestShareButton>
                                  <OKShareButton
                                    url={`${this.state.shareUrl}`}
                                    image={`${this.state.qrCodeUrlAddress}`}
                                    title="Coinbaazar MGTC Wallet"
                                    className="pl10"
                                  >
                                    <OKIcon size={40} round />
                                  </OKShareButton>
                                  <LinkedinShareButton
                                    url={`${this.state.shareUrl}`}
                                    title="Coinbaazar MGTC Wallet"
                                    summary="Hello user you should use Coinbaazar for buy and sell MGTC"
                                    className="pl10"
                                  >
                                    <LinkedinIcon size={40} round />
                                  </LinkedinShareButton>
                                  <RedditShareButton
                                    url={`${this.state.shareUrl}`}
                                    title="Coinbaazar MGTC Wallet"
                                    className="pl10"
                                  >
                                    <RedditIcon size={40} round />
                                  </RedditShareButton>

                                  <PocketShareButton
                                    url={`${this.state.shareUrl}`}
                                    title="Coinbaazar MGTC Wallet"
                                    className="pl10"
                                  >
                                    <PocketIcon size={40} round />
                                  </PocketShareButton>
                                  <WorkplaceShareButton
                                    url={`${this.state.shareUrl}`}
                                    quote="Coinbaazar MGTC Wallet"
                                    className="pl10"
                                  >
                                    <WorkplaceIcon size={40} round />
                                  </WorkplaceShareButton>
                                </div>
                              )}
                              {this.state.moreIcon == "" && (
                                <button
                                  onClick={() => this.allIcon()}
                                  className="btn mb10 ml10"
                                  style={{
                                    backgroundColor: "red",
                                    height: 40,
                                    width: 120,
                                    borderRadius: 20,
                                  }}
                                >
                                  <p
                                    className="font12 mt5 mr40"
                                    style={{ color: "#fff" }}
                                  >
                                    More
                                  </p>
                                </button>
                              )}
                              <p className="mt15">
                                Share Wallet to receive MGTC
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : this.state.tab == "send-btc" ? (
                      <div
                        id="tab2"
                        className={`tab-pane ${
                          this.state.tab === "send-btc" ? "active" : ""
                        } `}
                      >
                        <div className="cardBox p30 max-WT-500 center-box radius10 formBox">
                          <form className="mb0">
                            <div className="form-group">
                              <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                                Send MagnetoCoin
                              </h4>
                              <div className=" border radius10 bgGray p10">
                                <div className="d-flex align-items-center flex-wrap">
                                  <label className="weight600 mb0">
                                    In your wallet:
                                  </label>

                                  <span className="ml-auto w-50">
                                    {this.state.totalMgt} MGTC
                                  </span>
                                </div>
                                <div className="d-flex align-items-center flex-wrap mt10">
                                  <label className="weight600 mb0">
                                    Transaction Fee:
                                  </label>
                                  <span
                                    className="ml-auto w-50"
                                    onChange={this.handleOnChange}
                                  >
                                    {this.state.transactionFee
                                      ? this.state.transactionFee
                                      : ""}{" "}
                                    MGTC
                                  </span>
                                </div>
                                <div className="d-flex align-items-center flex-wrap mt10">
                                  <label className="weight600 mb0">
                                    You send upto:
                                  </label>
                                  <span className="ml-auto w-50">0 MGTC</span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                                Receiving Magneto address
                              </h4>
                              <div className="">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Magneto address"
                                  name="MGTAddress"
                                  value={this.state.MGTAddress}
                                  onChange={(e) => this.getMgtVal(e)}
                                />
                              </div>
                              {this.state.MGTError != "" ? (
                                <label className="error">
                                  {this.state.MGTError}
                                </label>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                                Amount in Magneto Coin
                              </h4>
                              <div className="d-flex">
                                <div className="w-100 align-items-center load-img">
                                  <input
                                    type="text"
                                    id="MGT"
                                    className="form-control"
                                    placeholder="0.000MGTC"
                                    name="MGTAmount"
                                    value={this.state.MGTAmount}
                                    onChange={(e) => this.getMgtVal(e)}
                                  />
                                  {this.state.loader2 ? (
                                    <span>
                                      <img
                                        src={require("../assets/ajax-loader.gif")}
                                        style={{
                                          height: "25px",
                                          width: "25px",
                                        }}
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                </div>
                                <button
                                  type="button"
                                  className="btn btnGreen radius4 ml10"
                                  data-toggle="modal"
                                  onClick={() => {
                                    this.setState({
                                      MGTAmount: this.state.totalMgt,
                                    });
                                    setTimeout(() => {
                                      this.fetchAmount();
                                    }, 1000);
                                  }}
                                  data-target="#deleteAccountModal"
                                >
                                  Max
                                </button>
                              </div>
                              {this.state.MGTAmountError != "" ? (
                                <label className="error">
                                  {this.state.MGTAmountError}
                                </label>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                                Description
                              </h4>
                              <div className="">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Appears in the transaction list"
                                  maxLength={200}
                                  name="Description"
                                  value={this.state.Description}
                                  onChange={this.handleOnChange}
                                />
                              </div>
                            </div>
                            {/* commentonlye for MGT */}
                            {/* <div className="form-group">
                                      <h4 className="font18 mt0 mb5">Amount</h4>
                                      <div className="d-flex">
                                        <div className="w-100 align-items-center load-img">
                                          <input type="text" className="form-control" placeholder="Appears in the transaction list" name="Amount" value={this.state.price}  onChange={(e)=> this.handleOnChange(e)}/>
                                          {this.state.loader1 ?  
                                                              <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px" }} alt="" /></span>
                                                              :null}
                                        </div>
                                        
                                        <div className="minWidth100 pl10">
                                          <select className="form-control select-style1" value={this.state.currency} onChange={(e) => this.onClick_currency(e)}>
                                          
                                            {this.Populate_currency()}
                                          </select>
                                        </div>
                                      </div>
                                      {this.state.amountError != "" ?
                                        <label className="error">{this.state.amountError}</label>
                                        : null}
                                    </div> */}
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btnGreen w-100 radius4"
                                data-toggle="modal"
                                onClick={this.MGTsend}
                                data-target="#deleteAccountModal"
                              >
                                Continue
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : this.state.tab == "transaction" ? (
                      <div
                        id="tab3"
                        className={`tab-pane  radius4 ${
                          this.state.tab === "transaction" ? "active" : ""
                        } `}
                      >
                        <div className="mb20">
                          <h4 className="dBlueColor">Wallet Transactions</h4>
                        </div>

                        <div className="cardBox">
                          <div className="tableBox">
                            {/* <div className="tableBoxHead d-flex align-items-center flex-wrap">
                                      <h4 className="mb15 font20">Recently</h4>
                                    </div>
                                    <div className="tableBoxBody">
                                      <div className="table-responsive">
                                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                          <thead>
                                            <tr>
                                              <th>Date</th>
                                              <th>Received MGT</th>
                                              <th>Sent MGT</th>
                                              <th>Address</th>
                                              <th>Description</th>
                                              <th>Withdrawal Fee</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.whole_MGT_data1 != undefined ?
                                            this.populateMGTRecently()
                                          :null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div> */}
                          </div>

                          {/* <div className="tableBox">
                                    <div className="tableBoxBody">
                                      <div className="table-responsive">
                                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                          <thead>
                                            <tr>
                                              <th>Total Received</th>
                                              <th>Total Sent</th>
                                              <th>Total Withdrawal Fee</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {(this.state.reciveAmount != undefined) ?
                                              <tr>
                                                <td>{this.state.reciveAmount}</td>
                                                <td>{this.state.sendAmount}</td>
                                                <td>{this.state.withdrawAmount}</td>
                                              </tr>
                                              : <tr>
                                                <td colspan="6" align="center"> <label className="error text-center">No record found.</label></td>
                                              </tr>}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div> */}

                          {/* <div className="tableBox">
                                    <div className="tableBoxHead d-flex align-items-center flex-wrap">
                                      <h4 className="mb15 font20">Earlier Month</h4>
                                    </div>
                                  </div> */}

                          <div className="tableBox">
                            {/* <div className="tableBoxHead d-flex align-items-center flex-wrap">
                                      <h4 className="mb15 font20">History</h4>
                                      </div> */}
                            {/* <div className="tableFilter2 ml-auto d-flex flex-wrap  align-items-center mb15">
                                        <div className="datepickerField d-flex  align-items-center">
                                          <label>From</label>
                                          <input type="text" className="form-control datepicker" />
                                        </div>
                                        <div className="datepickerField d-flex align-items-center">
                                          <label>To</label>
                                          <input type="text" className="form-control datepicker" />
                                        </div>
                                        <button type="button" className="btn btnGreen minWidth80">Resend</button>
                                      </div> */}

                            <div className="tableBoxHead d-flex align-items-center flex-wrap pt15">
                              <h4 className="mb15">History</h4>
                              <button
                                onClick={() =>
                                  this.props.history.push("/Historylist")
                                }
                                className="showMore btn ml-auto mb15 minWidth120"
                              >
                                Show More...
                              </button>
                            </div>
                            <div className="tableBoxBody">
                              <div className="table-responsive">
                                <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Txn. Id</th>
                                      <th>Description</th>
                                      <th>Sent MGTC</th>
                                      <th>Received MGTC</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.whole_MGT_data1 != undefined
                                      ? this.populateMGT()
                                      : null}
                                    {/* <tr>
                                              <td>11/11/2018</td>
                                              <td></td>
                                              <td>.0025</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>11/11/2018</td>
                                              <td></td>
                                              <td>.0025</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>12/11/2018</td>
                                              <td></td>
                                              <td></td>
                                              <td>00.25</td>
                                            </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* <div className="globalPagination">
                                    <ul className="pagination">
                                      <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">&laquo;</span>
                                          <span className="sr-only">Previous</span>
                                        </a>
                                      </li>
                                      <li className="page-item"><a className="page-link" href="#">1</a></li>
                                      <li className="page-item"><a className="page-link" href="/HistoryList">2</a></li>
                                      <li className="page-item">
                                        <a className="page-link" href="/HistoryList" aria-label="Next">
                                          <span aria-hidden="true">&raquo;</span>
                                          <span className="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div> */}
                        </div>
                      </div>
                    ) : null}
                    {/* <!-- tab3 End --> */}
                  </div>
                ) : (
                  <div className="max-WT-500 center-box">
                    <button
                      className="btn btnGreen w-100 radius4"
                      onClick={(e) => this.creatMGTwllet(e)}
                    >
                      Create Your Wallet
                    </button>
                  </div>
                )}
                {/* <!-- Tab-Content Start --> */}
              </div>
            )}
          </section>
        </main>
        {/* // <!-- createAccountSection Start --> */}
        {/* <section className="createAccountSection bgGreen">
          <div className="container-fluid">
            <div className="createAccountBox">
              <span className="wColor">Getting started is easy. Create your account now.</span>
              <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
            </div>
          </div>
        </section> */}
        <Footer {...this.props} />
      </div>
    );
  }
}
