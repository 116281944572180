import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { Api } from "../services/webservices";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import { base32 } from "../services/Decrypt";
import Countdown from "react-countdown-now";
import { CSVLink } from "react-csv";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { NAME } from "../services/globals";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import { changeUTCTime } from "../components/convertTimeToUTC";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";

let getUTCZone = "";
let converetdTime = [];
let header1 = [];
let data1 = [];
let header2 = [];
let data2 = [];
// var MappleToolTip = require('reactjs-mappletooltip');
export default class MainDashboard extends Component {
  constructor(props) {
    super(props);
    console.log("========props====>", this.props);
    this.state = {
      whole_Data1: "",
      whole_Data2: "",
      whole_data: [],
      filterFromHere: [],
      paymentMethod: ["Search by Payment Method"],
      loading: false,
      addr: "",
      showModal: false,
      EnableshowModal: false,
      tab: "2",
      utcZone: "Europe/London",
      completeBond: true,
      bondStatus: true,
    };
  }

  componentDidMount() {
    // console.log("+++++++++++++++from-dashboard")
    window.scrollTo(0, 0);

    let getUtc = localStorage.getItem("_utc_");
    if (getUtc == undefined || getUtc == "") {
      this.setState({
        utcZone: this.state.utcZone,
      });
    } else {
      this.setState({ utcZone: getUtc });
    }

    getUTCZone = localStorage.getItem("utcTimeZone");
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    let tabInd = localStorage.getItem("tab1");
    setTimeout(() => {
      this.setState(
        {
          _id: id,
          token: token,
          addr: addr,
          tab: tabInd || "2",
        },
        () => this._loadData(1)
      );
    }, 300);
  }

  CSVDownload = () => {
    if (this.state.ClosedTradeList.length == 0) {
      return;
    }
    let _data = [];

    header1 = [
      { label: "#", key: "S" },
      { label: "Created At", key: "createdAt" },
      { label: "Trading Partner", key: "tradingPartner" },
      { label: "Transaction status", key: "transactionStatus" },
      { label: "Trade type", key: "Trade_type" },
      { label: "Trade ID", key: "Trade_ID" },
      { label: "Fiat", key: "Fiat" },
      { label: "Trade Amount", key: "tradeAmount" },
      { label: "Trade Fee", key: "trade_fee" },
      { label: "Total BTC", key: "totalBTC" },
      { label: "Price/BTC", key: "Exchange_rate" },
    ];

    data1 = [];
    for (let j = 0; j < this.state.ClosedTradeList.length; j++) {
      var _a = {
        S: `${j + 1}`,
        createdAt: `${this.state.ClosedTradeList[j]["createdAt"]
          .replace("T", " ( ")
          .replace("Z", "")
          .split(".")[0] + " )"}`,
        tradingPartner:
          this.state.ClosedTradeList[j]["buyerId"] == this.state._id
            ? `${this.state.ClosedTradeList[j]["seller"][0]["seller_name"]}`
            : `${this.state.ClosedTradeList[j]["buyer"][0]["buyer_name"]}`,
        transactionStatus: `${this.state.ClosedTradeList[j]["request_status"]}`,
        Trade_type:
          this.state.ClosedTradeList[j]["userId"] == this.state._id
            ? `${this.state.ClosedTradeList[j]["type_of_trade_original"]}`
            : `${this.state.ClosedTradeList[j]["type_of_trade_other"]}`,
        Trade_ID: `${this.state.ClosedTradeList[j]["_id"]}`,
        Fiat: `${this.state.ClosedTradeList[j]["currency_type"]}`,
        tradeAmount: `${this.state.ClosedTradeList[j]["amount_in_currency"]}`,
        trade_fee: `${Number(
          this.state.ClosedTradeList[j]["transactionFee"]
        ).toFixed(8)}`,
        totalBTC: Number(
          this.state.ClosedTradeList[j]["amount_of_cryptocurrency"]
        ).toFixed(8),
        Exchange_rate: Number(
          this.state.ClosedTradeList[j]["exchangeRate"]
        ).toFixed(2),
      };

      data1.push(_a);
    }
  };

  CSVDownload2 = (type) => {
    if (type.length == 0) {
      return;
    }

    let header2 = [
      { label: "#", key: "S" },
      { label: "Created At", key: "createdAt" },
      { label: "Trading Partner", key: "tradingPartner" },
      { label: "Transaction status", key: "transactionStatus" },
      { label: "Trade ID", key: "Trade_ID" },
      { label: "Fiat", key: "Fiat" },
      { label: "Trade Amount", key: "tradeAmount" },
      { label: "Trade Fee", key: "trade_fee" },
      { label: "Total BTC", key: "totalBTC" },
      { label: "Price/BTC", key: "Exchange_rate" },
    ];
    data2 = [];

    for (let j = 0; j < type.length; j++) {
      var _a = {
        S: `${j + 1}`,
        createdAt: `${type[j]["createdAt"]
          .replace("T", " ( ")
          .replace("Z", "")
          .split(".")[0] + " )"}`,
        tradingPartner:
          type[j]["buyerId"] == this.state._id
            ? `${type[j]["seller"][0]["seller_name"]}`
            : `${type[j]["buyer"][0]["buyer_name"]}`,
        transactionStatus: `${type[j]["request_status"]}`,
        Trade_ID: `${type[j]["_id"]}`,
        Fiat: `${type[j]["currency_type"]}`,
        tradeAmount: `${type[j]["amount_in_currency"]}`,
        trade_fee: `${Number(type[j]["transactionFee"]).toFixed(8)}`,
        totalBTC: Number(type[j]["amount_of_cryptocurrency"]).toFixed(8),
        Exchange_rate: Number(type[j]["exchangeRate"]).toFixed(2),
      };

      data2.push(_a);
    }
  };

  stateApis = () => {
    this.userDetails();
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  userDetails = () => {
    var parameter = { userId: this.state._id };

    Api(
      "trade/userDetails",
      "POST",
      parameter,
      this.state._id,
      this,
      this.state.token
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var spendDays =
            Date.now() - Number(resp.data.result[0]["bondActivationTime"]);
          var dateRemaining = 24 * 60 * 60 * 1000 * 7 - spendDays;

          this.setState({
            bondTime: Math.round(dateRemaining),
            bondStatus: resp.data.result[0]["userBond"],
            ActivationTime: resp.data.result[0]["bondActivationTime"],
            completeBond: resp.data.result[0]["retrieveBondMoney"],
          });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  };
  wallet = () => {
    var parameter = { _id: this.state._id };

    Api(
      "user/userProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            var num = Number(resp.data.result.btc.total);

            var n = num.toFixed(8);

            this.setState({ total: n });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };

  uniquePaymentMethods() {
    this.state.whole_data.map((item) => {
      if (!this.state.paymentMethod.includes(item.payment_method)) {
        this.state.paymentMethod.push(item.payment_method);
      }
    });
  }

  _loadData(value) {
    var parameter = { userId: this.state._id, limit: 10, page: value };

    // this.setState({ loading: true })
    Api(
      "trade/user_ad_list",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState(
              {
                filterFromHere: resp.data.result["docs"],
                whole_data: resp.data.result["docs"],
                whole_dataTotal: resp.data.result["total"],
              },
              () => {
                this.populate_view();
                this.uniquePaymentMethods();

                for (var i in this.state.whole_data) {
                  converetdTime[i] = this.state.whole_data[i].createdAt;
                  converetdTime[i] = new Date(converetdTime[i]);
                  converetdTime[i] = converetdTime[i].getTime();
                  converetdTime[i] = changeUTCTime(
                    converetdTime[i],
                    getUTCZone
                  );
                }
              }
            );
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
    this.wallet();
    this.DisputedTradeList(1);
    this.ClosedTradeList(1);
    this.CompletedTradeList(1);
    this.CanceledTradeList(1);
    this.transferBondDetail();
  }
  transferBondDetail = () => {
    // this.setState({ loading: true })
    Api("trade/tradeBond", "GET", {}, this.state.token, this.state._id).then(
      (resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              tardeBondValue: resp.data["result"],
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
        }
      }
    );
  };
  DisputedTradeList(value) {
    var parameter = { userId: this.state._id, limit: 10, pageNumber: value };

    // this.setState({ loading: true })
    Api(
      "trade/disputeTradeList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status === 200) {
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
          if (resp.data.responseCode === 200) {
            this.setState({
              DisputedTradeList: resp.data.Data["docs"],
              DisputedTradeListTotal: resp.data.Data["total"],
            });
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  }
  CompletedTradeList(value) {
    var parameter = { userId: this.state._id, limit: 10, pageNumber: value };

    // this.setState({ loading: true })
    Api(
      "trade/getCompleteTradeList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              CompletedTradeList: resp.data.Data["docs"],
              CompletedTradeListTotal: resp.data.Data["total"],
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  }
  ClosedTradeList(value) {
    var parameter = { userId: this.state._id, limit: 10, pageNumber: value };
    // this.setState({ loading: true })
    Api(
      "trade/getAllTradeList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              ClosedTradeList: resp.data.Data["docs"],
              ClosedTradeListTotal: resp.data.Data["total"],
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  }
  CanceledTradeList(value) {
    var parameter = { userId: this.state._id, limit: 10, pageNumber: value };
    // this.setState({ loading: true })
    Api(
      "trade/getCancelTradeList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          }

          if (resp.data.responseCode == 200) {
            this.setState({
              CanceledTradeList: resp.data.Data["docs"],
              CanceledTradeListTotal: resp.data.Data["total"],
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  }

  populate_viewMore = () => {
    let table = [];
    // Outer loop to create parent

    if (this.state.whole_data.length == 0) {
      return (
        <tr>
          <td colSpan="8" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }
    for (
      var i = 0;
      i <
      (this.state.whole_data.length < 10 ? this.state.whole_data.length : 10);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 8; j++) {
        // if (j == 0) {
        //   children.push(<td>{`${i + 1}`}</td>);
        // } else
        if (j == 0) {
          children.push(
            <td>
              <span>{this.state.whole_data[i]["status"]}</span>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td>{`${this.state.whole_data[i][
              "type_of_trade_original"
            ].toUpperCase()}`}</td>
          );
        } else if (j == 2) {
          children.push(
            <td>
              {`${this.state.whole_data[i]["payment_method"]}`}
              <p className="mb-0 tags-pay">{this.populateTagBuy(i)}</p>
            </td>
          );
        } else if (j == 3) {
          if (this.state.whole_data[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 4) {
          this.state.whole_data[i]["toPay"]
            ? children.push(
                <td style={{ width: "100px" }}>
                  {"$" + `${this.state.whole_data[i]["toPay"]}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        }
        // else if (j == 4) {
        //     children.push(<td>{`${this.state.whole_data[i]["price_equation"]}`}</td>)
        // }
        else if (j == 5) {
          children.push(
            <td>
              <p style={{ marginBottom: 0, fontSize: 16 }}>
                {`min: ${this.state.whole_data[i]["min_transaction_limit"]} ${
                  this.state.whole_data[i]["currency_type"]
                }`}
              </p>
              <p style={{ marginBottom: 0, fontSize: 16 }}>
                {`max: ${this.state.whole_data[i]["max_transaction_limit"]} ${
                  this.state.whole_data[i]["currency_type"]
                }`}
              </p>
            </td>
          );
        } else if (j == 6) {
          this.state.whole_data[i]["status"] != "DISABLED"
            ? this.state.whole_data[i]["type_of_trade_original"] == "sell"
              ? children.push(
                  <td className="Width400 text-center">
                    <a
                      href={`/edit-offer/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80"
                    >
                      <span
                        id={i}
                        onClick={(e) => {
                          localStorage.setItem(
                            "tradeTerm",
                            this.state.whole_data[e.target.id]["terms_of_trade"]
                          );
                        }}
                      >
                        Edit
                      </span>
                    </a>
                    <a
                      href={`/sell-bitcoins-instantly/sell/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80 m-1"
                    >
                      Details
                    </a>
                  </td>
                )
              : children.push(
                  <td className="Width400 text-center">
                    <a
                      href={`/edit-offer/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80"
                    >
                      <span
                        id={i}
                        onClick={(e) => {
                          localStorage.setItem(
                            "tradeTerm",
                            this.state.whole_data[e.target.id]["terms_of_trade"]
                          );
                        }}
                      >
                        Edit
                      </span>
                    </a>
                    <a
                      href={`/buy-bitcoin-instantly/buy/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80 m-1"
                    >
                      Details
                    </a>
                  </td>
                )
            : children.push(
                <td className="Width400 text-center">
                  <a
                    href={`/buy-bitcoin-instantly/buy/${base32.encode(
                      this.state.whole_data[i]["_id"]
                    )}`}
                    target="_blank"
                    id={`${this.state.whole_data[i]["_id"]}`}
                    className="btn btnGray minWidth80 m-1"
                  >
                    Details
                  </a>
                </td>
              );
        } else if (j == 7) {
          this.state.whole_data[i]["status"] == "ACTIVE"
            ? children.push(
                <td className="text-center">
                  <label className={`switch custom-switch toggle-switch`}>
                    <input
                      type="checkbox"
                      checked
                      onClick={(e) =>
                        this.setState({
                          showModal: true,
                          deleteItem: e.target.id,
                        })
                      }
                      id={`${this.state.whole_data[i]["_id"]}`}
                    />
                    <span className="slider round" />
                    <div style={{marginTop:15,flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {this.state.whole_data[i]["visitor"]?(
                                  <p style={{margin:2,marginLeft:4}}>{this.state.whole_data[i]["visitor"]}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                  </label>
                </td>
              )
            : children.push(
                <td className="text-center">
                  <label className={`switch custom-switch`}>
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        this.setState({
                          EnableshowModal: true,
                          EnableItem: e.target.id,
                        })
                      }
                      id={`${this.state.whole_data[i]["_id"]}`}
                    />
                    <span className="slider round" />
                    <div style={{marginTop:15,flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {this.state.whole_data[i]["visitor"]?(
                                  <p style={{margin:2,marginLeft:4}}>{this.state.whole_data[i]["visitor"]}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                  </label>
                </td>
              );
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  // populateTagSell=(i)=>
  // {
  //   let a = []
  //   for(var k=0 ;k<this.state.whole_data2[i]["add_tags"].length;k++)
  //   {

  //     a.push(<span className="badge">{this.state.whole_data2[i]["add_tags"][k]}</span>)
  //   }
  //   return (a)
  // }
  populateTagBuy = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge" key={k}>
          {this.state.whole_data[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  populate_view = () => {
    let table = [];
    // Outer loop to create parent

    if (this.state.whole_data.length == 0) {
      return (
        <tr>
          <td colSpan="9" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }
    for (
      var i = 0;
      i < (this.state.whole_data.length < 5 ? this.state.whole_data.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children  onClick={(e) => this.setState({EnableshowModal:true,EnableItem:e.target.id})}
      for (let j = 0; j <= 8; j++) {
        if (j == 0) {
          // children.push(<td key={j}>{`${i + 1}`}</td>);
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <span>{this.state.whole_data[i]["status"]}</span>
            </td>
          );
        } else if (j == 2) {
          children.push(
            <td key={j}>{`${this.state.whole_data[i][
              "type_of_trade_original"
            ].toUpperCase()}`}</td>
          );
        } else if (j == 3) {
          children.push(
            <td key={j}>
              {`${this.state.whole_data[i]["payment_method"]}`}
              <p className="mb-0 tags-pay">{this.populateTagBuy(i)}</p>
            </td>
          );
        } else if (j == 4) {
          if (this.state.whole_data[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 5) {
          let num = Number(this.state.whole_data[i]["toPay"]);
          let s = num.toFixed(2);
          this.state.whole_data[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${s}`}
                </td>
              )
            : children.push(
                <td key={j} style={{ width: "100px" }}>
                  $0
                </td>
              );
        }
        // else if (j == 4) {
        //     children.push(<td>{`${this.state.whole_data[i]["price_equation"]}`}</td>)
        // }
        else if (j == 6) {
          children.push(
            <td key={j} style={{ width: 150 }}>
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 16,
                }}
              >
                {`min: ${this.state.whole_data[i]["min_transaction_limit"]} ${
                  this.state.whole_data[i]["currency_type"]
                }`}
              </p>
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 16,
                }}
              >
                {`max: ${this.state.whole_data[i]["max_transaction_limit"]} ${
                  this.state.whole_data[i]["currency_type"]
                }`}
              </p>
              {/* {`${this.state.whole_data[i]["currency_type"]}`} */}
            </td>
          );
        } else if (j == 7) {
          this.state.whole_data[i]["status"] != "DISABLED"
            ? this.state.whole_data[i]["type_of_trade_original"] == "sell"
              ? children.push(
                  <td key={j} className="Width400 text-center">
                    <a
                      href={`/edit-offer/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80"
                    >
                      <span
                        id={i}
                        onClick={(e) => {
                          localStorage.setItem(
                            "tradeTerm",
                            this.state.whole_data[e.target.id]["terms_of_trade"]
                          );
                        }}
                      >
                        Edit
                      </span>
                    </a>
                    <a
                      href={`/sell-bitcoins-instantly/sell/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80 m-1"
                    >
                      Details
                    </a>
                  </td>
                )
              : children.push(
                  <td key={j} className="Width400 text-center">
                    <a
                      href={`/edit-offer/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80"
                    >
                      <span
                        id={i}
                        onClick={(e) => {
                          localStorage.setItem(
                            "tradeTerm",
                            this.state.whole_data[e.target.id]["terms_of_trade"]
                          );
                        }}
                      >
                        Edit
                      </span>
                    </a>
                    <a
                      href={`/buy-bitcoin-instantly/buy/${base32.encode(
                        this.state.whole_data[i]["_id"]
                      )}`}
                      target="_blank"
                      id={`${this.state.whole_data[i]["_id"]}`}
                      className="btn btnGray minWidth80 m-1"
                    >
                      Details
                    </a>
                  </td>
                )
            : children.push(
                <td key={j} className="Width400 text-center">
                  <a
                    href={`/buy-bitcoin-instantly/buy/${base32.encode(
                      this.state.whole_data[i]["_id"]
                    )}`}
                    target="_blank"
                    id={`${this.state.whole_data[i]["_id"]}`}
                    className="btn btnGray minWidth80 m-1"
                  >
                    Details
                  </a>
                </td>
              );
        } else if (j == 8) {
          this.state.whole_data[i]["status"] == "ACTIVE"
            ? children.push(
                <td key={j} className="text-center">
                  <label className={`switch custom-switch toggle-switch`}>
                    <input
                      type="checkbox"
                      checked
                      readOnly
                      onClick={(e) =>
                        this.setState({
                          showModal: true,
                          deleteItem: e.target.id,
                        })
                      }
                      id={`${this.state.whole_data[i]["_id"]}`}
                    />
                    <span className="slider round" />
                    <div style={{marginTop:15,flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {this.state.whole_data[i]["visitor"]?(
                                  <p style={{margin:2,marginLeft:4}}>{this.state.whole_data[i]["visitor"]}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                  </label>
                </td>
              )
            : children.push(
                <td key={j} className="text-center">
                  <label className={`switch custom-switch`}>
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        this.setState({
                          EnableshowModal: true,
                          EnableItem: e.target.id,
                        })
                      }
                      id={`${this.state.whole_data[i]["_id"]}`}
                    />
                    <span className="slider round" />
                    <div style={{marginTop:15,flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {this.state.whole_data[i]["visitor"]?(
                                  <p style={{margin:2,marginLeft:4}}>{this.state.whole_data[i]["visitor"]}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                  </label>
                </td>
              );
        }
        //<td>{<img onClick={(e) => this.setState({EnableshowModal:true,EnableItem:e.target.id})} id={`${this.state.whole_data[i]["_id"]}`}  src={require("../assets/images/occupacy_red_circle.png")} style={{ height: "35px", width: "35px" }} alt="" />}</td>)
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populateDisputeTradeList = () => {
    let table = [];
    // Outer loop to create parent

    if (this.state.DisputedTradeList.length === 0) {
      return (
        <tr>
          <td colSpan="9" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    } else {
      for (
        var i = 0;
        i <
        (this.state.DisputedTradeList.length < 10
          ? this.state.DisputedTradeList.length
          : 10);
        i++
      ) {
        let children = [];
        //Inner loop to create children
        for (let j = 0; j < 9; j++) {
          if (j === 0) {
            // children.push(<td key={j}>{`${i + 1}`}</td>);
          } else if (j === 1) {
            this.state.DisputedTradeList[i]["createdAt"] !== undefined
              ? children.push(
                  <td key={j}>{`${new Date(
                    this.state.DisputedTradeList[i]["createdAt"]
                  ).toLocaleString("en-US", {
                    timeZone: this.state.utcZone,
                  })}`}</td>
                )
              : children.push(<td key={j}>{""}</td>);
          } else if (j === 2) {
            if (this.state.DisputedTradeList[i]["buyerId"] == this.state._id) {
              children.push(
                <td key={j}>{`${
                  this.state.DisputedTradeList[i]["seller"][0]["seller_name"]
                }`}</td>
              );
            } else {
              children.push(
                <td key={j}>{`${
                  this.state.DisputedTradeList[i]["buyer"][0]["buyer_name"]
                }`}</td>
              );
            }
          } else if (j === 3) {
            children.push(
              <td key={j}>{`${
                this.state.DisputedTradeList[i]["currency_type"]
              }`}</td>
            );
          } else if (j === 4) {
            // console.log(`${base32.encode(this.state.DisputedTradeList[i]["_id"])}`);
            children.push(
              <td key={j}>
                <a
                  href={`/send-buy-request/${base32.encode(
                    this.state.DisputedTradeList[i]["_id"]
                  )}`}
                  target="_blank"
                  className="anchor-styling"
                  id={`${this.state.DisputedTradeList[i]["uniqueId"]}`}
                  style={{ color: "voilet" }}
                >{`${this.state.DisputedTradeList[i]["uniqueId"]}`}</a>
              </td>
            );
          } else if (j === 5) {
            children.push(
              <td key={j}>{`${
                this.state.DisputedTradeList[i]["amount_in_currency"]
              }`}</td>
            );
          } else if (j === 6) {
            var trFee = Number(
              this.state.DisputedTradeList[i]["tradeFeeWithoutReferral"]
            ).toFixed(8);
            this.state.DisputedTradeList[i]["advertisement_owner_name"] === NAME
              ? children.push(<td key={j}>{`${trFee}`}</td>)
              : children.push(<td key={j}>{`${0.0}`}</td>);
          } else if (j === 7) {
            var amtInCrypto = Number(
              this.state.DisputedTradeList[i]["amount_of_cryptocurrency"]
            );
            children.push(<td key={j}>{`${amtInCrypto}`}</td>);
          } else if (j === 8) {
            var exRate = Number(
              this.state.DisputedTradeList[i]["exchangeRate"]
            ).toFixed(2);
            children.push(<td key={j}>{`${exRate}`}</td>);
          }
        }
        //Create the parent and add the children
        table.push(<tr key={i}>{children}</tr>);
      }
    }
    return table;
  };
  populateCompletedTradeList = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.CompletedTradeList.length == 0) {
      return (
        <tr>
          {" "}
          <td colSpan="11" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }

    for (
      var i = 0;
      i <
      (this.state.CompletedTradeList.length < 10
        ? this.state.CompletedTradeList.length
        : 10);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < 9; j++) {
        if (j === 0) {
          // children.push(<td key={j}>{`${i + 1}`}</td>);
        } else if (j == 1) {
          children.push(
            <td key={j}>{`${new Date(
              this.state.CompletedTradeList[i]["createdAt"]
            ).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>
          );

          //children.push(<td style={{ width: "170px" }}>{`${this.state.CompletedTradeList[i]["createdAt"].replace("T", "( ").replace("Z", "").split(".")[0] + " )"}`}</td>)
        } else if (j === 2) {
          if (this.state.CompletedTradeList[i]["buyerId"] == this.state._id) {
            children.push(
              <td key={j}>{`${
                this.state.CompletedTradeList[i]["seller"][0]["seller_name"]
              }`}</td>
            );
          } else {
            children.push(
              <td key={j}>{`${
                this.state.CompletedTradeList[i]["buyer"][0]["buyer_name"]
              }`}</td>
            );
          }
        } else if (j === 3) {
          children.push(
            <td key={j}>
              {/* <a href={`/send-buy-request/${base32.encode(this.state.CompletedTradeList[i]["_id"])}`} className="anchor-styling" id={`${this.state.CompletedTradeList[i]["_id"]}`} style={{ color: "voilet" }} onClick={(e) => this.onCkickTradeID(e)} target="_blank">{`${this.state.CompletedTradeList[i]["uniqueId"]}`}</a> */}
              <a
                href={`/send-buy-request/${base32.encode(
                  this.state.CompletedTradeList[i]["_id"]
                )}`}
                target="_blank"
                className="anchor-styling"
                id={`${this.state.CompletedTradeList[i]["_id"]}`}
                style={{ color: "voilet" }}
              >{`${this.state.CompletedTradeList[i]["uniqueId"]}`}</a>
            </td>
          );
        } else if (j === 4) {
          children.push(
            <td key={j}>{`${
              this.state.CompletedTradeList[i]["currency_type"]
            }`}</td>
          );
        } else if (j === 5) {
          children.push(
            <td key={j}>{`${
              this.state.CompletedTradeList[i]["amount_in_currency"]
            }`}</td>
          );
        } else if (j === 6) {
          var trFee = Number(
            this.state.CompletedTradeList[i]["tradeFeeWithoutReferral"]
          ).toFixed(8);
          this.state.CompletedTradeList[i]["advertisement_owner_name"] == NAME
            ? children.push(<td key={j}>{`${trFee}`}</td>)
            : children.push(<td key={j}>{`${0.0}`}</td>);
        } else if (j === 7) {
          var amtInCrypto = Number(
            this.state.CompletedTradeList[i]["amount_of_cryptocurrency"]
          ).toFixed(8);
          children.push(<td key={j}>{`${amtInCrypto}`}</td>);
        } else if (j === 8) {
          var exRate = Number(
            this.state.CompletedTradeList[i]["exchangeRate"]
          ).toFixed(2);
          children.push(<td key={j}>{`${exRate}`}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  onCkickTradeID = (e) => {
    e.preventDefault();
    //this.props.history.push(`/send-buy-request/${base32.encode(e.target.id)}`)
  };
  populateClosedTradeList = () => {
    this.CSVDownload();
    let table = [];
    // Outer loop to create parent

    if (this.state.ClosedTradeList.length == 0) {
      return (
        <tr>
          {" "}
          <td colSpan="11" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }
    for (
      var i = 0;
      i <
      (this.state.ClosedTradeList.length < 10
        ? this.state.ClosedTradeList.length
        : 10);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < 11; j++) {
        if (j == 0) {
          // children.push(<td key={j}>{`${i + 1}`}</td>);
        } else if (j == 1) {
          var UTCtime = new Date(this.state.ClosedTradeList[i]["createdAt"]);

          children.push(
            <td key={j}>{`${new Date(
              this.state.ClosedTradeList[i]["createdAt"]
            ).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>
          );

          // children.push(<td style={{ width: "170px" }}>{`${this.state.ClosedTradeList[i]["createdAt"].replace("T", " ( ").replace("Z", "").split(".")[0] + " )"}`}</td>)
        } else if (j == 2) {
          if (this.state.ClosedTradeList[i]["buyerId"] == this.state._id) {
            children.push(
              <td key={j}>{`${
                this.state.ClosedTradeList[i]["seller"][0]["seller_name"]
              }`}</td>
            );
          } else {
            children.push(
              <td key={j}>{`${
                this.state.ClosedTradeList[i]["buyer"][0]["buyer_name"]
              }`}</td>
            );
          }
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${
              this.state.ClosedTradeList[i]["request_status"]
            }`}</td>
          );
        } else if (j == 4) {
          if (this.state.ClosedTradeList[i]["userId"] == this.state._id) {
            children.push(
              <td key={j}>{`${
                this.state.ClosedTradeList[i]["type_of_trade_original"]
              }`}</td>
            );
          } else {
            children.push(
              <td key={j}>{`${
                this.state.ClosedTradeList[i]["type_of_trade_other"]
              }`}</td>
            );
          }
        } else if (j == 5) {
          children.push(
            <td key={j}>
              <a
                href={`/send-buy-request/${base32.encode(
                  this.state.ClosedTradeList[i]["_id"]
                )}`}
                target="_blank"
                className="anchor-styling"
                id={`${this.state.ClosedTradeList[i]["_id"]}`}
                style={{ color: "voilet" }}
              >{`${this.state.ClosedTradeList[i]["uniqueId"]}`}</a>
            </td>
          );
        } else if (j == 6) {
          children.push(
            <td key={j}>{`${
              this.state.ClosedTradeList[i]["currency_type"]
            }`}</td>
          );
        } else if (j == 7) {
          children.push(
            <td key={j}>{`${
              this.state.ClosedTradeList[i]["amount_in_currency"]
            }`}</td>
          );
        } else if (j == 8) {
          var trFee = Number(
            this.state.ClosedTradeList[i]["tradeFeeWithoutReferral"]
          ).toFixed(8);
          this.state.ClosedTradeList[i]["advertisement_owner_name"] === NAME
            ? children.push(<td key={j}>{`${trFee}`}</td>)
            : children.push(<td key={j}>{`${0.0}`}</td>);
        } else if (j == 9) {
          var amtInCrypto = Number(
            this.state.ClosedTradeList[i]["amount_of_cryptocurrency"]
          ).toFixed(8);
          children.push(<td key={j}>{`${amtInCrypto}`}</td>);
        } else if (j == 10) {
          var exRate = Number(
            this.state.ClosedTradeList[i]["exchangeRate"]
          ).toFixed(2);
          children.push(<td key={j}>{`${exRate}`}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populateCanceledTradeList = () => {
    let table = [];
    // Outer loop to create parent

    if (this.state.CanceledTradeList == undefined) {
      return (
        <tr>
          {" "}
          <td colSpan="9" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }
    if (this.state.CanceledTradeList.length == 0) {
      return (
        <tr>
          {" "}
          <td colSpan="9" align="center">
            You don't have any trade yet.
          </td>
        </tr>
      );
    }
    for (
      var i = 0;
      i <
      (this.state.CanceledTradeList.length < 10
        ? this.state.CanceledTradeList.length
        : 10);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < 9; j++) {
        if (j === 0) {
          // children.push(<td key={j}>{`${i + 1}`}</td>);
        } else if (j === 1) {
          children.push(
            <td key={j}>{`${new Date(
              this.state.CanceledTradeList[i]["createdAt"]
            ).toLocaleString("en-US", { timeZone: this.state.utcZone })}`}</td>
          );

          // children.push(<td style={{ width: "170px" }}>{`${this.state.CanceledTradeList[i]["createdAt"].replace("T", " ( ").replace("Z", "").split(".")[0] + " )"}`}</td>)
        } else if (j === 2) {
          if (this.state.CanceledTradeList[i]["buyerId"] === this.state._id) {
            children.push(
              <td key={j}>{`${
                this.state.CanceledTradeList[i]["seller"][0]["seller_name"]
              }`}</td>
            );
          } else {
            children.push(
              <td key={j}>{`${
                this.state.CanceledTradeList[i]["buyer"][0]["buyer_name"]
              }`}</td>
            );
          }
        } else if (j === 3) {
          children.push(
            <td key={j}>{`${
              this.state.CanceledTradeList[i]["currency_type"]
            }`}</td>
          );
        } else if (j === 4) {
          children.push(
            <td key={j}>
              {/* <Link to={`/send-buy-request/${base32.encode(this.state.CanceledTradeList[i]["_id"])}`}> */}
              <a
                href={`/send-buy-request/${base32.encode(
                  this.state.CanceledTradeList[i]["_id"]
                )}`}
                target="_blank"
                className="anchor-styling"
                id={`${this.state.CanceledTradeList[i]["_id"]}`}
                style={{ color: "voilet" }}
              >{`${this.state.CanceledTradeList[i]["uniqueId"]}`}</a>
              {/* </Link> */}
            </td>
          );
        } else if (j === 5) {
          children.push(
            <td key={j}>{`${
              this.state.CanceledTradeList[i]["amount_in_currency"]
            }`}</td>
          );
        } else if (j === 6) {
          var trFee = Number(
            this.state.CanceledTradeList[i]["tradeFeeWithoutReferral"]
          ).toFixed(8);
          this.state.CanceledTradeList[i]["advertisement_owner_name"] === NAME
            ? children.push(<td key={j}>{`${trFee}`}</td>)
            : children.push(<td key={j}>{`${0.0}`}</td>);
        } else if (j === 7) {
          var amtInCrypto = Number(
            this.state.CanceledTradeList[i]["amount_of_cryptocurrency"]
          ).toFixed(8);
          children.push(<td key={j}>{`${amtInCrypto}`}</td>);
        } else if (j === 8) {
          var exRate = Number(
            this.state.CanceledTradeList[i]["exchangeRate"]
          ).toFixed(2);
          children.push(<td key={j}>{`${exRate}`}</td>);
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  // ShowSpecific = (e, type) => {
  // 	e.preventDefault();
  // 	var id = e.target.id;
  // 	var encryptData = base32.encode(id)

  // 	if (type == "buy") {
  // 		this.props.history.push(`/buy-bitcoin-instantly/${type}/${encryptData}`)
  // 	}
  // 	else if (type == "sell") {
  // 		this.props.history.push(`/sell-bitcoins-instantly/${type}/${encryptData}`)
  // 	}
  // }
  // EditSpecific = (e) => {
  // 	e.preventDefault();
  // 	var id = e.target.id;

  // 	var encryptData = base32.encode(id)

  // 	this.props.history.push(`/edit-offer/${encryptData}`)
  // }
  DeleteSpecific = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var parameter = {
      adId: this.state.deleteItem,
      status: "DISABLE",
      userId: this.state._id,
    };
    //    var encryptData = base32.encode(id)
    this.setState({ showModal: false });
    //   this.props.history.push(`/editadvertise/${encryptData}`)
    Api(
      "trade/changeAdStatus",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }

          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            this.setState({
              switch: !this.state.switch,
            });
            // this.componentDidMount()
            this._loadData(1);
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  EnableSpecific = (e) => {
    e.preventDefault();
    var id = e.target.id;
    this.setState({ EnableshowModal: false });
    var parameter = {
      adId: this.state.EnableItem,
      status: "ACTIVE",
      userId: this.state._id,
    };
    //    var encryptData = base32.encode(id)
    // this.setState({ loading: true })
    //   this.props.history.push(`/editadvertise/${encryptData}`)
    Api(
      `trade/changeAdStatus`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            // this.componentDidMount()
            this._loadData(1);
            this.setState({
              switch: !this.state.switch,
            });
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  retrive = () => {
    var spendDays = Date.now() - this.state.ActivationTime;
    var dateRemaining = 24 * 60 * 60 * 1000 * 7 - spendDays;

    if (dateRemaining > 0) {
      return;
    }
    var parameter = { userId: this.state._id };
    Api(
      `trade/returnBondAmountFromEscrow`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if ((resp.status = 200)) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        }
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };
  transferBond = () => {
    this.setState({ loading: true });

    var parameter = { userId: this.state._id };
    Api(
      `trade/tranferBondAmountToEscrow`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if ((resp.status = 200)) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            this.userDetails();
            this.setState({ loading: false });
          } else if (resp.data.responseCode == 502) {
            this.setState({ loading: false });
            this.props.history.push("NotFound");
          } else {
            this.setState({ loading: false });
            toast_func("error", resp.data.responseMessage);
          }
        }
      } catch (ex) {
        this.setState({ loading: false });
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
  };

  filterByPayment(val) {
    if (val == "Search by Payment Method") {
      this._loadData(1);
    } else {
      let arr = this.state.filterFromHere.filter((item) => {
        return item.payment_method === val;
      });
      this.setState({ whole_data: arr });
    }
  }

  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Modal isOpen={this.state.showModal}>
          <ModalBody>
            <img
              src={require("../assets/images/Alert.png")}
              style={{ height: "75px", width: "75px" }}
              className="logOut-img"
              alt=""
            />
            <h5 className="mb30 mt30 font17">
              Are you sure you want to Disable Ad.?
            </h5>
            <button
              onClick={(e) => this.DeleteSpecific(e)}
              className="btn btnGreen max-WT-120 font16"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btnRed max-WT-120 ml10 font16"
              data-dismiss="modal"
              onClick={() => this.setState({ showModal: false })}
            >
              No
            </button>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.EnableshowModal}>
          <ModalBody>
            {/* <img src={require("../assets/images/delete.png")} style={{height:"75px",width:"75px"}} className="logOut-img" alt=""/> */}
            <h5 className="mb30 mt30 font17">
              Are you sure you want to Enable Ad.?
            </h5>
            <button
              onClick={(e) => this.EnableSpecific(e)}
              className="btn btnGreen max-WT-120 font16"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btnRed max-WT-120 ml10 font16"
              data-dismiss="modal"
              onClick={() => this.setState({ EnableshowModal: false })}
            >
              No
            </button>
          </ModalBody>
        </Modal>
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle dashboardSection">
            <div className="container">
              <div className="sectionHead">
                <h4 className="header2">Dashboard</h4>
                <div className="align-items-start d-flex justify-content-between txtBox radius8 p20 mb60">
                  <div>
                    <p className="mt-0" style={{ color: "var(--text-grey)" }}>
                      On this page you can view and manage your advertisements
                      and trade.
                    </p>
                    {!this.state.completeBond ? (
                      !this.state.bondStatus ? (
                        <div className="mt10">
                          <p style={{ color: "var(--text-grey)" }}>
                            Transfer bonds for your ads. to be visible , you can
                            retrieve after 7 days.
                          </p>
                          {/* <a className="btn btnHeader btnActive ml15 newButton" onClick={() => this.transferBond()}>Transfer bond ( {this.state.tardeBondValue ? this.state.tardeBondValue : null} ₿)</a> */}
                          {/* <Countdown date={Date.now() + this.state.bondTime} /> */}
                        </div>
                      ) : (
                        <div>
                          <p style={{ color: "var(--text-grey)" }}>
                            Transfer bonds for your ads. to be visible , you can
                            retrieve after 7 days.
                          </p>
                          {/* <Countdown date={Date.now() + this.state.bondTime}
													onComplete={() => {
														setTimeout(() => {
															this.userDetails()
														}, 2000)

													}} /> */}
                          {/* <a className="btn btnHeader btnActive ml15 newButton" onClick={() => this.retrive()} >Retrive</a> */}
                        </div>
                      )
                    ) : (
                      <div />
                    )}
                  </div>
                  <div>
                    {!this.state.completeBond ? (
                      !this.state.bondStatus ? (
                        <div className="mt5">
                          {/* <p >Transfer bonds for your ads. to be visible , you can retrieve after 7 days.</p> */}
                          <a
                            className="btn btnHeader btnActive ml15 newButton"
                            onClick={() => this.transferBond()}
                          >
                            Transfer bond ({" "}
                            {this.state.tardeBondValue
                              ? this.state.tardeBondValue
                              : null}{" "}
                            ₿)
                          </a>
                          {/* <Countdown date={Date.now() + this.state.bondTime} /> */}
                        </div>
                      ) : (
                        <div
                          className="text-center"
                          style={{ color: "var(--text-grey)" }}
                        >
                          {/* <p >Transfer bonds for your ads. to be visible , you can retrieve after 7 days.</p> */}
                          <Countdown
                            date={Date.now() + this.state.bondTime}
                            onComplete={() => {
                              setTimeout(() => {
                                this.userDetails();
                              }, 2000);
                            }}
                          />
                          <a
                            className="btn btnHeader btnActive ml15 newButton"
                            onClick={() => this.retrive()}
                          >
                            Retrieve
                          </a>
                        </div>
                      )
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
              {/* <ul className="nav nav-tabs w-100  mb30" role="tablist">

								<li className="nav-item max-WT-20Per tabox">
									<Link className={`${this.state.tab == "1" ? "nav-link active show" : "nav-link"}`} to={"/wallet"}>Wallet: {this.state.total ? <span>{this.state.total}</span> : <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "15px", width: "15px" }} alt="" /></span>}</Link>
								</li>

								<li className="nav-item max-WT-20Per tabox">
									<a className={`${this.state.tab === "2" ? "nav-link active show" : "nav-link"}`} onClick={() => this.setState({ tab: "2" })}>Open Trade & Advertisement</a>
								</li>
								<li className="nav-item max-WT-20Per tabox">
									<a className={`${this.state.tab === "3" ? "nav-link active show" : "nav-link"}`} onClick={() => this.setState({ tab: "3" })}>All Trades</a>
								</li>
								<li className="nav-item max-WT-20Per tabox">
									<a className={`${this.state.tab === "4" ? "nav-link active show" : "nav-link"}`} onClick={() => this.setState({ tab: "4" })}>Completed Trades</a>
								</li>
								<li className="nav-item max-WT-20Per tabox">
									<a className={`${this.state.tab === "5" ? "nav-link active show" : "nav-link"}`} onClick={() => this.setState({ tab: "5" })}>Cancelled Trades</a>
								</li>
								<li className="nav-item max-WT-20Per tabox">
									<a className={`${this.state.tab === "6" ? "nav-link active show" : "nav-link"}`} onClick={() => this.setState({ tab: "6" })}>Disputed Trades</a>
								</li>
							</ul> */}
              <div className="tabList mb30">
                <Link
                  to={"/Wallet"}
                  className={`${
                    this.state.tab == "1" ? "taboxActive" : "tabox"
                  }`}
                >
                  <div
                    onClick={() => {
                      this.setState({ tab: "1" });
                      localStorage.setItem("tab1", "1");
                    }}
                  >
                    Wallet:{" "}
                    {this.state.total ? (
                      <span>{this.state.total}</span>
                    ) : (
                      <span>
                        <img
                          src={require("../assets/ajax-loader.gif")}
                          style={{ height: "15px", width: "15px" }}
                          alt=""
                        />
                      </span>
                    )}
                  </div>
                </Link>
                <div
                  className={`${
                    this.state.tab === "2" ? "taboxActive pdImp" : "tabox pdImp"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "2" });
                    localStorage.setItem("tab1", "2");
                  }}
                >
                  <a>Open Trade & Advertisement</a>
                </div>
                <div
                  className={`${
                    this.state.tab === "3" ? "taboxActive" : "tabox"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "3" });
                    localStorage.setItem("tab1", "3");
                  }}
                >
                  <a>All Trades</a>
                </div>
                <div
                  className={`${
                    this.state.tab === "4" ? "taboxActive" : "tabox"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "4" });
                    localStorage.setItem("tab1", "4");
                  }}
                >
                  <a>Completed Trades</a>
                </div>
                <div
                  className={`${
                    this.state.tab === "5" ? "taboxActive" : "tabox"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "5" });
                    localStorage.setItem("tab1", "5");
                  }}
                >
                  <a>Cancelled Trades</a>
                </div>
                <div
                  className={`${
                    this.state.tab === "6" ? "taboxActive" : "tabox"
                  }`}
                  onClick={() => {
                    this.setState({ tab: "6" });
                    localStorage.setItem("tab1", "6");
                  }}
                >
                  <a>Disputed Trades</a>
                </div>
              </div>
              <div className="tab-content">
                <div
                  id="tab2"
                  className={`${
                    this.state.tab == "2" ? "tab-pane active show" : "tab-pane"
                  }`}
                >
                  <div className="tableBox">
                    <div className="tableBoxHead align-items-center flex-wrap pl0">
                      <h4 className="mb15 header2">Your Advertisements</h4>

                      {this.state.populatemore ? (
                        <div style={{ display: "flex" }}>
                          <select
                            onChange={(e) =>
                              this.filterByPayment(e.target.value)
                            }
                            className="searchByP"
                            style={{
                              width: 260,
                              height: 45,
                              fontSize: 18,
                              padding: "0 10px",
                            }}
                          >
                            {this.state.paymentMethod.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </select>
                          <button
                            className="showMore btn ml-auto mb15 minWidth120"
                            onClick={() =>
                              this.setState({
                                populatemore: false,
                              })
                            }
                          >
                            Show Less...
                          </button>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <select
                            onChange={(e) =>
                              this.filterByPayment(e.target.value)
                            }
                            style={{
                              width: 260,
                              height: 45,
                              fontSize: 18,
                              padding: "0 10px",
                            }}
                          >
                            {this.state.paymentMethod.map((item, index) => {
                              return <option key={index} value={item}>{item}</option>;
                            })}
                          </select>
                          <button
                            className="showMore btn ml-auto mb15 minWidth120"
                            onClick={() =>
                              this.setState({
                                populatemore: true,
                              })
                            }
                          >
                            Show More...
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive tbl">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead className="tbl_hd">
                            <tr>
                              {/* <th>S. No</th> */}
                              <th>Status</th>

                              <th>Trade type</th>
                              <th>Payment Method</th>
                              <th>Price/BTC</th>

                              {/* <th>Equation</th> */}
                              <th>
                                to Pay <label> on the dollar</label>
                              </th>
                              <th>Trade Limit</th>
                              <th>Action</th>
                              <th>On/Off status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.populatemore
                              ? this.populate_viewMore()
                              : this.populate_view()}
                            {/* <tr>
                                       <td colspan="6" align="center">You don't have any advertisement yet.</td>
                                    </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.whole_dataTotal ? (
                    this.state.populatemore ? (
                      <Pagination
                        className="mt10"
                        defaultCurrent={1}
                        total={this.state.whole_dataTotal}
                        onChange={(value) => this._loadData(value)}
                      />
                    ) : null
                  ) : null}
                </div>

                <div
                  id="tab3"
                  className={`${
                    this.state.tab == "3" ? "tab-pane active show" : "tab-pane"
                  }`}
                >
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap pl0">
                      <h4 className="mb15 font20">All Trades</h4>

                      <CSVLink
                        filename={"all_trades.csv"}
                        className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"
                        data={data1}
                        headers={this.state.header1}
                      >
                        <i className="fas fa-download" />
                        CSV File
                      </CSVLink>

                      {/* <button className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"><i className="fas fa-download"></i> CSV File</button> */}
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive tbl">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead className="tbl_hd">
                            <tr>
                              {/* <th>#</th> */}
                              <th>Created At</th>
                              <th>Trading Partner</th>
                              <th>Transaction Status</th>
                              <th>Type</th>
                              <th>Trade ID</th>
                              <th>Fiat</th>
                              <th>Trade Amount</th>
                              <th>Trade Fee</th>
                              <th>Total BTC</th>
                              <th>Price/BTC</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ClosedTradeList
                              ? this.populateClosedTradeList()
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.ClosedTradeListTotal ? (
                    <Pagination
                      className="mt10"
                      defaultCurrent={1}
                      total={this.state.ClosedTradeListTotal}
                      onChange={(value) => this.ClosedTradeList(value)}
                    />
                  ) : null}
                </div>

                <div
                  id="tab4"
                  className={`${
                    this.state.tab == "4" ? "tab-pane active show" : "tab-pane"
                  }`}
                >
                  {this.state.CompletedTradeList
                    ? this.CSVDownload2(this.state.CompletedTradeList)
                    : null}
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap pl0">
                      <h4 className="mb15 font20">Closed Trades, Finished</h4>
                      <CSVLink
                        filename={"complete_trades.csv"}
                        className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"
                        data={data2}
                        headers={this.state.header2}
                      >
                        <i className="fas fa-download" />
                        CSV File
                      </CSVLink>
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive tbl">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead className="tbl_hd">
                            <tr>
                              {/* <th>#</th> */}
                              <th>Created At</th>
                              <th>Trading Partner</th>
                              <th>Trade ID</th>
                              <th>Fiat</th>
                              <th>Trade Amount</th>
                              <th>Trade Fee</th>
                              <th>Total BTC</th>
                              <th>Price/BTC</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.CompletedTradeList
                              ? this.populateCompletedTradeList()
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.CompletedTradeListTotal ? (
                    <Pagination
                      className="mt10"
                      defaultCurrent={1}
                      total={this.state.CompletedTradeListTotal}
                      onChange={(value) => this.CompletedTradeList(value)}
                    />
                  ) : null}
                </div>

                <div
                  id="tab5"
                  className={`${
                    this.state.tab == "5" ? "tab-pane active show" : "tab-pane"
                  }`}
                >
                  {this.state.CanceledTradeList
                    ? this.CSVDownload2(this.state.CanceledTradeList)
                    : null}
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap pl0">
                      <h4 className="mb15 font20">Closed Trades, Cancelled</h4>
                      <CSVLink
                        filename={"cancel_trades.csv"}
                        className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"
                        data={data2}
                        headers={this.state.header2}
                      >
                        <i className="fas fa-download" />
                        CSV File
                      </CSVLink>
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive tbl">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead className="tbl_hd">
                            <tr>
                              {/* <th>#</th> */}
                              <th>Created At</th>
                              <th>Trading Partner</th>
                              <th>Fiat</th>
                              <th>Trade ID</th>
                              <th>Trade Amount</th>
                              <th>Trade Fee</th>
                              <th>Total BTC</th>
                              <th>Price/BTC</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.CanceledTradeList
                              ? this.populateCanceledTradeList()
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.CanceledTradeListTotal ? (
                    <Pagination
                      className="mt10"
                      defaultCurrent={1}
                      total={this.state.CanceledTradeListTotal}
                      onChange={(value) => this.CanceledTradeList(value)}
                    />
                  ) : null}
                </div>
                <div
                  id="tab6"
                  className={`${
                    this.state.tab == "6" ? "tab-pane active show" : "tab-pane"
                  }`}
                >
                  {this.state.DisputedTradeList
                    ? this.CSVDownload2(this.state.DisputedTradeList)
                    : null}
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap pl0">
                      <h4 className="mb15 font20">Disputed Trades</h4>
                      <CSVLink
                        filename={"disputed_trades.csv"}
                        className="btnGreen btn ml-auto minWidth120 wColor weight500 mb15"
                        data={data2}
                        headers={this.state.header2}
                      >
                        <i className="fas fa-download" />
                        CSV File
                      </CSVLink>
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive tbl">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead className="tbl_hd">
                            <tr>
                              {/* <th>#</th> */}
                              <th>Created At</th>
                              <th>Trading Partner</th>
                              <th>Trade ID</th>
                              <th>Fiat</th>
                              <th>Trade Amount</th>
                              <th>Trade Fee</th>
                              <th>Total BTC</th>
                              <th>Price/BTC</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.DisputedTradeList
                              ? this.populateDisputeTradeList()
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.DisputedTradeListTotal ? (
                    <Pagination
                      className="mt10"
                      defaultCurrent={1}
                      total={this.state.DisputedTradeListTotal}
                      onChange={(value) => this.DisputedTradeList(value)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
