import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import Recaptcha from "react-recaptcha";
import $ from "jquery";
import subject_doc from "../JSON/subject.json";
import { validateEmail, validateMessage } from "../utils/validation";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
export default class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      subject: "",
      subjectError: "",
      email: "",
      emailError: "",
      emailStatus: false,
      message: "",
      messageError: "",
      messageStatus: false,
      subject_doc: "",
      file_data: "",
      showFile: "",
      file_dataError: "",
      image: "",
      isVerifiedError: "",
      isVerified: false,
      myImage: "",
      validFile: "",
      _id: "",
      token: "",
      loading: false,
    };
    this.getBase64 = this.getBase64.bind(this);
  }
  populate_subject = () => {
    let subject_json = [];
    for (var i = 0; i < subject_doc.length; i++) {
      subject_json.push(<option>{subject_doc[i].name}</option>);
    }
    return subject_json;
  };
  subject = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ subject_doc: value });
  };
  recapctchaLoaded() {}
  verifyCallback(response) {
    if (response) {
      this.setState({ isVerified: true });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // var addr = localStorage.getItem("Address")
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    this.setState({ _id: id, token: token });
  }

  handleUserInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (name == "mobileNo") {
      if (isNaN(value) != true) {
        this.setState({ [name]: value }, () => {
          this.validate(name, value);
        });
      }
    } else if (name == "file_data") {
      let _file_data = $(`#file_data`).prop("files")[0];
      this.getBase64(_file_data, "file_data");
      let fileName = value.split(/(\\|\/)/g).pop();
      this.setState({ [name]: value, showFile: fileName }, () =>
        this.validate(name, value)
      );
    } else {
      this.setState({ [name]: value }, () => {
        this.validate(name, value);
      });
    }
  };
  getBase64(file, name) {
    let self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      let bse64data1 = reader.result.substr(reader.result).split(",")[0];

      let bse64data = reader.result.substr(reader.result.indexOf(",") + 1);

      if (name == "file_data") {
        self.setState({
          myImage: bse64data1 + "," + bse64data,
        });
      }
      self.setState({ file_dataError: "" });
      setTimeout(() => {}, 500);
    };
    reader.onerror = function(error) {};
  }

  validate = (name, value) => {
    if (name === "subject") {
      if (this.state.subject == "") {
        this.setState({
          subjectError: "*Please select subject",
        });
      } else {
        this.setState({ subjectError: "" });
      }
    } else if (name === "email") {
      if (validateEmail(this.state.email).status == false) {
        this.setState({
          emailError: validateEmail(this.state.email).error,
        });
      } else {
        this.setState({ emailError: "" });
      }
    } else if (name === "message") {
      if (this.state.message == "") {
        this.setState({
          messageError: "*Please enter message",
        });
      } else {
        this.setState({ messageError: "" });
      }
    } else if (name === "file_data") {
      if (this.state.file_data == "") {
        // this.setState({ file_dataError: "*Please attach file" })
      } else {
        // this.setState({ file_dataError: "" })
      }
    } else if (this.state.isVerified != false) {
    } else {
      this.setState({
        isVerifiedError: "*Verification failed",
      });
    }
  };

  contact_Us = (e) => {
    e.preventDefault();

    if (this.state.subject == "") {
      this.setState({
        subjectError: "*Please select subject",
      });
    } else {
      this.setState({ subjectError: "" });
    }

    if (validateEmail(this.state.email).status == true) {
      this.setState({
        emailStatus: true,
        emailError: "",
      });
    } else {
      this.setState({
        emailError: validateEmail(this.state.email).error,
      });
    }

    if (this.state.message == "") {
      this.setState({
        messageError: "*Please enter message",
      });
    } else {
      this.setState({ messageError: "" });
    }
    if (this.state.file_data == "") {
      // this.setState({ file_dataError: "*Please attach file" })
    } else {
      // this.setState({ file_dataError: "" })
    }

    if (this.state.isVerified == "") {
      this.setState({
        isVerifiedError: "*Verification failed",
      });
    } else {
      this.setState({ isVerifiedError: "" });
    }
    if (
      this.state.subject &&
      this.state.emailStatus &&
      this.state.message &&
      this.state.isVerified
    ) {
      this._contectApi(e);
    }
  };

  _contectApi = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var parameter = {
      userId: this.state._id,
      subject: this.state.subject,
      email: this.state.email,
      message: this.state.message,
      image: this.state.myImage ? this.state.myImage : "",
    };
    // "image": (this.state.bse64data1 + "," + this.state.bse64data) ? ((this.state.bse64data1 + "," + this.state.bse64data)) : ""
    try {
      Api(
        "admin/addContactUs",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        if (resp.status == 200) {
          // Header.logout(resp)
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 2500);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Unable to reach server please contact administrator"
          );
        }
      });
    } catch (ex) {
      toast_func("error", "Unable to reach server please try again later");
    }
  };

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <main className="mainContent">
          <section className="staticContentSection buySellBox2 pb50">
            <div className="container">
              <div className="cardBox max-WT-550 center-box createAdv pb30">
                <div className="sectionHead text-center">
                  {/* <span className="sectionHeadicon mb10 d-inline-block"><img src={require("../assets/images/envelope.png")} /></span> */}
                  <h2 className="font18 textInitial fontfamTB">CONTACT US</h2>
                </div>

                <form className="w-100 main-form">
                  <div className="formBox max-WT-400 center-box">
                    <div className="form-group">
                      <select
                        type="subject"
                        name="subject"
                        value={this.state.subject}
                        className="form-control select-style1"
                        onChange={this.handleUserInput}
                      >
                        <option>Subject</option>
                        {this.populate_subject()}
                      </select>
                      {this.state.subjectError != "" ? (
                        <label className="error">
                          {this.state.subjectError}
                        </label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        maxLength={35}
                        name="email"
                        onChange={this.handleUserInput}
                        value={this.state.email}
                        className="form-control radius4"
                        placeholder="Email ID"
                      />
                      {this.state.emailError != "" ? (
                        <label className="error">{this.state.emailError}</label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <textarea
                        type="message"
                        maxLength={1000}
                        name="message"
                        onChange={this.handleUserInput}
                        value={this.state.message}
                        className="form-control radius4 minHeight100"
                        placeholder="Message Here."
                      />
                      {this.state.messageError != "" ? (
                        <label className="error">
                          {this.state.messageError}
                        </label>
                      ) : null}
                    </div>

                    <div className="form-group mb10">
                      <div className="kycfile-uplaod mb15">
                        <input
                          type="file"
                          id="file_data"
                          name="file_data"
                          accept=".jpg, .jpeg, .png"
                          value={this.state.file_data}
                          onChange={this.handleUserInput}
                          className="form-control"
                        />
                        <span
                          className="label-control"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Attach Document
                        </span>
                        <i className="fas fa-paperclip" />
                      </div>
                      <label
                        style={{
                          color: "green",
                        }}
                      >
                        {this.state.showFile}
                      </label>
                      {this.state.file_dataError != "" ? (
                        <label className="error">
                          {this.state.file_dataError}
                        </label>
                      ) : null}
                    </div>

                    <div className="form-group pl20">
                      <label className="control-label  mb5 weight500">
                        Please, verify you are not a robot
                      </label>
                      <div className="captchImg">
                        <Recaptcha
                          sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                          render="explicit"
                          onloadCallback={this.recapctchaLoaded}
                          verifyCallback={() =>
                            this.setState({
                              isVerified: true,
                              isVerifiedError: "",
                            })
                          }
                        />
                      </div>
                      {this.state.isVerifiedError != "" ? (
                        <label className="error">
                          {this.state.isVerifiedError}
                        </label>
                      ) : null}
                    </div>
                    <div className="text-center">
                      <button
                        onClick={(e) => this.contact_Us(e)}
                        type="submit"
                        className="btn btnGreen w-100 radius4"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
