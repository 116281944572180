//Modules
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactModal } from "../services/globals";
import Fingerprint from "fingerprintjs";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
//Components
import {
  validateName,
  validateEmail,
  validatePassword,
  validateMobileNo,
} from "../utils/validation";
import { Api } from "../services/webservices";
import Header from "../components/header";
import Footer from "../components/footer";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import { ClipLoader } from "react-spinners";

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      emailStatus: false,
      password: "",
      passwordError: "",
      passwordStatus: false,
      isVerified: null,
      modalIsOpen: false,
      country_name: "",
      __data: "Please wait ...",
      fingerprint: "",
      loading: false,
    };
  }
  componentWillMount() {
    var fingerprint = new Fingerprint().get();
    this.setState({ fingerprint: fingerprint });
    var searchUrl = this.props.history.location.search.split("=")[1];

    setTimeout(() => {
      var parameter = { _id: searchUrl, browser_id: this.state.fingerprint };
      this.setState({ loading: true });
      try {
        Api("user/emailVerify", "POST", parameter).then((resp) => {
          if (resp.status == 200) {
            this.setState({ loading: false });
            if (resp.data.responseCode == 200) {
              this.setState({
                __data: resp.data.responseMessage,
              });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              this.setState({
                __data: resp.data.responseMessage,
              });
            }
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }, 1000);

    return;
    if (searchUrl !== "") {
      var getCode = searchUrl.split("/")[1];
    }
  }
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container">
              <div className="cardBox max-WT-550 center-box">
                {/* <div className="cardHead pb10 text-center"> */}
                <h4 className="font18 mt0 mb5 text-center emailVer">
                  {this.state.__data}
                </h4>

                {/* <h2>{this.state.__data}</h2> */}
                {/* </div> */}
              </div>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
