import React, { Component } from "react";
import { Api, socket, getCurrency } from "../services/webservices";
var digiCurr = [];
var fiatCurr = [];

let digital_curr = [];
export default class CurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyModal: false,
      digital_curr: [],
      fiat_curr: [],
      tab: "digital",
      currency: "",
    };
  }

  componentDidMount = async () => {
    console.log("prop===", this.props);
    let dig_curr = [];
    let keys = Object.values(this.props.currencyJson.crypto);
    keys.map((item) => {
      if (item.symbol != "BTC") {
        dig_curr.push(item);
      }
    });
    digiCurr = dig_curr;
    this.setState({ digital_curr: dig_curr });
    console.log("================digital_curr===>", dig_curr);

    let fi_curr = [];
    let key = Object.entries(this.props.currencyJson.fiat);
    key.map((item) => {
      fi_curr.push(item);
    });
    fiatCurr = fi_curr;
    this.setState({ fiat_curr: fi_curr });
    console.log("================fiat_curr===>", fi_curr);
    // this.setState({images:this.props.productImage})
  };

  handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log("value===", value);
    this.setState({ [name]: value });
    if (this.state.tab === "digital") {
      var array = digiCurr;
      if (value == "") {
        this.setState({ digital_curr: array });
      } else {
        function search(nameKey, myArray) {
          let filterVal = [];
          console.log("nameKey===>", nameKey);
          for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].symbol.toLowerCase().match(nameKey.toLowerCase())) {
              // return myArray[i];
              console.log("Ritik===>", myArray[i].symbol);
              filterVal.push(myArray[i]);
            }
          }
          return filterVal;
        }

        var resultObject = search(value, array);
        this.setState({ digital_curr: resultObject });
      }
    } else {
      var array = fiatCurr;
      if (value == "") {
        this.setState({ fiat_curr: array });
      } else {
        let filterVal = [];
        function search(nameKey, myArray) {
          console.log("nameKey5555===>", nameKey);
          for (var i = 0; i < myArray.length; i++) {
            if (myArray[i][0].toLowerCase().match(nameKey.toLowerCase())) {
              // return myArray[i];
              filterVal.push(myArray[i]);
            }
          }
        }
        this.setState({ fiat_curr: filterVal });
        var resultObject = search(value, array);
      }
    }
  };

  onClick_currency = (e, obj) => {
    e.preventDefault();
    // console.log("=====ashish==>",obj)
    // var value = e.target.value
    if (this.state.tab === "digital") {
      this.props.setValue(obj.symbol);
      this.setState({ currency: obj.symbol });
    } else {
      this.props.setValue(obj[0]);
      this.setState({ currency: obj[0] });
    }
    // this.props.setValue(obj)
    // this.fetchAmount()
  };
  // setMethod=(e,item)=>{
  //   e.preventDefault()
  //   this.setState({bank:item.name, paymethods:false, method:item.name,paymentId:item._id});
  // }
  selecttab(tabval) {
    this.setState({ tab: tabval });
    //  alert(this.state.tab)
    //  this.getInvoiceList()
  }
  render() {
    return (
      <form
        className="mb0"
        style={{
          height: "350px",
          overflowY: "auto",
          backgroundColor: "var(--white-color)",
        }}
      >
        <div className="formBox  max-WT-750 center-box">
          <div className="form-group mt20">
            <div className="input-per2">
              <input
                type="text"
                maxLength="15"
                className="form-control modalSearch"
                placeholder="All Currency"
                name="currency"
                value={this.state.currency}
                onChange={(e) => this.handleOnChange(e)}
              />
              <span onClick={(e) => this.searchFilter(null, null, e)}>
                <i className="fa fa-search" />
              </span>
            </div>
          </div>
          <div className="tabList2 mb30" style={{ justifyContent: "center" }}>
            <div
              className={`${
                this.state.tab === "digital" ? "taboxActive2" : "tabox2"
              }`}
              data-toggle="tab"
              href="#tab1"
              onClick={() => this.selecttab("digital")}
            >
              <a>Crypto</a>
            </div>
            <div
              className={`${
                this.state.tab === "fiat" ? "taboxActive2" : "tabox2"
              }`}
              data-toggle="tab"
              href="#tab2"
              onClick={() => this.selecttab("fiat")}
            >
              <a>Fiat</a>
            </div>
          </div>
          {this.state.tab === "digital" ? (
            <div className="form-group" id="tab1">
              <div className="row">
                {this.state.digital_curr.map((item, index) => {
                  return (
                    <div className="col-md-4" key={index}>
                      <button
                        className="currBtn"
                        title={item.name}
                        onClick={(e) => this.onClick_currency(e, item)}
                      >
                        <img src={item.icon_url} />
                        {/* {this.Populate_currency()} */}
                        {item.symbol}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : this.state.tab === "fiat" ? (
            <div className="form-group" id="tab2">
              <div className="row">
                {this.state.fiat_curr.map((item, index) => {
                  return (
                    <div className="col-md-4" key={index}>
                      <button
                        className="currBtn"
                        title={item[1]}
                        onClick={(e) => this.onClick_currency(e, item)}
                      >
                        <img
                          src={require("../assets/images/curr.png")}
                          style={{
                            width: 60,
                            height: 60,
                            resizeMode: "contain",
                          }}
                        />
                        {/* {this.Populate_currency()} */}
                        {/* {item.symbol} */}
                        {item[0]}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </form>
    );
  }
}
