import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api, socket } from "../services/webservices";
import { ClipLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  showbox,
  showBoxfun,
  TRADE,
  TRADEfun,
  IMAGE,
  IMAGEfun,
  NAME,
  NAMEfun,
  RECEIVER,
  RECEIVERfun,
} from "../services/globals";
import { base32 } from "../services/Decrypt";
/*              Modules           */
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import ImageModal from "../components/imageModal";

export default class BuyCoinDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userInfo: "",
      view_type: "",
      isloggedIn: null,
      currency: "",
      adId: "",
      BTC: "",
      error: "",
      priceNow: 0,
      suggestions: [],
      utcZone: "Europe/London",
      youGet: null,
      product_images: [],
      showImage: false,
    };
  }
  _register = (e) => {
    e.preventDefault();
    this.props.history.push("/signup");
  };
  componentDidMount = async () => {
    // alert(this.props.match.params.buy_id)
    
    let getUtc = localStorage.getItem("_utc_");
    if (getUtc == undefined || getUtc == "") {
      this.setState({ utcZone: this.state.utcZone });
    } else {
      this.setState({ utcZone: getUtc });
    }
    var DecryptData = base32.decode(this.props.match.params.buy_id);
    this.setState({
      adId: DecryptData,
      DecryptAdId: this.props.match.params.buy_id,
    });

    this.setState({ view_type: this.props.match.params.view_type });
    this.fetchData(DecryptData);
    window.scrollTo(0, 0);
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    var isloggedIn = localStorage.getItem("data");
    var isloggedIn = localStorage.getItem("data");
    setTimeout(() => {
      this.setState(
        {
          _id: id,
          token: token,
          addr: addr,
          isloggedIn: isloggedIn,
          _userID: this.props.match.params.buy_id,
        },
        () => this.stateApis()
      );
    }, 300);

    socket.on("amountToBtc", async (res) => {
      await this.setState({ BTC: res.result });
      
      await this.youGetWorth(res.result);
    });
    socket.on("btcToAmount", (res) => {
      this.setState({ currency: res.result }, () => {
        if (
          Number(this.state.currency) <
            Number(this.state.userInfo["min_transaction_limit"]) ||
          Number(this.state.currency) >
            Number(this.state.userInfo["max_transaction_limit"])
        ) {
          this.setState({
            error: "Amount should be between min and max limit.",
          });
        } else {
          this.setState({ error: "" });
        }
      });
    });
    $(window).scroll(function() {
      //console.log("shoeeaaaaaaaaaaaa   ")
      //alert("ddddd")
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $("header").addClass("header-bg");
        $(".fixedBox").addClass("fixdtop");
      } else {
        $("header").removeClass("header-bg");
        $(".fixedBox").removeClass("fixdtop");
      }
    });
  };
  handleChange = (selectedOption) => {
    socket.emit("amountToBtc", {
      amount: Number(selectedOption.value),
      pricePerBtc: Number(this.state.price1),
    });
    this.setState({ selectedOption, error: "" });
  };
  stateApis = () => {
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  fetchData(DecryptData) {
    Api(
      `trade/detail_trade/${DecryptData}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            var num = Number(resp.data.result["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            var price = n1 + "." + n2;

            this.setState({
              userInfo: resp.data.result,
              price: price,
              tradeOwId: resp.data.result.user_id,
              price1: resp.data.result["price_equation"],
              receiverId: resp.data.result["user_id"],
              product_images: resp.data.result.product_images,
            });
            this.findUSD();
            console.log("USER INFO====>>>>>>", resp.data.result.user_id);
            if (this.state.userInfo.restrict_amount.length !== 0) {
              let arrRestrictedamount = [];
              for (
                var i = 0;
                i < this.state.userInfo.restrict_amount.length;
                i++
              ) {
                var value1 = this.state.userInfo.restrict_amount[i];
                var restrictedamount = {
                  value: `${value1}`,
                  label: `${value1}`,
                };
                arrRestrictedamount.push(restrictedamount);
              }
              this.setState({ suggestions: arrRestrictedamount });
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }
  validateSendTradeRequest = (e) => {
    e.preventDefault();

    if (this.state.userInfo.restrict_amount.length !== 0) {
      if (this.state.selectedOption == undefined || this.state.BTC == "") {
        this.setState({ error: "Please enter details" });
      } else {
        this.setState({ error: "" });
        if (this.state.hit == undefined) {
          if (this.state.error == "") {
            this.sendTradeRequest();
          }
        }
      }
    } else {
      if (this.state.currency == "" || this.state.BTC == "") {
        this.setState({ error: "Please enter details" });
      } else {
        this.setState({ error: "" });
        if (this.state.hit == undefined) {
          if (this.state.error == "") {
            this.sendTradeRequest();
          }
        }
      }
    }
  };
  sendTradeRequest = () => {
    this.setState({ loading: true });
    var parameter = {
      amount_in_currency:
        this.state.userInfo.restrict_amount.length !== 0
          ? this.state.selectedOption.value
          : this.state.currency,
      // "amount_of_cryptocurrency": this.state.BTC,
      userId: this.state._id,
      adId: this.state.adId,
    };
    Api(
      "trade/tradeExchangeRequest",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            this.setState({ hit: false });
            var parameter1 = {
              trade: resp.data.result["_id"],
            };

            socket.emit("sendMessage", {
              // receiverId: this.state.receiverId.toString(),
              receiverId: [`${resp.data["addOwnerId"]}`],
              senderId: this.state._id.toString(),
              senderName: NAME,
              message: "Buy trade request from " + `${NAME}`,
              tradeId: resp.data.result["_id"],
              image: null,
              notificationType: "chat",
              type: "GROUP",
              requestType: "TRADE",
            });
            Api(
              "trade/checkbalanceAfterTrade",
              "POST",
              parameter1,
              this.state.token,
              this.state._id
            );

            setTimeout(() => {
              this.props.history.push(
                `/send-buy-request/${base32.encode(resp.data.result._id)}`
              );
              window.location.reload();
            }, 2500);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  handleOnChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    e.preventDefault();
    if (name == "currency") {
      if (!isNaN(value) || value == ".") {
        this.setState({ [name]: value.trim() }, () => {
          if (
            Number(value) <
              Number(this.state.userInfo["min_transaction_limit"]) ||
            Number(value) > Number(this.state.userInfo["max_transaction_limit"])
          ) {
            this.setState({
              error: "Amount should be between min and max limit.",
            });
          } else {
            this.setState({ error: "" });
          }

          socket.emit("amountToBtc", {
            amount: Number(this.state.currency),
            pricePerBtc: Number(this.state.price1),
          });
        });
      }
    } else if (name == "BTC") {
      if (!isNaN(value) || value == ".") {
        this.setState({ [name]: value.trim() }, () => {
          this.youGetWorth(this.state.BTC);
          socket.emit("btcToAmount", {
            amount: Number(this.state.BTC),
            pricePerBtc: Number(this.state.price1),
          });
          // if (this.state.BTC !== "") {
          //     this.setState({ error: "" })
          // }
        });
      }
    }
  };

  renderTrems() {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: this.state.userInfo["terms_of_trade"],
        }}
      />
    );
  }
  seeFeedback = () => {
    this.props.history.push(`/feedback/${base32.encode(this.state.tradeOwId)}`);
  };

  youGetWorth(btc){
    this.setState({ youGet: (this.state.priceNow * btc).toFixed(4) });
  }

  findUSD = async (btc) => {
    var youGet = null;
    var parameter = {
      localCurrency: this.state.userInfo['currency_type'], //"USD",
      margin: "",
    };
    await Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var priceNow = resp.data.result.price;
          this.setState({priceNow: priceNow})
        } else if (resp.data.responseCode == 502) {
          return null;
        }
      }
    });
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("data");
    // console.log("pankaj ayadav kumart ", this.state.userInfo["terms_of_trade"])
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              {/* <div className="sectionHead">
								<h2 className="mb15">Buy Bitcoin Using {this.state.userInfo["payment_method"]}</h2>
								{/* <ul className="detail-list"><li>It is shown once the trade is opened. </li>
                                    <li>  Trade instructions must be clear and bulleted if possible.</li>
                                    <li> Clear instructions are required for smooth transactions. Keep the lengthy text at the bottom</li>
                                </ul> */}
              {/* </div>  */}

              <div className="row colCenter mt30">
                <div className="col-md-12 col-lg-5 mb20">
                  <div className="cardBox align-items-center minHeight570">
                    <div className="cardHead">
                      <h4 className="mb15">
                        Buy Bitcoin Using{" "}
                        {this.state.userInfo["payment_method"]}
                      </h4>
                    </div>
                    <div className="w-100">
                      <div className="max-WT-450 center-box">
                        {/* <div className="coinDetailbox">
                                                    <div className="coinDetailLeft">Price:</div>
                                                    {this.state.userInfo != "" ?
                                                        <div className="coinDetailRight">{this.state.userInfo["tradePrice"]}</div>
                                                        : null}
                                                </div> */}
                        <div className="coinDetailbox mt30">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/paymntModeIcon.png")}
                            />
                            Payment mode:
                          </div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.userInfo["payment_method"]}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/userIcon.png")}
                            />
                            User:
                          </div>
                          <div className="coinDetailRight lineHeight1">
                            {this.state.userInfo != "" ? (
                              <a>{this.state.userInfo["user_name"]}</a>
                            ) : null}
                            {/* <sup>(+0/-0)</sup> */}
                            {/* <div className="font13">Feedback score 0%<a className="ml5" onClick={()=>this.seeFeedback()}>See feedback</a></div> */}
                            {this.state._id ? (
                              <a
                                className="ml5"
                                onClick={() => this.seeFeedback()}
                              >
                                <u className="seeFeed">See feedback</u>
                              </a>
                            ) : null}
                          </div>
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/tradeLimitIcon.png")}
                            />
                            Created At:
                          </div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {new Date(
                                this.state.userInfo["createdAt"]
                              ).toLocaleString("en-US", {
                                timeZone: this.state.utcZone,
                              })}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/bitcoinIcon.png")}
                            />
                            Price/BTC:
                          </div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.price}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/currencyIcon.png")}
                            />
                            Currency type:
                          </div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.userInfo["currency_type"]}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">
                            <img
                              className="iconMar"
                              src={require("../assets/newImages/locationIcon.png")}
                            />
                            Location:
                          </div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.userInfo["location"]}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">Trade Limit:</div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.userInfo["min_transaction_limit"]} -{" "}
                              {this.state.userInfo["max_transaction_limit"]}
                            </div>
                          ) : null}
                        </div>
                        <div className="coinDetailbox">
                          <div className="coinDetailLeft">Payment window:</div>
                          {this.state.userInfo != "" ? (
                            <div className="coinDetailRight">
                              {this.state.userInfo["payment_time"]} min.
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* {<img onClick={(e) => this.setState({showModal:true,deleteItem:e.target.id})}  id={`${this.state.whole_data[i]["_id"]}`} src={require("../assets/images/occupacy_green_circle.png")} style={{ height: "35px", width: "35px" }} alt="" /> */}
                      {this.state.view_type !== "view" ? (
                        <div className="max-WT-300 center-box">
                          <h4
                            className="text-center font18 fontfamQB  mt30 mb5"
                            style={{ color: "var(--text-grey)" }}
                          >
                            How much you want to buy?
                          </h4>
                          <div className="d-flex align-items-center formBox justify-content-between mb15">
                            {this.state.userInfo ? (
                              this.state.userInfo.restrict_amount.length ==
                              0 ? ( // <div className="w45Per">
                                //     <input type="text" name="currency" value={this.state.currency} onChange={(e) => this.handleOnChange(e)} className="form-control weight600 text-center" placeholder="0.00" />
                                // </div>
                                <div className="input-group w45Per">
                                  <input
                                    type="text"
                                    name="currency"
                                    value={this.state.currency}
                                    onChange={this.handleOnChange}
                                    className="form-control text-center"
                                    placeholder="0.00"
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      {this.state.userInfo["currency_type"]}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="w45Per">
                                  <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={this.state.suggestions}
                                  />
                                </div>
                              )
                            ) : null}
                            {this.state.userInfo ? (
                              <div className="input-group w45Per">
                                <input
                                  type="text"
                                  name="BTC"
                                  value={this.state.BTC}
                                  onChange={(e) => this.handleOnChange(e)}
                                  className="form-control weight600 text-center"
                                  placeholder="0.00"
                                  disabled={
                                    this.state.userInfo.restrict_amount
                                      .length !== 0
                                  }
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    ₿
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {this.state.BTC != "" && (
                            <p className="worth">
                              {" "}
                              {this.state.youGet == null
                                ? "$0.00"
                                : this.state.userInfo['currency_type']+ " - " + this.state.youGet}{" "}
                              worth of Bitcoins
                            </p>
                          )}
                          {this.state.error != "" ? (
                            <span className="error mb15">
                              {this.state.error}
                            </span>
                          ) : null}
                          {isloggedIn == null ? (
                            <div className="text-center">
                              <p
                                className="font13 mb7"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Register to proceed further
                              </p>
                              <a
                                className="btn btnGreen"
                                onClick={(e) => this._register(e)}
                              >
                                Register
                              </a>
                            </div>
                          ) : (
                            <div className="text-center">
                              {/* <input type="text" className="form-control weight600 mb10" placeholder="Write contact message optional" /> */}
                              <a
                                className="btn btnGreen"
                                onClick={(e) =>
                                  this.validateSendTradeRequest(e)
                                }
                              >
                                Send Trade Request
                              </a>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="cardBox minHeight570">
                    <div className="cardHead">
                      {this.state.userInfo != "" ? (
                        <h4 className="mb15 textInitial">
                          Terms Of trade with {this.state.userInfo["user_name"]}
                        </h4>
                      ) : null}
                      {/* {this.state.userInfo != "" ?
	                                      // <p>{this.state.userInfo["terms_of_trade"]}</p>
	                                    this.renderTrems()
	                                      : null} */}
                    </div>
                    <div className="w-100 fontfamQR termpad">
                      <div
                        className="sectionHead mb0"
                        style={{
                          textAlign: "justify",
                          color: "var(-text-grey)",
                        }}
                      >
                        {/* {this.state.userInfo != "" ?
													<h2 className="mb15 textInitial">Terms Of trade with {this.state.userInfo["user_name"]}</h2>
													: null} */}
                        {this.state.userInfo != "" // <p>{this.state.userInfo["terms_of_trade"]}</p>
                          ? this.renderTrems()
                          : null}
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                      </div>
                    </div>
                    {this.state.product_images.length != 0 ? (
                      <div className="d-flex termpad">
                        {this.state.product_images.map((item, index) => {
                          return (
                            <div
                              className="addImg_l"
                              onClick={(e) =>
                                this.setState({ showImage: true })
                              }
                            >
                              <img src={`${item.imageUrl}`} className="" />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    <Modal
                      isOpen={this.state.showImage}
                      fade={false}
                      style={{
                        alignSelf: "center",
                        justifyContent: "center",
                        margintop: "10%",
                        minWidth: "180px",
                        margintop: "60px",
                      }}
                    >
                      <div className="cardBox max-WT-950 center-box">
                        <div className="cardHead center-box pb10 text-center posRel">
                          <span
                            className="crossBtn"
                            onClick={() => this.setState({ showImage: false })}
                          >
                            <i className="fa fa-times" />
                          </span>

                          <h4 className="font18 mt0 mb5 text-center">
                            Product Image
                          </h4>
                        </div>
                        <ImageModal productImage={this.state.product_images} />
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* <!-- createAccountSection Start --> */}
        {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        <Footer {...this.props} />
        {/* <!-- createAccountSection Start -->	    */}
      </div>
    );
  }
}
