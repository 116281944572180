import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { base32 } from "../services/Decrypt";
import { Api } from "../services/webservices";
import { Modal } from "reactstrap";

/*              Globals                 */
import {
  showbox,
  showBoxfun,
  TRADE,
  TRADEfun,
  IMAGE,
  IMAGEfun,
  NAME,
  NAMEfun,
} from "../services/globals";
/*              Toaster                 */
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import ImageModal from "../components/imageModal";
export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
      po: 0,
      ne: 0,
      showImage: false,
    };
  }
  componentDidMount() {
    var DecryptData = base32.decode(this.props.match.params._id);

    window.scrollTo(0, 0);
    this.setState({ userId: DecryptData });
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      if (addr !== null) {
        this.setState(
          {
            _id: id,
            token: token,
            addr: addr,
            _userID: this.props.match.params._id,
          },
          () => {
            this.stateApis();
          }
        );
      }
    }, 300);
    $(window).scroll(function() {
      //console.log("shoeeaaaaaaaaaaaa   ")
      //alert("ddddd")
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $("header").addClass("header-bg");
        $(".fixedBox").addClass("fixdtop");
      } else {
        $("header").removeClass("header-bg");
        $(".fixedBox").removeClass("fixdtop");
      }
    });
  }

  seeFeedback = () => {
    this.props.history.push(
      `/feedback/${base32.encode(this.state.feedUserId)}`
    );
  };
  TrustUser = () => {
    if (this.state.block) {
      return;
    }
    if (this.state.userInfo !== "") {
      var parameter = {
        name: this.state.userInfo["user_name"].toString(),
        userId: this.state._id,
        status: this.state.trust ? "untrust" : "trust",
      };
      Api(
        "trade/trust_others",
        "POST",
        parameter,
        this.state._id,
        this.state.token
      ).then((resp) => {
        try {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            this.fetchData();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    }
  };
  BlockUser = () => {
    if (this.state.trust) {
      return;
    }

    if (this.state.userInfo !== "") {
      var parameter = {
        name: this.state.userInfo["user_name"].toString(),
        userId: this.state._id,
        status: this.state.block ? "unblock" : "block",
      };
      Api(
        "trade/blocked_others",
        "POST",
        parameter,
        this.state._id,
        this.state.token
      ).then((resp) => {
        try {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            this.fetchData();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    }
  };
  populateTagSell = (i) => {
    let a = [];
    for (var k = 0; k < this.state.userDataSell[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge" key={k}>
          {this.state.userDataSell[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  viewDetail = (e, Id) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(Id);
    this.props.history.push(`/user-details/${encryptData}`);
  };
  populateTagBuy = (i) => {
    let a = [];
    for (var k = 0; k < this.state.userDataBuy[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge">
          {this.state.userDataBuy[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  fetchData = () => {
    var parameter = {
      userId: this.state.userId,
    };

    Api(
      "trade/userDetails",
      "POST",
      parameter,
      this.state._id,
      this,
      this.state.token
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var accCreated = new Date(resp.data.result[0].created_at);
          var accCreatedTimeStamp = accCreated.getTime();
          var getTS = accCreatedTimeStamp;
          var updatedCreatedAt = new Date(getTS);
          var accCreatedDate =
            updatedCreatedAt.getFullYear() +
            "-" +
            parseInt(updatedCreatedAt.getMonth() + 1) +
            "-" +
            updatedCreatedAt.getDate();
          var accCreatedTime =
            updatedCreatedAt.getHours() +
            ":" +
            updatedCreatedAt.getMinutes() +
            ":" +
            updatedCreatedAt.getSeconds();
          resp.data.result.accCreatedAt = accCreatedDate + " " + accCreatedTime;
          var lastSeen;
          if (resp.data.result[0].lastActive == undefined) {
            lastSeen = new Date();
          } else {
            lastSeen = new Date(resp.data.result[0].lastActive);
          }

          var lastSeenTimeStamp = lastSeen.getTime();
          var getlastSeenTS = lastSeenTimeStamp;
          var lastSeenAt = new Date(getlastSeenTS);
          var lastSeenDate =
            lastSeenAt.getFullYear() +
            "-" +
            parseInt(lastSeenAt.getMonth() + 1) +
            "-" +
            lastSeenAt.getDate();
          var lastSeenTime =
            lastSeenAt.getHours() +
            ":" +
            lastSeenAt.getMinutes() +
            ":" +
            lastSeenAt.getSeconds();
          resp.data.result.lastSeen = lastSeenDate + " " + lastSeenTime;
          this.setState({
            accCreatedAt: accCreatedDate + " " + accCreatedTime,
            lastSeen: lastSeenDate + " " + lastSeenTime,
          });

          // var num = Number(resp.data.result["price_equation"]);
          // var n = num.toFixed(8);
          // var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // var n2 = n.split(".")[1]
          // var price =n1+"."+n2
          console.log(
            "rrrrrrrrrrrrr response from userprofile===>",
            resp.data.result
          );
          this.setState({
            userInfo: resp.data.result[0],
            feedUserId: resp.data.result[0]["_id"],
            profilePic: resp.data.result[0].profilePic,
            emailVerified: resp.data.result[0].verified_email,
            phoneVerified: resp.data.result[0].verified_phone,
          });
          if (resp.data.result.length !== 0) {
            let arr1 = [];
            let arr2 = [];
            for (let index = 1; index < resp.data.result.length; index++) {
              if (resp.data.result[index]["type_of_trade_other"] == "buy") {
                arr1.push(resp.data.result[index]);
              } else if (
                resp.data.result[index]["type_of_trade_other"] == "sell"
              ) {
                arr2.push(resp.data.result[index]);
              }
            }
            this.setState({ userDataBuy: arr1, userDataSell: arr2 });
            console.log("===============ash======>>>>", arr1);
          }
          if (resp.data.result[0]["trusted_by"].length) {
            resp.data.result[0]["trusted_by"].map((item, index) => {
              if (item.name == NAME) {
                this.setState({ trust: true });
              } else {
                // this.setState({trust:false})
              }
            });
          } else {
            this.setState({ trust: false });
          }
          if (resp.data.result[0]["show_real_name"] == true) {
            this.setState({ _name: resp.data.result[0]["name"] });
          }

          if (resp.data.result[0]["blocked_by"].length) {
            resp.data.result[0]["blocked_by"].map((item, index) => {
              if (item.name == NAME) {
                this.setState({ block: true });
              } else {
                //this.setState({block:false})
              }
            });
          } else {
            this.setState({ block: false });
          }
          this.setState({ _IDStatus: resp.data.result[0]["kyc_status"] });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
    this.allData();
  };
  allData = () => {
    var parameter = {
      userId: this.state.userId,
    };
    Api(
      `feedback/feedbackScore`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          if (resp.data.responseMessage == "No feedback found")
            this.setState({ feedbackScore: resp.data.result, po: 0, ne: 0 });
          else
            this.setState({
              feedbackScore: resp.data.result,
              po: resp.data.feedbackScore.positive,
              ne: resp.data.feedbackScore.negative,
            });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });

    Api(
      `trade/completedTradeOfUser`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          this.setState({ completedTradeList: resp.data.result1 });
          if (!resp.data.firstCompleteTrade) {
            let firstPur = new Date(resp.data.firstCompleteTrade.updatedAt);
            let firstPurchase =
              firstPur.getFullYear() +
              "-" +
              parseInt(firstPur.getMonth() + 1) +
              "-" +
              firstPur.getDate();
            this.setState({ firstPurchase: firstPurchase });
          }
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  };
  stateApis = () => {
    this.fetchData();
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  BuySpecific = (e, Id, method) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(Id);

    this.props.history.push(`/buy-bitcoin-instantly/${method}/${encryptData}`);
  };
  SellSpecific = (e, Id, method) => {
    e.preventDefault();
    var id = e.target.id;
    // var id1 = this.state.whole_data2[id]["_id"]
    var encryptData = base32.encode(Id);
    this.props.history.push(
      `/sell-bitcoins-instantly/${method}/${encryptData}`
    );
  };
  populateBuy = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.userDataBuy.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found </label>
          </td>
        </tr>
      );
    }
    for (
      let i = 0;
      i <
      (this.state.userDataBuy.length < 5 ? this.state.userDataBuy.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.userDataBuy[i]["user_id"]}`}
                onClick={(e) =>
                  this.viewDetail(e, this.state.userDataBuy[i]["user_id"])
                }
              >{`${this.state.userDataBuy[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: -10 }}>
                {`${this.state.userDataBuy[i].hasOwnProperty(
                  "product_images"
                )}` ? (
                  <img
                  className="proimg"
                    src={
                      this.state.userDataBuy[i]["product_images"][0]
                        ? this.state.userDataBuy[i]["product_images"][0][
                            "imageUrl"
                          ]
                        : require("../assets/newImages/imgAlbum.png")
                    }
                    style={{ width: 70, height: 70,border:this.state.userDataBuy[i].product_images.length? "solid 2px #f39c12" :"none" }}
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.userDataBuy[i]["product_images"],
                      })
                    }
                  />
                ) : (
                  <img
                    style={{ width: 70, height: 70 }}
                    src={require("../assets/newImages/imgAlbum.png")}
                  />
                )}
              </div>
              <div style={{ marginTop: -10, marginLeft: 10 }}>
                {`${this.state.userDataBuy[i]["payment_method"]}`}
                <p className="mb-0 tags-pay">
                  {this.populateTagBuy(i)}
                  {/* <span className="badge">Primary</span>
                    <span className="badge">Primary</span> */}
                </p>
              </div>
            </td>
          );
        } else if (j == 2) {
          if (this.state.userDataBuy[i]["price_equation"] !== undefined) {
            var num = Number(this.state.userDataBuy[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.userDataBuy[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.userDataBuy[i]["min_transaction_limit"] +
              "-" +
              this.state.userDataBuy[i]["max_transaction_limit"] +
              " " +
              this.state.userDataBuy[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.userDataBuy[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.userDataBuy[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j === 5) {
          children.push(
            <td className="Width110 text-center">
              <a
                id={`${this.state.userDataBuy[i]["_id"]}`}
                onClick={(e) =>
                  this.BuySpecific(
                    e,
                    this.state.userDataBuy[i]["_id"],
                    this.state.userDataBuy[i]["payment_method"]
                  )
                }
                className="btn btnGray minWidth80"
              >
                BUY
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populateBuyMore = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.userDataBuy.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found </label>
          </td>
        </tr>
      );
    }
    for (let i = 0; i < this.state.userDataBuy.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.userDataBuy[i]["user_id"]}`}
                onClick={(e) =>
                  this.viewDetail(e, this.state.userDataBuy[i]["user_id"])
                }
              >{`${this.state.userDataBuy[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: -10 }}>
                {`${this.state.userDataBuy[i].hasOwnProperty(
                  "product_images"
                )}` ? (
                  <img
                  className="proimg"
                    src={
                      this.state.userDataBuy[i]["product_images"][0]
                        ? this.state.userDataBuy[i]["product_images"][0][
                            "imageUrl"
                          ]
                        : require("../assets/newImages/imgAlbum.png")
                    }
                    style={{ width: 70, height: 70, border:this.state.userDataBuy[i]["product_images"][0][
                      "imageUrl"
                    ]? "solid 2px #f39c12" :"none"}}
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.userDataBuy[i]["product_images"],
                      })
                    }
                  />
                ) : (
                  <img
                    style={{ width: 70, height: 70 }}
                    src={require("../assets/newImages/imgAlbum.png")}
                  />
                )}
              </div>
              <div style={{ marginTop: -10, marginLeft: 10 }}>
                {`${this.state.userDataBuy[i]["payment_method"]}`}
                <p className="mb-0 tags-pay">
                  {this.populateTagBuy(i)}
                  {/* <span className="badge">Primary</span>
                <span className="badge">Primary</span> */}
                </p>
              </div>
            </td>
          );
        } else if (j == 2) {
          if (this.state.userDataBuy[i]["price_equation"] !== undefined) {
            var num = Number(this.state.userDataBuy[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.userDataBuy[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.userDataBuy[i]["min_transaction_limit"] +
              "-" +
              this.state.userDataBuy[i]["max_transaction_limit"] +
              " " +
              this.state.userDataBuy[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.userDataBuy[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.userDataBuy[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j == 5) {
          children.push(
            <td className="Width110 text-center">
              <a
                id={`${this.state.userDataBuy[i]["_id"]}`}
                onClick={(e) =>
                  this.BuySpecific(
                    e,
                    this.state.userDataBuy[i]["_id"],
                    this.state.userDataBuy[i]["payment_method"]
                  )
                }
                className="btn btnGray minWidth80"
              >
                BUY
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populateSell = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.userDataSell == undefined) {
      return;
    }
    if (this.state.userDataSell.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found </label>
          </td>
        </tr>
      );
    }
    for (
      let i = 0;
      i <
      (this.state.userDataSell.length < 5 ? this.state.userDataSell.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <a
                className="anchor-styling"
                id={`${this.state.userDataSell[i]["user_id"]}`}
                onClick={(e) =>
                  this.viewDetail(e, this.state.userDataSell[i]["user_id"])
                }
              >{`${this.state.userDataSell[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: -10 }}>
                {`${this.state.userDataSell[i].hasOwnProperty(
                  "product_images"
                )}` ? (
                  <img
                  className="proimg"
                    src={
                      this.state.userDataSell[i]["product_images"][0]
                        ? this.state.userDataSell[i]["product_images"][0][
                            "imageUrl"
                          ]
                        : require("../assets/newImages/imgAlbum.png")
                    }
                    style={{ width: 70, height: 70 , border:this.state.userDataSell[i]["product_images"][0][ "imageUrl"]? "solid 2px #f39c12":"none"}}
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.userDataSell[i]["product_images"],
                      })
                    }
                  />
                ) : (
                  <img
                    style={{ width: 70, height: 70 }}
                    src={require("../assets/newImages/imgAlbum.png")}
                  />
                )}
              </div>
              <div style={{ marginTop: -10, marginLeft: 10 }}>
                {`${this.state.userDataSell[i]["payment_method"]}`}
                <p className="mb-0 tags-pay">
                  {this.populateTagSell(i)}
                  {/* <span className="badge">Primary</span>
                <span className="badge">Primary</span> */}
                </p>
              </div>
            </td>
          );
        } else if (j == 2) {
          if (this.state.userDataSell[i]["price_equation"] !== undefined) {
            var num = Number(this.state.userDataSell[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.userDataSell[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.userDataSell[i][
              "min_transaction_limit"
            ] +
              "-" +
              this.state.userDataSell[i]["max_transaction_limit"] +
              " " +
              this.state.userDataSell[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.userDataSell[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.userDataSell[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j == 5) {
          children.push(
            <td className="Width110 text-center" key={j}>
              <a
                id={`${this.state.userDataSell[i]["_id"]}`}
                onClick={(e) =>
                  this.SellSpecific(
                    e,
                    this.state.userDataSell[i]["_id"],
                    this.state.userDataSell[i]["payment_method"]
                  )
                }
                className="btn btnGray minWidth80"
              >
                SELL
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populateSellMore = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.userDataSell == undefined) {
      return;
    }
    if (this.state.userDataSell.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found </label>
          </td>
        </tr>
      );
    }
    for (let i = 0; i < this.state.userDataSell.length; i++) {
      let children = [];

      for (let j = 0; j <= 5; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.userDataSell[i]["user_id"]}`}
                onClick={(e) =>
                  this.viewDetail(e, this.state.userDataSell[i]["user_id"])
                }
              >{`${this.state.userDataSell[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            // <td>
            //   {`${this.state.userDataSell[i]["payment_method"]}`}
            //   <p className="mb-0 tags-pay">
            //     {this.populateTagSell(i)}
            //     {/* <span className="badge">Primary</span>
            //               <span className="badge">Primary</span> */}
            //   </p>
            // </td>
            <td style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: -10 }}>
                {`${this.state.userDataSell[i].hasOwnProperty(
                  "product_images"
                )}` ? (
                  <img
                  className="proimg"
                    src={
                      this.state.userDataSell[i]["product_images"][0]
                        ? this.state.userDataSell[i]["product_images"][0][
                            "imageUrl"
                          ]
                        : require("../assets/newImages/imgAlbum.png")
                    }
                    style={{ width: 70, height: 70 , border: "solid 2px #f39c12"}}
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.userDataSell[i]["product_images"],
                      })
                    }
                  />
                ) : (
                  <img
                    style={{ width: 70, height: 70 }}
                    src={require("../assets/newImages/imgAlbum.png")}
                  />
                )}
              </div>
              <div style={{ marginTop: -10, marginLeft: 10 }}>
                {`${this.state.userDataSell[i]["payment_method"]}`}
                <p className="mb-0 tags-pay">
                  {this.populateTagSell(i)}
                  {/* <span className="badge">Primary</span>
                 <span className="badge">Primary</span> */}
                </p>
              </div>
            </td>
          );
        } else if (j == 2) {
          if (this.state.userDataSell[i]["price_equation"] !== undefined) {
            var num = Number(this.state.userDataSell[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.userDataSell[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.userDataSell[i]["min_transaction_limit"] +
              "-" +
              this.state.userDataSell[i]["max_transaction_limit"] +
              " " +
              this.state.userDataSell[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.userDataSell[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.userDataSell[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
          // children.push(<td>{`${this.state.userDataSell[i]["margin"]}`}</td>)
        } else if (j == 5) {
          children.push(
            <td className="Width110 text-center">
              <a
                id={`${this.state.userDataSell[i]["_id"]}`}
                onClick={(e) =>
                  this.SellSpecific(
                    e,
                    this.state.userDataSell[i]["_id"],
                    this.state.userDataSell[i]["payment_method"]
                  )
                }
                className="btn btnGray minWidth80"
              >
                SELL
              </a>
            </td>
          );
        }
      }

      table.push(<tr>{children}</tr>);
    }
    return table;
  };

  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        {/* <Header {...this.props} 
                shouldSocketEmit ={true/false}
                /> */}
        <ToastContainer />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <div className="row colCenter">
                <div className="col-lg-5 marBtm">
                  <div className="cardBox">
                    <div className="cardHead text-center">
                      <h4 className="textInitial">User details</h4>
                    </div>
                    <div className="p30">
                      <div className="profileImgBox d-flex align-items-center mb15">
                        <div className="profileImg minWidth100 pr15">
                          {this.state.profilePic ? (
                            <img src={`${this.state.profilePic}`} />
                          ) : null}
                        </div>
                        <div
                          className="ml-auto w-100"
                          style={{ color: "var(--text-grey)" }}
                        >
                          {this.state.userInfo != "" ? (
                            <div>
                              <h5>{this.state.userInfo["user_name"]}</h5>
                              <span>{this.state.userInfo["introduction"]}</span>
                            </div>
                          ) : null}
                          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                        </div>
                      </div>
                      {/* <div className="profileViewField">
                                            <label className="viewFieldLeft">Trade volume</label>
                                            <div className="viewFieldRight">500+ BTC</div>
                                        </div> */}
                      <div className="profileViewField lineHeight1">
                        <label className="viewFieldLeft dBlueColor">
                          Number of confirmed trade
                        </label>
                        <div className="viewFieldRight">
                          {this.state.completedTradeList}
                        </div>
                        {/* <div className="viewFieldRight">
                              300+
                              <p className="font11 mb0">.....with 1764 diffrent partners</p>
                           </div> */}
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Feedback score{" "}
                        </label>
                        <div className="viewFieldRight">
                          {/* {this.state.feedbackScore ? this.state.feedbackScore:null} */}
                          {/* <div className="globalRating">
                                 <i className="fas fa-star rated"></i>
                                 <i className="fas fa-star rated"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                              </div> */}
                          <a className="ml5" onClick={() => this.seeFeedback()}>
                            {" "}
                            {"( +"}
                            {this.state.po}
                            {" / "}
                            {" -"}
                            {this.state.ne}
                            {" ) "} <u className="seeFeed">See feedback</u>
                          </a>
                        </div>
                      </div>
                      {this.state._name != undefined ? (
                        <div className="profileViewField">
                          <label className="viewFieldLeft dBlueColor">
                            Real name
                          </label>

                          <div className="viewFieldRight">
                            {this.state._name}
                          </div>
                        </div>
                      ) : null}
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          First purchase
                        </label>
                        {this.state.firstPurchase ? (
                          <div className="viewFieldRight">
                            {this.state.firstPurchase}
                          </div>
                        ) : null}
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Account created
                        </label>
                        {this.state.accCreatedAt ? (
                          <div className="viewFieldRight">
                            {this.state.accCreatedAt}
                          </div>
                        ) : null}
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Last seen
                        </label>
                        {this.state.lastSeen ? (
                          <div className="viewFieldRight">
                            {this.state.lastSeen}
                          </div>
                        ) : null}
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Language{" "}
                        </label>
                        <div className="viewFieldRight">English</div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Email
                        </label>
                        <div className="viewFieldRight">
                          <div className="checkbox-design-block posRel">
                            <label className="checkbox-design mb0 ml15">
                              <input
                                type="checkbox"
                                name="emailVerified"
                                checked={this.state.emailVerified}
                                disabled={true}
                              />
                              <span />
                            </label>
                            {/* {this.state.userInfo != "" ?
                                                    <span >{this.state.userInfo["email"]}</span>
                                                    : null} */}
                          </div>
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Phone number
                        </label>
                        <div className="viewFieldRight">
                          <div className="checkbox-design-block posRel">
                            <label className="checkbox-design mb0 ml15">
                              <input
                                type="checkbox"
                                name="phoneVerified"
                                checked={this.state.phoneVerified}
                                disabled={true}
                              />
                              <span />
                            </label>
                            {/* {this.state.result ?
                                                    this.state.result.country_code ?
                                                <span>{this.state.result['country_code']}-{this.state.result['phone_number']}</span>
                                                :null:null} */}
                          </div>
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          KYC Status
                        </label>
                        <div className="viewFieldRight">
                          {this.state._IDStatus ? this.state._IDStatus : null}
                          {/* <div className="checkbox-design-block">
                                 <label className="checkbox-design mb0">
                                 <input type="checkbox" name="" />
                                 <span></span>verified 4 years, 7 months ago
                                 </label>
                              </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="profileViewField">
                                            <label className="viewFieldLeft">Trust</label>
                                            <div className="viewFieldRight">
                              Trusted by 500+ people
                           </div>
                                        </div> */}
                    {/* <div className="profileViewField">
                                            <label className="viewFieldLeft">Block</label>
                                            <div className="viewFieldRight">
                              Blocked by 26 people
                           </div>
                                        </div> */}
                  </div>
                </div>
                <div
                className="col-12 col-lg-6"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="col-lg-5 marBtm"
                    style={{ maxWidth: "100%", flexBasis: "65%" }}
                  >
                    {this.state._id ? (
                      this.state._id !== this.state.userId ? (
                        <div className="cardBox " style={{ height: "100%" }}>
                          <div className="cardHead text-center">
                            <h4 className="textInitial">
                              <i className="fas fa-star mr5" />
                              Already Trusting you
                            </h4>
                          </div>
                          <div className="p30">
                            <p style={{ color: "var(--text-grey)" }}>
                              It's you
                            </p>
                            <div className="text-center d-flex colCenter">
                              <button
                                type="button"
                                className="btn btnRed max-WT-160"
                                onClick={() => this.TrustUser()}
                              >
                                {this.state.trust
                                  ? "Untrust User"
                                  : "Trust User"}
                              </button>
                              <button
                                type="button"
                                className="btn btnGreen max-WT-160 ml10"
                                onClick={() => this.BlockUser()}
                              >
                                {this.state.block
                                  ? "Unblock User"
                                  : "Block User"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {/* <div className="cardBox p30 minHeight200 mb15">
                                        <h2 className="font22 textInitial mb15">Seller escrow release times</h2>
                                        <div className="profileViewField">
                                            <label className="viewFieldLeft widthAuto minWidth80">Median:</label>
                                            <div className="viewFieldRight widthAuto">1 min.</div>
                                        </div>
                                        <div className="profileViewField">
                                            <label className="viewFieldLeft widthAuto minWidth80">Average:</label>
                                            <div className="viewFieldRight widthAuto">15 min.</div>
                                        </div>
                                    </div> */}
                  </div>
                  <div
                    className="cardBox"
                    style={{ margin: "10px", flexBasis: "25%", height: "100%" }}
                  >
                    <div className="cardHead text-center">
                      <h4 className="textInitial">External Stats</h4>
                    </div>
                    <div
                      className="p20"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        color: "var(--text-grey)",
                      }}
                    >
                      <div>
                        <h3>{this.state.userInfo["importedFeedback"]}+</h3>
                        <p>Imported Feedback</p>
                      </div>
                      <div>
                        <h3>{this.state.userInfo["importedTrades"]}+</h3>
                        <p>Imported Trades</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buySellBox2">
                <div className="sectionHead mb20">
                  <h2 className="dBlueColor">
                    Other Advertisement By {this.state.userInfo["user_name"]}
                  </h2>
                </div>
                <div className="tableBox">
                  <div className="tableBoxHead d-flex align-items-center flex-wrap">
                    <h4 className="mb15">Buy Bitcoins</h4>
                  </div>
                  <div className="tableBoxBody">
                    <div className="table-responsive tbl">
                      <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                        <thead className="tbl_hd">
                          <tr>
                            <th>Buy with</th>
                            <th>Payment Method</th>
                            <th>Price/BTC</th>
                            <th>Limit</th>
                            <th>To Pay on the dollar</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userDataBuy
                            ? this.state.populateBuyMore
                              ? this.populateBuyMore()
                              : this.populateBuy()
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ marginTop: 15, display: "flex", width: "55%" }}>
                    <button
                      className="showMore btn ml-auto mb15 minWidth120"
                      onClick={() =>
                        this.setState({
                          populateBuyMore: !this.state.populateBuyMore,
                        })
                      }
                    >
                      {this.state.populateBuyMore
                        ? "Show Less..."
                        : "Show More..."}
                    </button>
                  </div>
                </div>
                <div className="tableBox">
                  <div className="tableBoxHead d-flex align-items-center flex-wrap">
                    <h4 className="mb15">Sell Bitcoins</h4>
                  </div>
                  <div className="tableBoxBody">
                    <div className="table-responsive tbl">
                      <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                        <thead className="tbl_hd">
                          <tr>
                            <th>Sell with</th>
                            <th>Payment Method</th>
                            <th>Price/BTC</th>
                            <th>Limit</th>
                            <th style={{ padding: 10 }}>
                              To Pay on the dollar
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userDataSell
                            ? this.state.populateSellMore
                              ? this.populateSellMore()
                              : this.populateSell()
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ marginTop: 15, display: "flex", width: "55%" }}>
                    <button
                      className="showMore btn ml-auto mb15 minWidth120"
                      onClick={() =>
                        this.setState({
                          populateSellMore: !this.state.populateSellMore,
                        })
                      }
                    >
                      {this.state.populateSellMore
                        ? "Show Less..."
                        : "Show More..."}
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="feedbackList mt30 max-WT-500">

                                <div className="feedbackBox">
                                    <div className="feedUserLeft">
                                        <span className="feedUserImg"><img src="images/user-icon.png" alt="image" /></span>
                                    </div>
                                    <div className="feedUserRight">
                                        <h5><span className="feedUserName">Hennah baker</span><span className="feedTime">May 26, 2018. 1:08 p.m</span></h5>
                                        <p><i className="far fa-thumbs-up gColor"></i>Nice trade, fast response.</p>
                                    </div>
                                </div>

                                <div className="feedbackBox">
                                    <div className="feedUserLeft">
                                        <span className="feedUserImg"><img src="images/user-icon.png" alt="image" /></span>
                                    </div>
                                    <div className="feedUserRight">
                                        <h5><span className="feedUserName">Hennah baker</span><span className="feedTime">May 26, 2018. 1:08 p.m</span></h5>
                                        <p><i className="far fa-thumbs-down rColor"></i>Nice trade, fast response.</p>
                                    </div>
                                </div>

                            </div> */}
            </div>

            <Modal
              isOpen={this.state.showImage}
              fade={false}
              style={{
                alignSelf: "center",
                justifyContent: "center",
                margintop: "10%",
                maxWidth: "680px",
                margintop: "60px",
              }}
            >
              <div className="cardBox max-WT-950 center-box">
                <div className="cardHead center-box pb10 text-center posRel">
                  <span
                    className="crossBtn"
                    onClick={() =>
                      this.setState({
                        showImage: false,
                      })
                    }
                  >
                    <i className="fa fa-times" />
                  </span>

                  <h4 className="font18 mt0 mb5 text-center">Product Image</h4>
                </div>
                <ImageModal productImage={this.state.prodImg} />
              </div>
            </Modal>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
