import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container1440">
          {/* <div className="lang">
          <div style = {{textAlign:'right', backgroundColor:"white", paddingTop:2, paddingLeft:10, paddingRight:10, height:63, borderRadius:5}} id="google_translate_element"></div>
          <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
          </div> */}
          <div className="row rowPd">
            <div className="col-md-2">
              <div className="footLogo">
                <img
                  src={require("../assets/newImages/header_logo.png")}
                  alt="Logo"
                />
              </div>
              <div className="socialLinks">
                <img src={require("../assets/newImages/qrCode.png")} />
              </div>
            </div>
            <div className="col-md-2">
              <div className="addressBox">
                <label>About Coinbaazar</label>
                <ul className="footerMenu mb0">
                  <li>
                    <Link to="/aboutus">About Us</Link>
                  </li>
                  <li>
                    {/* <Link to="/contactus">Contact Us</Link> */}
                    <Link to={"/contactus"}>Contact Us</Link>
                  </li>
                  {/* <li>                   
                    <a >Security Bouties</a>
                  </li>
                  <li>
                    <a>Statistics</a>
                  </li> */}
                  <li>
                    <Link to={"/faq"}>FAQ</Link>
                  </li>
                  <li>
                    {/* <a href = "https://blog.coinbaazar.com" target="_blank" >Blog</a> */}
                    {/* <Link to={'/faq'} 
                     onClick = { ()=> this.changeBtnTypeState('/maindashboard') }
                       className={`btn btnHeader ${this.state.headerBtnType == '/faq' ? 'btnActive' : ''}`}
                      >Help</Link> */}
                  </li>
                  <li>
                    <Link to={"/terms"}>Terms of Services</Link>
                  </li>
                  <li>
                    <Link to={"/privacypolicy"}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="addressBox">
                <label>Office </label>

                {/* Office 28, Floor 4 , Block 4, 71 Vazha- Pshavela Avenue.
                  Tbilisi , 0186 , Georgia */}
                {/* <span>Coinbaazar LLC </span> */}
                <p>
                  Coinbaazar LLC
                  <br />
                  #32 Bob Walsh street, Didube district, Tbilisi 0159, Georgia
                </p>
                <p>help@coinbaazar.com</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="addressBox">
                <label>Additional Links</label>

                <ul className="footerMenu mb0">
                  <li>
                    <Link to="/referral">Referral</Link>
                  </li>
                </ul>
                <li>
                  <Link to="/merchant">Merchant</Link>
                </li>
                <li>
                  <Link to="/social_media">Social Media</Link>
                </li>
                <li>
                  <Link to="/security">Security</Link>
                </li>
                <li>
                  <Link to="/giftcard" target="_blank">
                    Buy Bitcoin with Gift Cards
                  </Link>
                </li>
                <li>
                  <Link to="/banktransfer" target="_blank">
                    Buy Bitcoin with Bank Transfer
                  </Link>
                </li>
                <li>
                  <Link to="/altcoin" target="_blank">
                    Buy Bitcoin with Altcoin
                  </Link>
                </li>
                <li>
                  <Link to="/cashdeposit" target="_blank">
                    Buy Bitcoin with Cash Deposits
                  </Link>
                </li>
                <li>
                  <Link to="/goods&services" target="_blank">
                    Buy Bitcoin with Goods & Services
                  </Link>
                </li>
              </div>

              {/* <div className="addressBox">
                <label>Follow Us</label>
                <div className="socialLinks d-flex">
                  <a
                    href="https://www.facebook.com/Coin-Baazar-384046989104752"
                    target="_blank"
                    className="sprite Icon1"
                  />
                  <a
                    href="https://twitter.com/coin_baazar"
                    target="_blank"
                    className="sprite Icon2"
                  />
                  {/* <a href="https://www.linkedin.com/in/coin-baazar-344018182/" target="_blank" className="sprite Icon3" /> */}
              {/* <a
                    className="sprite Icon5"
                    href="https://www.instagram.com/coin_baazar/"
                    target="_blank"
                  />
                  <div>   <a
                    className="sprite2"
                    href="https://t.me/Coinbaazar_Exchange"
                    target="_blank">
                
                    <img src={require("../assets/images/telegrm.png")} className="teleIcon"></img>
                    </a>
                    </div>
                </div>
                
              </div>  */}
            </div>
            <div className="col-md-3">
              {/* <div className="footerLogo"> */}
              <div className="addressBox mb15">
                <label>Download App</label>
                <div className="footerApp">
                  <div className="googleApp">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.nobillio.coinbaazar"
                      target="_blank"
                    >
                      <img
                        src={require("../assets/newImages/googlePlay.png")}
                        alt="icon"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://apps.apple.com/in/app/coinbaazar-buy-sell-bitcoins/id1548921926"
                      target="_blank"
                    >
                      <img
                        src={require("../assets/newImages/appstr.png")}
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </div>

              {/* </div> */}
              <div className="footerApp">
                <div className="googleApp">
                  <img
                    src={require("../assets/newImages/comodo.png")}
                    alt="icon"
                  />
                </div>
                <div className="cloudfare">
                  <img
                    src={require("../assets/newImages/cloudfare.png")}
                    alt="icon"
                  />
                </div>
              </div>

              <div className="footerApp">
                <div className="bitcoin">
                <a
                      href="https://b.tc/conference/2022/partners/coinbaazar"
                      target="_blank"
                    >
                  <img
                    src={require("../assets/newImages/bitcoinN.png")}
                    alt="icon"
                  />
                  </a>
                </div>
                <div className="dun">
                  <img
                    src={require("../assets/newImages/dun.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="addressBox mb15">
                <label>Language</label>
                <div className="footerApp">
                  <div
                    style={{
                      textAlign: "left",
                      color: "white",
                      paddingTop: 2,
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: 63,
                      borderRadius: 5,
                    }}
                    id="google_translate_element"
                  />
                  <script
                    type="text/javascript"
                    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                  />
                </div>
                <div className="footerApp">
                  <div className="googleApp">
                    <a href="https://cryptoplo.com/" target="_blank">
                      <img
                        src={require("../assets/newImages/cryptopolo.png")}
                        alt="icon"
                      />
                    </a>
                  </div>
                  <div className="talkIcon">
                    <a href="https://talkoncrypto.com/" target="_blank">
                      <img
                        src={require("../assets/newImages/talk.png")}
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="footerHead pRelative ">
            <p className="copyRight">2021 © Coinbaazar. All rights reserved</p>
            <p>
              Coinbaazar LLC is the owner of the logo displayed on
              coinbaazar.com and coinbaazar apps, which is a registered
              trademark (or ®) of Coinbaazar LLC. Any unauthorized use or
              reproduction of this logo is strictly prohibited. The use of any
              third-party logos does not imply any endorsement, affiliation, or
              sponsorship of coinbaazar llc, coinbaazar.com or coinbaazar apps
              and has no relation with Paypal, Apple, MoneyGram, Western Union,
              Amazon, Reloadit, Perfect Money, WebMoney, Google Wallet, Sephora,
              Neteller , Square Cash, Xbox , Alipay, Chase QuickPay, Skrill,
              Vanilla, OneVanilla, Venmo etc, any govt. or private banks, any
              gift card company and any other payment methods. We make no claims
              about being supported by or supporting these services. Their
              respective wordmarks and trademarks belong to them only.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
