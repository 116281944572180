import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = { userId: "" };
  }
  componentDidMount() {
    let user_id = localStorage.getItem("data");
    this.setState({ userId: user_id });
  }

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };
  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <section className="mechantBnr">
          <div className="container1440">
            <div className="row rowSpace3">
              <div className="col-md-6">
                <div className="text1">
                  <h2 className="header2 fontfamTB">
                    EARN consistent income through referrals{" "}
                  </h2>
                  <p className="font16 txtJus" style={{ color: "black" }}>
                    Coinbaazar referral program lets you earn bitcoins on every
                    successful trade by your referrals
                  </p>
                  {!this.state.userId && (
                    <button
                      type="button"
                      className="btn btnWhite  mt15"
                      onClick={() => this.props.history.push("/signup")}
                    >
                      Sign Up Now <i className="fa fa-chevron-right" />
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="width85">
                  <img
                    src={require("../assets/newImages/referral_1.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div>
              <div className="refHeader">
                <span className="txt1">GET</span>
                <span className="txt2">INSTANT</span>
                <span className="txt1">PAYOUT</span>
              </div>
              <div className="refTxt">
                <p style={{ margin: 0 }}>
                  Share your referral code with friends & family
                  <br />
                  Every successful trade by them for buying or selling bitcoins
                  with any payment method, lets you earn free bitcoins.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="row rowSpace">
              <div className="col-md-6">
                <div className="text2">
                  <h2 className="header2 fontfamTM">Earnings Unlimited </h2>
                  <span className="font16">
                    No bar on the numbers of referrals!! Instant payout on every
                    successful trade, that they do!!
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img_1">
                  <img
                    src={require("../assets/newImages/referral2.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="refBnr"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="refHeader">
              <span className="font30 fontfamTM dBlueColor">
                Build your own
              </span>
              <span className="font30 fontfamTB dBlueColor ml10">
                Community
              </span>
              <p className="font16">
                Refer as many people as you can to grow your community
              </p>
              <div className="cnterImg">
                <img src={require("../assets/newImages/ref5.png")} />
              </div>
            </div>
          </div>
        </section>
        <section
          className="pb50"
          style={{ backgroundColor: "var(--white-color)" }}
        >
          <div className="container1440">
            <div className="row rowSpace flexRev">
              <div className="col-md-6">
                <div className="refImgLft">
                  <img src={require("../assets/newImages/ref3.png")} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="refTxt3">
                  <h2 className="header2 fontfamTM">Withdraw when you need</h2>
                  <span
                    className="font16"
                    style={{ color: "var(--text-grey)" }}
                  >
                    Cash your bitcoins or withdraw whenever you want to...
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container1440">
            <div className="row rowSpace">
              <div className="col-md-6">
                <div className="text3">
                  <h2 className="header2 fontfamTM">Financial Security </h2>
                  <span className="font16">
                    Your bitcoin wallet will be your
                  </span>
                  <span className="header2 font16 fontfamTM ml5 mr5">
                    piggybank. That will
                  </span>
                  <span className="font16">make you financially secure</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="refImg">
                  <img src={require("../assets/newImages/ref4.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </div>
    );
  }
}
