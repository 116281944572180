import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api } from "../services/webservices";
import { toast_func } from "../components/toaster";
import { ToastContainer, toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import renderHTML from "react-render-html";
export default class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = { _data: "", loading: false };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    var parameter = { type: "TERMS" };
    this.setState({ loading: true });
    try {
      Api("static/staticContent", "POST", parameter).then((resp) => {
        this.setState({ loading: false });
        if (resp.status == 200) {
          this.setState({ loading: false });
          setTimeout(() => {
            this.setState({
              _data: resp.data.succ.description,
            });
          }, 500);
        } else {
          toast_func(
            "error",
            "Unable to reach server please contact to administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };

  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <main className="mainContent">
        <Header {...this.props} parentCallback1={this.handleCallback} />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <ToastContainer />
        <section className="staticContentSection buySellBox2 mb50">
          <div className="container1440">
            <div className="cardBox createAdv">
              <div className="sectionHead">
                <h2 className="font18 textInitial fontfamTB">
                  Terms and Conditions
                </h2>
              </div>
              <p className="aboutusTxt">
                {this.state._data != undefined
                  ? renderHTML(`${this.state._data}`)
                  : null}
              </p>
            </div>
          </div>
        </section>

        {isloggedIn !== "true" ? (
          <section className="mt35">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mar_auto">
                  <div className="createAccountBox">
                    <p className="">
                      Getting started is easy. Create your account now.
                    </p>
                    <Link to={"/signup"}>
                      <a className="btn btnBlack max-WT-190 wColor p10 pb10">
                        Create an Account Now
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <Footer {...this.props} />
      </main>
    );
  }
}
