import Modal from "react-modal";
import React from 'react';

let notification = 0
let data =  '' 

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };
  
export const ReactModal = (props)=> {
    return(<Modal
    isOpen={props.visible}
    style={customStyles}
    {...props}
    />)
    }
    export var showbox = false
    export function showBoxfun(val){
      showbox = val
      return showbox
    }    
    export var TRADE = ""
    export function TRADEfun(val){
      TRADE = val
      return TRADE
    }  
    export let IMAGE = ""
    export function IMAGEfun(val){
      IMAGE = val
      return IMAGE
    }
    export let NAME = ""
    export function NAMEfun(val){
    
      NAME = val
      return NAME
    }
    export let RECEIVER = ""
    export function RECEIVERfun(val){
      RECEIVER = val
      return RECEIVER
    }

  
    export const getNotification = ()=>{
      return notification;

    }

    export const setNotification = (data)=>{
      notification = data

    }


    export const getData = ()=>{
      return data;

    }

    export const setData = (d)=>{
      data = d

    }