import React, { Component } from "react";
import Headers from "../components/header";
import Footer from "../components/footer";
import { Api, socket, getCurrency } from "../services/webservices";
import Slider from "react-slick";
import {
  validateUserName,
  validateEmail,
  validateMessage,
  validateRestrictAmount,
  validateMobileNo,
  validateConfirmPassword,
} from "../utils/validation";
import CurrencyModal from "../components/currencyModal";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";

let currencyJson = [];
let arr = [];
export default class CreateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "create",
      body: "",
      gifttab: "",
      amountStatus: "",
      amount: "",
      amountError: "",
      amountStatus: false,
      recipientname: "",
      recipientnameStatus: false,
      recipientnameError: "",
      sendername: false,
      sendernameError: "",
      sendername: "",
      email: "",
      emailError: "",
      emailStatus: false,
      msg: "",
      msgStatus: false,
      msgError: "",
      currencyModal: false,
      currency: "USD",
      calAmount: "",
      BTCAmount: "",
      fundingBalance: "",
      totalBalance: 0,
      selectedIndex: 0,
      sliderArr: [],
      giftCardHistory: [],
      giftCardData: "",
      EnableshowModal: false,
      selectedIMAGE:
        "https://uat.coinbaazar.com/static/media/vacation1.105202f2.png",
      vacationImage: [
        {
          image: require("../assets/newImages/vacation1.png"),
          link:
            "https://uat.coinbaazar.com/static/media/vacation1.105202f2.png",
        },
        {
          image: require("../assets/newImages/vacation2.png"),
          link:
            "https://uat.coinbaazar.com/static/media/vacation2.7916db68.png",
        },
        {
          image: require("../assets/newImages/vacation3.png"),
          link:
            "https://uat.coinbaazar.com/static/media/vacation3.e1e07ca5.png",
        },
        {
          image: require("../assets/newImages/vacation4.png"),
          link:
            "https://uat.coinbaazar.com/static/media/vacation4.7dcf3bb9.png",
        },
        {
          image: require("../assets/newImages/vacation5.png"),
          link:
            "https://uat.coinbaazar.com/static/media/vacation5.6244211d.png",
        },
      ],
      festivalImage: [
        {
          image: require("../assets/newImages/fest1.png"),
          link: "https://uat.coinbaazar.com/static/media/fest1.6a67e082.png",
        },
        {
          image: require("../assets/newImages/fest2.jpg"),
          link: "https://uat.coinbaazar.com/static/media/fest2.af106e34.jpg",
        },
        {
          image: require("../assets/newImages/fest3.png"),
          link: "https://uat.coinbaazar.com/static/media/fest3.2c5e37f0.png",
        },
        {
          image: require("../assets/newImages/fest4.png"),
          link: "https://uat.coinbaazar.com/static/media/fest4.de8cc1a0.png",
        },
        {
          image: require("../assets/newImages/fest5.png"),
          link: "https://uat.coinbaazar.com/static/media/fest5.403ebe1b.png",
        },
        {
          image: require("../assets/newImages/fest6.png"),
          link: "https://uat.coinbaazar.com/static/media/fest6.e1ff6e83.png",
        },
        {
          image: require("../assets/newImages/fest7.png"),
          link: "https://uat.coinbaazar.com/static/media/fest7.79e9d7b5.png",
        },
        {
          image: require("../assets/newImages/fest8.png"),
          link: "https://uat.coinbaazar.com/static/media/fest8.6bd25944.png",
        },
      ],
      bitcoinImage: [
        {
          image: require("../assets/newImages/bitcoin1.png"),
          link: "https://uat.coinbaazar.com/static/media/bitcoin1.e66e4308.png",
        },
        {
          image: require("../assets/newImages/bitcoin2.png"),
          link: "https://uat.coinbaazar.com/static/media/bitcoin2.1ee98a0b.png",
        },
        {
          image: require("../assets/newImages/bitcoin3.png"),
          link: "https://uat.coinbaazar.com/static/media/bitcoin3.e9018e91.png",
        },
        {
          image: require("../assets/newImages/bitcoin4.png"),
          link: "https://uat.coinbaazar.com/static/media/bitcoin4.57e1dee8.png",
        },
      ],
      zodiacImage: [
        {
          image: require("../assets/newImages/zodiac-Taurus.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Taurus.ac804470.png",
        },
        {
          image: require("../assets/newImages/zodiac-gemeni.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-gemeni.c354d464.png",
        },
        {
          image: require("../assets/newImages/zodiac-cancer.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-cancer.23cbeccd.png",
        },
        {
          image: require("../assets/newImages/zodiac-Leo.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Leo.b409c74d.png",
        },
        {
          image: require("../assets/newImages/zodiac-Virgo.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Virgo.51a74ae7.png",
        },
        {
          image: require("../assets/newImages/zodiac-Libra.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Libra.660717a5.png",
        },
        {
          image: require("../assets/newImages/zodiac-scorpio.png"),
          link:
            "	https://uat.coinbaazar.com/static/media/zodiac-scorpio.70a0df27.png",
        },
        {
          image: require("../assets/newImages/zodiac-sagittarius.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-sagittarius.cf21ded6.png",
        },
        {
          image: require("../assets/newImages/zodiac-capricon.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-capricon.0836581a.png",
        },
        {
          image: require("../assets/newImages/zodiac-Aquarius.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Aquarius.035b832c.png",
        },
        {
          image: require("../assets/newImages/zodiac-Pisces.png"),
          link:
            "https://uat.coinbaazar.com/static/media/zodiac-Pisces.8e478d25.png",
        },
      ],
      entertainmentImage: [
        {
          image: require("../assets/newImages/ent1.png"),
          link: "https://uat.coinbaazar.com/static/media/ent1.7eccc196.png",
        },
        {
          image: require("../assets/newImages/ent2.png"),
          link: "https://uat.coinbaazar.com/static/media/ent2.8232fc48.png",
        },
        {
          image: require("../assets/newImages/ent3.png"),
          link: "https://uat.coinbaazar.com/static/media/ent3.5aba62cc.png",
        },
        {
          image: require("../assets/newImages/ent4.png"),
          link: "https://uat.coinbaazar.com/static/media/ent4.0a87bbd8.png",
        },
        {
          image: require("../assets/newImages/ent5.png"),
          link: "https://uat.coinbaazar.com/static/media/ent5.c38ab08b.png",
        },
        {
          image: require("../assets/newImages/ent6.png"),
          link: "https://uat.coinbaazar.com/static/media/ent6.fdb35fae.png",
        },
      ],
      shoppingImage: [
        {
          image: require("../assets/newImages/shopping1.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping1.7c6c56bf.png",
        },
        {
          image: require("../assets/newImages/shopping2.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping2.1dc5466a.png",
        },
        {
          image: require("../assets/newImages/shopping3.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping3.09a8636d.png",
        },
        {
          image: require("../assets/newImages/shopping4.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping4.a0ba4fc0.png",
        },
        {
          image: require("../assets/newImages/shopping5.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping5.89c45e50.png",
        },
        {
          image: require("../assets/newImages/shopping6.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping6.dd745e36.png",
        },
        {
          image: require("../assets/newImages/shopping7.png"),
          link:
            "https://uat.coinbaazar.com/static/media/shopping7.5526eb44.png",
        },
      ],
      musicImage: [
        {
          image: require("../assets/newImages/music1.png"),
          link: "https://uat.coinbaazar.com/static/media/music1.f9069979.png",
        },
        {
          image: require("../assets/newImages/music2.png"),
          link: "https://uat.coinbaazar.com/static/media/music2.b39e4bb8.png",
        },
        {
          image: require("../assets/newImages/music3.png"),
          link: "https://uat.coinbaazar.com/static/media/music3.05f2ce7e.png",
        },
        {
          image: require("../assets/newImages/music4.png"),
          link: "https://uat.coinbaazar.com/static/media/music4.9953fceb.png",
        },
        {
          image: require("../assets/newImages/music5.png"),
          link: "https://uat.coinbaazar.com/static/media/music5.e644e5a3.png",
        },
        {
          image: require("../assets/newImages/music6.png"),
          link: "https://uat.coinbaazar.com/static/media/music6.c59c29cc.png",
        },
        {
          image: require("../assets/newImages/music7.png"),
          link: "https://uat.coinbaazar.com/static/media/music7.1bb2b4a3.png",
        },
      ],
      threeDImage: [
        {
          image: require("../assets/newImages/3d1.png"),
          link: "https://uat.coinbaazar.com/static/media/3d1.e3520bfd.png",
        },
        {
          image: require("../assets/newImages/3d2.png"),
          link: "https://uat.coinbaazar.com/static/media/3d2.07b95cc5.png",
        },
        {
          image: require("../assets/newImages/3d3.png"),
          link: "https://uat.coinbaazar.com/static/media/3d3.eb79400e.png",
        },
        {
          image: require("../assets/newImages/3d4.png"),
          link: "https://uat.coinbaazar.com/static/media/3d4.833a90e8.png",
        },
        {
          image: require("../assets/newImages/3d5.png"),
          link: "https://uat.coinbaazar.com/static/media/3d5.9cc82c9a.png",
        },
        {
          image: require("../assets/newImages/3d6.png"),
          link: "https://uat.coinbaazar.com/static/media/3d6.4f8db581.png",
        },
      ],
      metaverseImage: [
        {
          image: require("../assets/newImages/meta1.png"),
          link: "https://uat.coinbaazar.com/static/media/meta1.2c325cc4.png",
        },
        {
          image: require("../assets/newImages/meta2.png"),
          link: "https://uat.coinbaazar.com/static/media/meta2.3ee45f24.png",
        },
        {
          image: require("../assets/newImages/meta3.png"),
          link: "https://uat.coinbaazar.com/static/media/meta3.90f78c06.png",
        },
        {
          image: require("../assets/newImages/meta4.png"),
          link: "https://uat.coinbaazar.com/static/media/meta4.3266fafb.png",
        },
        {
          image: require("../assets/newImages/meta5.png"),
          link: "https://uat.coinbaazar.com/static/media/meta5.5b369587.png",
        },
        {
          image: require("../assets/newImages/meta6.png"),
          link: "https://uat.coinbaazar.com/static/media/meta6.62ee58d5.png",
        },
      ],
    };
    //this.setValue = this.setValue.bind(this)
  }
  componentDidMount = async () => {
    currencyJson = await getCurrency();
    var id = await localStorage.getItem("data");
    var token = await localStorage.getItem("token");
    var vacation = this.state.vacationImage;

    await this.setState({
      _id: id,
      token: token,
      sliderArr: vacation,
      gifttab: "vacation",
    });
    this.getBalance(id);

    Api(
      "gift/createGiftcard_list",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp !== "error") {
        this.setState({
          giftCardData: "Send your first bitcoin Gift Card",
        });
        if (resp.data.responseCode == 200) {
          this.setState({
            giftCardHistory: resp.data.succ,
          });
        } else {
          this.setState({
            giftCardData: "No Data found",
          });
        }
      } else {
        this.setState({
          giftCardData: "No Data found",
        });
      }
    });
  };
  selecttab(selectTab) {
    console.log("=====parameter========>", selectTab);
    // this.setState({ tab: selectTab });
  }
  selectgifttab(selectTab) {
    console.log("======<>>", selectTab);
    var vacation = this.state.vacationImage;
    var festival = this.state.festivalImage;
    var bitcoin = this.state.bitcoinImage;
    var zodiac = this.state.zodiacImage;
    var entertainment = this.state.entertainmentImage;
    var shopping = this.state.shoppingImage;
    var music = this.state.musicImage;
    var threeD = this.state.threeDImage;
    var metaverse = this.state.metaverseImage;

    if (selectTab == "vacation") {
      this.setState({ sliderArr: vacation });
    }

    if (selectTab == "festival") {
      this.setState({ sliderArr: festival });
    }
    if (selectTab == "bitcoin") {
      this.setState({ sliderArr: bitcoin });
    }
    if (selectTab == "zodiac") {
      this.setState({ sliderArr: zodiac });
    }
    if (selectTab == "entertainment") {
      this.setState({ sliderArr: entertainment });
    }
    if (selectTab == "shopping") {
      this.setState({ sliderArr: shopping });
    }
    if (selectTab == "music") {
      this.setState({ sliderArr: music });
    }
    if (selectTab == "3D") {
      this.setState({ sliderArr: threeD });
    }
    if (selectTab == "metaverse") {
      this.setState({ sliderArr: metaverse });
    }

    this.setState({ gifttab: selectTab });
  }

  getBalance = (_id) => {
    var parameter = { _id: _id };
    // this.forceUpdate()
    console.log("getting balance into header=====>");

    Api("user/userProfile", "POST", parameter, this.state.token, _id).then(
      (resp) => {
        try {
          if (resp.status == 200) {
            // console.log("==>",resp)
            if (resp.data.responseCode == 200) {
              // console.log('balance==>>',resp.data.result)
              var num = Number(resp.data.result.btc.total);
              var n = num.toFixed(8);

              console.log("getting balance by api=====>", n);
              // this.setState({ totalBalance: n, name: 'pk', profilePic: resp.data.result.profilePic }, () => this.saveDetails())

              this.setState({ totalBalance: n });
            }
            //else toast_func("error", resp.data.responseMessage)
          }
          //else toast_func("error", "some problem occured please contact administrator")
        } catch (ex) {
          //toast_func("error", "some problem occured please contact administrator")
        }
      }
    );
  };

  handleAmountChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    if (name == "Amount") {
      if (!isNaN(value) && value !== " ") {
        this.setState({ [name]: value });
      }
    }
  };

  validation = (e) => {
    e.preventDefault();
    var value = e.target.value;
    var name = e.target.name;
    var type = e.target.type;

    this.setState({ [name]: value });

    if (name === "amount") {
      var marginRegex = /^\d+(\.\d{1,5})?$/;
      if (marginRegex.test(value)) {
        console.log("===>amount ", value);
        this.setState(
          {
            amount: value,
            amountError: "",
            amountStatus: false,
          },
          () => this.fetchBTC()
        );
      } else {
        console.log("=error==>  ");
        this.setState({
          amountStatus: true,
          amountError: "Enter valid amount",
        });
      }
    }
    if (name === "email") {
      if (validateEmail(value).status) {
        console.log("===>email ", value);
        this.setState({
          email: value,
          emailError: "",
          emailStatus: false,
        });
      } else {
        console.log("=Email-error==>  ");
        this.setState({
          emailStatus: true,
          emailError: validateEmail(this.state.email).error,
        });
      }
    }
    if (name === "recipientname") {
      if (value) {
        console.log("===> ", validateUserName(this.state.recipientname).status);
        this.setState({
          recipientname: value,
          recipientnameError: "",
          recipientnameStatus: false,
        }); // mtlb sahi
        console.log("==recipient name ==>", value);
      } else {
        console.log("===>   2 ", validateUserName(value).status); // mtlb error
        this.setState({
          recipientnameStatus: true,
          recipientnameError: validateUserName(this.state.recipientname).error,
        });
      }
    }
    if (name === "sendername") {
      if (value) {
        this.setState({
          sendername: value,
          sendernameError: "",
          sendernameStatus: false,
        });
        console.log("===sender==>", value);
      } else {
        this.setState({
          sendernameStatus: true,
          sendernameError: validateUserName(this.state.sendername).error,
        });
      }
    }

    if (name === "msg") {
      this.setState({ msg: value, msgError: "" });
      console.log("===msg====>", value);
    }
  };
  // fetch = () =>{

  //   socket.emit('currentBTCValue', {
  //       localCurrency: `${this.state.currency}`
  //   })

  //   socket.on('currentBTCValue', (resp) => {

  //       this.setState({ current: resp.result.price.toFixed(2) })

  //   })
  // }
  // setValue(value) {
  //   this.setState({ currencyModal: false })
  //   console.log('Asi==>', value)
  //   this.setState({ currency: value, amount:'' },() => this.fetchBTC())
  //   // this.setState({ amount: value, amountError: "", amountStatus: false }, () => this.fetchBTC())
  // }
  onClick_currency = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ currency: value }, () => this.fetchBTC());
  };

  createGiftCard = async (e) => {
    var id = await localStorage.getItem("data");
    e.preventDefault();
    this.setState({ EnableshowModal: false });
    var parameter = {
      giftCardAmount: Number(this.state.BTCAmount),
      email: this.state.email,
      receiverName: this.state.recipientname,
      senderName: this.state.sendername,
      message: this.state.msg,
      giftCardImg: this.state.selectedIMAGE,
    };
    // console.log("=====parameter========>", "gift/create_gift_card", "POST", parameter, this.state.token, this.state._id);
    Api(
      "gift/create_gift_card",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((Response) => {
      console.log("=============>" + JSON.stringify(Response));
      if (Response.status == 200) {
        if (Response.data.responseCode == 200) {
          this.setState({
            amount: "",
            BTCAmount: "",
            email: "",
            recipientname: "",
            sendername: "",
            msg: "",
            fundingBalance: "",
          });
          toast_func("success", "Gift card send successfully");
          console.log("=====>" + Response.data.responseMessage);
          socket.emit("getUserBalance", {
            userId: id,
          });
          socket.on("getUserBalance", (resp) => {
            var num = Number(resp.balance);
            var n = num.toFixed(8);
            this.setState({
              fundingBalance: n,
              totalBalance: n,
            });
            // alert('jgk')
            console.log("here we will get balance ===>>", resp);
          });
          this.getBalance(id);
        } else {
          toast_func("error", Response.data.responseMessage);
          console.log("111=====>>>error", JSON.stringify(Response));
        }
      } else {
        toast_func("error", Response.data.responseMessage);
        console.log("222=====>>>error", JSON.stringify(Response));
      }
    });
  };

  GiftCard = (e) => {
    e.preventDefault();
    // alert('hello')
    if (
      this.state.amountStatus == false &&
      this.state.amount != "" &&
      this.state.amountError == ""
    ) {
      if (
        this.state.emailStatus == false &&
        this.state.email != "" &&
        this.state.emailError == ""
      ) {
        if (
          this.state.msgStatus == false &&
          this.state.msg != "" &&
          this.state.msgError == ""
        ) {
          this.setState({
            EnableshowModal: true,
          });
        } else {
          this.setState({
            msgError: "Please enter message here",
          });
        }
      } else {
        this.setState({
          emailError: "Please enter Email address",
        });
      }
    } else {
      this.setState({
        amountError: "Please enter Amount",
      });
    }
  };
  // fetchAmount(amount) {
  //   console.log("okay ----->",this.state.currency)
  //   var parameter = {
  //     "localCurrency": this.state.currency,
  //     "btc": this.state.amount,
  //   }
  //   this.setState({loader1:true})
  //   Api("user/priceEquation", "POST", parameter, this.state.token, this.state._id)
  //     .then((resp) => {
  //       this.setState({loader1:false});
  //       console.log('fatchAmount==>>',resp);
  //       if (resp.status == 200) {
  //         if (resp.data.responseCode == 200) {
  //           var num = Number(resp.data.result["price"]);
  //           var n = num.toFixed(2);
  //           var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //           var n2 = n.split(".")[1]
  //           var price =n1+"."+n2
  //           //alert(JSON.stringify(resp.data.result["price"]))
  //           //this.setState({Amount})
  //           console.log("working fine======>",price)
  //           alert(n)
  //           this.setState({ calAmount: n})
  //         }
  //         else if(resp.data.responseCode == 502)
  //         {
  //             this.props.history.push("NotFound")

  //         }
  //       }
  //     })
  // }

  fetchBTC() {
    console.log("okay--------------A>", this.state.currency);
    var parameter = { localCurrency: this.state.currency, margin: "" };
    this.setState({ loader2: true });
    Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader2: false });
      console.log("fatchBTC==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          console.log("rrrrrrrr", resp.data);
          var priceNow = resp.data.result.price;
          console.log("rrrrrrr price now===>", priceNow);
          console.log("rrrrrrr amount entered===>", this.state.price);
          var btc = this.state.amount / priceNow;
          console.log("rrrrrrr price now===>", btc);
          var btcamnt = btc.toFixed(8);
          var funb = (
            Number(this.state.totalBalance) - Number(btcamnt)
          ).toFixed(8);
          if (Number(this.state.totalBalance) < Number(btcamnt)) {
            this.setState({
              amountError: "You dont have enough BTC",
              BTCAmount: btcamnt,
              fundingBalance: funb,
            });
          } else {
            this.setState({
              BTCAmount: btcamnt,
              fundingBalance: funb,
              amountError: "",
            });
          }
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
          this.setState({ loader2: false });
        }
      }
    });
  }
  // Populate_currency = () => {
  //   let currencyArray = []
  //   for (var i = 0; i < currencyJson.length; i++) {
  //     currencyArray.push(<option key={i} value={currencyJson[i].code}>{currencyJson[i].code}</option>)
  //   }
  //   return currencyArray;
  // }
  Populate_currency = () => {
    let currencyArray = [];
    if (currencyJson.fiat) {
      let currArr = Object.keys(currencyJson.fiat);
      for (var i = 0; i < currArr.length; i++) {
        currencyArray.push(
          <option key={i} value={currArr[i]}>
            {currArr[i]}
          </option>
        );
      }
    }
    // for (var i = 0; i < currencyJson.length; i++) {
    //   currencyArray.push(<option value={currencyJson[i].code} key={i} >{currencyJson[i].code}</option>)
    // }
    return currencyArray;
  };
  setImageArr = async (item, index) => {
    // alert(JSON.stringify(item))
    // console.log("+==>", item , index)
    this.setState({
      selectedIndex: index,
      selectedIMAGE: item.link,
    });
  };
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    const setting = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 1000,
      slidesToShow: 1, // slidesToScroll: 1,
      accessibility: true,
    };
    return (
      <div>
        <Headers {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle3">
            <div className="container1440">
              <div className="buySellBox2 pt0">
                <div className="sectionHead">
                  <h2 className="textInitial header2 mb30">
                    Crypto Gift Card for your Loved One's
                  </h2>
                  <div className="tabList2 mb30">
                    <div
                      className={`${
                        this.state.tab === "create" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={() => {
                        this.selecttab("create");
                        this.props.history.push(`/create-gift-card`);
                      }}
                    >
                      <a>Create</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "check" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={() => {
                        this.selecttab("check");
                        this.props.history.push(`/check-balance`);
                      }}
                    >
                      <a>Check balance</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "redeem" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={() => {
                        this.selecttab("redeem");
                        this.props.history.push(`/redeem-card`);
                      }}
                    >
                      <a>Redeem</a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="giftT">
                      <li>Select the e-Gift card image.</li>
                      {/* <li>Choose how many gift cards you want to send in the quantity section.</li> */}
                      <li>
                        Select your preferred currency and enter the gift card
                        amount.
                      </li>

                      <li>
                        Enter the recipient's details, which include the name
                        and email address.
                      </li>
                      <li>
                        Write a personal message to send along with your gift
                        card .
                      </li>
                    </ul>
                    {/* <div className="prodImg">
                    <Slider {...setting} slickGoTo={this.state.selectedIndex} dontAnimate={false} buttons={true}>
                      {this.state.sliderArr.map((item, index) =>
                        <div key={index} onClick={() => this.setImageArr(item,index)} className="slideimgbox boxsize2" >
                          <div className="slideimg">
                            <img
                              src={item.image}
                              alt="Image"
                            />
                          </div>
                          <div className="cardtext">
                          </div>
                        </div>
                      )}
                    </Slider>
                  </div> */}

                    {this.state.selectedIMAGE ? (
                      <div className="prodImg">
                        <img
                          className="bucket_img"
                          src={this.state.selectedIMAGE}
                        />
                      </div>
                    ) : null}

                    <div className="sectionHead">
                      <div className="tabList2 mb30 giftTabs">
                        <div
                          className={`${
                            this.state.gifttab === "vacation"
                              ? "taboxActive2 giftTabBox "
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => this.selectgifttab("vacation")}
                        >
                          <a>Vacation</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "festival"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("festival");
                          }}
                        >
                          <a>Festival</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "bitcoin"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("bitcoin");
                          }}
                        >
                          <a>Bitcoin</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "3D"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("3D");
                          }}
                        >
                          <a>3D</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "shopping"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("shopping");
                          }}
                        >
                          <a>Shopping</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "entertainment"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("entertainment");
                          }}
                        >
                          <a>Entertainment</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "music"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("music");
                          }}
                        >
                          <a>Music</a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "metaverse"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("metaverse");
                          }}
                        >
                          <a>Metaverse </a>
                        </div>
                        <div
                          className={`${
                            this.state.gifttab === "zodiac"
                              ? "taboxActive2 giftTabBox"
                              : "tabox2 giftTabBox"
                          }`}
                          onClick={() => {
                            this.selectgifttab("zodiac");
                          }}
                        >
                          <a>Zodiac </a>
                        </div>
                      </div>
                    </div>

                    {/* <div className="cardBucket"> */}
                    {this.state.gifttab == "vacation" ? (
                      <div className="cardBucket">
                        {this.state.vacationImage.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "festival" ? (
                      <div className="cardBucket">
                        {this.state.festivalImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "bitcoin" ? (
                      <div className="cardBucket">
                        {this.state.bitcoinImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "zodiac" ? (
                      <div className="cardBucket">
                        {this.state.zodiacImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "entertainment" ? (
                      <div className="cardBucket">
                        {this.state.entertainmentImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "shopping" ? (
                      <div className="cardBucket">
                        {this.state.shoppingImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "music" ? (
                      <div className="cardBucket">
                        {this.state.musicImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "3D" ? (
                      <div className="cardBucket">
                        {this.state.threeDImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.gifttab == "metaverse" ? (
                      <div className="cardBucket">
                        {this.state.metaverseImage.map((item, index) => (
                          <div
                            onClick={() => this.setImageArr(item, index)}
                            className="bucket"
                          >
                            <img className="bucket_img" src={item.image} />
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {/* <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage3.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage3.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage3.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage3.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage3.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div>
                    <div className="bucket"><img className="bucket_img" src={require("../assets/images/AppImage2.jpeg")}></img></div> */}
                    {/* </div> */}
                    <div className="cardStr">
                      <img src={require("../assets/images/AppImage4.jpeg")} />
                      <span>Card Store</span>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <form>
                      <div className="formBox myCard">
                        <label>Enter Amount and Select Currency </label>
                        <div className="form-group">
                          <div className="input-group" style={{}}>
                            <input
                              type="phone"
                              style={{ width: "50%" }}
                              className="form-control radius-TR0 radius-BR0 tablefilterInpRad"
                              placeholder="Enter Amount"
                              maxLength={10}
                              inputMode="numeric"
                              name="amount"
                              value={this.state.amount}
                              onChange={(e) => this.validation(e)}
                            />
                            <div
                              className="input-group-append"
                              style={{ width: "50%" }}
                            >
                              {/* <input type="text" name="currency"
                            placeholder="Select Currency"
                            readOnly value={this.state.currency}
                            className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0"
                            onClick={() => this.setState({ currencyModal: true })} /> */}
                              <select
                                className="form-control select-style1 radius-TL0 radius-BL0"
                                name="currency"
                                onChange={(e) => this.onClick_currency(e)}
                              >
                                <option>{this.state.currency}</option>
                                {this.Populate_currency()}
                              </select>
                            </div>
                          </div>
                        </div>
                        {this.state.amountError ? (
                          <label className="error">
                            {this.state.amountError}
                          </label>
                        ) : null}
                        {/* <label className="">Enter Quantity</label>
                    <div className="form-group">
                    <input type="text" name="quantity" autoComplete="off" className="form-control radius10" placeholder="No. of Gift Cards" />
                    </div> */}
                        {/* modal is not use here */}
                        <div className="">
                          {/* <input type="text" className="form-control select-style1" placeholder="Select Currency" name="currency1" value={this.state.currency} onClick={() =>  this.setState({ currencyModal: true })}></input> */}
                          <Modal
                            isOpen={this.state.currencyModal}
                            fade={false}
                            style={{
                              alignSelf: "center",
                              justifyContent: "center",
                              margintop: "10%",
                              maxWidth: "950px",
                              margintop: "60px",
                            }}
                          >
                            <div className="cardBox max-WT-950 center-box">
                              <div className="cardHead center-box pb10 text-center posRel">
                                <span
                                  className="crossBtn"
                                  onClick={() =>
                                    this.setState({
                                      currencyModal: false,
                                    })
                                  }
                                >
                                  <i className="fa fa-times" />
                                </span>

                                <h4 className="font18 mt0 mb5 text-center">
                                  Currency
                                </h4>
                              </div>
                              <CurrencyModal
                                currencyJson={currencyJson}
                                setValue={this.setValue}
                              />
                            </div>
                          </Modal>
                        </div>
                        <label>Amount in Bitcoins</label>
                        <div className="form-group">
                          <input
                            className="form-control radius10"
                            type="text"
                            readOnly
                            value={this.state.BTCAmount}
                            autoComplete="off"
                            placeholder=""
                          />
                          {/* <div className="amntCard">
                        <div>25%</div>
                        <div>50%</div>
                        <div>75%</div>
                        <div>100%</div>
                    </div> */}
                          {/* <div className="balnce"> */}
                          <div className="cardBal mt15 txtLeft">
                            Remaining Wallet Balance:{" "}
                            {this.state.fundingBalance}{" "}
                            <i className="fa fa-refresh" />
                          </div>
                          {/* <div className="cardBal mt15">Transfer <i className="fa fa-exchange"></i></div> */}
                          {/* </div> */}
                        </div>

                        <label className="">Email your Gift Card</label>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={(e) => this.validation(e)}
                            autoComplete="off"
                            className="form-control radius10"
                            placeholder="Enter Recipient Email"
                          />
                        </div>
                        {this.state.emailError ? (
                          <label className="error">
                            {this.state.emailError}
                          </label>
                        ) : null}
                        <label className="">Recipient Name</label>
                        <div className="form-group">
                          <input
                            type="text"
                            name="recipientname"
                            onChange={(e) => this.validation(e)}
                            value={this.state.recipientname}
                            autoComplete="off"
                            className="form-control radius10"
                            placeholder="Enter Recipient Name (Optional)"
                          />
                        </div>
                        <label className="">Sender Name</label>
                        <div className="form-group">
                          <input
                            type="text"
                            name="sendername"
                            onChange={(e) => this.validation(e)}
                            value={this.state.sendername}
                            autoComplete="off"
                            className="form-control radius10"
                            placeholder="Enter Sender Name (Optional)"
                          />
                        </div>
                        <label className="">
                          Include a heartfelt note with your gift card
                        </label>
                        <div className="form-group mb40">
                          <textarea
                            type="text"
                            name="msg"
                            rows={8}
                            maxLength={200}
                            value={this.state.msg}
                            onChange={(e) => this.validation(e)}
                            autoComplete="off"
                            className="form-control radius10"
                            placeholder="Write message here..."
                          />
                        </div>
                        {this.state.msgError ? (
                          <label className="error">{this.state.msgError}</label>
                        ) : null}
                        <div className="text-center mb30">
                          <button
                            type="submit"
                            onClick={(e) => this.GiftCard(e)}
                            className="btn btnGreen pt10 pb10 radius10"
                          >
                            Create Gift Card
                          </button>
                        </div>
                        <hr />
                        {/* <div className="cardBal txtRyt mb20">Create Gift Card <i className="fa fa-exchange"></i></div> */}
                        {/* <div className="alrtMsg">After confirmation, -- coin will be deducted from your funding wallet.</div> */}

                        <div className="totAmntBox">
                          <div className="tot_amnt">
                            Amount in BTC: {this.state.BTCAmount}
                          </div>
                          {!this.state._id ? (
                            <button
                              type="submit"
                              className="btn btnGreen pt10 pb10 radius10"
                              style={{ float: "right" }}
                            >
                              Login to create
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </form>
                    {/* <p className="formLinksInfo mt15">Don’t have an account?  <Link to={"/signup/"}>Sign Up</Link></p> */}
                  </div>
                </div>
                <div className="container-fluid customTable">
                  <table className="table table-bordered">
                    <thead class="table-light nobillioTableHead">
                      <tr>
                        <th>Sender Name</th>
                        <th>Recipient Name </th>
                        <th>Recipient Email</th>
                        <th>Created At</th>
                        <th>Amount</th>
                        <th>Redeem At</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody className="table-group-divider  nobillioTableBody">
                      {this.state.giftCardHistory.length ? (
                        this.state.giftCardHistory.map((item) => {
                          return (
                            <tr>
                              <td>{item.senderName}</td>
                              <td>{item.receiverName}</td>
                              <td>{item.receivingMail}</td>
                              <td>
                                {new Date(item.createdAt)
                                  .toLocaleString()
                                  .split(",")
                                  .map((item) => (
                                    <p style={{ margin: 0 }}>{item}</p>
                                  ))}
                              </td>
                              <td>{item.btcAmount}</td>
                              <td>
                                {item.redeemedAt &&
                                  new Date(item.createdAt)
                                    .toLocaleString()
                                    .split(",")
                                    .map((item) => (
                                      <p style={{ margin: 0 }}>{item}</p>
                                    ))}
                              </td>
                              <td
                                style={
                                  item.cardStatus == "redeemed"
                                    ? {
                                        textTransform: "capitalize",
                                        color: "green",
                                        fontWeight: 600,
                                      }
                                    : {
                                        textTransform: "capitalize",
                                        color: "#e45f00",
                                        fontWeight: 600,
                                      }
                                }
                              >
                                {item.cardStatus}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" align="center">
                            <label className="font20 rColor text-center">
                              {this.state.giftCardData}
                            </label>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="row mt40">
                  <div className="col-lg-12">
                    <hr />
                    <label
                      style={{
                        color: "#3e5c85",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Your use of this gift card constitutes acceptance of the
                      following terms and conditions:
                    </label>
                    <ul className="giftIns">
                      <li>
                        Please treat this card as cash; it will not be replaced
                        if it is lost or stolen.
                      </li>
                      <li>
                        This gift card can be redeemed only at coinbaazar. This
                        card cannot be exchanged for cash, check or credit.
                      </li>
                      <li>
                        This card is valid for one (1) year from the date of
                        issue. For gift card balance, terms, and expiration
                        date, present this card at coinbaazar. To redeem a Gift
                        card, you must first create an account.
                      </li>
                      <li>
                        Make sure you enter the correct email address in the
                        recipient's email address. Coinbaazar won't be
                        responsible for any loss of funds because of a
                        typographical error.
                      </li>
                      <li>
                        Bitcoins are transferred instantly on redemption of Gift
                        Card, but in the event of maintenance or other
                        unforeseen issues, it may take a while for the funds to
                        reflect in your account.
                      </li>
                      <li>
                        Your use of the Coinbaazar brand should not imply
                        partnership, endorsement or sponsorship and you agree
                        that you will not misrepresent or embellish the
                        relationship between Coinbaazar and your business.
                      </li>
                      <li>
                        All Gift Cards must comply with the General Conditions.
                        We reserve the right at our sole discretion, to void or
                        reject for redemption of any Gift Card or Claim Code
                        that is suspected to have been obtained or created
                        through fraud, deception, data breach, account
                        compromise, or any other improper or illegal conduct or
                        security incident.
                      </li>
                      <li>
                        There are numerous Gift Card payment scams. coinbaazar
                        is not responsible for and assumes no liability for any
                        illegal activity or fraud committed by a third party in
                        connection with a Gift Card.
                      </li>
                      <li>
                        When you buy, receive, or add a Gift Card to your
                        account, you agree that these terms and conditions, as
                        well as any disputes that may come up between you and
                        Coinbaazar LLC and its affiliates about your use of a
                        Gift Card or your Coinbaazar Bitcoin Balance, will be
                        governed by the laws of the country where you live.
                      </li>
                      <li>
                        Any dispute or claim regarding Gift Cards or your
                        Coinbaazar crypto balance will be resolved by the
                        dispute resolution team, which can be reached via our
                        live chat support or by submitting a ticket via the
                        "contact us" option.
                      </li>
                      <li>
                        The gift card is issued by Coinbaazar LLC., a
                        Georgia-based limited liability company. No service Fee
                        is charged for creating, checking, or redeeming a gift
                        card.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <Modal isOpen={this.state.EnableshowModal}>
                <ModalBody>
                  {/* <img src={require("../assets/images/delete.png") ,  link :''} style={{height:"75px",width:"75px"}} className="logOut-img" alt=""/> */}
                  <h5 className="mb30 mt30 font17">
                    Are you sure you want to send the gift card to "
                    {this.state.email}" ?
                  </h5>
                  <button
                    onClick={(e) => this.createGiftCard(e)}
                    className="btn btnGreen max-WT-120 font16"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btnRed max-WT-120 ml10 font16"
                    data-dismiss="modal"
                    onClick={() => this.setState({ EnableshowModal: false })}
                  >
                    No
                  </button>
                </ModalBody>
              </Modal>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
