import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Api } from "../services/webservices";
import { ClipLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { base32 } from "../services/Decrypt";
let getUTCZone = "";
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      token: "",
      result: "",
      emailVerified: false,
      phoneVerified: false,
      email: "",
      phone_number: "",
      country_code: "",
      realName: "",
      user_doc_id: "",
      feedbacklist: [],
      po: 0,
      ne: 0,
      userInfo: {},
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    getUTCZone = localStorage.getItem("utcTimeZone");

    this.setState({ _id: id, token: token }, () => this._loadData());
  }
  _loadData() {
    var parameter = { _id: this.state._id };
    this.setState({ loading: true });
    try {
      Api(
        "user/userProfile",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        if (resp.status == 200) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            var accCreated = new Date(resp.data.result.created_at);
            var accCreatedTimeStamp = accCreated.getTime();
            var getTS = accCreatedTimeStamp;
            var updatedCreatedAt = new Date(getTS);
            var accCreatedDate =
              updatedCreatedAt.getFullYear() +
              "-" +
              parseInt(updatedCreatedAt.getMonth() + 1) +
              "-" +
              updatedCreatedAt.getDate();
            var accCreatedTime =
              updatedCreatedAt.getHours() +
              ":" +
              updatedCreatedAt.getMinutes() +
              ":" +
              updatedCreatedAt.getSeconds();
            resp.data.result.accCreatedAt =
              accCreatedDate + " " + accCreatedTime;

            var lastSeen = new Date(resp.data.result.updatedAt);
            var lastSeenTimeStamp = lastSeen.getTime();
            var getlastSeenTS = lastSeenTimeStamp;
            var lastSeenAt = new Date(getlastSeenTS);
            var lastSeenDate =
              lastSeenAt.getFullYear() +
              "-" +
              parseInt(lastSeenAt.getMonth() + 1) +
              "-" +
              lastSeenAt.getDate();
            var lastSeenTime =
              lastSeenAt.getHours() +
              ":" +
              lastSeenAt.getMinutes() +
              ":" +
              lastSeenAt.getSeconds();
            resp.data.result.lastSeen = lastSeenDate + " " + lastSeenTime;

            if (resp.data.result.kyc_docs.length > 0) {
              var step = resp.data.result.kyc_docs.length;
              this.setState({
                user_doc_id: resp.data.result.kyc_docs[step - 1].user_doc_id,
              });
            }

            this.setState({ _IDStatus: resp.data.result["kyc_status"] });
            this.setState(
              {
                profilePic: resp.data.result.profilePic,
                realName: resp.data.result.name,
                country_code: resp.data.result.country_code,
                phone_number: resp.data.result.phone_number,
                email: resp.data.result.email,
                result: resp.data.result,
                emailVerified: resp.data.result.verified_email,
                phoneVerified: resp.data.result.verified_phone,
                userInfo: resp.data.result,
              },
              () => {}
            );
          }
        }
      });
    } catch (ex) {
      // toast_func("error", "Unable to reach server please contact administrator")
    }

    this.loadFeedback();
    this.allData();
  }
  allData = () => {
    var parameter = {
      userId: this.state._id,
    };
    Api(
      `feedback/feedbackScore`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          // this.setState({feedbackScore:resp.data.result})
          if (resp.data.responseMessage == "No feedback found")
            this.setState({ feedbackScore: resp.data.result, po: 0, ne: 0 });
          else
            this.setState({
              feedbackScore: resp.data.result,
              po: resp.data.feedbackScore.positive,
              ne: resp.data.feedbackScore.negative,
            });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });

    Api(
      `trade/completedTradeOfUser`,
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          this.setState({ completedTradeList: resp.data.result1 });
          if (!resp.data.firstCompleteTrade) {
            this.setState({ firstPurchase: resp.data.updatedAt });
          }
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  };
  loadFeedback = () => {
    var parameter = {
      feedbackTo: this.state._id,
      limit: 10,
      pageNumber: 1,
    };
    Api(
      "feedback/getFeedbackList",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      console.log("rrrrrrrrrrr======> feedback response", resp);
      let AllList = [];
      if (resp.status == 200) {
        if (resp.responseCode == 200) {
          setTimeout(() => {
            for (let i = 0; i < resp.data.Data.docs.length; i++) {
              let AllList = [];
              AllList.push(
                <div className="feedbackBox">
                  <div className="feedUserLeft">
                    <span className="feedUserImg">
                      <img
                        src={
                          resp.data.Data.docs[i]["feedbackFrom"]["profilePic"]
                        }
                        alt="image"
                      />
                    </span>
                  </div>
                  <div className="feedUserRight">
                    <h5>
                      <span className="feedUserName">
                        {resp.data.Data.docs[i]["feedbackFrom"]["user_name"]}
                      </span>
                      <span className="feedTime">
                        {
                          resp.data.Data.docs[i]["createdAt"]
                            .replace("T", " ")
                            .replace("Z", " ")
                            .split(".")[0]
                        }
                      </span>
                    </h5>
                    <p>
                      <i
                        className={`far fa-thumbs-${
                          resp.data.Data.docs[i]["feedbackType"] == "negative"
                            ? "down rColor"
                            : "up gColor"
                        }`}
                      />
                      {resp.data.Data.docs[i]["feedbackMessage"]
                        ? resp.data.Data.docs[i]["feedbackMessage"]
                        : ""}
                    </p>
                  </div>
                </div>
              );
            }
          }, 300);
        }
        this.setState({ feedbacklist: AllList });
      }
    });
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <div className="row colCenter">
                <div className="col-lg-5 marBtm">
                  {/* <main className="mainContent">
        
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <div className="row">
                <div className="col-lg-6"> */}
                  {/* <!-- cardBox Start --> */}
                  <div className="cardBox">
                    <div className="cardHead text-center">
                      <h4 className="textInitial">User details</h4>
                    </div>
                    <div className="p30">
                      <div className="profileImgBox d-flex align-items-center mb15">
                        <div className="profileImg minWidth100 pr15">
                          {this.state.profilePic ? (
                            <img src={`${this.state.profilePic}`} />
                          ) : null}
                        </div>
                        <div className="ml-auto w-100">
                          <h5 style={{ color: "var(--text-grey)" }}>
                            {this.state.result.user_name}
                          </h5>
                          <span>{this.state.result.introduction}</span>
                        </div>
                      </div>
                      {/* <div className="profileViewField">
                      <label className="viewFieldLeft">Trade volume</label>
                      <div className="viewFieldRight">500+ BTC</div>
                    </div> */}
                      <div className="profileViewField lineHeight1">
                        <label className="viewFieldLeft dBlueColor">
                          Number of confirmed trade
                        </label>
                        <div className="viewFieldRight">
                          <span>{this.state.completedTradeList}</span>
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Feedback score{" "}
                        </label>
                        <div className="viewFieldRight">
                          {/* <a className="ml5" href={`/feedback/${this.state._userID}`} >  {"( +"}{this.state.po}{" / "}{" -"}{this.state.ne}{" ) "} See feedback</a> */}
                          {this.state._id ? (
                            <a
                              className="ml5"
                              href={`/feedback/${base32.encode(
                                this.state._id
                              )}`}
                            >
                              {" "}
                              {"( +"}
                              {this.state.po}
                              {" / "}
                              {" -"}
                              {this.state.ne}
                              {" ) "} See feedback
                            </a>
                          ) : null}
                          {/* {this.state.feedbackScore ?
                      
                          <span >{this.state.feedbackScore}</span>
                          :null} */}
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Real name
                        </label>
                        <div className="viewFieldRight">
                          {this.state.realName}
                        </div>
                      </div>

                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          First purchase
                        </label>
                        {this.state.firstPurchase ? (
                          <div className="viewFieldRight">
                            {this.state.firstPurchase}
                          </div>
                        ) : null}
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Account created
                        </label>
                        <div className="viewFieldRight">
                          {this.state.result.accCreatedAt}
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Last seen
                        </label>
                        <div className="viewFieldRight">
                          {this.state.result.lastSeen}
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Language{" "}
                        </label>
                        <div className="viewFieldRight">English</div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Email
                        </label>
                        <div className="viewFieldRight">
                          <div className="checkbox-design-block posRel">
                            <label className="checkbox-design mb0 ml15">
                              <input
                                type="checkbox"
                                name="emailVerified"
                                checked={this.state.emailVerified}
                                disabled={true}
                              />
                              <span />
                            </label>
                            <span>{this.state.email}</span>
                          </div>
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          Phone number
                        </label>
                        <div className="viewFieldRight">
                          <div className="checkbox-design-block posRel">
                            <label className="checkbox-design mb0 ml15">
                              <input
                                type="checkbox"
                                name="phoneVerified"
                                checked={this.state.phoneVerified}
                                disabled={true}
                              />
                              <span />
                            </label>
                            <span>
                              {this.state.country_code}-
                              {this.state.phone_number}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="profileViewField">
                        <label className="viewFieldLeft dBlueColor">
                          KYC status
                        </label>
                        <div className="viewFieldRight">
                          {/* <div className="checkbox-design-block">
                          <label className="checkbox-design mb0"> */}
                          {/* <input type="checkbox" name="" disabled/> */}
                          {this.state._IDStatus ? this.state._IDStatus : null}
                          {/* </label>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- cardBox End --> */}
                </div>
                <div className="col-lg-5">
                  <div className="cardBox minHeight250 mb50">
                    <div className="cardHead text-center">
                      <h4 className="textInitial">
                        <i className="fas fa-star mr5" />
                        Already Trusting you
                      </h4>
                    </div>
                    <div className="p30">
                      <p style={{ color: "var(--text-grey)" }}>It's you</p>
                    </div>
                  </div>

                  <div className="minHeight250 cardBox">
                    <div className="cardHead text-center">
                      <h4 className="textInitial">External Stats</h4>
                    </div>
                    <div
                      className="p20"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        color: "var(--text-grey)",
                      }}
                    >
                      <div>
                        <h3>{this.state.userInfo["importedFeedback"]}+</h3>
                        <p>Imported Feedback</p>
                      </div>
                      <div>
                        <h3>{this.state.userInfo["importedTrades"]}+</h3>
                        <p>Imported Trades</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="cardBox p30 minHeight200 mb15">
                    <h2 className="font22 textInitial mb15">Seller escrow release times</h2>
                    <div className="profileViewField">
                      <label className="viewFieldLeft widthAuto minWidth80">Median:</label>
                      <div className="viewFieldRight widthAuto">1 min.</div>
                    </div>
                    <div className="profileViewField">
                      <label className="viewFieldLeft widthAuto minWidth80">Average:</label>
                      <div className="viewFieldRight widthAuto">15 min.</div>
                    </div>
                  </div> */}
                  {/* <!-- CardBox End --> */}
                  {this.state.AllList ? (
                    <div className="cardBox minHeight250">
                      <div className="cardHead text-center">
                        <h4 className="textInitial">Feedback</h4>
                      </div>
                      <div className="p30">
                        <p className="font13">Feedback left by users</p>
                      </div>
                      {this.state.AllList ? this.state.AllList : null}
                      {/* <div className="feedbackBox">
                      <div className="feedUserLeft">
                        <span className="feedUserImg"><img src={require("../assets/images/user-icon.png")} alt="image" /></span>
                      </div>
                      <div className="feedUserRight">
                        <h5><span className="feedUserName">Hennah baker</span><span className="feedTime">May 26, 2018. 1:08 p.m</span></h5>
                        <p><i className="far fa-thumbs-up gColor"></i>Nice trade, fast response.</p>
                      </div>
                    </div>

                    <div className="feedbackBox">
                      <div className="feedUserLeft">
                        <span className="feedUserImg"><img src={require("../assets/images/user-icon.png")} alt="image" /></span>
                      </div>
                      <div className="feedUserRight">
                        <h5><span className="feedUserName">Hennah baker</span><span className="feedTime">May 26, 2018. 1:08 p.m</span></h5>
                        <p><i className="far fa-thumbs-down rColor"></i>Nice trade, fast response.</p>
                      </div>
                    </div> */}
                    </div>
                  ) : null}
                  {/* <!-- CardBox End --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
               <div className="container-fluid">
                  <div className="createAccountBox">
                     <span className="wColor">Getting started is easy. Create your account now.</span>
                     <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                  </div>
               </div>
            </section> */}
          {/* <!-- createAccountSection Start -->	 */}
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
