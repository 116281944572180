import React, { Component } from "react";
import Headers from "../components/header";
import Footer from "../components/footer";
import { Api, socket, getCurrency } from "../services/webservices";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";

export default class RedeemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "redeem",
      cardNumber: "",
      btcAmount: "",
      cardErr: "",
      price: 0.0,
      tab: "redeem",
    };
  }

  componentDidMount = async () => {
    var id = await localStorage.getItem("data");
    var token = await localStorage.getItem("token");
    await this.setState({
      _id: id,
      token: token,
    });
  };

  getBalance = (_id) => {
    var parameter = { _id: _id };

    Api(
      "user/check_balance",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          // console.log("==>",resp)
          if (resp.data.responseCode == 200) {
            // console.log('balance==>>',resp.data.result)
            var num = Number(resp.data.result.btc.total);
            var n = num.toFixed(8);

            console.log("getting balance by api=====>", n);
            // this.setState({ totalBalance: n, name: 'pk', profilePic: resp.data.result.profilePic }, () => this.saveDetails())

            this.setState({ totalBalance: n });
          }
          //else toast_func("error", resp.data.responseMessage)
        }
        //else toast_func("error", "some problem occured please contact administrator")
      } catch (ex) {
        //toast_func("error", "some problem occured please contact administrator")
      }
    });
  };

  GiftCard = (e) => {
    e.preventDefault();
    // alert('hello')
    if (this.state.cardNumber != "") {
      var parameter = { giftCardCode: this.state.cardNumber };

      console.log(
        "=====parameter========>",
        "gift/create_gift_card",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      );
      Api(
        "gift/create_gift_card",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((Response) => {
        console.log("=============>" + JSON.stringify(Response));
        if (Response.status == 200) {
          if (Response.data.responseCode == 200) {
            toast_func("success", Response.data.responseMessage);
            console.log("=====>" + Response.data.responseMessage);
          } else {
            toast_func("error", Response.data.message);
            console.log("=====>>>error", Response.data.message);
          }
        }
      });
    } else {
      toast_func("error", "Please card number");
    }
  };

  selecttab(e, selectTab) {
    // e.preventDefault();
    console.log("=====parameter========>", selectTab);
    // this.setState({ tab: selectTab });
  }

  cardNum = (e) => {
    e.preventDefault();

    var value = e.target.value.toUpperCase();
    var name = e.target.name;
    var type = e.target.type;

    this.setState({ [name]: value, cardErr: "" });

    if (value.length == 12) {
      this.checkbalance(value);
    }
  };

  checkbalance = (val) => {
    // e.preventDefault()
    var parameter = { giftCardCode: val.toUpperCase() };
    // console.log("=====parameter========>", "gift", "POST", parameter, this.state.token, this.state._id);
    Api(
      "gift/check_balance",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((Response) => {
      // console.log("=============>" + JSON.stringify(Response));
      if (Response.status == 200) {
        if (Response.data.responseCode == 200 && Response.data.succ) {
          var amount = Response.data.succ.btcAmount;
          this.setState(
            {
              btcAmount: amount,
            },
            () => this.fetchAmount()
          );
          // toast_func("success", Response.data.responseMessage);
          console.log("=====>" + JSON.stringify(Response));
        } else {
          // toast_func("error", Response.data.message);
          console.log("=====>>>error", Response.data.message);
          this.setState({
            cardErr: "Please enter correct gift card code.",
            btcAmount: 0,
            price: 0,
          });
        }
      }
    });
  };

  fetchAmount() {
    console.log("okay ----->", this.state.currency);
    var parameter = { localCurrency: "USD", btc: this.state.btcAmount };
    this.setState({ loader1: true });
    Api(
      "user/priceEquation",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });
      console.log("fatchAmount==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var num = Number(resp.data.result["price"]);
          var n = num.toFixed(2);
          // var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // var n2 = n.split(".")[1]
          // var price =n1+"."+n2
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})
          // console.log("working fine--->",price)
          this.setState({ price: n });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  }

  RedeemAmount = () => {
    if (this.state.cardNumber && this.state.cardNumber.length == 12) {
      var parameter = { giftCardCode: this.state.cardNumber.toUpperCase() };

      console.log(
        "=====parameter========>",
        "gift",
        "POST",
        parameter,
        this.state._id
      );
      Api(
        "gift/redeem_gift_card",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((Response) => {
        console.log(
          "=============>",
          this.state._id + " <=>  " + JSON.stringify(Response)
        );
        if (Response.status == 200) {
          if (Response.data.responseCode == 200) {
            this.setState({
              cardNumber: "",
              btcAmount: 0.0,
              price: 0.0,
            });

            socket.on("getUserBalance", (resp) => {
              console.log("=====parameter========>", resp);
              var num = Number(resp.balance);
              var n = num.toFixed(8);
              this.setState({
                totalBalance: n,
              });
            });
            toast_func("success", Response.data.responseMessage);
            console.log("=====>" + Response.data.responseMessage);
          } else {
            toast_func("error", Response.data.message);
            console.log("=====>>>error", JSON.stringify(Response));
          }
        }
      });
    } else {
      this.setState({
        cardErr: "Please enter the correct gift card code",
      });
    }
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };
  render() {
    return (
      <div>
        <Headers {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle3">
            <div className="container1440">
              <div className="buySellBox2 pt0">
                <div className="sectionHead">
                  <h2 className="textInitial header2 mb30">
                    Crypto Gift Card for your Loved One's
                  </h2>
                  <div className="tabList2 mb30">
                    <div
                      className={`${
                        this.state.tab === "create" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "create");
                        this.props.history.push(`/create-gift-card`);
                      }}
                    >
                      <a>Create</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "check" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "check");
                        this.props.history.push(`/check-balance`);
                      }}
                    >
                      <a>Check balance</a>
                    </div>
                    <div
                      className={`${
                        this.state.tab === "redeem" ? "taboxActive2" : "tabox2"
                      }`}
                      onClick={(e) => {
                        this.selecttab(e, "redeem");
                        this.props.history.push(`/redeem-card`);
                      }}
                    >
                      <a>Redeem</a>
                    </div>
                  </div>
                </div>
                <div className="cardHeader">
                  <h1>Already have a Coinbaazar Gift Card </h1>
                  <p className="mb5 rcvCoin">Redeem your E-Gift Card here</p>
                </div>
                <div className="max-WT-500 center-box">
                  <div className="redeemImg">
                    {/* <img src={require("../assets/newImages/bitcoin-gifts.png")}></img> */}
                    {/* <img src={require("../assets/newImages/present-box-opening.gif")}></img> */}
                    <img
                      src={require("../assets/newImages/bitcoin-barcode.gif")}
                    />
                  </div>
                  <div className="">
                    <ul className="giftIns ml20">
                      <li>
                        Please enter the correct code that you have received.
                      </li>
                      <li>
                        Your wallet will be credited with Bitcoins after
                        pressing the Redeem button.
                      </li>
                      <li>
                        The Redeem Action is final as it cannot be reversed
                        back.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="cardBox p30 max-WT-500 center-box radius4 formBox">
                  <form className="mb0">
                    <div className="form-group">
                      <h4 className="font16 mt0 mb5 dBlueColor fontfamQB">
                        Enter your Code
                      </h4>
                      <div className="d-flex">
                        <div className="w-100 align-items-center load-img">
                          <input
                            type="text"
                            className="form-control giftCd"
                            placeholder="Code #"
                            value={this.state.cardNumber}
                            onChange={(e) => this.cardNum(e)}
                            maxLength={12}
                            name="cardNumber"
                          />
                        </div>
                        <button
                          type="button"
                          disabled={!this.state.cardNumber}
                          onClick={(e) => this.RedeemAmount(e)}
                          className="btn btnGreen radius4 ml10"
                        >
                          Redeem
                        </button>
                      </div>
                      {this.state.cardErr ? (
                        <label className="error">{this.state.cardErr}</label>
                      ) : null}
                      <div className="balnce">
                        <div className="cardBal mt15 txtLeft">
                          BTC Amount: {this.state.btcAmount}
                        </div>
                        <div className="cardBal mt15 txtLeft">
                          Amount: {this.state.price} USD
                        </div>
                      </div>
                      <div className="cardBal mt15 txtLeft">
                        This amount will be added to your wallet
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
