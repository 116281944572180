import React, { Component } from "react";

/* Module */
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { changeUTCTime } from "../components/convertTimeToUTC";
import Pagination from "rc-pagination";
import ImageModal from "../components/imageModal";
import CurrencyModal from "../components/currencyModal";
/* components */
import Header from "../components/header";
import Footer from "../components/footer";

/* JSON */

// import currencyJson from "../JSON/currency.json"
import _country from "../JSON/country.json";
/* services */
import { Api, getCurrency } from "../services/webservices";
import { base32 } from "../services/Decrypt";

let getUTCZone = "";
let converetdTime = [];
let currencyJson = [];

export default class Cointab1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      tab: "buy",
      currency: "",
      Amount: "",
      paymentMethod: [],
      paymentMethodS: [],
      currencyModal: false,
      country: "",
      bank: "",
      whole_data: "",
      whole_data1: [],
      whole_data2: [],
      tutorial: false,
      tutorialinc: false,
      whole_dataTotal: [],
      whole_dataTotal2: [],
      pgval: 1,
      pgval1: 1,
      totalpg: null,
      totalpg1: null,
      currPg: null,
      currPg1: null,
      noData: "Searching....",
      paymethods: false,
      method: "",
      showImage: false,
    };
    this.setValue = this.setValue.bind(this);
  }
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    currencyJson = await getCurrency();
    this.setState({ loading: true });
    // this.setState({ tab: this.props.match.params.type })
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    var tab = this.props.match.params.view_type;
    // this.setState({ tab: tab })
    setTimeout(() => {
      this.setState({
        _id: id,
        token: token,
        addr: addr,
      });
    }, 300);

    Api(
      "trade/paymentMethodList",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.data.responseCode == 200) {
          this.setState({
            paymentMethod: resp.data.result,
            paymentMethodS: resp.data.result,
          });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else {
        } //toast_func("error", "Some problem occured please contact administrator")
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.loadBuySellData();
  };

  allData = (arr) => {
    let tempArr = this.state.whole_data1;
    console.log("====================tempArr", tempArr);
    for (let i = 0; i < arr.length; i++) {
      var parameter = { userId: arr[i]["user_id"] };
      Api(
        `feedback/feedbackScore`,
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.responseMessage == "No feedback found") {
              arr[i]["nega"] = 0;
              arr[i]["posi"] = 0;
              tempArr.push(arr[i]);
              this.setState({
                whole_data1: tempArr,
              });
            } else {
              arr[i]["nega"] = resp.data.feedbackScore.negative;
              arr[i]["posi"] = resp.data.feedbackScore.positive;
              tempArr.push(arr[i]);
              this.setState({
                whole_data1: tempArr,
              });
            }
          }
        }
      });
    }
  };

  allData2 = (arr) => {
    let tempArr = this.state.whole_data2;
    for (let i = 0; i < arr.length; i++) {
      var parameter = { userId: arr[i]["user_id"] };
      Api(
        `feedback/feedbackScore`,
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.responseMessage == "No feedback found") {
              arr[i]["nega"] = 0;
              arr[i]["posi"] = 0;
              tempArr.push(arr[i]);
              this.setState({
                whole_data2: tempArr,
              });
            } else {
              arr[i]["nega"] = resp.data.feedbackScore.negative;
              arr[i]["posi"] = resp.data.feedbackScore.positive;
              tempArr.push(arr[i]);
              this.setState({
                whole_data2: tempArr,
              });
            }
          }
        }
      });
    }
  };

  stateApis = () => {
    return;

    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  loadBuySellData = (e) => {
    var parameter1 = { type: "buy" };
    var parameter2 = { type: "sell" };

    this.setState({
      loader1: true,
      loader2: true,
    });
    Api(
      "trade/filter_trade",
      "POST",
      parameter1,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({
        loader1: false,
        loader2: false,
      });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.result.docs.length != 0) {
              console.log("===========", resp.data.result);
              this.setState({
                // whole_data1: resp.data.result.docs,
                whole_dataTotal: resp.data.result.total,
                totalpg: resp.data.result.pages,
                currPg: resp.data.result.page,
              });
              this.allData(resp.data.result["docs"]);
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          // toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.setState({ loading: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter2,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.result.docs.length != 0) {
              this.setState({
                // whole_data2: resp.data.result.docs,
                whole_dataTotal2: resp.data.result.total,
                totalpg1: resp.data.result.pages,
                currPg1: resp.data.result.page,
              });
              this.allData2(resp.data.result.docs);
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          //toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  Populate_currency = () => {
    let currencyArray = [];
    for (var i = 0; i < currencyJson.length; i++) {
      currencyArray.push(<option key={i}>{currencyJson[i].code}</option>);
    }
    return currencyArray;
  };
  Populate_country = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option key={i}>{_country[i].name}</option>);
    }
    return __array;
  };
  Populate_paymentMethods = () => {
    let __array = [];
    for (var i = 0; i < this.state.paymentMethod.length; i++) {
      __array.push(<option key={i}>{this.state.paymentMethod[i].name}</option>);
    }
    return __array;
  };
  onClick_currency = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "Select currency") {
      this.setState({
        currency: "",
        currencyError: "",
      });
    } else {
      this.setState({
        currency: value,
        currencyError: "",
      });
    }
  };
  handleOnChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    if (name == "Amount") {
      if (!isNaN(value) && value !== " ") {
        this.setState({ [name]: value });
      }
    }
  };

  handalMethod(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
    if (name == "bank") {
      var array = this.state.paymentMethod;

      if (value == "") {
        this.setState({ paymentMethodS: array });
      }
      let filterVal = [];
      function search(nameKey, myArray) {
        console.log("Ritik===>", nameKey);
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].name.toLowerCase().match(nameKey.toLowerCase())) {
            // return myArray[i];
            console.log("Ritik===>", myArray[i].name);
            filterVal.push(myArray[i]);
          }
        }
      }
      this.setState({
        paymentMethodS: filterVal,
      });

      var resultObject = search(value, array);
    }
  }

  setMethod = (e, item) => {
    // console.log("Ritik===>>",item)
    e.preventDefault();
    this.setState({
      method: item.name,
      bank: item.name,
      paymentId: item._id,
      paymethods: false,
    });
  };

  onClick_country = (e) => {
    e.preventDefault();
    var value = e.target.value;

    if (value == "Select Country")
      this.setState({
        country: "",
        countryError: "",
      });
    else
      this.setState({
        country: value,
        countryError: "",
      });
  };
  onClick_paymentBank = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "All Online offers")
      this.setState({ bank: "", bankError: "" });
    else
      this.setState({
        bank: value,
        bankError: "",
      });
  };
  populateTagSell = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data2[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge" key={k}>
          {this.state.whole_data2[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };

  _loadData(value) {
    window.scrollTo(0, 0);
    var parameter = {
      type: "buy",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };
    var parameter1 = {
      type: "sell",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };

    // this.setState({ loading: true })
    Api(
      "trade/filter_trade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            console.log("ddddddddddddd", resp.data.result);
            this.setState(
              {
                whole_data1: resp.data.result["docs"],
                whole_dataTotal: resp.data.result["total"],
                totalpg: resp.data.result.pages,
                currPg: resp.data.result.page,
              },
              () => {
                this.allData(resp.data.result["docs"]);
                //this.populate_view1()
              }
            );
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  }

  _loadData2(value) {
    window.scrollTo(0, 0);
    var parameter = {
      type: "buy",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };
    var parameter1 = {
      type: "sell",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };

    // this.setState({ loading: true })
    Api(
      "trade/filter_trade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            console.log("ddddddddddddd", resp.data.result);
            this.setState(
              {
                whole_data2: resp.data.result["docs"],
                // whole_dataTotal2: resp.data.result["total"],
                totalpg1: resp.data.result.pages,
                currPg1: resp.data.result.page,
              },
              () => {
                this.allData2(resp.data.result["docs"]);
                //this.populate_view2()
              }
            );
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  }

  populateTagBuy = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data1[i]["add_tags"].length; k++) {
      a.push(
        <span key={k} className="badge">
          {this.state.whole_data1[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  // searchFilter = (e) => {
  // 	e.preventDefault()
  // 	this.setState({ noData: "Searching...." })

  // 	if (this.state.tab == 'buy') {
  // 		if (this.state.bank != '') {
  // var method = this.state.bank.replace(/ /g, '-');
  // this.props.history.push(`/buy-bitcoins/${method}`);
  // 		}
  // 		else
  // this.props.history.push(`/buy-bitcoins/`);
  // 	} else {
  // 		if (this.state.bank != '') {
  // var method = this.state.bank.replace(/ /g, '-');
  // this.props.history.push(`/sell-bitcoins/${method}`);
  // 		}
  // 		else
  // this.props.history.push(`/sell-bitcoins/`);
  // 	}

  // 	this.setState({ whole_data1: "", whole_data2: "" });
  // 	var parameter = {
  // 		"type": "buy",
  // 		"payment_method": this.state.bank == "All Online offers" ? "" : this.state.bank,
  // 		"amount": this.state.Amount,
  // 		"location": this.state.country,
  // 		"currency_type": this.state.currency

  // 	}
  // 	var parameter1 = {
  // 		"type": "sell",
  // 		"payment_method": this.state.bank == "All Online offers" ? "" : this.state.bank,
  // 		"amount": this.state.Amount,
  // 		"location": this.state.country,
  // 		"currency_type": this.state.currency

  // 	}
  // 	this.setState({ loader1: true })
  // 	Api("trade/filter_trade", "POST", parameter, this.state.token, this.state._id).then(
  // 		(resp) => {
  // this.setState({ loader1: false })
  // try {
  // 	if (resp.status == 200) {
  // if (resp.data.responseCode == 200) {

  // 	if (resp.data.responseCode == 409 || resp.data.responseCode == 410 || resp.data.responseCode == 411) {
  // 		localStorage.removeItem("data");
  // 		localStorage.removeItem("isLoggedin");
  // 		localStorage.removeItem("data1");
  // 		localStorage.removeItem("token");

  // 		this.props.history.push("/")

  // 	}
  // 	if (resp.data.responseCode == 200) {
  // 		if (resp.data.result.docs.length != 0) {

  // this.setState({ noData: "" })
  // this.allData(resp.data.result.docs)
  // 		}
  // 		else {
  // this.setState({ noData: "Sorry no offers found." })

  // // toast_func("error", "No data matched for BUY as per your search")
  // 		}

  // 	}
  // 	else if (resp.data.responseCode == 502) {
  // 		this.props.history.push("NotFound")

  // 	}
  // 	else {
  // 		//toast_func("error", "No data matched as per your search")

  // 	}

  // }
  // else if (resp.data.responseCode == 401) {
  // 	this.setState({ whole_data1: [], noData: "Sorry no offers found." })
  // }
  // else {
  // 	//toast_func("error", resp.data.responseMessage)

  // }
  // 	}
  // 	else {
  // toast_func("error", resp.data.responseMessage)

  // 	}
  // }
  // catch (ex) {
  // 	toast_func("error", "Some problem occured please contact administrator")
  // }
  // 		})

  // 	this.setState({ loader2: true })
  // 	Api("trade/filter_trade", "POST", parameter1, this.state.token, this.state._id).then(
  // 		(resp) => {
  // this.setState({ loader2: false })
  // try {
  // 	if (resp.status == 200) {
  // if (resp.data.responseCode == 200) {

  // 	if (resp.data.responseCode == 409 || resp.data.responseCode == 410 || resp.data.responseCode == 411) {
  // 		localStorage.removeItem("data");
  // 		localStorage.removeItem("isLoggedin");
  // 		localStorage.removeItem("data1");
  // 		localStorage.removeItem("token");

  // 		this.props.history.push("/")

  // 	}
  // 	if (resp.data.responseCode == 200) {
  // 		if (resp.data.result.docs.length != 0) {

  // this.setState({ whole_dataTotal2: resp.data.result.total })
  // this.allData2(resp.data.result.docs)
  // 		}
  // 		else {
  // // toast_func("error", "No data matched for BUY as per your search")
  // 		}

  // 	}
  // 	else if (resp.data.responseCode == 502) {
  // 		this.props.history.push("NotFound")

  // 	}
  // 	else {
  // 		//toast_func("error", "No data matched as per your search")

  // 	}

  // }
  // else if (resp.data.responseCode == 401) {
  // 	this.setState({ whole_data2: [] })
  // }
  // else {
  // 	//toast_func("error", resp.data.responseMessage)

  // }
  // 	}
  // 	else {
  // toast_func("error", resp.data.responseMessage)

  // 	}
  // }
  // catch (ex) {
  // 	toast_func("error", "Some problem occured please contact administrator")
  // }

  // 		})

  // }
  searchFilter = (value1, value2, e) => {
    e.preventDefault();
    if (value1 == null) {
      this.setState({ pgval: 1 });
    }
    if (value2 == null) {
      this.setState({ pgval1: 1 });
    }
    this.setState({ noData: "Searching...." });

    if (this.state.tab == "buy") {
      if (this.state.bank != "") {
        var method = this.state.bank.replace(/ /g, "-");
        this.props.history.push(`/buy-bitcoins/${method}`);
      } else this.props.history.push(`/buy-bitcoins/`);
    } else {
      if (this.state.bank != "") {
        var method = this.state.bank.replace(/ /g, "-");
        this.props.history.push(`/sell-bitcoins/${method}`);
      } else this.props.history.push(`/sell-bitcoins/`);
    }

    if (value1 == null && value2 == null) {
      this.setState({
        whole_data1: [],
        whole_data2: [],
      });
    }
    this.setState({pgval: this.state.pgval + value1,pgval1: this.state.pgval1 + value2 });
   
    var parameter1 = {
      type: "buy",
      payment_method: this.state.bank,
      amount: this.state.Amount,
      location:
        this.state.country == "Select Country" ? "" : this.state.country,
      currency_type: this.state.currency,
      limit: 10,
      page: this.state.pgval + value1,
    };
    // page: value1,
    var parameter2 = {
      type: "sell",
      payment_method: this.state.bank,
      amount: this.state.Amount,
      location:
        this.state.country == "Select Country" ? "" : this.state.country,
      currency_type: this.state.currency,
      limit: 10,
      page: this.state.pgval1 + value2,
    };

    if (value1 != null || e) {
      this.setState({ loader1: true });
      console.log("=====>>>>>P1==", parameter1);
      Api(
        "trade/filter_trade",
        "POST",
        parameter1,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loader1: false });
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              this.allData(resp.data.result.docs);
              this.setState({
                totalpg: resp.data.result.pages,
              });
              this.setState({
                currPg: resp.data.result.page,
              });
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");

                this.props.history.push("/");
              } else if (resp.data.result.docs.length != 0) {
                // console.log("===========temp=======>>>>",tempAdslist)
                // resp.data.result.docs.map((item)=>{
                //   tempAdslist.push(item)
                // })
                // console.log("===========tempAdslist=======>>>>",tempAdslist);
                // this.setState(
                //   { whole_data1: tempAdslist, noData: "" ,populate_viewBuyMore: true},
                //   () => this.populate_viewBuyMore()
                // );
              } else {
                this.setState({
                  noData: "Sorry no offers found.",
                });
                // toast_func("error", "No data matched for BUY as per your search")
              }
            } else if (resp.data.responseCode == 401) {
              this.setState({
                whole_data1: [],
                noData: "Sorry no offers found.",
              });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              // toast_func("error", resp.data.responseMessage)
            }
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } catch (ex) {
          //toast_func("error", "Some problem occured please contact administrator")
        }
      });
    }
    if (value2 !== null || e) {
      this.setState({ loader2: true });
      console.log("=====>>>>>P2==", parameter2);
      Api(
        "trade/filter_trade",
        "POST",
        parameter2,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loader2: false });
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              if (resp.data.result.docs.length != 0) {
                this.allData2(resp.data.result.docs);
                this.setState({
                  totalpg1: resp.data.result.pages,
                });
                this.setState({
                  currPg1: resp.data.result.page,
                });
              } else {
                //toast_func("error", "No data matched for SELL as per your search")
              }
            } else if (resp.data.responseCode == 401) {
              this.setState({
                whole_data2: [],
              });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              //toast_func("error", resp.data.responseMessage)
            }
          } else {
            // toast_func("error", resp.data.responseMessage)
          }
        } catch (ex) {
          //toast_func("error", "Some problem occured please contact administrator")
        }
      });
    }
  };
  viewDetail = (e, Id) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(Id);
    this.props.history.push(`/user-details/${encryptData}`);
  };
  populate_view1 = () => {
    console.log(
      "==========populate_view1======   >>>>>>>>>  xxx=================="
    );
    let table = [];
    if (this.state.loader1 == true) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <span>
              <img
                src={require("../assets/ajax-loader.gif")}
                style={{ height: "25px", width: "25px" }}
                alt=""
              />
            </span>
          </td>
        </tr>
      );
    } else if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (
      let i = 0;
      i <
      (this.state.whole_data1.length < 5 ? this.state.whole_data1.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data1[i]["user_id"]}`}
                  ash
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data1[i]["user_id"])
                  }
                >{`${this.state.whole_data1[i]["user_name"]}`}</a>
                {this.state.whole_data1[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data1[i]["color"] }}
                    className="dot fa fa-circle"
                  />
                  {`${this.state.whole_data1[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data1[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data1[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data1[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
          //children.push(<td>{`${this.state.whole_data1[i]["user_name"]}`}</td>)
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data1[i]["payment_method"]
              }`}</a>

              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data1[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data1[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j === 5) {
          if (this.state.whole_data1[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data1[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data1[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data1[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data1[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j == 6) {
          if (this.state.tab == "buy") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data1[i]["_id"],
                      this.state.whole_data1[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minhyt minWidth80"
                >
                  Buy
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data1[i]["_id"],
                      this.state.whole_data1[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minhyt minWidth80"
                >
                  Sell
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populate_view1ShowMore = () => {
    console.log(
      "==========populate_view1ShowMore      >>>>>>>>>  ========================",
      this.state.whole_data1
    );
    let table = [];
    if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (let i = 0; i < this.state.whole_data1.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data1[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data1[i]["user_id"])
                  }
                >{`${this.state.whole_data1[i]["user_name"]}`}</a>
                {this.state.whole_data1[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data1[i]["color"] }}
                    className="dot fa fa-circle"
                  />
                  {`${this.state.whole_data1[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data1[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data1[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data1[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );

          // children.push(<td>{`${this.state.whole_data1[i]["user_name"]}`}</td>)
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data1[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td key={j}>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data1[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data1[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j === 5) {
          if (this.state.whole_data1[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data1[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data1[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data1[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data1[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j == 6) {
          if (this.state.tab == "buy") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data1[i]["_id"],
                      this.state.whole_data1[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Buy
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data1[i]["_id"],
                      this.state.whole_data1[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Sell
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populate_view2 = () => {
    let table = [];
    if (this.state.loader2 == true) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <span>
              <img
                src={require("../assets/ajax-loader.gif")}
                style={{ height: "25px", width: "25px" }}
                alt=""
              />
            </span>
          </td>
        </tr>
      );
    } else if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (
      let i = 0;
      i <
      (this.state.whole_data2.length < 5 ? this.state.whole_data2.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data2[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data2[i]["user_id"])
                  }
                >{`${this.state.whole_data2[i]["user_name"]}`}</a>
                {this.state.whole_data2[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data2[i]["color"] }}
                    className="dot fa fa-circle"
                  />
                  {`${this.state.whole_data2[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data2[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data2[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data2[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data2[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data2[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data2[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(
                <td key={j} style={{ width: "100px" }}>
                  $0
                </td>
              );
        } else if (j == 5) {
          if (this.state.whole_data2[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data2[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data2[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data2[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data2[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j == 6) {
          if (this.state.tab == "buy") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data2[i]["_id"],
                      this.state.whole_data2[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Buy
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td key={j} className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data2[i]["_id"],
                      this.state.whole_data2[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Sell
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populate_view2ShowMore = () => {
    let table = [];
    if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (let i = 0; i < this.state.whole_data2.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data2[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data2[i]["user_id"])
                  }
                >{`${this.state.whole_data2[i]["user_name"]}`}</a>
                {this.state.whole_data2[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data2[i]["color"] }}
                    className="dot fa fa-circle"
                  />
                  {`${this.state.whole_data2[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data2[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data2[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data2[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td>
              <a className="anchor-styling">{`${
                this.state.whole_data2[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data2[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data2[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(
                <td key={j} style={{ width: "100px" }}>
                  $0
                </td>
              );
        } else if (j == 5) {
          if (this.state.whole_data2[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data2[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data2[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data2[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data2[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j == 6) {
          if (this.state.tab == "buy") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data2[i]["_id"],
                      this.state.whole_data2[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Buy
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) =>
                    this.BuySellSpecific(
                      e,
                      this.state.whole_data2[i]["_id"],
                      this.state.whole_data2[i]["payment_method"]
                    )
                  }
                  className="btn btnGray minWidth80"
                >
                  Sell
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  BuySellSpecific = (e, Id, method) => {
    e.preventDefault();
    // var id = e.target.id;
    var encryptData = base32.encode(Id);

    if (this.state.tab == "buy") {
      method = method.replace(/ /g, "-");
      this.props.history.push(
        window.open(`/buy-bitcoin-instantly/${method}/${encryptData}`, "_blank")
      );
    } else if (this.state.tab == "sell") {
      method = method.replace(/ /g, "-");
      this.props.history.push(
        window.open(
          `/sell-bitcoins-instantly/${method}/${encryptData}`,
          "_blank"
        )
      );
    }
  };

  setValue(value) {
    this.setState({ currencyModal: false });
    console.log("Asi==>", value);
    this.setState({ currency: value });
  }

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--popUpBW-color", "#fff");
    } else {
      document.body.style.setProperty("--popUpBW-color", "#3e5c85");
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Modal isOpen={this.state.tutorial} style={{ width: "150%" }}>
          <div>
            <div className="modal-header">
              <h4 className="modal-title">Trade Tutorial</h4>
              <button
                type="button"
                onClick={() =>
                  this.setState({
                    tutorial: false,
                  })
                }
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body pt0 pb0 readTutoral">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              {this.state.tutorialinc ? (
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              ) : null}
            </div>
            <div className="modal-footer">
              <div className="pt10 borderTop w-100 text-right d-flex justify-content-between flex-wrap">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      tutorial: false,
                    })
                  }
                  className="btn btnGray w-50 font16 p10 pb10"
                >
                  Skip
                </button>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      tutorialinc: true,
                    })
                  }
                  className="btn btnGreen w-50 font16  p10 pb10"
                >
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <main className="mainContent">
          <section className="buySell_bg">
            <div className="container1440 ">
              <div className="buySellBox">
                <h2 className="header2 mb35">Buy/Sell Bitcoins</h2>
                <div>
                  <ul
                    className="nav globalTab max-WT-600 center-box w-100 mb30 tabGrp"
                    role="tablist"
                  >
                    <li
                      className={`nav-item mr8 ${
                        this.state.tab == "buy" ? "tabBtnsAct" : "tabBtns"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className="nav-link"
                        onClick={() => {
                          this.setState({
                            tab: "buy",
                            whole_data: "",
                          });
                          this.props.history.push(`/buy-bitcoins`);
                        }}
                        data-toggle="tab"
                      >
                        Quick Buy
                      </a>
                    </li>
                    <li
                      className={`nav-item  ${
                        this.state.tab == "sell" ? "tabBtnsAct" : "tabBtns"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className="nav-link"
                        onClick={() => {
                          this.setState({
                            tab: "sell",
                            whole_data: "",
                          });
                          this.props.history.push(`/sell-bitcoins`);
                        }}
                        data-toggle="tab"
                      >
                        Quick Sell
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tableFilter11">
                  <form className="main-form">
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="filterInlineField">
                        <div
                          className="input-group"
                          style={{ flexWrap: "nowrap" }}
                        >
                          <input
                            type="text"
                            style={{ minWidth: 80 }}
                            className="form-control radius-TR0 "
                            value={this.state.Amount}
                            name="Amount"
                            placeholder="Amount"
                            onChange={this.handleOnChange}
                          />
                          <div style={{ width: 115 }}>
                            <input
                              type="text"
                              className="form-control select-style1  radius-TL0 radius-BL0 borderLeft0"
                              placeholder="currency"
                              name="currency1"
                              defaultValue={this.state.currency}
                              onClick={() =>
                                this.setState({
                                  currencyModal: true,
                                })
                              }
                            />
                            {/* {this.Populate_currency()} */}
                            <Modal
                              isOpen={this.state.currencyModal}
                              fade={false}
                              style={{
                                alignSelf: "center",
                                justifyContent: "center",
                                margintop: "10%",
                                maxWidth: "950px",
                                margintop: "60px",
                              }}
                            >
                              <div className="cardBox max-WT-950 center-box">
                                <div className="cardHead center-box pb10 text-center posRel">
                                  <span
                                    className="crossBtn"
                                    onClick={() =>
                                      this.setState({
                                        currencyModal: false,
                                      })
                                    }
                                  >
                                    <i className="fa fa-times" />
                                  </span>

                                  <h4 className="font18 mt0 mb5 text-center">
                                    Currency
                                  </h4>
                                </div>
                                <CurrencyModal
                                  currencyJson={currencyJson}
                                  setValue={this.setValue}
                                />
                              </div>
                            </Modal>
                            {/* </select> */}
                          </div>
                        </div>
                      </div>
                      <div className="filterInlineField">
                        <select
                          className="form-control radius5 select-style1"
                          onChange={(e) => this.onClick_country(e)}
                        >
                          <option>Country</option>
                          {this.Populate_country()}
                        </select>
                      </div>
                      <div
                        className="filterInlineField"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <input
                          type="text"
                          value={this.state.bank}
                          readOnly
                          placeholder="Payment Method"
                          className="form-control radius5 select-style1"
                          onClick={() => this.setState({ paymethods: true })}
                        >
                          {/* <option>All Online offers</option>
	{this.Populate_paymentMethods()} */}
                        </input>
                        <button
                          style={{ width: "30%", marginLeft: 10 }}
                          className="btn btnGreen pt10 pb10"
                          onClick={(e) => this.searchFilter(null, null, e)}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                      <Modal
                        isOpen={this.state.paymethods}
                        fade={false}
                        style={{
                          alignSelf: "center",
                          justifyContent: "center",
                          margintop: "10%",
                          maxWidth: "950px",
                          margintop: "60px",
                        }}
                      >
                        <div className="cardBox max-WT-950 center-box">
                          <div className="cardHead center-box pb10 text-center posRel">
                            <span
                              className="crossBtn"
                              onClick={() =>
                                this.setState({
                                  paymethods: false,
                                })
                              }
                            >
                              <i className="fa fa-times" />
                            </span>

                            <h4 className="font18 mt0 mb5 text-center">
                              Method
                            </h4>
                          </div>
                          {/* <p className="rcvCoin pl15 pr15">if you setup 2 step verification, you can use the google authenticator app to receive codes even if you don't have an internet connection or mobile service for trades & transactions</p> */}
                          <form
                            className="mb0"
                            style={{
                              height: "350px",
                              overflowY: "auto",
                              backgroundColor: "var(--white-color)",
                            }}
                          >
                            <div className="formBox  max-WT-750 center-box">
                              {/* <div className="form-group">
        <label className="control-label">Authenticate code</label>
        <input type="text" className="form-control" name="auth_code" id="auth_cade" maxLength="6" />
    </div> */}
                              <div className="form-group mt20">
                                <div className="input-per2">
                                  <input
                                    type="text"
                                    maxLength="15"
                                    className="form-control modalSearch"
                                    placeholder="All Online offers"
                                    name="bank"
                                    value={this.state.bank}
                                    onChange={(e) => this.handalMethod(e)}
                                  />
                                  <span>
                                    <i className="fa fa-search" />
                                  </span>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  {this.state.paymentMethodS.map(
                                    (item, index) => {
                                      return (
                                        <div className="col-md-4" key={index}>
                                          <button
                                            className="paymethod"
                                            title={item.name}
                                            onClick={(e) =>
                                              this.setMethod(e, item)
                                            }
                                          >
                                            <img
                                              src={require("../assets/newImages/bitIcon.png")}
                                              className=""
                                            />
                                            {item.name.length < 15
                                              ? item.name
                                              : item.name.slice(0, 14) + ".."}
                                          </button>
                                        </div>
                                      );
                                    }
                                  )}

                                  {/* <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>Paytm Online Wallet</button></div>
        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i> Google Pay</button></div> */}
                                </div>
                                {/* <div className="row mt10">
        <div className="col-md-4">
            <button className="paymethod"><i className="fa fa-home"></i>Game Items</button>
        </div>
        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>PhonePe</button></div>
        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i> Paypal</button></div>
        </div> */}
                              </div>

                              {/* <div className='text-center'>
    <button type="submit" className="btn btnGreen w-100 radius4 mb20">Verify</button>
    </div> */}
                            </div>
                          </form>
                        </div>
                      </Modal>
                      <div id="quickBuyBitcoin">
                        <div id="quickSellBitcoin" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container1440">
              <div className="buySellBox2 pt0">
                {/* <!-- Tab-Content --> */}
                <div className="tab-content">
                  {/* <div className="tableFilter borderBottom0 pb0 mb30">
	<form className="main-form">
		<div className="d-flex align-items-center flex-wrap">
<div className="filterInlineField">
<div className="input-group">
	<input type="text" className="form-control borderRight0 radius-TR0 radius-BR0" value={this.state.Amount} name="Amount" placeholder="Enter Amount" onChange={this.handleOnChange} />
	<div className="input-group-append">
		<select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0" name="currency" onChange={(e) => this.onClick_currency(e)}>
<option>Select currency</option>
{this.Populate_currency()}
		</select>
	</div>
</div>
</div>
<div className="filterInlineField">
<select className="form-control select-style1" onChange={(e) => this.onClick_country(e)}>
	<option>Select Country</option>
	{this.Populate_country()}
</select>
</div>
<div className="filterInlineField">
<select className="form-control select-style1" onChange={(e) => this.onClick_paymentBank(e)}>
	<option>All Online offers</option>
	{this.Populate_paymentMethods()}
</select>
</div>
<button className="proxiNova btn btnGreen pt10 pb10 minWidth120" onClick={(e) => this.searchFilter(e)}>Search</button>
		</div>
	</form>
</div> */}
                  <div
                    id="tab1"
                    className={`tab-pane ${
                      this.state.tab == "buy" ? "active" : ""
                    } `}
                  >
                    <div className="tableBox">
                      <div className="tableBoxHead d-flex align-items-center flex-wrap">
                        <h4 className="mb15">Buy Bitcoin</h4>
                      </div>
                      <div className="tableBoxBody">
                        <div className="table-responsive tbl">
                          <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                            <thead className="tbl_hd">
                              <tr>
                                <th>Buy from</th>
                                <th>Buy with</th>
                                <th>Price/BTC</th>
                                <th>Limit</th>
                                <th>To Pay on the dollar</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.whole_data1.length ? (
                              this.state.whole_data1.map((item,index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <a
                                            className="underl"
                                            style={{
                                              justifyContent: "flex-start",
                                            }}
                                            onClick={(e) =>
                                              this.viewDetail(e, item.user_id)
                                            }
                                          >
                                            {item.user_name}
                                          </a>
                                          {item.kyc_status == "APPROVED" ? (
                                            <img
                                              style={{
                                                width: 30,
                                                height: 30,
                                                marginLeft: 5,
                                              }}
                                              src={require("../assets/images/completed.png")}
                                            />
                                          ) : null}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontSize: 15,
                                            margintop: 5,
                                          }}
                                        >
                                          {item.status == "ACTIVE" ? (
                                            <i
                                              style={{
                                                color: item.color,
                                                marginTop: 3,
                                                marginRight: 5,
                                              }}
                                              className="dot fa fa-circle"
                                            />
                                          ) : null}
                                          <span
                                            style={{
                                              fontSize: 14,
                                            }}
                                          >
                                            {item.userStatus}{" "}
                                          </span>
                                        </div>
                                        <a
                                          style={{
                                            fontSize: 15,
                                            alignSelf: "flex-start",
                                          }}
                                          href={`/feedback/${base32.encode(
                                            item.user_id
                                          )}`}
                                        >
                                          {" "}
                                          (+ {item.posi}
                                          /- {item.nega}){" "}
                                        </a>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      {/* product image */}
                                      {item.product_images ? (
                                        <div
                                          style={
                                            {
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                            }
                                            // alignContent:'center',
                                            // justifyContent:'center'
                                          }
                                        >
                                          {item.product_images[0] ? (
                                            <img
                                              className="proimg"
                                              src={
                                                item.product_images[0].imageUrl
                                              }
                                              alt=""
                                              style={{
                                                border: "solid 2px #f39c12",
                                              }}
                                              onClick={(e) =>
                                                this.setState({
                                                  showImage: true,
                                                  prodImg: item.product_images,
                                                })
                                              }
                                            />
                                          ) : (
                                            <img
                                              className="proimg"
                                              src={
                                                require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90 ,objectFit:'contain',margintop:10}}
                                              }
                                              alt=""
                                              onClick={(e) =>
                                                this.setState({
                                                  showImage: true,
                                                  prodImg: item.product_images,
                                                })
                                              }
                                            />
                                          )}
                                          <div
                                            className="imgcount"
                                            style={{
                                              backgroundColor: "#3e5c85",
                                              alignSelf: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 13,
                                                color: "white",
                                                marginTop: -5,
                                              }}
                                            >
                                              {item.product_images
                                                ? item.product_images.length
                                                : "0"}
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={
                                            {
                                              display: "flex",
                                              flexDirection: "column",
                                            }
                                            // justifyContent:'center',
                                            // alignContent:'center'
                                          }
                                        >
                                          <img
                                            className="proimg"
                                            src={
                                              require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90,objectFit:'contain' }}
                                            }
                                          />
                                          <div
                                            className="imgcount"
                                            style={{
                                              backgroundColor: "#3e5c85",
                                              alignSelf: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 13,
                                                color: "white",
                                                marginTop: -5,
                                              }}
                                            >
                                              0
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: "180px",
                                            color: "var(--black-color)",
                                          }}
                                        >
                                          {item.payment_method}
                                        </span>
                                        <div
                                          style={{
                                            marginBottom: 5,
                                            width: "200px",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          {item.add_tags.map((tag,ind) => {
                                            return (
                                              <p key={ind}
                                                style={{
                                                  color: "var(--text-grey)",
                                                  fontSize: 12,
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {tag}{" "}
                                              </p>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {Number(item.price_equation).toFixed(2)}{" "}
                                      {item.currency_type}
                                    </td>
                                    <td style={{ width: 150 }}>
                                      <p
                                        style={{
                                          marginBottom: 0,
                                          fontSize: 16,
                                        }}
                                      >
                                        {"min: " + item.min_transaction_limit}{" "}
                                        {item.currency_type}
                                      </p>
                                      <p
                                        style={{
                                          marginBottom: 0,
                                          fontSize: 16,
                                        }}
                                      >
                                        {"max: " + item.max_transaction_limit}{" "}
                                        {item.currency_type}
                                      </p>
                                    </td>
                                    <td>${Number(item.toPay).toFixed(2)}</td>
                                    <td>
                                      <button
                                        className="showMore btn ml-auto mb25 minWidth120"
                                        onClick={(e) =>
                                          this.BuySellSpecific(
                                            e,
                                            item._id,
                                            item.payment_method
                                          )
                                        }
                                      >
                                        {item.type_of_trade_other}
                                      </button>
                                      <div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {item.visitor?(
                                  <p style={{margin:2,marginLeft:4}}>{item.visitor}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="6" align="center">
                                  <label className="font20 rColor text-center">
                                    Sorry No Offer Found
                                  </label>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div style={{ display: "grid", marginTop: 15 }}>
                        {this.state.totalpg == this.state.currPg ||
                        !this.state.whole_data1.length ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: 600,
                              width: "100%",
                              fontSize: 20,
                              margintop: 5,
                            }}
                          >
                            No More Data Found
                          </p>
                        ) : (
                          <button
                            style={{ width: "fit-content", margin: "auto" }}
                            disabled={
                              this.state.totalpg == this.state.currPg ||
                              !this.state.whole_data1.length
                            }
                            onClick={(e) => this.searchFilter(1, null, e)}
                            className="showMore btn ml-auto mb25 minWidth120"
                          >
                            Show More...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <!-- tab1 End --> */}
                  <div
                    id="tab2"
                    className={`tab-pane ${
                      this.state.tab == "sell" ? "active" : ""
                    } `}
                  >
                    <div className="tableBox">
                      <div className="tableBoxHead d-flex align-items-center flex-wrap">
                        <h4 className="mb15">Sell Bitcoin</h4>
                      </div>
                      <div className="tableBoxBody">
                        <div className="table-responsive tbl">
                          <table className="table tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                            <thead className="tbl_hd">
                              <tr>
                                <th>Sell to</th>
                                <th>Sell with</th>
                                <th>Price/BTC</th>
                                <th>Limit</th>
                                <th>To Pay on the dollar</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.whole_data2.length ? (
                              this.state.whole_data2.map((item,index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <span
                                            className="underl"
                                            style={{
                                              justifyContent: "flex-start",
                                            }}
                                            onClick={(e) =>
                                              this.viewDetail(e, item.user_id)
                                            }
                                          >
                                            {item.user_name}
                                          </span>
                                          {item.kyc_status == "APPROVED" ? (
                                            <img
                                              style={{
                                                width: 30,
                                                height: 30,
                                                marginLeft: 5,
                                              }}
                                              src={require("../assets/images/completed.png")}
                                            />
                                          ) : null}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontSize: 15,
                                            margintop: 5,
                                          }}
                                        >
                                          {item.status == "ACTIVE" ? (
                                            <i
                                              style={{
                                                color: item.color,
                                                marginTop: 5,
                                                marginRight: 5,
                                              }}
                                              className="dot fa fa-circle"
                                            />
                                          ) : null}
                                          <span style={{ fontSize: 14 }}>
                                            {item.userStatus}{" "}
                                          </span>
                                        </div>
                                        <a
                                          style={{
                                            fontSize: 14,
                                            alignSelf: "flex-start",
                                          }}
                                          href={`/feedback/${base32.encode(
                                            item.user_id
                                          )}`}
                                        >
                                          {" "}
                                          (+ {item.posi}
                                          /- {item.nega}){" "}
                                        </a>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                      }}
                                    >
                                      {/* product image */}
                                      {item.product_images ? (
                                        <div
                                          style={
                                            {
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                            } // justifyContent:'center',
                                          }
                                        >
                                          {item.product_images[0] ? (
                                            <img
                                              className="proimg"
                                              src={
                                                item.product_images[0].imageUrl
                                              }
                                              alt=""
                                              style={{
                                                border: "solid 2px #f39c12",
                                              }}
                                              onClick={(e) =>
                                                this.setState({
                                                  showImage: true,
                                                  prodImg: item.product_images,
                                                })
                                              }
                                            />
                                          ) : (
                                            <img
                                              className="proimg"
                                              src={
                                                require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90 ,objectFit:'contain',margintop:10}}
                                              }
                                              alt=""
                                              onClick={(e) =>
                                                this.setState({
                                                  showImage: true,
                                                  prodImg: item.product_images,
                                                })
                                              }
                                            />
                                          )}
                                          <div
                                            className="imgcount"
                                            style={{
                                              backgroundColor: "#3e5c85",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 13,
                                                color: "white",
                                                marginTop: -5,
                                              }}
                                            >
                                              {item.product_images
                                                ? item.product_images.length
                                                : "0"}
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={
                                            {
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                            } // justifyContent:'center',
                                          }
                                        >
                                          <img
                                            className="proimg"
                                            src={
                                              require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90,objectFit:'contain' }}
                                            }
                                          />
                                          <div
                                            className="imgcount"
                                            style={{
                                              backgroundColor: "#3e5c85",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 13,
                                                color: "white",
                                                marginTop: -5,
                                              }}
                                            >
                                              0
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: "180px",
                                            color: "var(--black-color)",
                                          }}
                                        >
                                          {item.payment_method}
                                        </span>
                                        <div
                                          style={{
                                            marginBottom: 5,
                                            width: "200px",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          {item.add_tags.map((tag,ind) => {
                                            return (
                                              <p key={ind}
                                                style={{
                                                  color: "var(--text-grey)",
                                                  fontSize: 12,
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {tag}{" "}
                                              </p>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {Number(item.price_equation).toFixed(2)}{" "}
                                      {item.currency_type}
                                    </td>
                                    <td style={{ width: 150 }}>
                                      <p
                                        style={{
                                          marginBottom: 0,
                                          fontSize: 16,
                                        }}
                                      >
                                        min: {item.min_transaction_limit}{" "}
                                        {item.currency_type}
                                      </p>
                                      <p
                                        style={{
                                          marginBottom: 0,
                                          fontSize: 16,
                                        }}
                                      >
                                        max: {item.max_transaction_limit}{" "}
                                        {item.currency_type}
                                      </p>
                                    </td>
                                    <td>${Number(item.toPay).toFixed(2)}</td>
                                    <td>
                                      <button
                                        className="showMore btn ml-auto mb25 minWidth120"
                                        onClick={(e) =>
                                          this.BuySellSpecific(
                                            e,
                                            item._id,
                                            item.payment_method
                                          )
                                        }
                                      >
                                        {item.type_of_trade_other}
                                      </button>
                                      <div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {item.visitor?(
                                  <p style={{margin:2,marginLeft:4}}>{item.visitor}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="6" align="center">
                                  <label className="font20 rColor text-center">
                                    {this.state.noData}
                                    {/* Sorry No Offer Found */}
                                  </label>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                        {/* <div width={'100%'}>
                        {this.state.totalpg1 == this.state.currPg1 &&
                        <p style={{textAlign: 'center', fontSize: 20, margintop: 5}}>No More Data Found</p>}
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            justifyContent: "center",
                            width: "55%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <button
                            disabled={this.state.totalpg1 == this.state.currPg1}
                            onClick={(e) => this.searchFilter(null, 1, e)}
                            className="showMore btn ml-auto mb25 minWidth120"
                          >
                            Show More...
                          </button>
                        </div> */}
                        <div style={{ display: "grid", marginTop: 15 }}>
                          {this.state.totalpg1 == this.state.currPg1 ||
                          !this.state.whole_data2.length ? (
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 600,
                                width: "100%",
                                fontSize: 20,
                                margintop: 5,
                              }}
                            >
                              No More Data Found
                            </p>
                          ) : (
                            <button
                              style={{ width: "fit-content", margin: "auto" }}
                              disabled={
                                this.state.totalpg1 == this.state.currPg1 ||
                                !this.state.whole_data2.length
                              }
                              onClick={(e) => this.searchFilter(null, 1, e)}
                              className="showMore btn ml-auto mb25 minWidth120"
                            >
                              Show More...
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- tab2 End --> */}
                </div>
                {/* <!-- Tab-Content Start --> */}
              </div>
            </div>
            <Modal
              isOpen={this.state.showImage}
              fade={false}
              style={{
                alignSelf: "center",
                justifyContent: "center",
                margintop: "10%",
                maxWidth: "680px",
                margintop: "60px",
              }}
            >
              <div className="cardBox max-WT-950 center-box">
                <div className="cardHead center-box pb10 text-center posRel">
                  <span
                    className="crossBtn"
                    onClick={() =>
                      this.setState({
                        showImage: false,
                      })
                    }
                  >
                    <i className="fa fa-times" />
                  </span>

                  <h4 className="font18 mt0 mb5 text-center">Product Image</h4>
                </div>
                <ImageModal productImage={this.state.prodImg} />
              </div>
            </Modal>
          </section>
          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
