import React, { Component } from "react";
import Headers from "../components/header";
import Footer from "../components/footer";
import { base32 } from "../services/Decrypt";
import { Api } from "../services/webservices";
import $ from "jquery";
export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: 1 };
  }
  componentDidMount() {
    var userID = base32.decode(this.props.match.params.userID);

    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      this.setState({ userID: userID, _id: id, token: token }, () =>
        this.showFeedback()
      );
    }, 300);

    $(window).scroll(function () {
      //console.log("shoeeaaaaaaaaaaaa   ")
      //alert("ddddd")
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $("header").addClass("header-bg");
        $(".fixedBox").addClass("fixdtop");
      } else {
        $("header").removeClass("header-bg");
        $(".fixedBox").removeClass("fixdtop");
      }
    });
  }
  showFeedback = () => {
    let id = localStorage.getItem("data");
    let token = localStorage.getItem("token");
    //alert("hello===>"+token)
    var parameter = {
      // "feedbackTo":`${this.state.userID}`,
      feedbackTo: `${this.state.userID}`,
      feedbackType: "",
      limit: 500,
      pageNumber: 1,
    };

    Api("feedback/getFeedbackList", "POST", parameter, token, id).then(
      (resp) => {
        let negativeList = [];
        let positiveList = [];
        let neutralList = [];

        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            for (let i = 0; i < resp.data.Data.docs.length; i++) {
              // AllList.push(
              //     <div className="feedbackBox">
              //     <div className="feedUserLeft">
              //        <span className="feedUserImg"><img src={resp.data.Data.docs[i]['feedbackFrom']['profilePic']} alt="image" /></span>
              //     </div>
              //     <div className="feedUserRight">
              //        <h5><span className="feedUserName">{resp.data.Data.docs[i]['feedbackFrom']['user_name']}</span><span className="feedTime">{resp.data.Data.docs[i]['createdAt'].replace("T"," ").replace("Z"," ").split(".")[0]}</span></h5>
              //        <p><i className={`far fa-thumbs-${resp.data.Data.docs[i]['feedbackType']== "negative"?'down rColor':'up gColor'}`}></i>{resp.data.Data.docs[i]['feedbackMessage']?resp.data.Data.docs[i]['feedbackMessage']:"" }</p>
              //     </div>
              //  </div>
              // )

              if (resp.data.Data.docs[i]["feedbackType"] == "negative") {
                negativeList.push(
                  <div className="feedbackBox">
                    <div className="feedUserLeft">
                      <span className="feedUserImg">
                        <img
                          src={
                            resp.data.Data.docs[i]["feedbackFrom"]["profilePic"]
                          }
                          alt="image"
                        />
                      </span>
                    </div>
                    <div className="feedUserRight">
                      <h5>
                        <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                          <span className="feedUserName">
                            {resp.data.Data.docs[i]["feedbackFrom"]["user_name"]}
                          </span>
                          </div>
                          <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                            <p style={{ color: "var(--text-grey)" }}>
                              <i className="far fa-thumbs-down rColor" />
                              {resp.data.Data.docs[i]["feedbackMessage"]
                                ? resp.data.Data.docs[i]["feedbackMessage"]
                                : ""}
                            </p>
                          </div>
                          <span className="feedTime">
                            {
                              resp.data.Data.docs[i]["createdAt"]
                                .replace("T", " ")
                                .replace("Z", " ")
                                .split(".")[0]
                            }
                          </span>
                        
                      </h5>

                    </div>
                  </div>
                );
              } else if (resp.data.Data.docs[i]["feedbackType"] == "positive") {
                positiveList.push(
                  <div className="feedbackBox">
                    <div className="feedUserLeft">
                      <span className="feedUserImg">
                        <img
                          src={
                            resp.data.Data.docs[i]["feedbackFrom"]["profilePic"]
                          }
                          alt="image"
                        />
                      </span>
                    </div>
                    <div className="feedUserRight">
                      <h5>
                        <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                          <span className="feedUserName">
                            {resp.data.Data.docs[i]["feedbackFrom"]["user_name"]}
                          </span>
                        </div>
                        <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                          <p style={{ color: "var(--text-grey)" }}>
                            <i className="far fa-thumbs-up gColor" />
                            {resp.data.Data.docs[i]["feedbackMessage"]
                              ? resp.data.Data.docs[i]["feedbackMessage"]
                              : ""}
                          </p>
                        </div>
                        <span className="feedTime">
                          {
                            resp.data.Data.docs[i]["createdAt"]
                              .replace("T", " ")
                              .replace("Z", " ")
                              .split(".")[0]
                          }
                        </span>
                      </h5>

                    </div>
                  </div>
                );
              } else if (resp.data.Data.docs[i]["feedbackType"] == "nuetral") {
                neutralList.push(
                  <div className="feedbackBox">
                    <div className="feedUserLeft">
                      <span className="feedUserImg">
                        <img
                          src={
                            resp.data.Data.docs[i]["feedbackFrom"]["profilePic"]
                          }
                          alt="image"
                        />
                      </span>
                    </div>
                    <div className="feedUserRight">
                      <h5>
                        <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                          <span className="feedUserName">
                            {resp.data.Data.docs[i]["feedbackFrom"]["user_name"]}
                          </span>
                          </div>
                          <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-start' }}>
                            <p style={{ color: "var(--text-grey)" }}>
                              <i className="far fa-thumbs-up gColor" />
                              {resp.data.Data.docs[i]["feedbackMessage"]
                                ? resp.data.Data.docs[i]["feedbackMessage"]
                                : ""}
                            </p>
                          </div>
                          <span className="feedTime">
                            {
                              resp.data.Data.docs[i]["createdAt"]
                                .replace("T", " ")
                                .replace("Z", " ")
                                .split(".")[0]
                            }
                          </span>
                        
                      </h5>

                    </div>
                  </div>
                );
              }
            }

            if (neutralList.length) {
              this.setState({
                neutralList: neutralList,
              });
            }
            if (negativeList.length) {
              this.setState({
                negativeList: negativeList,
              });
            }
            if (positiveList.length) {
              this.setState({
                positiveList: positiveList,
              });
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
        }
      }
    );
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };
  render() {
    return (
      <div>
        <Headers {...this.props} parentCallback1={this.handleCallback} />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <div className="sectionHead text-center">
                <h2 className="textInitial header2">Feedback</h2>
              </div>
              <ul
                className="nav nav-tabs globalTab max-WT-600 center-box w-100"
                role="tablist"
              >
                <li className="nav-item w-33">
                  <div
                    className={`${this.state.tab == 1 ? "nav-link active" : "nav-link"
                      }`}
                    onClick={() => {
                      this.setState({ tab: 1 });
                    }}
                  >
                    Positive{" "}
                    {this.state.positiveList ? (
                      <span>({this.state.positiveList.length})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </div>
                </li>
                <li className="nav-item w-33">
                  <div
                    className={`${this.state.tab == 2 ? "nav-link active" : "nav-link"
                      }`}
                    onClick={() => {
                      this.setState({ tab: 2 });
                    }}
                  >
                    Neutral{" "}
                    {this.state.neutralList ? (
                      <span>({this.state.neutralList.length})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </div>
                </li>
                <li className="nav-item w-33">
                  <div
                    className={`${this.state.tab == 3 ? "nav-link active" : "nav-link"
                      }`}
                    onClick={() => {
                      this.setState({ tab: 3 });
                    }}
                  >
                    Negative{" "}
                    {this.state.negativeList ? (
                      <span>({this.state.negativeList.length})</span>
                    ) : (
                      <span>(0)</span>
                    )}
                  </div>
                </li>
              </ul>
              <div className="tab-content cardBox center-box max-WT-1000">
                {/* <div className="sectionHead">
                        <h2 className="font22 textInitial">Tony</h2>
                        <p className="font13 m0">Feedback left by users</p>
                     </div> */}
                {this.state.tab == 1 ? (
                  <div
                    id="tab1"
                    className={`${this.state.tab == 1 ? "tab-pane active " : "tab-pane"
                      }`}
                  >
                    <div
                      className="feedbackList feedback"
                      style={{ fontSize: 25 }}
                    >
                      {this.state.positiveList
                        ? this.state.positiveList
                        : `No record found`}
                    </div>
                  </div>
                ) : this.state.tab == 2 ? (
                  <div
                    id="tab2"
                    className={`${this.state.tab == 2 ? "tab-pane active " : "tab-pane"
                      }`}
                  >
                    <div
                      className="feedbackList feedback"
                      style={{ fontSize: 25 }}
                    >
                      {this.state.neutralList
                        ? this.state.neutralList
                        : `No record found`}
                    </div>
                  </div>
                ) : this.state.tab == 3 ? (
                  <div
                    id="tab3"
                    className={`${this.state.tab == 3 ? "tab-pane active " : "tab-pane"
                      }`}
                  >
                    <div
                      className="feedbackList feedback"
                      style={{ fontSize: 25 }}
                    >
                      {this.state.negativeList
                        ? this.state.negativeList
                        : `No record found`}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
{
  /* <div className="feedbackList">                    
<div className="feedbackBox">
   <div className="feedUserLeft">
      <span className="feedUserImg"><img src={require("../assets/images/user-icon.png")} alt="image" /></span>
   </div>
   <div className="feedUserRight">
      <h5><span className="feedUserName">Hennah baker</span><span className="feedTime">May 26, 2018. 1:08 p.m</span></h5>
      <p><i className="far fa-thumbs-down rColor"></i>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
   </div>
</div> 

</div> */
}
