import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { toast_func } from "../components/toaster";
import { Api, socket, getCurrency } from "../services/webservices";
let currencyJson = [];
export default class Salary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addEmp: false,
      allowance: "",
      amount: "",
      EarningsArr: [],
      DeductionArr: [],
      deduction: "",
      d_amount: "",
      // empId:"",name:"",role:"",email:"",total_w_days:"",lop:"",paid_days:"",
      showModal: false,
      employeeList: [],
      employee_id: "",
    };
  }
  componentDidMount = async () => {
    currencyJson = await getCurrency();
    this.employeeList();
  };
  employeeList() {
    this.setState({ loading: true });
    var _id = localStorage.getItem("data");
    var parameter = {
      userId: _id,
    };
    Api("user/getEmployeeList", "POST", parameter).then((resp) => {
      this.setState({ loading: false });

      try {
        if ((resp.status = 200)) {
          if (resp.data.responseCode == 200) {
            this.setState({ employeeList: resp.data.succ });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
  }
  handleUserInput = (e) => {
    e.preventDefault();
    let value = e.target.value;
    console.log("=====>", value);
    this.setState({ allowance: value });
  };
  handleAmountInput = (e) => {
    e.preventDefault();
    let value = e.target.value;
    console.log("=====>", value);
    this.setState({ amount: value });
  };
  addAllowance(e) {
    e.preventDefault();
    if (this.state.allowance && this.state.amount) {
      let arr = this.state.EarningsArr;
      arr.push({ name: this.state.allowance, amount: this.state.amount });
      console.log("arr====>", JSON.stringify(arr));

      this.setState({ EarningsArr: arr });
      this.setState({ allowance: "", amount: "" });
    }
  }
  removeAllowance(e, i) {
    e.preventDefault();
    let arr2 = this.state.EarningsArr;
    arr2.splice(i, 1);
    this.setState({ EarningsArr: arr2 });
  }

  handlededuction = (e) => {
    e.preventDefault();
    let value1 = e.target.value;
    console.log("=====>", value1);
    this.setState({ deduction: value1 });
  };
  handleAmount = (e) => {
    e.preventDefault();
    let value2 = e.target.value;
    console.log("=====>", value2);
    this.setState({ d_amount: value2 });
  };
  addDeductions(e) {
    e.preventDefault();
    if (this.state.deduction && this.state.d_amount) {
      let deduct_arr = this.state.DeductionArr;
      deduct_arr.push({
        name: this.state.deduction,
        d_amount: this.state.d_amount,
      });
      console.log("arr====>", JSON.stringify(deduct_arr));

      this.setState({ DeductionArr: deduct_arr });
      this.setState({ deduction: "", d_amount: "" });
    }
  }
  removeDeduction(e, i) {
    e.preventDefault();
    let arr3 = this.state.DeductionArr;
    arr3.splice(i, 1);
    this.setState({ DeductionArr: arr3 });
  }
  Populate_currency = () => {
    let currencyArray = [];
    if (currencyJson.fiat) {
      let currArr = Object.keys(currencyJson.fiat);
      for (var i = 0; i < currArr.length; i++) {
        currencyArray.push(
          <option key={i} value={currArr[i]}>
            {currArr[i]}
          </option>
        );
      }
    }
    // for (var i = 0; i < currencyJson.length; i++) {
    //   currencyArray.push(<option value={currencyJson[i].code} key={i} >{currencyJson[i].code}</option>)
    // }
    return currencyArray;
  };

  handleInputs(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  addEmployee(e) {
    e.preventDefault();
    var _id = localStorage.getItem("data");
    var parameter = {
      userId: _id,
      empId: this.state.empId,
      empName: this.state.name,
      role: this.state.role,
      email: this.state.email,
      total_working_days: this.state.total_w_days,
      lop: this.state.lop,
      paid_days: this.state.paid_days,
      earnings: this.state.EarningsArr,
      deductions: this.state.DeductionArr,
    };
    this.setState({ loading: true });
    Api("user/addEmployee", "POST", parameter).then((resp) => {
      if (resp.status == 200) {
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
          this.setState({
            addEmp: false,
            empId: "",
            name: "",
            role: "",
            email: "",
            total_w_days: "",
            lop: "",
            paid_days: "",
            EarningsArr: [],
            DeductionArr: [],
          });
        }
        // else if (resp.data.responseCode == 502) {
        //     this.props.history.push("NotFound")

        // }
        else {
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }
  searchEmployee(e) {
    e.preventDefault();
    var _id = localStorage.getItem("data");
    var parameter = {
      userId: _id,
      employeeName: this.state.employee,
    };
    this.setState({ loading: true });
    Api("user/searchemployee", "POST", parameter).then((resp) => {
      if (resp.status == 200) {
        this.setState({ loading: false });
        if (resp.data.responseCode == 200) {
          toast_func("success", resp.data.responseMessage);
          // this.setState({ employeeList: resp.data.succ })
        }
        // else if (resp.data.responseCode == 502) {
        //     this.props.history.push("NotFound")

        // }
        else {
          toast_func("error", resp.data.responseMessage);
        }
      } else {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }
  deleteEmployee() {
    this.setState({ loading: true });
    var parameter = {
      EmployeeId: this.state.employee_id,
    };
    Api("user/deleteEmployee", "POST", parameter).then((resp) => {
      this.setState({ loading: false });

      try {
        if ((resp.status = 200)) {
          if (resp.data.responseCode == 200) {
            this.employeeList();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle dashboardSection">
            <div className="container">
              <div className="sectionHead d-flex appBox">
                <h4 className="header2">Salary</h4>
                <div className="minWidth100 pl10">
                  <select
                    className="form-control select-style1"
                    placeholder="Select Currency"
                  >
                    <option>Select Currency</option>
                  </select>
                </div>
              </div>

              <div>
                {/* <form className="main-form">
										<div className="d-flex align-items-center flex-wrap appBox">
											<div className="salryInp">
													<input type="text" className="form-control "  placeholder="Enter Name"  />
											</div>
											<div className="salryInp">
													<input type="text" className="form-control "  placeholder="Enter Id"  />
											</div>
											<div className="salryInp">
													<input type="text" className="form-control "  placeholder="Role"  />
											</div>
                                            <div className="salryInp">
													<input type="text" className="form-control "  placeholder="Amount"  />
											</div>
                                            <div className="salryInp">
													<input type="text" className="form-control "  placeholder="Wallet"  />
											</div>
                                           
											
											<div >
											<button className="btn btnGreen minWidth120">Add</button>
											</div>
										</div>
									</form>                       */}
              </div>
              <div className="tableBox">
                <div className="tableBoxHead d-flex align-items-center flex-wrap p0 mt45">
                  {/* <h4 className="mb15 header2">Your Advertisements</h4> */}
                  <button
                    className="btn btnGreen minWidth120 mb15 mr10"
                    onClick={() => this.setState({ addEmp: true })}
                  >
                    Add Employee
                  </button>
                  <button
                    className="btn btnGreen minWidth120 mb15 mr10"
                    onClick={() =>
                      this.props.history.push("/salary-transaction")
                    }
                  >
                    View Transaction
                  </button>
                  <div className="ml-auto salrytab">
                    <span className="dBlueColor font18 fontfamTM">
                      You are sending: 0.00034 BTC
                    </span>
                    <button className="btn btnGreen mb15">Send Btc</button>
                  </div>
                </div>
                <div
                  className="form-group mt20"
                  style={{ width: "25%", float: "right" }}
                >
                  <div className="input-per2">
                    <input
                      type="text"
                      maxLength="15"
                      className="form-control modalSearch"
                      placeholder="Search Employee"
                      name="employee"
                      value={this.state.employee}
                      onChange={(e) => {
                        this.handleInputs(e);
                        this.searchEmployee(e);
                      }}
                    />
                    <span>
                      <i className="fa fa-search" />
                    </span>
                  </div>
                </div>
                <div className="tableBoxBody">
                  <div className="table-responsive tbl">
                    <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                      <thead className="tbl_hd">
                        <tr>
                          <th>
                            <span>
                              <input type="checkbox" className="" /> All
                            </span>
                          </th>
                          <th>Name</th>
                          <th>Id</th>
                          <th>Role</th>
                          <th>Amount in FIAT</th>
                          <th>Amount in BTC</th>
                          <th>Wallet</th>
                          <th>Fees</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.employeeList.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  className="form-control"
                                />
                              </td>
                              <td>{item.empName}</td>
                              <td>{item.empId}</td>
                              <td>{item.role}</td>
                              <td>10000</td>
                              <td>0.00012</td>
                              <td>13113</td>
                              <td>212</td>
                              <td>
                                <span>
                                  <i
                                    className="fa fa-edit"
                                    style={{
                                      color: "#5ab6d2",
                                      marginRight: "8px",
                                    }}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "#ff8000" }}
                                    onClick={() =>
                                      this.setState({
                                        showModal: true,
                                        employee_id: item._id,
                                      })
                                    }
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={this.state.addEmp}
                fade={false}
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  margintop: "10%",
                  maxWidth: "950px",
                  margintop: "60px",
                }}
              >
                <div className="cardBox max-WT-950 center-box">
                  <div className="cardHead center-box pb10 text-center posRel">
                    <span
                      className="crossBtn"
                      onClick={() => this.setState({ addEmp: false })}
                    >
                      <i className="fa fa-times" />
                    </span>

                    <h4 className="font18 mt0 mb5 text-center">Add Employee</h4>
                  </div>
                  <form className="mb15">
                    <div className="formBox  max-WT-750 center-box pt30 pb20">
                      <h4 className="font16  mb15 fontfamTM dBlueColor">
                        Employee Details
                      </h4>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Employee Name"
                            name="name"
                            value={this.state.name}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Employee Id"
                            name="empId"
                            value={this.state.empId}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Role"
                            name="role"
                            value={this.state.role}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Total working days"
                            name="total_w_days"
                            value={this.state.total_w_days}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="LOP"
                            name="lop"
                            value={this.state.lop}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Paid days"
                            name="paid_days"
                            value={this.state.paid_days}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Wallet address"
                            name="paid_days"
                            value={this.state.paid_days}
                            onChange={(e) => this.handleInputs(e)}
                          />
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <span className="font16  mb15 fontfamTM dBlueColor">
                            Earnings
                          </span>
                          <select
                            className="form-control select-style1 radius-TL0 radius-BL0"
                            style={{
                              width: "100px",
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                            name="currency"
                            onChange={(e) => this.onClick_currency(e)}
                          >
                            <option>{this.state.currency}</option>
                            {this.Populate_currency()}
                          </select>
                        </div>
                      </div>

                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Basic Salary"
                          />
                        </div>
                        <div className="col-md-6">
                          {this.state.EarningsArr.map((item, index) => {
                            return (
                              <div
                                className="mb5 d-flex"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  className="form-control mr10"
                                  name="allowance"
                                  value={item.name}
                                  readOnly
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  name="amount"
                                  value={item.amount}
                                  readOnly
                                />
                                <button
                                  className="btn btnGreen ml10"
                                  onClick={(e) =>
                                    this.removeAllowance(e, index)
                                  }
                                >
                                  <i class="fa fa-minus" />
                                </button>
                              </div>
                            );
                          })}
                          <div className="mb5 d-flex" style={{ width: "100%" }}>
                            <input
                              type="text"
                              className="form-control mr10"
                              name="allowance"
                              value={this.state.allowance}
                              onChange={this.handleUserInput}
                              placeholder="Allowance Type"
                            />
                            <input
                              type="text"
                              className="form-control"
                              name="amount"
                              value={this.state.amount}
                              onChange={this.handleAmountInput}
                              placeholder="Amount"
                            />

                            <button
                              className="btn btnGreen ml10"
                              onClick={(e) => this.addAllowance(e)}
                            >
                              <i class="fa fa-plus" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <h4 className="font16  mb15 fontfamTM dBlueColor">
                        Deductions
                      </h4>
                      <div className="row mb15">
                        <div className="col-md-6 salrymar">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="EPF"
                          />
                        </div>
                        <div className="col-md-6">
                          {this.state.DeductionArr.map((item, index) => {
                            return (
                              <div
                                className="mb5 d-flex"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  className="form-control mr10"
                                  name="deduction"
                                  value={item.name}
                                  readOnly
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  name="d_amount"
                                  value={item.d_amount}
                                  readOnly
                                />
                                <button
                                  className="btn btnGreen ml10"
                                  onClick={(e) =>
                                    this.removeDeduction(e, index)
                                  }
                                >
                                  <i class="fa fa-minus" />
                                </button>
                              </div>
                            );
                          })}
                          <div className="mb5 d-flex" style={{ width: "100%" }}>
                            <input
                              type="text"
                              className="form-control mr10"
                              name="deduction"
                              value={this.state.deduction}
                              onChange={this.handlededuction}
                              placeholder="Deduction Type"
                            />
                            <input
                              type="text"
                              className="form-control"
                              name="d_amount"
                              value={this.state.d_amount}
                              onChange={this.handleAmount}
                              placeholder="Amount"
                            />

                            <button
                              className="btn btnGreen ml10"
                              onClick={(e) => this.addDeductions(e)}
                            >
                              <i class="fa fa-plus" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-md-6">
                          <h4 className="font16  mb15 fontfamTM dBlueColor">
                            Net Pay: 20,000
                          </h4>
                        </div>
                      </div>
                      <div className="row jusCentr">
                        <button
                          className="btn btnGreen ml10"
                          onClick={(e) => this.addEmployee(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal>
              <Modal isOpen={this.state.showModal}>
                <ModalBody>
                  <img
                    src={require("../assets/images/Alert.png")}
                    style={{ height: "75px", width: "75px" }}
                    className="logOut-img"
                    alt=""
                  />
                  <h5 className="mb30 mt30 font17">
                    Are you sure you want to remove employee?
                  </h5>
                  <button className="btn btnGreen max-WT-120 font16">
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btnRed max-WT-120 ml10 font16"
                    data-dismiss="modal"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    No
                  </button>
                </ModalBody>
              </Modal>
            </div>
          </section>
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
