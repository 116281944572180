// Modules
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  showbox,
  showBoxfun,
  TRADE,
  TRADEfun,
  IMAGE,
  IMAGEfun,
  NAME,
  NAMEfun,
  RECEIVER,
  RECEIVERfun,
} from "../services/globals";
//Components
import Header from "../components/header";
import Recaptcha from "react-recaptcha";
import Footer from "../components/footer";
import {
  validateDoc,
  validatePassword,
  validateEmail,
  validateName,
  validateMobileNo,
  validateZip,
  validateCity,
  validateAddress,
  validateName1,
} from "../utils/validation";
import { Api } from "../services/webservices";
import _country from "../JSON/country.json";
import timeZone from "../JSON/timeZone.json";
import { ReactModal } from "../services/globals";
import identity_doc from "../JSON/Identity.json";
import { toast_func } from "../components/toaster";
import { ClipLoader } from "react-spinners";
import { moment } from "moment-timezone";
import { changeUTCTime } from "../components/convertTimeToUTC";
import Slider from "react-slick";

export default class editProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "basic_Info",
      newPassword: "",
      newPasswordStatus: false,
      newPassword1: "",
      newPasswordStatus1: false,
      oldPassword: "",
      _id: "",
      email_newEmail: "",
      email_newEmailStatus: "",
      email_newEmail1Status: "",
      introduction: "",
      email_newEmail1: "",
      email_oldPassword: "",
      _nameStatus: false,
      show_real_name: false,
      disable_info_from_Email: false,
      enable_web_notification: false,
      sell_vacation: false,
      buy_vacation: false,
      sms_notification_payment: false,
      sms_notification_trade: false,
      sms_notification_escrow: false,
      delete_password: "",
      zoneCode: "",
      phoneOTP: "",
      phoneOTPStatus: false,
      phoneCode: "",
      modalIsOpen: false,
      verified_email: false,
      verified_phone: false,
      first_name: "",
      first_nameError: "",
      first_nameStatus: false,
      last_name: "",
      last_nameError: "",
      last_nameStatus: false,
      post_code: "",
      post_codeError: "",
      post_codeStatus: false,
      date_of_birth: "",
      date_of_birth_Error: "",
      city: "",
      cityError: "",
      address: "",
      AddressError: "",
      country: "",
      identity_doc: "",
      file_data: "",
      getBase64Data: "",
      loading: false,
      verificationModal: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp1Status: false,
      otp2Status: false,
      otp3Status: false,
      otp4Status: false,
      isVerified: false,
      isVerifiedError: "",
      emailError: "",
      passwordError: "",
      newPasswordError: "",
      newPasswordError1: "",
      oldPasswordError: "",
      phoneOTPError: "",
      OTP_dropdownaError: "",
      countryError: "",
      name: "",
      nameError: "",
      realNameStatus: false,
      deleteAccountError: "",
      basicError: "",
      identity_docError: "",
      bse64data: "",
      bse64data1: "",
      file_dataError: "",
      token: "",
      showFile1: "",
      showFile2: "",
      showFile3: "",

      time_zone: "",
      cityStatus: false,
      files: "",
      account_security: "",
      address: "",
      showFile1Status: false,
      showFile2Status: false,
      showFile3Status: false,
      doc_id: "",
      doc_idError: "",
      doc_idStatus: false,
      showFile11: "",
      showFile22: "",
      showFile33: "",
      KYCStatus: "",
      countryCodeName: "",
      _countryCode: "",
      showFile4: "",
      utc: "",
    };
    this.getBase64 = this.getBase64.bind(this);
  }
  componentDidMount() {
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      this.setState({ _id: id, token: token, addr: addr }, () =>
        this._loadData()
      );
    }, 300);

    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          $("#blah")
            .attr("src", e.target.result)
            .width(150)
            .height(200);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    $(function() {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var maxDate = year + "-" + month + "-" + day;
      // alert(maxDate);
      $("#txtDate").attr("max", maxDate);
    });

    if ($(window).width() > 767) {
      var headerHeight = $("header").outerHeight();
      var fixedBoxHeight = $(".fixedBox").outerHeight();
      console.log("headerHeight=====>", headerHeight);
      console.log("fixedBoxHeight=====>", fixedBoxHeight);
      var setScrollTabPos = fixedBoxHeight - 60;
      $(".scrollTabBox").css({ "margin-top": setScrollTabPos });
    }

    /* ==================Scroll Effect============================ */
    var lastId,
      topMenu = $(".editProfileSection  .globalTab"),
      topMenuHeight = topMenu.outerHeight() + 240,
      // All list items
      menuItems = topMenu.find("a"),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function() {
        var item = $($(this).attr("href"));
        if (item.length) {
          return item;
        }
      });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.click(function(e) {
      var href = $(this).attr("href");

      $(this)
        .addClass("active")
        .parent("li.nav-item")
        .siblings()
        .find("a")
        .removeClass("active");
      if ($(window).width() > 767) {
        var offsetTop = href === "#" ? 0 : $(href).offset().top - 320;
      }
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: offsetTop,
          },
          1000
        );
      e.preventDefault();
    });

    // Bind to scroll
    $(window).scroll(function() {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;

      // Get id of current scroll item
      var cur = scrollItems.map(function() {
        if ($(this).offset().top < fromTop) return this;
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : "";

      if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems
          .parent()
          .removeClass("active")
          .end()
          .filter("[href='#" + id + "']")
          .parent()
          .addClass("active")
          .siblings()
          .find("a")
          .removeClass("active");
      }
    });
  }
  stateApis = () => {
    return;
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };

  Populate_codes = () => {
    // comsole.log("timetttt>>>",_country[i].text)
    let __array = [];
    for (var i = 0; i < timeZone.length; i++) {
      __array.push(<option key={i}>{timeZone[i].text}</option>);
    }
    return __array;
  };

  Populate_codes_1 = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(
        <option key={i}>
          {_country[i].name + "      (" + _country[i].dial_code + ")"}
        </option>
      );
    }
    return __array;
  };
  Populate_codes_2 = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option>{_country[i].name}</option>);
    }
    return __array;
  };
  Populate_codes2 = () => {
    // comsole.log("timetttt>>>",_country[i].text)
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option key={i}>{_country[i].name}</option>);
    }
    return __array;
  };
  Populate_codes3 = () => {
    // comsole.log("timetttt>>>",_country[i].text)
    let __array = [];
    for (var i = 0; i < identity_doc.length; i++) {
      __array.push(<option key={i}>{identity_doc[i].name}</option>);
    }
    return __array;
  };
  _loadData() {
    this.setState({ loading: true });

    var parameter = {
      _id: this.state._id,
    };
    try {
      Api(
        "user/userProfile",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        try {
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              this.setState({ result: resp });
              var _values = resp.data.result;

              if (_values.loginGuard && _values.two_factor_auth) {
                this.setState({ account_security: "Strong" });
              } else if (!_values.loginGuard && !_values.two_factor_auth) {
                this.setState({ account_security: "Weak" });
              } else {
                this.setState({ account_security: "Moderate" });
              }

              if (_values.kyc_docs.length > 0) {
                var step = _values.kyc_docs.length;
                this.setState({
                  KYCStatus: _values.kyc_docs[step - 1].kyc_status,
                  identity_doc: _values.kyc_docs[step - 1].user_doc_name,
                  showFile11: _values.kyc_docs[step - 1].frontView,
                  showFile22: _values.kyc_docs[step - 1].backView,
                  showFile33: _values.kyc_docs[step - 1].bothView,
                  doc_id: _values.kyc_docs[step - 1].user_doc_id,
                });
              }
              if (
                _values.phone_number == null ||
                _values.phone_number == "" ||
                _values.phone_number == undefined
              ) {
                this.setState({
                  phoneOTP: "",
                  phoneCode: "",
                });
              } else {
                this.setState({
                  phoneOTP: _values.phone_number,
                  phoneCode: _values.country_code,
                });
              }

              if (_values.country_code != "") {
                this.setState({ countryCode: _values.country_code });
              }
              if (_values.first_name) {
                this.setState({ first_name: _values.first_name });
              }
              if (_values.last_name) {
                this.setState({ last_name: _values.last_name });
              }
              if (_values.date_of_birth) {
                this.setState({ date_of_birth: _values.date_of_birth });
              }
              if (_values.country) {
                this.setState({ country: _values.country });
              }
              if (_values.city) {
                this.setState({ city: _values.city });
              }
              if (_values.address) {
                this.setState({ address: _values.address });
              }
              if (_values.post_code) {
                this.setState({ post_code: _values.post_code });
              }
              if (_values.files) {
                this.setState({ files: _values.files });
              }
              if (_values.name) {
                this.setState({ name: _values.name });
              }
              if (_values.verified_phone) {
                this.setState({ verified_phone: _values.verified_phone });
              }
              if (_values.name) {
                this.setState({ name: _values.name });
              }
              if (_values.verified_email) {
                this.setState({ verified_email: _values.verified_email });
              }
              if (_values.time_zone) {
                this.setState({ zoneCode: _values.time_zone });
              }
              if (_values.sms_notification_trade) {
                this.setState({
                  sms_notification_trade: _values.sms_notification_trade,
                });
              }
              if (_values.introduction) {
                this.setState({ introduction: _values.introduction });
              }
              if (_values.enable_web_notification) {
                this.setState({
                  enable_web_notification: _values.enable_web_notification,
                });
              }
              if (_values.disable_info_from_Email) {
                this.setState({
                  disable_info_from_Email: _values.disable_info_from_Email,
                });
              }
              if (_values.buy_vacation) {
                this.setState({ buy_vacation: _values.buy_vacation });
              }
              if (_values.sms_notification_escrow) {
                this.setState({
                  sms_notification_escrow: _values.sms_notification_escrow,
                });
              }
              if (_values.sms_notification_payment) {
                this.setState({
                  sms_notification_payment: _values.sms_notification_payment,
                });
              }
              if (_values.show_real_name) {
                this.setState({ show_real_name: _values.show_real_name });
              }
              if (_values.sell_vacation) {
                this.setState({ sell_vacation: _values.sell_vacation });
              }
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    } catch (ex) {
      toast_func("error", "Some problem occured please contact administrator");
    }
    this.stateApis();
  }
  resentOTP = () => {
    // alert(">"+this.state.countryCode+"<")

    if (validateMobileNo(this.state.phoneOTP).status) {
      this.setState({ phoneOTPStatus: true, phoneOTPError: "" });
    } else
      this.setState({
        phoneOTPStatus: false,
        phoneOTPError: validateMobileNo(this.state.phoneOTP).error,
      });

    if (
      this.state.countryCode == "Select by Country Code" ||
      this.state.countryCode == "" ||
      this.state.countryCode == undefined
    ) {
      this.setState({ countryCodeError: "Please select country code" });
    } else {
      this.setState({ countryCodeError: "" });
    }

    if (this.state.countryCodeError == "" && this.state.phoneOTPStatus) {
      var parameter = {
        _id: this.state._id,
        country_code: this.state._countryCode,
        phone_number: this.state.phoneOTP,
      };
      this.setState({ loading: true });
      try {
        Api(
          "user/sent_phone_otp1",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });

          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              localStorage.removeItem("isLoggedin");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              // toast_func("success", resp.data.responseMessage);
              this.setState({ modalIsOpen: true });
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Some problem occured please contact administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    }
  };
  verify1 = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;

    //this.setState({ [type]: value });
    setTimeout(() => {
      if (type == "file_data4") {
        let _file_data = $(`#file_data4`).prop("files")[0];
        this.getBase64(_file_data, "file_data4");
        let fileName = value.split(/(\\|\/)/g).pop();

        this.setState({
          [type]: value,
          showFile4: fileName,
          showFile4Status: true,
          profilepicError: "",
        });
      }
    }, 200);
  };
  verify = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    let value1 = value.split("");

    if (type == "first_name") {
      if (value !== " " && value1[value1.length - 1] !== " ") {
        this.setState({ [type]: value }, () => {
          this.verify3(type, value);
        });
      }
    } else if (type == "last_name") {
      if (value !== " " && value1[value1.length - 1] !== " ") {
        this.setState({ [type]: value }, () => {
          this.verify3(type, value);
        });
      }
    } else if (type == "city") {
      if (
        value == "" ||
        (value1[0] !== " " &&
          (isNaN(value1[value1.length - 1]) ||
            value1[value1.length - 1] == " "))
      ) {
        //
        this.setState({ [type]: value }, () => {
          this.verify3(type, value);
        });
      }
    } else if (type == "doc_id") {
      if (value == "" || value1[value1.length - 1] !== " ") {
        //
        this.setState({ [type]: value }, () => {
          this.verify3(type, value);
        });
      }
    } else {
      this.setState({ [type]: value }, () => {
        this.verify3(type, value);
      });
    }

    //  this.setState({ [type]: value });
  };

  getBase64(file, type) {
    let self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      let bse64data1 = reader.result.substr(reader.result).split(",")[0];
      let bse64data = reader.result.substr(reader.result.indexOf(",") + 1);
      let full_image = bse64data1 + bse64data;
      if (type == "file_data1") {
        self.setState({ getBase64Data: bse64data });
        self.setState({
          files1: bse64data1 + "," + bse64data,
          img1: full_image,
        });
      } else if (type == "file_data2") {
        self.setState({ getBase64Data: bse64data });
        self.setState({
          files2: bse64data1 + "," + bse64data,
          img2: full_image,
        });
      } else if (type == "file_data3") {
        self.setState({ getBase64Data: bse64data });
        self.setState({
          files3: bse64data1 + "," + bse64data,
          img3: full_image,
        });
      } else if (type == "file_data4") {
        self.setState({ getBase64Data: bse64data });
        self.setState({
          files4: bse64data1 + "," + bse64data,
          img4: full_image,
        });
      }
      //self.setState({ file_dataError: '' })
    };
    reader.onerror = function(error) {
      // display toaster
    };
  }

  userDetails = (e) => {
    e.preventDefault();

    if (validateName(this.state.first_name).status) {
      this.setState({ first_nameStatus: true, first_nameError: "" });
    } else
      this.setState({
        first_nameStatus: false,
        first_nameError: validateName(this.state.first_name).error,
      });

    if (validateName(this.state.last_name).status) {
      this.setState({ last_nameStatus: true, last_nameError: "" });
    } else
      this.setState({
        last_nameStatus: false,
        last_nameError: validateName(this.state.last_name).error,
      });

    if (validateZip(this.state.post_code).status) {
      this.setState({ post_codeStatus: true, post_codeError: "" });
    } else
      this.setState({
        post_codeStatus: false,
        post_codeError: validateZip(this.state.post_code).error,
      });

    if (this.state.address == "" || this.state.address == undefined) {
      this.setState({ AddressError: "*Please enter address" });
    } else {
      this.setState({ AddressError: "" });
    }

    if (this.state.city == "" || this.state.city == undefined) {
      this.setState({ cityError: "*Please enter city" });
    } else {
      this.setState({ cityError: "" });
    }

    if (
      this.state.date_of_birth == "" ||
      this.state.date_of_birth == undefined
    ) {
      this.setState({ date_of_birth_Error: "*Please enter date of birth" });
    } else {
      this.setState({ date_of_birth_Error: "" });
    }
    if (
      this.state.country == "Select" ||
      this.state.country == "" ||
      this.state.country == undefined
    ) {
      this.setState({ countryError: "*Please select country" });
    } else {
      this.setState({ countryError: "" });
    }

    if (
      this.state.identity_doc == "Select" ||
      this.state.identity_doc == "" ||
      this.state.identity_doc == undefined
    ) {
      this.setState({ identity_docError: "*Please select Identity document" });
    } else {
      this.setState({ identity_docError: "" });
    }

    if (!this.state.showFile1Status) {
      this.setState({ file_dataError1: "*Please upload file " });
    } else {
      this.setState({ file_dataError1: "" });
    }

    if (!this.state.showFile2Status) {
      this.setState({ file_dataError2: "*Please upload file " });
    } else {
      this.setState({ file_dataError2: "" });
    }

    if (!this.state.showFile3Status) {
      this.setState({ file_dataError3: "*Please upload file " });
    } else {
      this.setState({ file_dataError3: "" });
    }

    if (validateDoc(this.state.doc_id).status) {
      this.setState({ doc_idStatus: true, doc_idError: "" });
    } else
      this.setState({
        doc_idStatus: false,
        doc_idError: validateDoc(this.state.doc_id).error,
      });

    // if (type == 'doc_id') {
    //     if (validateDoc(this.state.doc_id).status) {
    //         this.setState({doc_idStatus:true, doc_idError: "" })
    //     } else this.setState({doc_idStatus:false, doc_idError: validateDoc(this.state.doc_id).error })
    // }
    // if (this.state.file_data == "") {
    //     this.setState({ file_dataError: "*Please attach file" })
    // }
    // else {
    //     this.setState({ file_dataError: "" })
    // }
    setTimeout(() => {
      if (
        this.state.doc_idStatus &&
        this.state.identity_docError == "" &&
        this.state.countryError == "" &&
        this.state.date_of_birth_Error == "" &&
        this.state.cityError == "" &&
        this.state.AddressError == "" &&
        this.state.post_codeStatus &&
        this.state.first_nameStatus &&
        this.state.showFile1Status &&
        this.state.showFile2Status &&
        this.state.showFile3Status
      ) {
        this.profileUpdate();
      }
    }, 100);
  };
  profileUpdate = () => {
    this.setState({ loading: true });
    var parameter = {
      _id: this.state._id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      country: this.state.country,
      date_of_birth: this.state.date_of_birth,
      address: this.state.address,
      city: this.state.city,
      post_code: this.state.post_code,
      doc_name: this.state.identity_doc,
      frontView: this.state.files1,
      backView: this.state.files2,
      bothView: this.state.files3,
      doc_id: this.state.doc_id,
    };
    try {
      Api(
        "user/uploadKyc",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
        }
      });
    } catch (ex) {
      toast_func("error", "some problem occured please contact administrator");
    }
  };
  changeCheckBox = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    var value1 = $.parseJSON(value);
    this.setState({ [type]: !value1 });
  };
  phoneVerify = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    let value1 = value.split("");
    // if(!isNaN(value))

    if (value !== " " && !isNaN(value) && value1.length < 14) {
      this.setState({ phoneOTP: value }, () => this.verify2(type, value));
    }
  };
  verify2 = (type, value) => {
    if (type == "phoneVerify") {
      if (validateMobileNo(this.state.phoneOTP).status) {
        this.setState({ phoneOTPStatus: true, phoneOTPError: "" });
      } else
        this.setState({
          phoneOTPStatus: false,
          phoneOTPError: validateMobileNo(this.state.phoneOTP).error,
        });
    }
  };
  _introduction = (e) => {
    let type = e.target.name;
    let value = e.target.value;

    this.setState({ [type]: value });
  };
  realName_handleUserInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let value1 = value.split("");
    if (
      (value1[0] !== " " &&
        (isNaN(value1[value1.length - 1]) ||
          value1[value1.length - 1] == " ")) ||
      value == ""
    ) {
      this.setState({ [name]: value }, () => this.validate(name, value));
    }
  };
  validate = (name, value) => {
    if (name == "name") {
      if (validateName(this.state.name).status) {
        this.setState({ nameError: "" });
      } else this.setState({ nameError: validateName1(this.state.name).error });
    }
  };
  _realNameSet = (e) => {
    e.preventDefault();

    if (validateName(this.state.name).status) {
      this.setState({ nameError: "" });
    } else this.setState({ nameError: validateName(this.state.name).error });

    if (this.state.nameError == "" && this.state.name != undefined) {
      this.realName_update(e);
    }
  };
  _search = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ zoneCode: value, basicError: "" });
    localStorage.setItem("TimeZone", value);

    let _data = timeZone.findIndex((data) => data.text == value);

    let _utc = timeZone[_data].utc[0];
    this.setState({ utc: _utc });

    // }
    var getTimeZone = localStorage.getItem("TimeZone");
  };
  _search_1 = (e) => {
    e.preventDefault();
    var _value = e.target.value;
    var _index = _country.findIndex((x) => x.dial_code == value);

    // var value = value.substr(1, value.indexOf(' '));
    // var value=value.trim()
    var value = _value.split(" ");
    var _length = value.length;
    var _newvar = value[_length - 1];
    var _newvar1 = _newvar.trim();
    var _newvar2 = _newvar1.slice(1, _newvar1.length - 1);

    this.setState({
      _countryCode: _newvar2,
      countryCode: _value,
      countryCodeError: "",
    });
    // this.setState({ countryCode: value, countryCodeError: "", countryCodeName: _country[_index].name })
  };
  _search_2 = (e) => {
    e.preventDefault();
    var value = e.target.value;
    var _index = _country.findIndex((x) => x.name == value);
    //var value = value.substr(1, value.indexOf(' '));
    this.setState({
      countryCodeName: value,
      countryCodeError: "",
      countryCode: _country[_index].dial_code,
    });
  };
  _search2 = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ country: value, countryError: "" });
  };
  _search3 = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ identity_doc: value, identity_docError: "" });
  };

  realName_update = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.name !== undefined) {
      var parameter = {
        _id: this.state._id,
        name: this.state.name.trim(),
      };

      this.setState({ loading: true });
      try {
        Api(
          "user/updateUserInfo",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
            } else toast_func("error", resp.data.responseMessage);
          } else toast_func("error", resp.data.responseMessage);
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  };
  email_handleUserInput = (e) => {
    e.preventDefault();

    let type = e.target.name;
    let value = e.target.value;
    this.setState({ [type]: value });
    setTimeout(() => {
      if (type == "email_newEmail") {
        if (validateEmail(this.state.email_newEmail).status) {
          this.setState({ email_newEmailStatus: true, emailError: "" });
        } else
          this.setState({
            emailError: validateEmail(this.state.email_newEmail).error,
            email_newEmailStatus: false,
          });
      } else if (type == "email_oldPassword") {
        if (this.state.email_oldPassword == "") {
          this.setState({
            passwordError: validatePassword(this.state.email_oldPassword).error,
          });
        } else {
          this.setState({ passwordError: "" });
        }
      }
    }, 100);
  };
  recapctchaLoaded() {}
  submitOtp(e) {
    e.preventDefault();
    let userId = localStorage.getItem("data");
    // alert(userId)
    if (
      this.state.otp1Status &&
      this.state.otp2Status &&
      this.state.otp3Status &&
      this.state.otp4Status
    ) {
      this.setState({ loading: true });
      var parameter = {
        _id: userId,
        country_code: this.state._countryCode,
        phone_number: this.state.phoneOTP,
        phoneOtp:
          this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4,
      };
      try {
        Api(
          "user/verify1",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              localStorage.removeItem("isLoggedin");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              this.setState({ otp1: "", otp2: "", otp3: "", otp4: "" });

              this.setState({ modalIsOpen: false }, () =>
                this.componentDidMount()
              );
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    } else {
      toast_func("error", "Please enter OTP");
    }
  }
  email_change = (e) => {
    e.preventDefault();

    if (this.state.email_oldPassword == "") {
      this.setState({ passwordError: "*Please enter password" });
    }
    if (validateEmail(this.state.email_newEmail).status) {
      this.setState({ email_newEmailStatus: true, emailError: "" });
    } else
      this.setState({
        emailError: validateEmail(this.state.email_newEmail).error,
        email_newEmailStatus: false,
      });

    if (this.state.isVerified == false) {
      this.setState({ isVerifiedError: "*Verification failed" });
    } else {
      this.setState({ isVerifiedError: "" });
    }

    if (
      this.state.email_newEmailStatus &&
      this.state.email_oldPassword != "" &&
      this.state.isVerified
    ) {
      this.setState({ loading: true });
      var parameter = {
        _id: this.state._id,
        email: this.state.email_newEmail,
        password: this.state.email_oldPassword,
      };
      try {
        Api(
          "user/changeEmail",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });

          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 205) {
              toast_func("success", resp.data.responseMessage);
              // this.setState({email_newEmail:"",email_oldPassword:""})
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              setTimeout(() => {
                this.props.history.push("/login");
              }, 2500);
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Unable to reach server please contact administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  };
  _setRealName = (e) => {
    e.preventDefault();
    if (this.state.name == "") {
      this.setState({ nameError: "*Please enter the name" });
      return;
    }
    this.setState({ loading: true });
    var parameter = {
      _id: this.state._id,
      real_name: this.state.name,
    };
    try {
      Api(
        "user/updateUserInfo ",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Unable to reach server please contact administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  };
  deleteAccount = () => {
    if (this.state.delete_password == "") {
      this.setState({ deleteAccountError: "*Please enter password" });
    } else {
      this.setState({ deleteAccountError: "" });
    }
    if (this.state.delete_password != "") {
      this.setState({ loading: true });
      var parameter = {
        _id: this.state._id,
        password: this.state.delete_password,
      };
      try {
        Api(
          "user/account_deletion_request ",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              localStorage.removeItem("isLoggedin");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Unable to reach server please contact administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  };
  delete_password = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    this.setState({ [type]: value }, () => this.check());
  };
  check = () => {
    if (this.state.delete_password != "") {
      this.setState({ deleteAccountError: "" });
    } else {
      this.setState({ deleteAccountError: "*Please enter password" });
    }
  };
  handleUserInput = (e) => {
    let type = e.target.name;
    let value = e.target.value;

    if (type == "newPassword") {
      if (value !== " ") {
        this.setState({ [type]: value }, () => {
          this.verify3(type, value);
        });
      }
    } else {
      this.setState({ [type]: value }, () => {
        this.verify3(type, value);
      });
    }
  };
  verify3 = (type, value) => {
    if (type == "newPassword") {
      if (validatePassword(this.state.newPassword).status) {
        if (this.state.newPassword == this.state.oldPassword) {
          this.setState({
            newPasswordStatus: false,
            newPasswordError: "*Old and new password can not be same.",
          });
          return;
        }

        this.setState({ newPasswordStatus: true, newPasswordError: "" });
      } else
        this.setState({
          newPasswordStatus: false,
          newPasswordError: validatePassword(this.state.newPassword).error,
        });
    } else if (type == "newPassword1") {
      if (this.state.newPassword == this.state.newPassword1) {
        this.setState({ newPasswordStatus1: true, newPasswordError1: "" });
      } else
        this.setState({
          newPasswordStatus1: false,
          newPasswordError1: "*Confirm password and new password must be same.",
        });
    } else if (type == "first_name") {
      if (validateName(this.state.first_name).status) {
        this.setState({ first_nameStatus: true, first_nameError: "" });
      } else
        this.setState({
          first_nameStatus: false,
          first_nameError: validateName(this.state.first_name).error,
        });
    } else if (type == "last_name") {
      if (validateName(this.state.last_name).status) {
        this.setState({ last_nameStatus: true, last_nameError: "" });
      } else
        this.setState({
          last_nameStatus: false,
          last_nameError: validateName(this.state.last_name).error,
        });
    } else if (type == "post_code") {
      if (validateZip(this.state.post_code).status) {
        this.setState({ post_codeStatus: true, post_codeError: "" });
      } else
        this.setState({
          post_codeStatus: false,
          post_codeError: validateZip(this.state.post_code).error,
        });
    } else if (type == "city") {
      if (validateCity(this.state.city).status) {
        this.setState({ cityStatus: true, cityError: "" });
      } else
        this.setState({
          cityStatus: false,
          cityError: validateCity(this.state.city).error,
        });
    } else if (type == "date_of_birth") {
      if (this.state.date_of_birth != "") {
        this.setState({ date_of_birth_Error: "" });
      } else
        this.setState({ date_of_birth_Error: "Please enter Date of Birth" });
    } else if (type == "doc_id") {
      if (validateDoc(this.state.doc_id).status) {
        this.setState({ doc_idError: "", doc_idStatus: true });
      } else
        this.setState({
          doc_idStatus: false,
          doc_idError: validateDoc(this.state.doc_id).error,
        });
    } else if (type == "address") {
      if (this.state.address == "") {
        this.setState({ AddressError: "*Please enter address" });
      } else {
        this.setState({ AddressError: "" });
      }
    }

    // for attach file
    else if (type == "file_data1") {
      let _file_data = $(`#file_data1`).prop("files")[0];
      this.getBase64(_file_data, "file_data1");
      let fileName = value.split(/(\\|\/)/g).pop();

      this.setState({
        [type]: value,
        showFile1: fileName,
        showFile1Status: true,
        file_dataError1: "",
      });
    } else if (type == "file_data2") {
      let _file_data = $(`#file_data2`).prop("files")[0];
      this.getBase64(_file_data, "file_data2");
      let fileName = value.split(/(\\|\/)/g).pop();

      this.setState({
        [type]: value,
        showFile2: fileName,
        showFile2Status: true,
        file_dataError2: "",
      });
    } else if (type == "file_data3") {
      let _file_data = $(`#file_data3`).prop("files")[0];
      this.getBase64(_file_data, "file_data3");
      let fileName = value.split(/(\\|\/)/g).pop();

      this.setState({
        [type]: value,
        showFile3: fileName,
        showFile3Status: true,
        file_dataError3: "",
      });
    }
  };
  handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "otp1") {
      if (!this.state.otp1Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: true });
          this.secoundInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp1Status: false });
        }
      }
    } else if (name == "otp2") {
      if (!this.state.otp2Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: true });
          this.thirdInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp2Status: false });
        }
      }
    } else if (name == "otp3") {
      if (!this.state.otp3Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: true });
          this.fourthInput.focus();
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp3Status: false });
        }
      }
    } else if (name == "otp4") {
      if (!this.state.otp4Status) {
        if (!isNaN(value) && value != " ") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: true });
        }
      } else {
        if (value == "") {
          this.setState({ [name]: value });
          this.setState({ otp4Status: false });
        }
      }
    }
  };
  profilePicture = (e) => {
    e.preventDefault();
    if (!this.state.showFile4Status) {
      this.setState({ profilepicError: "Please select the image first" });
      return;
    }

    var parameter = {
      userId: this.state._id,
      image: this.state.files4,
    };
    this.setState({ loading: true });

    Api(
      "user/updateProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      this.setState({ loading: false });
      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            window.location.reload();
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Unable to reach server please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    });
  };
  _userInfo = () => {
    if (
      this.state.zoneCode == "Select Time Zone" ||
      this.state.zoneCode == "" ||
      this.state.zoneCode == undefined
    ) {
      this.setState({ basicError: "Please select time zone above." });
    } else {
      this.setState({ basicError: "" });
    }

    if (
      this.state.zoneCode == "Select Time Zone" ||
      this.state.zoneCode == "" ||
      this.state.zoneCode == undefined
    ) {
    } else {
      this.setState({ loading: true });
      var parameter = {
        _id: this.state._id,
        show_real_name: this.state.show_real_name,
        disable_info_from_Email: this.state.disable_info_from_Email,
        enable_web_notification: this.state.enable_web_notification,
        sell_vacation: this.state.sell_vacation,
        buy_vacation: this.state.buy_vacation,
        sms_notification_payment: this.state.sms_notification_payment,
        sms_notification_trade: this.state.sms_notification_trade,
        sms_notification_escrow: this.state.sms_notification_escrow,
        introduction: this.state.introduction,
        time_zone: this.state.zoneCode,
        utc: this.state.utc,
      };
      try {
        Api(
          "user/updateUserInfo",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              localStorage.setItem("_utc_", this.state.utc);
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Unable to reach server please contact administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  };

  _forgotPassword(e) {
    e.preventDefault();

    if (this.state.oldPassword == "") {
      this.setState({ oldPasswordError: "*Please enter old password" });
    }

    if (validatePassword(this.state.newPassword).status) {
      this.setState({ newPasswordStatus: true, newPasswordError: "" });
    } else
      this.setState({
        newPasswordStatus: false,
        newPasswordError: validatePassword(this.state.newPassword).error,
      });

    if (this.state.newPassword == this.state.newPassword1) {
      this.setState({ newPasswordStatus1: true, newPasswordError1: "" });
    } else
      this.setState({
        newPasswordStatus1: false,
        newPasswordError1: "*Confirm password must be same",
      });

    if (
      this.state.newPasswordStatus &&
      this.state.newPasswordStatus1 &&
      this.state.oldPassword != ""
    ) {
      this.setState({ loading: true });
      var parameter = {
        _id: this.state._id,
        password: this.state.oldPassword,
        newPassword: this.state.newPassword,
      };
      try {
        Api(
          "user/updatePassword1",
          "POST",
          parameter,
          this.state.token,
          this.state._id
        ).then((resp) => {
          this.setState({ loading: false });
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              toast_func("success", resp.data.responseMessage);
              this.setState({
                oldPassword: "",
                newPassword: "",
                newPassword1: "",
              });
            } else {
              toast_func("error", resp.data.responseMessage);
            }
          } else {
            toast_func(
              "error",
              "Unable to reach server please contact administrator"
            );
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    }
  }
  conso() {}
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
    }
  };
  render() {
    const settings = {
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      // arrows: true,
      infinite: true,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        {/*                
                <ReactModal 
                            
                            visible={this.state.modalIsOpen}>
                                       
                                       <div id="verificationModal" tabindex="-1" role="dialog" >
                                           <div className="modal-dialog max-WT-500" role="document">
                                               <form className="mb0">
                                                   <div className="modal-content">
                                                       <div className="modal-header">
                                                           <h4 className="modal-title">Phone number verification</h4>
                                                           <button onClick={() => this.setState({ modalIsOpen: false })} type="button" className="close" data-dismiss="modal">&times;</button>
                                                       </div>
                                                       <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                                                           <div className="w-100">
                                                               <div className="sectionHead text-center">
                                                                   <h2>Enter Verification Code</h2>
                                                                   <p>A text message with code was sent to your phone</p>
                                                               </div>
                                                               <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                                                                   <input type="text" name="otp1" value={this.state.otp1} ref={(secondIndex) => this.firstInput = secondIndex} onChange={this.handleOtp} className="form-control radius6 text-center" />
                                                                   <input type="text" name="otp2" value={this.state.otp2} ref={(thirdIndex) => this.secoundInput = thirdIndex} onChange={this.handleOtp} className="form-control radius6 text-center" />
                                                                   <input type="text" name="otp3" value={this.state.otp3} ref={(fourthIndex) => this.thirdInput = fourthIndex} onChange={this.handleOtp} className="form-control radius6 text-center" />
                                                                   <input type="text" name="otp4" value={this.state.otp4} ref={(fifthIndex) => this.fourthInput = fifthIndex} onChange={this.handleOtp} className="form-control radius6 text-center" />
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div className="modal-footer">
                                                           <div className="pt10 borderTop w-100 text-right">
                                                               <button type="submit" onClick={(e) => this.submitOtp(e)} className="btn btnGreen max-WT-120 mr15">SUBMIT</button>
                                                               <button type="button" onClick={this.resentOTP} className="btn btnGreen max-WT-120 ml5">RESEND</button>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </form>
                                           </div>
                                       </div>
                                   </ReactModal> */}
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}

        <main className="mainContent">
          <section className="editProfileSection commonSectionStyle">
            <div className="container1440 max-WT-1200">
              <div className="fixedBox">
                <div
                  className="mb15 pRelative"
                  style={{ backgroundColor: "var(--white-color)" }}
                >
                  <h4 className="header2">Edit Profile</h4>
                  <p className="mt0" style={{ color: "var(--text-grey)" }}>
                    On this page you can view and manage your profile
                  </p>
                  {/* <span className="editSetting hideLarge"><i className="fas fa-cog"></i></span> */}
                </div>
                {/* <ul className="nav nav-tabs globalTab w-100  mb10" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section1" onClick={() => this.setState({ tab: "basic_info" })}>Basic User Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link"  href="#section2" onClick={() => this.setState({ tab: "change_password" })}>Change Password</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section3" onClick={() => this.setState({ tab: "change_email" })}>Change Email address</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section4" onClick={() => this.setState({ tab: "verification" })}>Verification</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section5" onClick={() => this.setState({ tab: "set_name" })}>Set Real Name</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section6" onClick={() => this.setState({ tab: "personal_info" })}>Show Personal Data</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section7" onClick={() => this.setState({ tab: "security" })}>Security</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#section8" onClick={() => this.setState({ tab: "account_details" })}>Account Deletion</a>
                                    </li>
                                </ul> */}
                <Slider {...settings} className="globalTab">
                  <a href="#section1" className="editLink">
                    <div
                      onClick={() =>
                        this.setState({
                          tab: "basic_info",
                        })
                      }
                      className={`${
                        this.state.tab === "basic_info"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                    >
                      <span>Basic User Information </span>
                    </div>
                  </a>
                  <a href="#section2" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "change_password"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "change_password" })}
                    >
                      <span>Change Password</span>
                    </div>
                  </a>
                  <a href="#section3" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "change_email"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "change_email" })}
                    >
                      <span>Change Email address</span>
                    </div>
                  </a>
                  <a href="#section4" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "verification"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "verification" })}
                    >
                      <span>Verification</span>
                    </div>
                  </a>

                  <a href="#section5" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "set_name"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "set_name" })}
                    >
                      <span>Set Real Name </span>
                    </div>
                  </a>
                  <a href="#section6" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "personal_info"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "personal_info" })}
                    >
                      <span>Show Personal Data</span>
                    </div>
                  </a>
                  <a href="#section7" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "security"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "security" })}
                    >
                      <span>Security</span>
                    </div>
                  </a>
                  <a href="#section8" className="editLink">
                    <div
                      className={`${
                        this.state.tab === "account_details"
                          ? "editTabsActive"
                          : "editProTabs"
                      }`}
                      onClick={() => this.setState({ tab: "account_details" })}
                    >
                      <span>Account Deletion</span>
                    </div>
                  </a>
                </Slider>
              </div>
              <Modal isOpen={this.state.modalIsOpen}>
                <ModalBody>
                  <div id="verificationModal" tabindex="-1" role="dialog">
                    <div className="modal-dialog max-WT-500" role="document">
                      <form className="mb0">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Phone number verification
                            </h4>
                            <button
                              onClick={() =>
                                this.setState({
                                  modalIsOpen: false,
                                })
                              }
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
                            <div className="w-100">
                              <div className="sectionHead text-center">
                                <h2>Enter Verification Code</h2>
                                <p>
                                  A text message with code was sent to your
                                  phone
                                </p>
                              </div>
                              <div className="d-flex align-items-center otpField center-box max-WT-300 w-100">
                                <input
                                  type="text"
                                  name="otp1"
                                  value={this.state.otp1}
                                  ref={(secondIndex) =>
                                    (this.firstInput = secondIndex)
                                  }
                                  onChange={this.handleOtp}
                                  className="form-control radius6 text-center"
                                />
                                <input
                                  type="text"
                                  name="otp2"
                                  value={this.state.otp2}
                                  ref={(thirdIndex) =>
                                    (this.secoundInput = thirdIndex)
                                  }
                                  onChange={this.handleOtp}
                                  className="form-control radius6 text-center"
                                />
                                <input
                                  type="text"
                                  name="otp3"
                                  value={this.state.otp3}
                                  ref={(fourthIndex) =>
                                    (this.thirdInput = fourthIndex)
                                  }
                                  onChange={this.handleOtp}
                                  className="form-control radius6 text-center"
                                />
                                <input
                                  type="text"
                                  name="otp4"
                                  value={this.state.otp4}
                                  ref={(fifthIndex) =>
                                    (this.fourthInput = fifthIndex)
                                  }
                                  onChange={this.handleOtp}
                                  className="form-control radius6 text-center"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <div className="pt10 w-100 text-right">
                              <button
                                type="submit"
                                onClick={(e) => this.submitOtp(e)}
                                className="btn btnGreen max-WT-120 mr15"
                              >
                                SUBMIT
                              </button>
                              <button
                                type="button"
                                onClick={this.resentOTP}
                                className="btn btnGreen max-WT-120 ml5"
                              >
                                RESEND
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              {/* <!-- Tab-Content --> */}
              <div className="scrollTabBox">
                {/* {this.state.tab == "basic_info" ?  */}
                <div id="section1" className="row mb30">
                  <div className="col-md-8 detailBox marTp1">
                    <div className="cardHead">
                      <h4 className="mb7">Basic User Information</h4>
                      {/* <p className="mb0 mt0">Email:{this.state.result.data.responseMessage[0].email}</p> */}
                    </div>
                    <div className="sectionHead colPad mb0">
                      <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                        Time Zone
                      </h4>
                      <select
                        className="form-control select-style1 selectInput"
                        value={this.state.zoneCode}
                        id="_country"
                        onChange={(e) => this._search(e)}
                      >
                        <option>Select Time Zone</option>
                        {this.Populate_codes()}
                        {/* {this.state._country.map((item,index)=><option key={index} value={item.dial_code}>{item.dial_code} </option>)} */}
                      </select>
                    </div>
                    <div className="row colPad">
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="show_real_name"
                            maxLength={72}
                            value={this.state.show_real_name}
                            checked={this.state.show_real_name}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Show me as real name verifier to others
                          <p className="greyColor">
                            Your username is shown when people make new trades
                            with your old trading partners
                          </p>
                        </label>
                      </div>

                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="disable_info_from_Email"
                            value={this.state.disable_info_from_Email}
                            checked={this.state.disable_info_from_Email}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Disabled sensitive information from email notification
                          <p className="greyColor">
                            The emails will just ask you to log in to the site,
                            where the actual notification will be
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="row colPad">
                      <div className=" col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="enable_web_notification"
                            value={this.state.enable_web_notification}
                            checked={this.state.enable_web_notification}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Enable web notification
                          <p className="greyColor">
                            You will get external notifications outside the
                            browser window
                          </p>
                        </label>
                      </div>
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="sell_vacation"
                            value={this.state.sell_vacation}
                            checked={this.state.sell_vacation}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Selling Vacation
                          <p className="greyColor">
                            Disable all your advertisement for sale temporarily
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="row colPad">
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="buy_vacation"
                            value={this.state.buy_vacation}
                            checked={this.state.buy_vacation}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Buying Vacation
                          <p className="greyColor">
                            Disable all your advertisement for purchase
                            temporarily
                          </p>
                        </label>
                      </div>
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="sms_notification_trade"
                            value={this.state.sms_notification_trade}
                            checked={this.state.sms_notification_trade}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Send SMS notification for new trade contacts
                          <p className="greyColor">
                            Send SMS notification for new contacts requests
                            (Price free)
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="row colPad">
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="sms_notification_payment"
                            value={this.state.sms_notification_payment}
                            checked={this.state.sms_notification_payment}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Send SMS notification for new Online Payment
                          <p className="greyColor">
                            Send SMS notification for new online payments in
                            your advertisement (Price Free)
                          </p>
                        </label>
                      </div>
                      <div className="col-md-6 checkbox-design-block checkDark">
                        <label className="checkbox-design mb0 dBlueColor">
                          <input
                            type="checkbox"
                            name="sms_notification_escrow"
                            value={this.state.sms_notification_escrow}
                            checked={this.state.sms_notification_escrow}
                            onChange={(e) => this.changeCheckBox(e)}
                          />
                          <span />
                          Send SMS notification for online escrow released
                          <p className="greyColor">
                            Send SMS notification for new online escrow released
                            (Price Free)
                          </p>
                        </label>
                      </div>
                    </div>

                    <div className="sectionHead mb0 colPad">
                      <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                        Introduction
                      </h4>
                      <textarea
                        className="form-control minHeight100"
                        name="introduction"
                        value={this.state.introduction}
                        maxLength={200}
                        onChange={this._introduction}
                      />
                      <p
                        className="font11 mb0"
                        style={{ color: "var(--text-grey)" }}
                      >
                        *Show on your Public Profile Plain Text only up, to 200
                        Characters
                      </p>
                      <label className="error">{this.state.basicError}</label>
                    </div>
                    <div className="sectionHead colPad">
                      <button
                        type="submit"
                        onClick={(e) => this._userInfo(e)}
                        className="btn btnGreen w-100 mt50"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/* : this.state.tab == "change_password" ? */}
                <div id="section2" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7">Change Password</h4>
                    </div>
                    <form className="mb20">
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          Old Password*
                        </h4>
                        <input
                          type="password"
                          className="form-control"
                          name="oldPassword"
                          value={this.state.oldPassword}
                          onChange={this.handleUserInput}
                        />
                        {this.state.oldPasswordError != "" ? (
                          <label className="error">
                            {this.state.oldPasswordError}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          New Password*
                        </h4>
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          value={this.state.newPassword}
                          onChange={this.handleUserInput}
                        />
                        {this.state.newPasswordError != "" ? (
                          <label className="error">
                            {this.state.newPasswordError}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          Confirm Password*
                        </h4>
                        <input
                          type="password"
                          name="newPassword1"
                          className="form-control"
                          value={this.state.newPassword1}
                          onChange={this.handleUserInput}
                        />
                        {this.state.newPasswordError1 != "" ? (
                          <label className="error">
                            {this.state.newPasswordError1}
                          </label>
                        ) : null}
                      </div>
                      <div className="text-center colPad">
                        <button
                          type="submit"
                          onClick={(e) => this._forgotPassword(e)}
                          className="btn btnGreen w-100"
                        >
                          Change
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* : this.state.tab == "change_email" ? */}
                <div id="section3" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7">Change Email</h4>
                    </div>
                    <form className="mb20">
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          Your Password*
                        </h4>
                        <input
                          type="password"
                          className="form-control"
                          name="email_oldPassword"
                          value={this.state.email_oldPassword}
                          onChange={this.email_handleUserInput}
                        />
                        {this.state.passwordError != "" ? (
                          <label className="error">
                            {this.state.passwordError}
                          </label>
                        ) : null}
                      </div>
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          New Email address
                        </h4>
                        <input
                          type="email"
                          className="form-control"
                          name="email_newEmail"
                          value={this.state.email_newEmail}
                          onChange={this.email_handleUserInput}
                        />
                        {this.state.emailError != "" ? (
                          <label className="error">
                            {this.state.emailError}
                          </label>
                        ) : null}
                      </div>

                      <div className="captchImg form-group colPad">
                        <Recaptcha
                          sitekey="6LfoW5kUAAAAANJBVkOkyyjm_OLB7NlCKpdJLo7c"
                          render="explicit"
                          onloadCallback={this.recapctchaLoaded}
                          verifyCallback={() =>
                            this.setState({
                              isVerified: true,
                              isVerifiedError: "",
                            })
                          }
                        />
                        {this.state.isVerifiedError != "" ? (
                          <label className="error">
                            {this.state.isVerifiedError}
                          </label>
                        ) : null}
                      </div>
                      <div className="text-center colPad">
                        <button
                          type="submit"
                          className="btn btnGreen w-100"
                          onClick={(e) => this.email_change(e)}
                        >
                          Change Email address
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* : this.state.tab == "verification" ?  */}
                <div id="section4" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7">Verification</h4>
                    </div>
                    <h4 className="font14 colPad lineHeight1 dBlueColor fontfamQB">
                      <span className="pr7">Email Verified: </span>
                      <span style={{ color: "#25e017" }}>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>
                    </h4>
                    <h4 className="font14 colPad lineHeight1 dBlueColor fontfamQB">
                      {this.state.verified_phone ? (
                        <div>
                          <span>Phone number Verified:</span>
                          <span style={{ color: "#25e017" }}>
                            <i className="fa fa-check" aria-hidden="true" />
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span>Phone number Verified: </span>
                          <span style={{ color: "red" }}>
                            {" "}
                            <i className="fa fa-times" aria-hidden="true" />
                          </span>
                        </div>
                      )}
                    </h4>
                    {this.state.verified_phone !== true ? (
                      <p className="colPad mb0">
                        Verify your phone number to active backup method,
                        increase the reputation of your account and get access
                        to more advertisements.Choose your country code and
                        enter your phone number
                      </p>
                    ) : (
                      <p />
                    )}
                    <form className="mb20">
                      {this.state.verified_phone !== true ? (
                        <div>
                          <div className="colPad">
                            <select
                              className="form-control select-style3 selectInput"
                              value={this.state.countryCode}
                              onChange={(e) => this._search_1(e)}
                            >
                              <option>Select by Country Code</option>
                              {this.Populate_codes_1()}
                            </select>
                            {this.state.countryCodeError != "" ? (
                              <label className="error">
                                {this.state.countryCodeError}
                              </label>
                            ) : null}

                            {/* <div className=" col-md-6 sub-rowclass2">
                                                  <select className="form-control select-style3" value={this.state.countryCodeName} onChange={(e) => this._search_2(e)}>
                                                <option>Select by Country Name</option>
                                                {this.Populate_codes_2()}
                                            </select></div> */}
                          </div>

                          <div className="colPad">
                            <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                              Phone Number
                            </h4>
                            <input
                              type="text"
                              className="form-control"
                              name="phoneVerify"
                              maxLength={14}
                              value={this.state.phoneOTP}
                              onChange={this.phoneVerify}
                            />
                            {this.state.phoneOTPError != "" ? (
                              <label className="error">
                                {this.state.phoneOTPError}
                              </label>
                            ) : null}
                          </div>

                          <div className="text-center colPad">
                            <button
                              type="button"
                              className="btn btnGreen w-100"
                              onClick={this.resentOTP}
                            >
                              Send Verification SMS
                            </button>
                          </div>
                        </div>
                      ) : null}

                      <h4 className="font14 colPad lineHeight1 dBlueColor fontfamQB">
                        <span className="pr7">Identity Verified: </span>
                        {this.state.KYCStatus == "REJECTED" ||
                        this.state.KYCStatus == "PENDING" ? (
                          <label style={{ color: "red" }}>
                            {this.state.KYCStatus}
                          </label>
                        ) : this.state.KYCStatus == "" ? (
                          <label style={{ color: "#25e017" }}>NO</label>
                        ) : (
                          <label style={{ color: "#25e017" }}>
                            {this.state.KYCStatus}
                          </label>
                        )}
                      </h4>

                      <div>
                        <div className="colPad pt5">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            First Name
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              className="form-control"
                              maxLength={10}
                              placeholder="First name"
                              name="first_name"
                              value={this.state.first_name}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              name="first_name"
                              value={this.state.first_name}
                              readOnly
                            />
                          )}
                          {this.state.first_nameError != "" ? (
                            <label className="error">
                              {this.state.first_nameError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Last Name
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              name="last_name"
                              maxLength={15}
                              value={this.state.last_name}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              name="last_name"
                              value={this.state.last_name}
                              onChange={this.verify}
                              readOnly
                            />
                          )}
                          {this.state.last_nameError != "" ? (
                            <label className="error">
                              {this.state.last_nameError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad posRel">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Date of Birth
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="date"
                              id="txtDate"
                              className="form-control"
                              placeholder="dd/mm/yy"
                              name="date_of_birth"
                              value={this.state.date_of_birth}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="date"
                              id="txtDate"
                              className="form-control"
                              placeholder="dd/mm/yy"
                              name="date_of_birth"
                              value={this.state.date_of_birth}
                              readOnly
                            />
                          )}
                          <span className="setPosCalendr">
                            {/* <i className='fa fa-calendar'></i> */}
                            <img
                              src={require("../assets/newImages/caledarIcon.png")}
                            />
                          </span>
                          {this.state.date_of_birth_Error != "" ? (
                            <label className="error">
                              {this.state.date_of_birth_Error}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Country
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <select
                              className="form-control select-style1"
                              value={this.state.country}
                              onChange={(e) => this._search2(e)}
                            >
                              <option>Select</option>
                              {this.Populate_codes2()}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="country"
                              name="country"
                              value={this.state.country}
                              readOnly
                            />
                          )}

                          {this.state.countryError != "" ? (
                            <label className="error">
                              {this.state.countryError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Identity Document
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <select
                              className="form-control select-style1"
                              value={this.state.identity_doc}
                              onChange={(e) => this._search3(e)}
                            >
                              <option>Select</option>
                              {this.Populate_codes3()}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="identity_doc"
                              name="identity_doc"
                              value={this.state.identity_doc}
                              readOnly
                            />
                          )}
                          {this.state.identity_docError != "" ? (
                            <label className="error">
                              {this.state.identity_docError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            City
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              maxLength={60}
                              name="city"
                              value={this.state.city}
                              onChange={this.verify}
                              className="form-control"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="city"
                              value={this.state.city}
                              readOnly
                            />
                          )}

                          {this.state.cityError != "" ? (
                            <label className="error">
                              {this.state.cityError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Address
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              className="form-control"
                              maxLength={60}
                              placeholder=""
                              name="address"
                              value={this.state.address}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="address"
                              value={this.state.address}
                              readOnly
                            />
                          )}
                          {this.state.AddressError != "" ? (
                            <label className="error">
                              {this.state.AddressError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Post/Zip code
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              maxLength={15}
                              name="post_code"
                              value={this.state.post_code}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="post_code"
                              value={this.state.post_code}
                              onChange={this.verify}
                              readOnly
                            />
                          )}

                          {this.state.post_codeError != "" ? (
                            <label className="error">
                              {this.state.post_codeError}
                            </label>
                          ) : null}
                        </div>
                        <div className="colPad">
                          <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                            Document ID
                          </h4>
                          {this.state.KYCStatus == "REJECTED" ||
                          this.state.KYCStatus == "" ? (
                            <input
                              type="text"
                              className="form-control"
                              maxLength={32}
                              placeholder=""
                              name="doc_id"
                              value={this.state.doc_id}
                              onChange={this.verify}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="doc_id"
                              value={this.state.doc_id}
                              onChange={this.verify}
                              readOnly
                            />
                          )}
                          {this.state.doc_idError != "" ? (
                            <label className="error">
                              {this.state.doc_idError}
                            </label>
                          ) : null}
                        </div>
                        {this.state.KYCStatus == "REJECTED" ||
                        this.state.KYCStatus == "" ? (
                          <label className="pl30 pr30 fontfamQR">
                            * Only .jpeg, .png and .jpg are acceptable{" "}
                          </label>
                        ) : null}
                        {this.state.KYCStatus == "PENDING" ||
                        this.state.KYCStatus == "APPROVED" ? (
                          <h6 className="error pl30 pr30 mt10">
                            Uploaded Documents{" "}
                          </h6>
                        ) : null}
                        {this.state.KYCStatus == "REJECTED" ||
                        this.state.KYCStatus == "" ? (
                          <div className="colPad pt0">
                            <div className="kycfile-uplaod">
                              <input
                                type="file"
                                id="file_data1"
                                name="file_data1"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                value={this.state.file_data1}
                                onChange={this.verify}
                              />
                              <span
                                className="label-control"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Upload File (scan copy of front side )
                              </span>
                              <i className="fas fa-paperclip" />
                            </div>
                            {this.state.showFile1 != "" ? (
                              <img
                                id="blah"
                                src={`${this.state.files1}`}
                                alt={`${this.state.showFile1}`}
                              />
                            ) : this.state.showFile11 != "" ? (
                              <a>
                                <img
                                  src={`${this.state.showFile11}`}
                                  id="blah"
                                  alt={`${this.state.showFile11}`}
                                />
                              </a>
                            ) : null}
                            {this.state.file_dataError1 != "" ? (
                              <label className="error">
                                {this.state.file_dataError1}
                              </label>
                            ) : null}
                          </div>
                        ) : null}

                        {this.state.KYCStatus == "REJECTED" ||
                        this.state.KYCStatus == "" ? (
                          <div className="colPad pt0">
                            <div className="kycfile-uplaod">
                              <input
                                type="file"
                                id="file_data2"
                                name="file_data2"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                value={this.state.file_data2}
                                onChange={this.verify}
                              />
                              <span
                                className="label-control"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Upload File (scan copy of back side )
                              </span>
                              <i className="fas fa-paperclip" />
                            </div>
                            {this.state.showFile2 != "" ? (
                              <img
                                id="blah"
                                src={`${this.state.files2}`}
                                alt={`${this.state.showFile2}`}
                              />
                            ) : this.state.showFile22 != "" ? (
                              <a>
                                <img
                                  src={`${this.state.showFile22}`}
                                  id="blah"
                                  alt={`${this.state.showFile22}`}
                                />
                              </a>
                            ) : null}

                            {this.state.file_dataError2 != "" ? (
                              <label className="error">
                                {this.state.file_dataError2}
                              </label>
                            ) : null}
                          </div>
                        ) : null}

                        {this.state.KYCStatus == "REJECTED" ||
                        this.state.KYCStatus == "" ? (
                          <div className="colPad pt0">
                            <div className="kycfile-uplaod ">
                              <input
                                type="file"
                                id="file_data3"
                                name="file_data3"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                value={this.state.file_data3}
                                onChange={this.verify}
                              />
                              <span
                                className="label-control"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Please upload your photo holding your ID
                              </span>
                              <i className="fas fa-paperclip" />
                            </div>
                            {this.state.showFile3 != "" ? (
                              <img
                                id="blah"
                                src={`${this.state.files3}`}
                                alt={`${this.state.showFile3}`}
                              />
                            ) : this.state.showFile33 != "" ? (
                              <a>
                                <img
                                  src={`${this.state.showFile33}`}
                                  id="blah"
                                  alt={`${this.state.showFile33}`}
                                />
                              </a>
                            ) : null}
                            {this.state.file_dataError3 != "" ? (
                              <label className="error">
                                {this.state.file_dataError3}
                              </label>
                            ) : null}
                          </div>
                        ) : null}

                        {/* {this.state.showFile3 != "" ?
                                                <label style={{ color: "Green" }}>{this.state.showFile3}</label>
                                                : null} */}
                        {/* {this.state.showFile33 != "" ?
                                                <a href={`${this.state.showFile33}`} target="_blank" style={{ color: "Green" }}>{this.state.showFile33}</a>
                                                : null} */}

                        {this.state.KYCStatus == "PENDING" ? null : (
                          <div className="colPad text-center pt0">
                            <button
                              type="submit"
                              className="btn btnGreen w-100"
                              onClick={this.userDetails}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>

                <div id="section5" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="lineHeight1">Real Name</h4>
                    </div>
                    <h4 className="font14 mb15 lineHeight1">
                      {/* <span className="pr7">Real name verification:</span> */}
                      {/* <span className="weight500">0 verification</span    > */}
                    </h4>
                    <form className="mb20">
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          Your Name
                        </h4>
                        <input
                          type="text"
                          className="form-control"
                          maxLength={60}
                          value={this.state.name}
                          name="name"
                          onChange={this.realName_handleUserInput}
                        />
                        {this.state.nameError != "" ? (
                          <label className="error">
                            {this.state.nameError}
                          </label>
                        ) : null}
                        <p
                          className="mb0 font14"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Enter your full name as it appears on bank account
                        </p>
                      </div>
                      <div className="text-center colPad pt0">
                        <button
                          type="submit"
                          onClick={(e) => this._realNameSet(e)}
                          className="btn btnGreen w-100"
                        >
                          Set real name
                        </button>
                      </div>
                      <div className="colPad">
                        <div className="kycfile-uplaod">
                          <input
                            type="file"
                            id="file_data4"
                            name="file_data4"
                            accept=".jpg, .jpeg, .png"
                            className="form-control"
                            value={this.state.file_data4}
                            onChange={this.verify1}
                          />
                          <span
                            className="label-control"
                            style={{ color: "var(--text-grey)" }}
                          >
                            Upload File (Profile picture)
                          </span>
                          <i className="fas fa-paperclip" />
                        </div>
                        {this.state.showFile4 != "" ? (
                          <img
                            id="blah"
                            src={`${this.state.files4}`}
                            alt={`${this.state.showFile4}`}
                          />
                        ) : null}
                        {this.state.profilepicError ? (
                          <label className="error">
                            {this.state.profilepicError}
                          </label>
                        ) : null}
                      </div>

                      <div className="text-center colPad pt0">
                        <button
                          type="submit"
                          onClick={(e) => this.profilePicture(e)}
                          className="btn btnGreen w-100 mt15"
                        >
                          Upload profile picture
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* : this.state.tab == "personal_info" ? */}
                <div id="section6" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7">Personal data</h4>
                    </div>
                    <div className="colPad">
                      <p className="font14 greyColor mb8">
                        Below you will find your personal data in CSV files.
                      </p>

                      <ul className="">
                        <li className="mb10 font14 lineHeight1  fontfamQB">
                          <a className="dBlueColor" href="/profile">
                            Personal info
                          </a>
                        </li>
                        <li className="mb10 font14 lineHeight1 fontfamQB">
                          <a className="dBlueColor" href="/twofactor">
                            Login history
                          </a>
                        </li>
                        <li className="mb10 font14 lineHeight1 fontfamQB">
                          <a className="dBlueColor" href="dashboard">
                            Trade history
                          </a>
                        </li>
                        <li className="font14lineHeight1 fontfamQB">
                          <a className="dBlueColor" href="HistoryList">
                            Transaction history
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* : this.state.tab == "account_details" ? */}
                <div id="section7" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7">Security</h4>
                    </div>
                    <div className="colPad ">
                      <h4 className="font14 mb15 lineHeight1">
                        <span
                          className="pr7 lineHeight1 fontfamQR"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Your account security level is:
                        </span>

                        <span className="weight500 rColor">
                          {this.state.account_security}
                        </span>
                      </h4>
                      <ul className="">
                        <li className="font14 lineHeight1 fontfamQB">
                          <Link to={"/twofactor"}>
                            <span className="dBlueColor">
                              Edit security options
                            </span>
                            {/* <a className='dBlueColor' href="two-factor-authentication.html">Edit security options</a> */}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* : this.state.tab == "security" ? */}
                <div id="section8" className="row mb30">
                  <div className="col-md-8 detailBox">
                    <div className="cardHead">
                      <h4 className="mb7 lineHeight1">Account Deletion</h4>
                    </div>
                    <form className="mb20">
                      <div className="form-group colPad">
                        <h4 className="font14 mb7 lineHeight1 dBlueColor fontfamQB">
                          Your password
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <input
                          type="password"
                          className="form-control"
                          name="delete_password"
                          onChange={this.delete_password}
                        />
                        <p
                          className="mb0 font14"
                          style={{ color: "var(--text-grey)" }}
                        >
                          Please confirm account deletion request with your
                          password
                        </p>
                        {this.state.deleteAccountError != "" ? (
                          <label className="error">
                            {this.state.deleteAccountError}
                          </label>
                        ) : null}
                      </div>
                      <div className="text-center colPad pt0">
                        <button
                          type="button"
                          className="btn btnGreen w-100"
                          onClick={() => this.deleteAccount()}
                        >
                          Request admin for account deletion
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* :null} */}
              </div>
              {/* <!-- Tab-Content Start --> */}
            </div>
          </section>
          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        </main>

        {/* <!-- createAccountSection Start -->	    */}
        <Footer {...this.props} />
      </div>
    );
  }
}
