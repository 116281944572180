import React, { Component } from "react";
import logo from "./stop.jpg";
import "../assets/css/notFoundCss.css";

class StopSignUp extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div style={{ flex: 1 }} className="App">
        <img src={logo} className="stop-Sign-App-logo" alt="logo" />
        <h3> Sorry! Registration Not Available</h3>
        <p> Try Again Later</p>
      </div>
    );
  }
}

export default StopSignUp;
