import React, { Component } from 'react';
import logo from './notFound.jpg';
import '../assets/css/notFoundCss.css';

class NotFound extends Component {
    render() {
        return (
            <div style={{ flex: 1 }} className="App">
                <header className="App-header">
                    <img src={logo} className="Not-App-logo" alt="logo" />
                </header>
            </div>
        );
    }
}

export default NotFound;
