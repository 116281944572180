import React, { Component } from "react";
import { validatePassword } from "../utils/validation";
import { Api } from "../services/webservices";
import Header from "../components/header";
import Footer from "../components/footer";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { toast_func } from "../components/toaster";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
export default class Resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      oldPasswordError: "",
      oldPasswordStatus: false,
      newPassword: "",
      newPasswordError: "",
      newPasswordStatus: false,
      loading: false,
      togglePswrd: true,
      toggleCnfrmPswrd: true,
    };
  }
  componentDidMount() {
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(45, 40, 60, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    var canvas = document.getElementById("myCanvas"),
      context = canvas.getContext("2d");

    make_base();
    function make_base() {
      var base_image = new Image();
      base_image.src =
        "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
      //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
      //   base_image.src = '../assets/newImages/logo.png';
      base_image.onload = function() {
        context.drawImage(base_image, 10, 15, 80, 60);
      };
    }
  }
  handleUserInput = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    this.setState({ [type]: value });

    setTimeout(() => {
      if (type == "oldPassword") {
        if (validatePassword(this.state.oldPassword).status) {
          this.setState({
            oldPasswordStatus: true,
          });
        } else this.setState({ oldPasswordStatus: false });
      } else if (type == "newPassword") {
        if (validatePassword(this.state.newPassword).status) {
          this.setState({
            newPasswordStatus: true,
          });
        } else this.setState({ newPasswordStatus: false });
      }
    }, 100);
  };
  showPswrd(val) {
    if (val == "show") {
      this.setState({ togglePswrd: false });
      var x = document.getElementById("myPswrd");
      x.type = "text";
    } else {
      this.setState({ togglePswrd: true });
      var x = document.getElementById("myPswrd");
      x.type = "password";
    }
  }
  handleCnfrmPswrd(val) {
    if (val == "show") {
      this.setState({ toggleCnfrmPswrd: false });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "text";
    } else {
      this.setState({ toggleCnfrmPswrd: true });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "password";
    }
  }
  _forgotPassword(e) {
    e.preventDefault();

    if (
      this.state.oldPassword != "" &&
      this.state.oldPasswordStatus == true &&
      this.state.newPassword != "" &&
      this.state.newPasswordStatus == true
    ) {
      this.setState({ loading: true });
      var parameter = {
        password: this.state.oldPassword,
        newPassword: this.state.newPassword,
      };
      try {
        Api("user/updatePassword", "POST", parameter).then((resp) => {
          Header.logout(resp);
          if (resp.data.responseCode == 200) {
            this.setState({ loading: false });
            toast_func("success", resp.data.responseMessage);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        });
      } catch (ex) {
        toast_func(
          "error",
          "Unable to reach server please contact administrator"
        );
      }
    } else {
      toast_func("error", "Please Enter Valid password");
    }
  }
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div>
        {/* <Header {...this.props} />
                {this.state.loading ?<div className='loader'> <img className="" style={{height:100,width:100}} src={require('../assets/lg.gif')} /> </div>
                                   :null
                                   } */}
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle bgColour">
            <div className="container">
              <div className="loginbox">
                <div className="box_3">
                  <a href="/">
                    {" "}
                    <canvas id="myCanvas" className="canvas" />
                  </a>
                  <div className="pdBox">
                    <img src={require("../assets/newImages/resetPswrd2.png")} />
                  </div>
                </div>
                <div className="box_2">
                  <form>
                    <div className="formBox max-WT-400 formSpace">
                      <div>
                        <h2 className="header2">Reset Account Password</h2>
                      </div>
                      <label>New Password</label>
                      <div className="form-group posRel">
                        <input
                          type="password"
                          id="myPswrd"
                          className="form-control radius10"
                          name="newPassword"
                          value={this.state.newPassword}
                          onChange={this.handleUserInput}
                          placeholder="Please enter your new password"
                        />
                        {this.state.togglePswrd == true ? (
                          <span
                            title="Hide"
                            className="fa fa-eye-slash setPos"
                            onClick={() => this.showPswrd("show")}
                          />
                        ) : (
                          <span
                            title="Show"
                            className="fa fa-eye setPos"
                            onClick={() => this.showPswrd("hide")}
                          />
                        )}
                      </div>
                      <label>Confirm Password</label>
                      <div className="form-group posRel">
                        <input
                          type="password"
                          id="cnfrmPswrd"
                          className="form-control radius10"
                          name="oldPassword"
                          value={this.state.oldPassword}
                          onChange={this.handleUserInput}
                          placeholder="Please enter your old password"
                        />
                        {this.state.toggleCnfrmPswrd == true ? (
                          <span
                            title="Hide"
                            className="fa fa-eye-slash setPos"
                            onClick={() => this.handleCnfrmPswrd("show")}
                          />
                        ) : (
                          <span
                            title="Show"
                            className="fa fa-eye setPos"
                            onClick={() => this.handleCnfrmPswrd("hide")}
                          />
                        )}
                      </div>

                      <div className="text-center mt10">
                        <button
                          type="submit"
                          onClick={(e) => this._forgotPassword(e)}
                          className="btn btnGrey w-100 pt10 pb10 radius10"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          {/* <main className="mainContent">

                    <section className="staticContentSection commonSectionStyle">
                        <div className="container">
                            <div className="cardBox max-WT-550 center-box d-flex align-items-center minHeight350">
                                <form className="mb0 w-100">
                                    <div className="sectionHead borderBottom pb10 text-center">
                                        <h2>Reset Password</h2>
                                        
                                    </div>
                                   
                                    <div className="formBox max-WT-400 center-box">
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="oldPassword" value={this.state.oldPassword} onChange={this.handleUserInput} placeholder="Please enter your old password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="newPassword" value={this.state.newPassword} onChange={this.handleUserInput} placeholder="Please enter your new password" />
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" onClick={(e) => this._forgotPassword(e)} className="btn btnGreen w-100 pt10 pb10 radius4">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section> */}
          {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
