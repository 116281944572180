import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { LoadingOverlay, Loader } from "react-overlay-loader";
export default class enableAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <div className="cardBox max-WT-550 center-box">
                <div className="sectionHead mb15 borderBottom pb10 text-center">
                  <h2 className="textInitial">Enable Authenticator</h2>
                </div>
                <p>Chose an authentication method to improve security.</p>
                <form className="mb0">
                  <div className="formBox  max-WT-400 center-box">
                    <div className="radio-design-block mb15">
                      <label className="radio-design   mb0 font16">
                        <input type="radio" name="option" />
                        <span />
                        Mobile app (Recommended)
                      </label>
                    </div>
                    <div className="radio-design-block mb15">
                      <label className="radio-design  mb0 font16">
                        <input type="radio" name="option" />
                        <span />
                        printed codes
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn btnGreen p10 pb10 w-100"
                    >
                      PROCEED TO ACTIVATION
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a href="signup.html" className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        </main>

        {/* <!-- createAccountSection Start -->	 */}
        <Footer {...this.props} />
      </div>
    );
  }
}
