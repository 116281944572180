import React, { Component } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { base32 } from "../services/Decrypt";
import { Api, socket } from "../services/webservices";
import Slider from "react-slick";
import { CopyToClipboard } from "react-copy-to-clipboard";
import fileDownload from "js-file-download";
import axios from "axios";
import {
  showbox,
  showBoxfun,
  TRADE,
  TRADEfun,
  IMAGE,
  IMAGEfun,
  NAME,
  NAMEfun,
  RECEIVER,
  RECEIVERfun,
} from "../services/globals";
/*              Modules              */
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Carousel from "react-image-carousel";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import ImageModal from "../components/imageModal";
let arr1 = [];
let __arr = [];
let arrImage = [];
const setting = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 1, // slidesToScroll: 1,
  accessibility: true,
  initialSlide: 0,
};

export default class SendBuyRequest extends Component {
  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.state = {
      feedbackType: "",
      feedbackPositive: false,
      feedbackNegative: false,
      feedbackNeutral: false,
      Modal: false,
      reason1Status: false,
      reason2Status: false,
      reason3Status: false,
      reasonOtherStatus: false,
      textmessage: "",
      populateAllChats: [],
      getBase64Data: "",
      reason: "",
      AuthModal: false,
      auth_code: "",
      userInfo: "",
      tremModal: false,
      DisputeModal: false,
      order: false,
      Disputereason: "",
      flag: true,
      confirmRelease: false,
      EnableshowModal: false,
      copied: false,
      sendFeedback: false,
      copyText: "",
      url: "",
      btn: document.getElementById("downloadImage"),
      counter: 3,
      showImage: false,
      prodImg: [],
      fileType: "",
      receiveId: "",
      opponent_user_id: "",
      activeStatus: "",
      activeColor: "green",
      youGet: "loading..",
    };
    this.getBase64 = this.getBase64.bind(this);
  }
  componentWillUnmount = () => {
    if (this.state.receiverId) {
      socket.emit("currentlyChattingReverse", {
        // receiverId: this.state.receiverId.toString(),
        receiverId: [`${this.state.receiverId}`],
        senderId: this.state._id.toString(),
        tradeId: this.state.trade_id,
        //  notificationType:"chat",
        //  type:'TRADE'
      });
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    var DecryptData = base32.decode(this.props.match.params.trade_id);

    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");

    socket.on("reloadPage", (resp) => {
      if (resp.result) {
        this.componentDidMount();
      }
    });

    socket.on("chatImage", (resp) => {
      arrImage = [];
      this.setState({ ImagesData: [] });

      if (resp.data.length) {
        for (let i = 0; i < resp.data.length; i++) {
          arrImage.push(
            <li>
              <img className="thumb ml15" src={resp.data[i]["image"]} />
            </li>
          );
        }
        this.setState({ ImagesData: arrImage });
      }
    });

    setTimeout(() => {
      if (id !== null) {
        this.setState(
          { _id: id, token: token, addr: addr, trade_id: DecryptData },
          () => this.chatHistoryAPI()
        );
        this.tradeDetail();
      }
    }, 300);

    // var msgList = document.getElementById("tradingChatList");

    //msgList.scrollTop = msgList.scrollHeight;
    socket.on("receivemessage", (res) => {
      if (res.requireData.image !== undefined) {
        this.state.prodImg.unshift(res.requireData.image);
      }
      socket.emit("getUserBalance", { userId: id });
      socket.emit("chatImage", {
        tradeId: this.state.trade_id,
      });

      var index11 = __arr.map(
        (item) => res.requireData.message == item.message
      ); //(obj.time)
      var index = index11.indexOf(true);
      var _index11 = __arr.map((item) => res.requireData.time == item.time);
      var _index = _index11.indexOf(true);

      this.setState({
        loader: false,
        files1: null,
        showFile1: null,
        file_data1: null,
        img1: null,
      });
      if (res.requireData.image == undefined) {
        if (_index !== -1 && index !== -1) {
        } else {
          if (res.requireData.tradeId == DecryptData) {
            var obj = {
              time: res.requireData.time,
              message: res.requireData.message,
            };
            __arr.push(obj);

            if (res.requireData.type == "sender") {
              if (res.requireData.image == undefined) {
                arr1.push(
                  <li className="right clearfix">
                    <div className="chat-inner-box clearfix">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <CopyToClipboard text={res.requireData["message"]}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            onClick={(e) => this.startCountdown("copied")}
                            style={{
                              color: "var(--text-grey)",
                              cursor: "pointer",
                            }}
                          >
                            <g fill="currentColor">
                              <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                              <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                            </g>
                          </svg>
                        </CopyToClipboard>
                        <p>{res.requireData["message"]}</p>
                      </div>
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else {
                if (res.requireData.fileType == "image") {
                  arr1.push(
                    <li className="right clearfix">
                      <div className="chat-inner-box clearfix">
                        <img
                          id="blah"
                          src={res.requireData.image}
                          onClick={() => {
                            this.setState({ showImage: true });
                          }}
                        />

                        {res.requireData["message"] !== " " ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <CopyToClipboard text={res.requireData["message"]}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                onClick={(e) => this.startCountdown("copied")}
                                style={{
                                  color: "var(--text-grey)",
                                  cursor: "pointer",
                                }}
                              >
                                <g fill="currentColor">
                                  <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                  <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                                </g>
                              </svg>
                            </CopyToClipboard>

                            <p>{res.requireData["message"]}</p>
                          </div>
                        ) : null}
                        <span className="dateandtime">
                          {
                            new Date(res.requireData["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>{" "}
                    </li>
                  );
                } else {
                  arr1.push(
                    <li className="right clearfix">
                      <div className="chat-inner-box clearfix">
                        {/* <img
                          id="blah"
                          src={res.requireData.image}
                          onClick={() => this.setState({ showImage: true })}
                        /> */}
                        {/* <iframe
                          width="220"
                          height="200"
                          src="https://res.cloudinary.com/georgia007/video/upload/v1692181984/vd1vhy6tkamx7wflkwmv.mp4"
                        /> */}

                        {res.requireData["message"] !== " " ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <CopyToClipboard text={res.requireData["message"]}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                onClick={(e) => this.startCountdown("copied")}
                                style={{
                                  color: "var(--text-grey)",
                                  cursor: "pointer",
                                }}
                              >
                                <g fill="currentColor">
                                  <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                  <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                                </g>
                              </svg>
                            </CopyToClipboard>

                            <p>{res.requireData["message"]}</p>
                          </div>
                        ) : null}
                        <span className="dateandtime">
                          {
                            new Date(res.requireData["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>{" "}
                    </li>
                  );
                }
              }
            } else if (res.requireData.type == "receiver") {
              if (res.requireData.colorType == "ADMIN") {
                arr1.push(
                  <li className="left clearfix">
                    <div className="chat-inner-box clearfix">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            backgroundColor: "#f789715c",
                            color: "var(--black-color)",
                          }}
                        >
                          {res.requireData["message"]}
                        </p>
                        <CopyToClipboard text={res.requireData["message"]}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            onClick={(e) => this.startCountdown("copied")}
                            style={{
                              color: "var(--text-grey)",
                              cursor: "pointer",
                            }}
                          >
                            <g fill="currentColor">
                              <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                              <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                            </g>
                          </svg>
                        </CopyToClipboard>
                      </div>
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else if (res.requireData.image == undefined) {
                arr1.push(
                  <li className="left clearfix">
                    <div className="chat-inner-box clearfix">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p> {res.requireData["message"]} </p>
                        <CopyToClipboard text={res.requireData["message"]}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            onClick={(e) => this.startCountdown("copied")}
                            style={{
                              color: "var(--text-grey)",
                              cursor: "pointer",
                            }}
                          >
                            <g fill="currentColor">
                              <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                              <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                            </g>
                          </svg>
                        </CopyToClipboard>
                      </div>
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else {
                arr1.push(
                  <li className="left clearfix">
                    <div className="chat-inner-box clearfix">
                      {res.requireData.image.split("/").includes("image") ? (
                        <img
                          id="blah"
                          src={res.requireData.image}
                          onClick={() => this.setState({ showImage: true })}
                        />
                      ) : (
                        <div className="attch_vid">
                          <iframe
                            width="220"
                            height="200"
                            src={res.requireData.image}
                            onClick={() => this.setState({ showImage: true })}
                          />
                          <button>
                            <i
                              class="fa fa-play-circle"
                              style={{ color: "#f66067" }}
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      )}
                      {res.requireData["message"] == " " ? null : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ width: "fit-content" }}>
                            {res.requireData["message"]}
                          </p>
                          <CopyToClipboard text={res.requireData["message"]}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={(e) => this.startCountdown("copied")}
                              style={{
                                color: "var(--text-grey)",
                                cursor: "pointer",
                              }}
                            >
                              <g fill="currentColor">
                                <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                              </g>
                            </svg>
                          </CopyToClipboard>
                        </div>
                      )}
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              }
            }
          }
        }
      } else {
        if (_index == -1) {
          if (res.requireData.tradeId == DecryptData) {
            var obj = {
              time: res.requireData.time,
              message: res.requireData.message,
            };
            __arr.push(obj);

            if (res.requireData.type == "sender") {
              if (res.requireData.image == undefined) {
                arr1.push(
                  <li className="right clearfix">
                    <div className="chat-inner-box clearfix">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <CopyToClipboard text={res.requireData["message"]}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            onClick={(e) => this.startCountdown("copied")}
                            style={{
                              color: "var(--text-grey)",
                              cursor: "pointer",
                            }}
                          >
                            <g fill="currentColor">
                              <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                              <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                            </g>
                          </svg>
                        </CopyToClipboard>

                        <p>{res.requireData["message"]}</p>
                      </div>
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else {
                arr1.push(
                  <li className="right clearfix">
                    <div className="chat-inner-box clearfix">
                      {res.requireData.image.split("/").includes("image") ? (
                        <img
                          id="blah"
                          src={res.requireData.image}
                          onClick={() => this.setState({ showImage: true })}
                        />
                      ) : (
                        <div className="attch_vid">
                          <iframe
                            width="220"
                            height="200"
                            src={res.requireData.image}
                            onClick={() => this.setState({ showImage: true })}
                          />
                          <button>
                            <i
                              class="fa fa-play-circle"
                              style={{ color: "#f66067" }}
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      )}

                      {res.requireData["message"] !== " " ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <CopyToClipboard text={res.requireData["message"]}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={(e) => this.startCountdown("copied")}
                              style={{
                                color: "var(--text-grey)",
                                cursor: "pointer",
                              }}
                            >
                              <g fill="currentColor">
                                <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                              </g>
                            </svg>
                          </CopyToClipboard>

                          <p>{res.requireData["message"]}</p>
                        </div>
                      ) : null}
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>{" "}
                  </li>
                );
              }
            } else if (res.requireData.type == "receiver") {
              if (res.requireData.image == undefined) {
                arr1.push(
                  <li className="left clearfix">
                    <div className="chat-inner-box clearfix">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p> {res.requireData["message"]} </p>
                        <CopyToClipboard text={res.requireData["message"]}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            onClick={(e) => this.startCountdown("copied")}
                            style={{
                              color: "var(--text-grey)",
                              cursor: "pointer",
                            }}
                          >
                            <g fill="currentColor">
                              <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                              <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                            </g>
                          </svg>
                        </CopyToClipboard>
                      </div>
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else {
                arr1.push(
                  <li className="left clearfix">
                    <div className="chat-inner-box clearfix">
                      {res.requireData.image.split("/").includes("image") ? (
                        <img
                          id="blah"
                          src={res.requireData.image}
                          onClick={() => this.setState({ showImage: true })}
                        />
                      ) : (
                        <div className="attch_vid">
                          <iframe
                            width="220"
                            height="200"
                            src={res.requireData.image}
                            onClick={() => this.setState({ showImage: true })}
                          />
                          <button>
                            <i
                              class="fa fa-play-circle"
                              style={{ color: "#f66067" }}
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      )}
                      {res.requireData["message"] == " " ? null : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p> {res.requireData["message"]} </p>
                          <CopyToClipboard text={res.requireData["message"]}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={(e) => this.startCountdown("copied")}
                              style={{
                                color: "var(--text-grey)",
                                cursor: "pointer",
                              }}
                            >
                              <g fill="currentColor">
                                <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                              </g>
                            </svg>
                          </CopyToClipboard>
                        </div>
                      )}
                      <span className="dateandtime">
                        {
                          new Date(res.requireData["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              }
            }
          }
        }
      }
      this.setState({ populateAllChats: arr1 }, () => {
        this.tradeDetail();
        $("#chat-ul-list").scrollTop($("#chat-ul-list")[0].scrollHeight);
      });
    });
    this.setState({ populateAllChats: arr1 }, () => {
      $("#chat-ul-list").scrollTop($("#chat-ul-list")[0].scrollHeight);
    });

    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          $("#blah")
            .attr("src", e.target.result)
            .width(150)
            .height(200);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    // $(".chat").animate({ scrollTop: $(this).outerHeight() },100);
    // return false;

    // $("textareaChat").on("keypress", function(e) {
    //     if ((e.keyCode == 10 || e.keyCode == 13)) {
    //         e.preventDefault();

    //     }
    // });

    setTimeout(() => {
      try {
        $("#chat-ul-list").scrollTop($("#chat-ul-list")[0].scrollHeight);
      } catch (ex) {}
    }, 1500);

    $(window).scroll(function() {
      //console.log("shoeeaaaaaaaaaaaa   ")
      //alert("ddddd")
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $("header").addClass("header-bg");
        $(".fixedBox").addClass("fixdtop");
      } else {
        $("header").removeClass("header-bg");
        $(".fixedBox").removeClass("fixdtop");
      }
    });
  }

  findUSD = async (btc) => {
    var youGet = null;
    var parameter = {
      localCurrency: this.state.result['currency_type'], //"USD",
      margin: "",
    };
    await Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var priceNow = resp.data.result.price;
          this.setState({ youGet: (priceNow * btc).toFixed(4) });
        } else if (resp.data.responseCode == 502) {
          return null;
        }
      }
    });
  };

  viewAttachment(e, i) {
    e.preventDefault();
    //setting.initialSlide = this.state.prodImg.length - 1;
    console.log(
      this.state.prodImg.length + "documentttt====>",
      $(e.target).children.children
    );
  }

  counterFun(time, tempFlag) {
    console.log("timeeeeee======>" + time);
    if (tempFlag == true) {
      //console.log("======>"+tempFlag)
      this.setState({ flag: false });
      if (time > 0) {
        var x = 0;
        var days = 0;
        var hours = 0;
        var minutes = 0;
        var seconds = 0;
        clearInterval(x);
        // document.getElementById("timerId").innerHTML = days + " : " + hours + " : " + minutes + " : " + seconds;
        var countDownDate = new Date().getTime() + time * 1000;
        x = setInterval(() => {
          var now = new Date().getTime();
          var distance = countDownDate - now;
          console.log(
            countDownDate +
              "<====countDownDate======>" +
              now +
              "=====" +
              distance
          );
          days = Math.floor(distance / (1000 * 60 * 60 * 24));
          hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          //   seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (distance <= 0) {
            clearInterval(x);
            setTimeout(() => {
              this.tradeDetail();
            }, 300);
            days = 0;
            hours = 0;
            minutes = 0;
            seconds = 0;
          }
          document.getElementById("timerId").innerHTML =
            days + " : " + hours + " : " + minutes;
          return days + " : " + hours + " : " + minutes + " : " + seconds;
        }, 1000);
      }
    }
  }

  fetchData(DecryptData) {
    Api(
      `trade/detail_trade/${DecryptData}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({
              userInfo: resp.data.result,
            });
            // this.chatHistoryAPI();
            console.log("========ashish=====", resp.data.result);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }
  tremsTrad = () => {
    this.setState({ tremModal: true });
  };
  closeTrems = () => {
    this.setState({ tremModal: false });
  };

  callJS = () => {
    window.$(".center").slick({
      centerMode: true,
      centerPadding: "60px",
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    });
  };
  chat = () => {
    if (this.state.receiverId) {
      socket.emit("currentlyChatting", {
        // receiverId: this.state.receiverId.toString(),
        receiverId: [`${this.state.receiverId}`],
        senderId: this.state._id.toString(),
        tradeId: this.state.trade_id,
        //  notificationType:"chat",
        type: "GROUP",
      });
    }
    setTimeout(() => {
      socket.emit("notificationList", {
        userId: this.state._id,
      });
    }, 100);
  };
  tradeDetail = () => {
    var parameter = {
      tradeId: this.state.trade_id,
    };
    //this.setState({ loading: true })
    Api(
      "trade/treadeDetails",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status === 200) {
        if (resp.data.responseCode === 200) {
          this.fetchData(resp.data.result["advertisement_id"]);
          this.setState({
            loading: false,
            test: false,
          });
          if (resp.data.result == null) {
            return;
          }

          this.setState({ result: resp.data.result });
          this.findUSD(resp.data.result["amount_of_cryptocurrency"])
          // var _createdAt = resp.data.result["createdAt"].split("T")[0]
          // var date_parts = _createdAt.split("-")
          // var time_parts = resp.data.result["createdAt"].split("T")[1].split(".")[0].split(":");
          // var newDate = new Date(date_parts[0],parseInt(date_parts[1],10)-1,date_parts[2],time_parts[0],time_parts[1],time_parts[2]);

          var T1 = new Date(resp.data.result["createdAt"]);
          var difference = Date.now() - T1.getTime();

          var finalTime =
            resp.data.result["payment_window_time"] * 60 - difference / 1000;

          if (this.state._id == resp.data.result["buyerId"]) {
            this.setState({
              opponent: "Selling",
              amount_in_BTC: resp.data.result["amount_of_cryptocurrency"],
            });
          } else {
            this.setState({ opponent: "Buying" });
          }

          this.setState({
            adID: resp.data.result["advertisement_id"],
            remainingPaymentTime: finalTime,
          });
          if (resp.data.result["status"] === "CANCEL") {
            if (this.state._id == resp.data.result["buyerId"]) {
              this.setState(
                {
                  buttonText: " ",
                  tradeCancelled: true,
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  remainingPaymentTime: 0,
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            } else {
              this.setState(
                {
                  buttonText: " ",
                  tradeCancelled: true,
                  receiverId: resp.data.result["buyerId"],
                  remainingPaymentTime: 0,
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
            }
          } else if (resp.data.result["dispute_status"] === true) {
            this.setState({ tradeDisputed: true });
            if (this.state._id == resp.data.result["buyerId"]) {
              this.setState(
                {
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            } else
              this.setState(
                {
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
          } else if (resp.data.result["request_status"] == "Paid") {
            if (this.state._id == resp.data.result["buyerId"]) {
              if (resp.data.result["remainingPaymentTime"] < 0) {
                this.setState({ Dispute: true, paid_status: false });
              } else {
                this.setState(
                  {
                    paid_status: true,
                    receiverId: resp.data.result["seller"][0]["seller_id"],
                    remainingPaymentTime: finalTime,
                  },
                  () => this.chat()
                );
              }
            } else {
              if (resp.data.result["remainingPaymentTime"] > 0) {
                this.setState({ remainingPaymentTime: finalTime });
              } else {
                this.setState({ remainingPaymentTime: finalTime, test: true });
              }
              this.setState(
                {
                  buttonText: "Release BTC",
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
            }

            if (this.state._id == resp.data.result["buyerId"]) {
              this.setState(
                {
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            } else
              this.setState(
                {
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
          } else if (resp.data.result["request_status"] == "Complete") {
            if (
              resp.data.result["buyerFeedback"] !== this.state._id &&
              resp.data.result["sellerFeedback"] !== this.state._id
            ) {
              this.setState({ showFeedback: true });
            }
            this.setState({
              tradeCompleted: true,
              paid_status: false,
              tradeDisputed: false,
            });
            if (this.state._id == resp.data.result["buyerId"]) {
              this.setState(
                {
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            } else
              this.setState(
                {
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
          } else if (resp.data.result["status"] === "DISPUTE") {
            if (this.state._id == resp.data.result["buyerId"]) {
              if (this.state._id == resp.data.result["buyerId"]) {
                this.setState(
                  {
                    Dispute: true,
                    receiverId: resp.data.result["seller"][0]["seller_id"],
                    name: resp.data.result["seller"][0]["seller_name"],
                  },
                  () => this.chat()
                );
              } else {
                this.setState(
                  {
                    Dispute: true,
                    receiverId: resp.data.result["buyerId"],
                    name: resp.data.result["buyer"][0]["buyer_name"],
                  },
                  () => this.chat()
                );
              }
            } else {
              if (resp.data.result["remainingPaymentTime"] > 0) {
                this.setState(
                  {
                    buttonText: "Release BTC",
                    receiverId: resp.data.result["buyerId"],
                    name: resp.data.result["buyer"][0]["buyer_name"],
                    test: true,
                  },
                  () => this.chat()
                );
              } else {
                this.setState(
                  {
                    buttonText: "Release BTC",
                    receiverId: resp.data.result["buyerId"],
                    name: resp.data.result["buyer"][0]["buyer_name"],
                  },
                  () => this.chat()
                );
              }
            }
          } else if (resp.data.result["status"] === "COMPLETE") {
            if (this.state._id == resp.data.result["buyerId"]) {
              this.setState(
                {
                  buttonText: " ",
                  tradeCompleted: true,
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            } else {
              this.setState(
                {
                  buttonText: " ",
                  tradeCompleted: true,
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
            }
          } else {
            // if (resp.data.result["type_of_trade_original"] == "sell") {

            if (this.state._id == resp.data.result["seller"][0]["seller_id"]) {
              if (resp.data.result["remainingPaymentTime"] >= 0) {
                this.setState({ remainingPaymentTime: finalTime });
              }
              this.setState(
                {
                  buttonText: "Release BTC",
                  receiverId: resp.data.result["buyerId"],
                  name: resp.data.result["buyer"][0]["buyer_name"],
                },
                () => this.chat()
              );
            } else {
              if (resp.data.result["remainingPaymentTime"] >= 0) {
                this.setState({ remainingPaymentTime: finalTime });
              }
              this.setState(
                {
                  buttonText: "I have paid",
                  receiverId: resp.data.result["seller"][0]["seller_id"],
                  name: resp.data.result["seller"][0]["seller_name"],
                },
                () => this.chat()
              );
            }
          }
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  };

  sendMessage = (e) => {
    e.preventDefault();
    let elem = document.getElementById("chat-ul-list");
    elem.style.height = "270px";
    let chatbox = document.getElementById("chatbox");
    chatbox.style.height = "39px";

    // alert(">" + this.state.files1 + "<");
    if (this.state.textmessage == "" && !this.state.files1) {
      return this.setState({ textmessage: "" });
    }
    if (!this.state.files1) {
      socket.emit("sendMessage", {
        receiverId: [`${this.state.receiverId}`],
        senderId: this.state._id.toString(),
        message: this.state.textmessage,
        tradeId: this.state.trade_id,
        //image: null,
        senderName: NAME,
        notificationType: "chat",
        type: "GROUP",
      });
      // this.chatHistoryAPI();
      this.setState({ textmessage: "", loader: true, TextError: "" });
    } else {
      if (this.state.files1) {
        console.log("file1", this.state.files1);
        this.state.prodImg.unshift(this.state.files1);
        socket.emit("sendMessage", {
          receiverId: [`${this.state.receiverId}`],
          senderId: this.state._id.toString(),
          message: this.state.textmessage == "" ? " " : this.state.textmessage,
          tradeId: this.state.trade_id,
          image: this.state.files1,
          fileType: this.state.fileType,
          senderName: NAME,
          type: "GROUP",
        });
        console.log("test", this.state.receiverId);
        this.chatHistoryAPI();
        this.setState({ textmessage: "", loader: true, TextError: "" });
      } else {
        this.setState({ TextError: "Please Enter message" });
      }
    }
  };

  clearInterval = () => {
    clearInterval(this.interval);
    this.setState({ copyText: "" });
  };

  startCountdown = () => {
    this.setState({ copyText: "message copied successfully", counter: 3 });
    this.interval = setInterval(() => {
      this.setState(
        (prevState) => ({
          counter: prevState.counter - 1,
        }),
        () => {
          if (this.state.counter === 0) {
            this.clearInterval();
          }
        }
      );
    }, 1000);
  };

  chatHistoryAPI = () => {
    var parameter = {
      tradeId: this.state.trade_id,
      userId: this.state._id,
    };
    this.setState({ loading: true });

    Api(
      "trade/chatHistory",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            //toast_func("success", resp.data.responseMessage)
            if (resp.data.result[0].receiverId == parameter.userId) {
              this.setState({
                opponent_user_id: resp.data.result[0].senderId,
              });
              this.opponentStatus(resp.data.result[0].senderId);
            } else {
              this.setState({
                opponent_user_id: resp.data.result[0].receiverId,
              });
              this.opponentStatus(resp.data.result[0].receiverId);
            }
            console.log("resp.data.result", resp.data.result);
            resp.data.result.map((item) => {
              this.setState({ receiverId: item.receiverId });
              if (item.image !== undefined) {
                this.state.prodImg.unshift(item.image);
              }
            });
            arr1 = [];

            for (var i = 0; i < resp.data.result.length; i++) {
              resp.data.result[i]["copyStatus"] = false;

              if (resp.data.result[i].colorType == "ADMIN") {
                arr1.push(
                  <li className="left clearfix">
                    <div
                      className="chat-inner-box clearfix"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <CopyToClipboard text={resp.data.result[i]["message"]}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          onClick={(e) => this.startCountdown("copied")}
                          style={{
                            color: "var(--text-grey)",
                            cursor: "pointer",
                          }}
                        >
                          <g fill="currentColor">
                            <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                            <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                          </g>
                        </svg>
                      </CopyToClipboard>
                      <p
                        style={{
                          backgroundColor: "#f789715c",
                          color: "var(--black-color)",
                        }}
                        onClick={(e) => this.startCountdown("copied")}
                      >
                        {resp.data.result[i]["message"]}
                      </p>
                      <span className="dateandtime">
                        {
                          new Date(resp.data.result[i]["time"])
                            .toString()
                            .split("GMT")[0]
                        }
                      </span>
                    </div>
                  </li>
                );
              } else if (resp.data.result[i]["senderId"] !== this.state._id) {
                if (resp.data.result[i].image) {
                  arr1.push(
                    <li className="left clearfix">
                      <div className="chat-inner-box clearfix">
                        {resp.data.result[i].image
                          .split("/")
                          .includes("image") ? (
                          <img
                            id="blah"
                            // style={{ height: 200, width: 200 }}
                            src={resp.data.result[i].image}
                            onClick={() => {
                              this.setState({ showImage: true });
                            }}
                          />
                        ) : (
                          <div id={"attach_" + i} className="attch_vid">
                            <iframe
                              width="220"
                              height="200"
                              src={resp.data.result[i].image}
                              onClick={() => {
                                this.setState({ showImage: true });
                              }}
                            />
                            <button>
                              <i
                                class="fa fa-play-circle"
                                style={{ color: "#f66067" }}
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        )}
                        {resp.data.result[i]["message"] == " " ? null : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            <p>{resp.data.result[i]["message"]}</p>
                            <CopyToClipboard
                              text={resp.data.result[i]["message"]}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                onClick={(e) => this.startCountdown("copied")}
                                style={{
                                  color: "var(--text-grey)",
                                  cursor: "pointer",
                                }}
                              >
                                <g fill="currentColor">
                                  <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                  <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                                </g>
                              </svg>
                            </CopyToClipboard>
                          </div>
                        )}
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                } else {
                  arr1.push(
                    <li key={i} className="left clearfix">
                      <div
                        className="chat-inner-box clearfix"
                        style={{
                          // display: "flex",
                          // alignItems: "center",
                          marginRight: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginRight: 2,
                          }}
                        >
                          <p>{resp.data.result[i]["message"]}</p>
                          <CopyToClipboard
                            text={resp.data.result[i]["message"]}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={(e) => this.startCountdown("copied")}
                              style={{
                                color: "var(--text-grey)",
                                cursor: "pointer",
                              }}
                            >
                              <g fill="currentColor">
                                <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                              </g>
                            </svg>
                          </CopyToClipboard>
                        </div>
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                }
              } else {
                if (resp.data.result[i].image) {
                  if (resp.data.result[i].image.endsWith("jpg")) {
                    arr1.push(
                      <li key={i} className="right clearfix">
                        <div className="chat-inner-box clearfix">
                          <img
                            id="blah"
                            src={resp.data.result[i].image}
                            onClick={() => {
                              this.setState({ showImage: true });
                            }}
                          />
                          {resp.data.result[i]["message"] == " " ? null : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                              }}
                            >
                              <CopyToClipboard
                                text={resp.data.result[i]["message"]}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  onClick={(e) => this.startCountdown("copied")}
                                  style={{
                                    color: "var(--text-grey)",
                                    cursor: "pointer",
                                  }}
                                >
                                  <g fill="currentColor">
                                    <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                    <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                                  </g>
                                </svg>
                              </CopyToClipboard>

                              <p onClick={(e) => this.startCountdown("copied")}>
                                {resp.data.result[i]["message"]}
                              </p>
                            </div>
                          )}
                          <span className="dateandtime">
                            {
                              new Date(resp.data.result[i]["time"])
                                .toString()
                                .split("GMT")[0]
                            }
                          </span>
                        </div>
                      </li>
                    );
                  } else {
                    arr1.push(
                      <li
                        key={i}
                        className="right clearfix"
                        onClick={(e) => {
                          this.viewAttachment(e, i);
                          this.setState({ showImage: true });
                        }}
                      >
                        <div className="chat-inner-box clearfix">
                          {resp.data.result[i].image
                            .split("/")
                            .includes("image") ? (
                            <img
                              style={{ height: 200, width: 200 }}
                              src={resp.data.result[i].image}
                              onClick={() => {
                                this.setState({ showImage: true });
                              }}
                              alt="coinbaazar"
                            />
                          ) : (
                            <div id={"attach_" + i} className="attch_vid">
                              <iframe
                                width="220"
                                height="200"
                                src={resp.data.result[i].image}
                              />
                              <button>
                                <i
                                  class="fa fa-play-circle"
                                  style={{ color: "#f66067" }}
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          )}
                          {resp.data.result[i]["message"] == " " ? null : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                              }}
                            >
                              <CopyToClipboard
                                text={resp.data.result[i]["message"]}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  onClick={(e) => this.startCountdown("copied")}
                                  style={{
                                    color: "var(--text-grey)",
                                    cursor: "pointer",
                                  }}
                                >
                                  <g fill="currentColor">
                                    <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                    <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                                  </g>
                                </svg>
                              </CopyToClipboard>

                              <p onClick={(e) => this.startCountdown("copied")}>
                                {resp.data.result[i]["message"]}
                              </p>
                            </div>
                          )}
                          <span className="dateandtime">
                            {
                              new Date(resp.data.result[i]["time"])
                                .toString()
                                .split("GMT")[0]
                            }
                          </span>
                        </div>
                      </li>
                    );
                  }
                } else {
                  arr1.push(
                    <li key={i} className="right clearfix">
                      <div
                        className="chat-inner-box clearfix"
                        style={{
                          // display: "flex",
                          // alignItems: "center",
                          marginRight: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <CopyToClipboard
                            text={resp.data.result[i]["message"]}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={(e) => this.startCountdown("copied")}
                              style={{
                                color: "var(--text-grey)",
                                cursor: "pointer",
                              }}
                            >
                              <g fill="currentColor">
                                <path d="M15.24 2h-3.894c-1.764 0-3.162 0-4.255.148c-1.126.152-2.037.472-2.755 1.193c-.719.721-1.038 1.636-1.189 2.766C3 7.205 3 8.608 3 10.379v5.838c0 1.508.92 2.8 2.227 3.342c-.067-.91-.067-2.185-.067-3.247v-5.01c0-1.281 0-2.386.118-3.27c.127-.948.413-1.856 1.147-2.593c.734-.737 1.639-1.024 2.583-1.152c.88-.118 1.98-.118 3.257-.118h3.07c1.276 0 2.374 0 3.255.118A3.601 3.601 0 0 0 15.24 2Z" />
                                <path d="M6.6 11.397c0-2.726 0-4.089.844-4.936c.843-.847 2.2-.847 4.916-.847h2.88c2.715 0 4.073 0 4.917.847c.843.847.843 2.21.843 4.936v4.82c0 2.726 0 4.089-.843 4.936c-.844.847-2.202.847-4.917.847h-2.88c-2.715 0-4.073 0-4.916-.847c-.844-.847-.844-2.21-.844-4.936v-4.82Z" />
                              </g>
                            </svg>
                          </CopyToClipboard>
                          <p>{resp.data.result[i]["message"]}</p>
                        </div>
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                }
              }
            }
            this.setState({ populateAllChats: arr1 }, () => {});
          } else toast_func("error", resp.data.responseMessage);
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      } catch (ex) {
        toast_func("error", "Please reload the page!");
      }
    });
    $("#chat-ul-list").scrollTop($("#chat-ul-list")[0].scrollHeight);
    this.imageSocket();
    this.verify2FA();
  };
  imageSocket = () => {
    socket.emit("chatImage", {
      tradeId: this.state.trade_id,
    });
  };
  feedbackList = () => {};
  stateApis = () => {
    return;
    // Api(`user/deposits_save/${this.state.addr}/${this.state._id}`, "GET", {}, this.state.token, this.state._id)
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };

  opponentStatus(val) {
    Api(
      "user/userProfile",
      "POST",
      { _id: val },
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            console.log("ashish===", resp.data.userData);
            // alert(resp.data.userData.userStatus)
            this.setState({
              activeStatus: resp.data.userData.userStatus,
              activeColor: resp.data.userData.color,
            });
          } // const currentTime = Date.now();
          // const timeDifference = currentTime - resp.data.result.lastActive;
          // const timeThreshold1 = 20 * 60 * 1000; // 20 minutes in milliseconds
          // const timeThreshold2 = 40 * 60 * 1000; // 40 minutes in milliseconds

          // if (timeDifference < timeThreshold1) {
          //   this.setState({
          //     activeStatus: "active",
          //   });
          // } else if (
          //   timeDifference >= timeThreshold1 &&
          //   timeDifference < timeThreshold2
          // ) {
          //   this.setState({
          //     activeStatus: "away",
          //   });
          // } else {
          //   this.setState({
          //     activeStatus: "offline",
          //   });
          // }
          console.log("=>>>11111", this.state.activeStatus);
        }
      } catch (ex) {}
    });
  }

  SubmitReason = () => {
    if (
      !this.state.reason1Status &&
      !this.state.reason2Status &&
      !this.state.reason3Status &&
      !this.state.reasonOtherStatus
    ) {
      this.setState({ reasonError: "Please select a reason" });
      return;
    } else if (this.state.reasonOtherStatus && this.state.reason == "") {
      this.setState({ reasonError: "Please select a reason" });
      return;
    } else {
      var _Reason = this.state.reason1Status
        ? "Incorrect trade amount"
        : this.state.reason2Status
          ? "Seller did not respond"
          : this.state.reason3Status
            ? "Trade terms did not match"
            : `${this.state.reason}`;

      var parameter = {
        tradeId: this.state.trade_id,
        advertisementId: this.state.adID,
        reason: `${_Reason}`,
      };
      Api(
        "trade/cancelTrade",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        try {
          if (resp.status === 200) {
            if (resp.data.responseCode === 200) {
              toast_func("success", resp.data.responseMessage);

              socket.emit("sendMessage", {
                // receiverId: this.state.receiverId.toString(),
                receiverId: [`${this.state.receiverId}`],
                senderId: this.state._id.toString(),
                message: `Trade is cancelled from ${NAME}. ${_Reason}`,
                tradeId: this.state.trade_id,
                image: null,
                notificationType: "",
                senderName: NAME,
                type: "GROUP",
              });

              setTimeout(() => {
                // this.props.history.push("/")
              }, 2500);
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else toast_func("error", resp.data.responseMessage);
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
      this.setState({ Modal: false });
    }
  };
  iHavePaid = () => {
    this.setState({ loading: true });
    var parameter = {
      tradeId: this.state.trade_id,
      advertisementId: this.state.trade_id,
      paid_status: "true",
      request_status: "Paid",
      status: "PAID",
      notificationType: "ihavepaid",
    };
    Api(
      "trade/iHavePaidTrade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            // this.setState({ request_status: true })
            this.tradeDetail();
            socket.emit("sendMessage", {
              // receiverId: this.state.receiverId.toString(),
              receiverId: [`${this.state.receiverId}`],
              senderId: this.state._id.toString(),
              message:
                "Paid request successfully from " +
                `${NAME}` +
                ": Your payment is currently being verified by the vendor. The vendor will release Bitcoins once they have received payment confirmation.",
              tradeId: this.state.trade_id,
              image: null,
              notificationType: "",
              senderName: NAME,
              type: "GROUP",
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  TRADEDISPUTED = () => {
    toast_func(
      "error",
      "For further enquery adminstrator will contact you soon"
    );
  };
  DisputeTrade = () => {
    if (this.state.Disputereason === "") {
      this.setState({ DisputeReasonError: "*Please enter the Dispute reason" });
      return;
    }

    var parameter = {
      tradeId: this.state.trade_id,
      dispute_status: "true",
      request_status: "Dispute",
      status: "DISPUTE",
      notificationType: "dispiteTrade",
      disputeReason: this.state.Disputereason,
    };
    var parameter2 = {
      tradeId: this.state.trade_id,
    };
    this.setState({ loading: true });
    Api(
      "trade/disputeTrade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status === 200) {
          if (resp.data.responseCode === 200) {
            toast_func("success", resp.data.responseMessage);
            this.setState({ Dispute: true, tradeDisputed: true });
            this.setState({ DisputeModal: false, disputeReason: "" });

            socket.emit("sendMessage", {
              // receiverId: this.state.receiverId.toString(),
              receiverId: [`${this.state.receiverId}`],
              senderId: this.state._id.toString(),
              message: `Trade is disputed from ${NAME}. ${
                this.state.Disputereason
              }`,
              tradeId: this.state.trade_id,
              image: null,
              notificationType: "",
              senderName: NAME,
              type: "GROUP",
            });

            setTimeout(() => {
              Api(
                "escrow/assignTradeToManager",
                "POST",
                parameter2,
                this.state.token,
                this.state._id
              );
            }, 100);
          } else toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  ReleaseBTC = () => {
    this.setState({ confirmRelease: true, EnableshowModal: false });
    if (this.state.result["paid_status"] == false) {
      toast_func("error", "Buyer didn’t confirm payment");
      return;
    }

    if (!this.state.confirmRelease) {
      this.setState({ EnableshowModal: true });
      return;
    } else {
      this.setState({ EnableshowModal: false });
    }

    this.setState({ loading: true });
    var parameter = {
      tradeId: this.state.trade_id,
      received_status: "true",
      request_status: "Complete",
      status: "COMPLETE",
    };
    Api(
      "trade/paymentReceived",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            // this.setState({ Dispute: true })
            this.setState({
              tradeDisputed: false,
              paid_status: false,
              confirmRelease: false,
            });
            setTimeout(() => {
              this.tradeDetail();
            }, 100);
            socket.emit("sendMessage", {
              // receiverId: this.state.receiverId.toString(),
              receiverId: [`${this.state.receiverId}`],
              senderId: this.state._id.toString(),
              message: "BTC is released from " + `${NAME}`,
              tradeId: this.state.trade_id,
              image: null,
              notificationType: "",
              senderName: NAME,
              type: "GROUP",
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  verify2FA = () => {
    this.setState({ loading: true });

    Api(
      `user/checkGoogle2FA/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      if (resp.status == 200) {
        if (resp.data.responseCode == 201) {
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else if (resp.data.responseCode == 200) {
          this.setState({ G2FA: true });
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      }
    });
    $("#chat-ul-list").scrollTop($("#chat-ul-list")[0].scrollHeight);
  };
  feedback = (e) => {
    if (
      !this.state.feedbackPositive &&
      !this.state.feedbackNegative &&
      !this.state.feedbackNeutral
    ) {
      this.setState({ feedbackError: "Please select feedback type" });
    } else {
      this.setState({ sendFeedback: true });
    }
    e.preventDefault();
    var parameter = {
      tradeId: this.state.trade_id,
      feedbackFrom: this.state._id,
      feedbackTo: this.state.receiverId,
      feedbackType: this.state.feedbackPositive
        ? "positive"
        : this.state.feedbackNegative
          ? "negative"
          : this.state.feedbackNeutral
            ? "nuetral"
            : "",
      feedbackMessage: this.state.feedback,
    };

    Api(
      "feedback/addFeedback",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status === 200) {
          if (resp.data.responseCode === 200) {
            toast_func("success", resp.data.responseMessage);
            this.setState({
              feedback: "",
              sendFeedback: false,
              showFeedback: false,
            });
            this.tradeDetail();
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  };
  getBase64 = async (file, type) => {
    //let self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let bse64data1 = reader.result.substr(reader.result).split(",")[0];
      let bse64data = reader.result.substr(reader.result.indexOf(",") + 1);
      let full_image = bse64data1 + "," + bse64data;
      let data = {
        filePath: full_image,
        fileType: type == "video" ? "video" : "image",
      };
      Api("trade/upload_file", "POST", data, "", "").then((response) => {
        console.log("======uuu=0=====>", response.data.data);
        if (response.status == 200) {
          if (response.data.responseCode == 200) {
            this.setState({
              files1: response.data.data,
              img1: response.data.data,
              fileType: type,
            });
            this.setState({ loader: false });
          }
        } else {
          this.setState({ loader: false });
        }
      });
    };
    // if (type == "file_data1") {
    //   console.log("base64", bse64data1 + "," + bse64data);
    //   self.setState({ getBase64Data: bse64data });
    //   self.setState({
    //     files1: bse64data1 + "," + bse64data,
    //     img1: full_image,
    //   });
    // }

    reader.onerror = function(error) {
      // display toaster
    };
  };
  verify = (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    var file = e.target.files[0];
    if (e.target.files[0].type.startsWith("video")) {
      this.getBase64(file, "video");
      // const formData = new FormData();
      // formData.append("videoFile", file);
      // formData.append("fileType", "video");
    } else {
      this.getBase64(file, "image");
      // var file = e.target.files[0];
      // const formData = new FormData();
      // formData.append("videoFile", file);
      // formData.append("fileType", "image");

      // fetch(`https://uat.coinbaazar.com/api/v1/trade/upload_file`, {
      //   method: "POST",
      //   body: formData,
      // }).then((response) => {
      //   if (response.status == 200) {
      //     if (response.data.code == 200) {
      //       this.setState({
      //         files1: response.data.url,
      //         img1: response.data.url,
      //       });
      //     }
      //   }
      // });
    }

    this.setState({
      [e.target.name]: e.target.value,
      showFile1: e.target.value.split(/(\\|\/)/g).pop(),
      showFile1Status: true,
      file_dataError1: "",
    });
  };

  handleupdateFiled = (e) => {
    console.log(e.target.value);
    let elem = document.getElementById("chat-ul-list");
    elem.style.height = "270px";
    elem.style.height =
      elem.style.height.slice(0, -2) -
      e.target.style.height.slice(0, -2) +
      50 +
      "px";
    e.target.style.height = "40px";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  handleOnChange = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;

    if (type === "feedback") {
      this.setState({ [type]: value });
    } else if (type === "reason") {
      this.setState({ [type]: value });
    } else if (type === "textmessage") {
      let elem = document.getElementById("chat-ul-list");
      elem.style.height = "270px";
      elem.style.height =
        elem.style.height.slice(0, -2) -
        e.target.style.height.slice(0, -2) +
        50 +
        "px";
      e.target.style.height = "40px";
      e.target.style.height = e.target.scrollHeight + "px";

      this.setState({ [type]: value, TextError: "" });
    } else if (type === "Disputereason") {
      this.setState({ [type]: value, DisputeReasonError: "" });
    }
  };

  handlecase = (e) => {
    let value = e.target.value;

    //alert(">>"+value+"<<")
    var _value = this.state.auth_code.trim();

    if (!isNaN(value) && value !== " ") {
      this.setState({ auth_code: value }, () => this.setdata());
    }
  };
  activate = (e) => {
    e.preventDefault();

    var _value = this.state.auth_code.trim();

    if (_value.length == 6) {
      var parameter = {
        _id: this.state._id,
        otp: this.state.auth_code,
      };
    } else {
      return;
    }

    this.setState({ loading: true });

    Api(
      "user/twoFactorOtpVerification",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            setTimeout(() => {
              this.ReleaseBTC();
              this.setState({ auth_code: "", AuthModal: false });
            }, 2500);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  setdata = () => {
    var _value = this.state.auth_code.trim();

    if (_value.length !== 6) {
      this.setState({ auth_codeError: "Please enter the valid token number" });
    } else {
      this.setState({ auth_codeError: "" });
    }
  };

  releaseAmount() {
    this.ReleaseBTC();
  }
  open = (name) => {
    // alert(val)
    // ref.current.animateNextTransition();
    if (name == "DHL") {
      window.open("https://www.dhl.com/", "_blank");
      // setWeblink(fb);
      // // console.log('======>>',fb , weblink)
      // setWebView(true)
    }
    if (name == "DELHIVERY") {
      window.open("https://www.delhivery.com/", "_blank");
      // setWeblink(twitter);
      // setWebView(true)
      // console.log('======>>',twitter , weblink)
    }
    if (name == "UPS") {
      // setWeblink(youtube);
      // setWebView(true)
      window.open("https://www.ups.com/", "_blank");
    }
    if (name == "FedEX") {
      // setWeblink(tele);
      window.open("https://www.fedex.com/", "_blank");
      // setWebView(true)
    }
  };

  forceDownload = async (link, e) => {
    e.preventDefault();
    let name = link.split("/").includes("image")
      ? "coinbaazar.jpg"
      : "coinbaazar.mp4";
    axios.get(link, { responseType: "blob" }).then((res) => {
      fileDownload(res.data, name);
    });
  };

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  auto_grow(element) {
    console.log(element);
    // element.style.height = "5px";
    // element.style.height = element.scrollHeight + "px";
  }

  viewDetail = (e, userId) => {
    e.preventDefault();

    var encryptData = base32.encode(userId);
    this.props.history.push(`/user-details/${encryptData}`);
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />

        {this.state.counter > 0 && this.state.copyText !== "" ? (
          <div
            style={{
              position: "fixed",
              top: "17%",
              right: "1%",
              zIndex: 999,
              backgroundColor: "var(--success)",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p
              style={{
                color: "var(--text-grey)",
                marginBottom: 0,
              }}
            >
              {this.state.copyText}
            </p>
          </div>
        ) : null}

        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Modal isOpen={this.state.EnableshowModal}>
          <ModalBody>
            {/* <img src={require("../assets/images/delete.png")} style={{height:"75px",width:"75px"}} className="logOut-img" alt=""/> */}
            <h5 className="mb30 mt30 font17">
              Are you sure you want to release BTC?
            </h5>
            <button
              onClick={(e) => this.releaseAmount()}
              className="btn btnGreen max-WT-120 font16"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btnRed max-WT-120 ml10 font16"
              data-dismiss="modal"
              onClick={() => this.setState({ EnableshowModal: false })}
            >
              No
            </button>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.Modal}>
          <ModalBody>
            <div className="sectionHead mb15 mt15">
              <h2 className="font22 textInitial ">
                Please select the reason for cancelling the trade
              </h2>
            </div>
            <div className="radio-design-block mb10 text-left ml10">
              <label className="radio-design pt5  mb0 font16 lineHeight1 ">
                <input
                  type="radio"
                  name="option"
                  onClick={() =>
                    this.setState({
                      showTextbox: false,
                      reason1Status: true,
                      reason2Status: false,
                      reason3Status: false,
                      reasonOtherStatus: false,
                      reasonError: "",
                    })
                  }
                />
                <span />
                Incorrect trade amount
              </label>
            </div>
            <div className="radio-design-block mb10 text-left ml10">
              <label className="radio-design  pt5 mb0 font16  lineHeight1">
                <input
                  type="radio"
                  name="option"
                  onClick={() =>
                    this.setState({
                      showTextbox: false,
                      reason1Status: false,
                      reason2Status: true,
                      reason3Status: false,
                      reasonOtherStatus: false,
                      reasonError: "",
                    })
                  }
                />
                <span />
                Seller did not respond
              </label>
            </div>
            <div className="radio-design-block mb10 text-left ml10">
              <label className="radio-design  pt5 mb0 font16 lineHeight1">
                <input
                  type="radio"
                  name="option"
                  onClick={() =>
                    this.setState({
                      showTextbox: false,
                      reason1Status: false,
                      reason2Status: false,
                      reason3Status: true,
                      reasonOtherStatus: false,
                      reasonError: "",
                    })
                  }
                />
                <span />
                Trade terms did not match
              </label>
            </div>
            <div className="radio-design-block mb10 text-left ml10">
              <label className="radio-design pt5 mb0 font16 lineHeight1">
                <input
                  type="radio"
                  name="option"
                  onClick={() =>
                    this.setState({
                      showTextbox: true,
                      reason1Status: false,
                      reason2Status: false,
                      reason3Status: false,
                      reasonOtherStatus: true,
                      reasonError: "",
                    })
                  }
                />
                <span />
                Reason not listed
              </label>
              {this.state.showTextbox ? (
                <textarea
                  className="form-control height75 textareaChat mt10"
                  maxLength={100}
                  name="reason"
                  value={this.state.reason}
                  onChange={(e) => this.handleOnChange(e)}
                  placeholder="Enter your Reason here"
                />
              ) : null}
              {this.state.reasonError ? (
                <span className="error">{this.state.reasonError}</span>
              ) : null}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btnGreen pt10 pb10 max-WT-250"
                onClick={(e) => this.SubmitReason(e)}
              >
                Submit
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.AuthModal}>
          <ModalBody>
            <div className="cardBox max-WT-550 center-box">
              <div className="sectionHead mb15 borderBottom pb10 text-center">
                <h2 className="textInitial">Authentication code</h2>
              </div>
              <p>
                if you setup 2 step verification, you can use the google
                authenticator app to receive codes even if you don't have an
                internet connection or mobile service for trades & transactions.
              </p>
              <form className="mb0">
                <div className="formBox  max-WT-400 center-box">
                  <div className="form-group">
                    <div className="max-WT-120 center-box" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Authenticate code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="auth_code"
                      id="auth_code"
                      maxLength={6}
                      value={this.state.auth_code}
                      onChange={this.handlecase}
                    />
                  </div>
                  {this.state.auth_codeError !== "" ? (
                    <label className="error">{this.state.auth_codeError}</label>
                  ) : null}
                  <button
                    type="submit"
                    className="btn btnGreen p10 pb10 w-100"
                    onClick={(e) => this.activate(e)}
                  >
                    RELEASE
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.tremModal}>
          <ModalBody>
            <div className="sectionHead mb15 mt15">
              <h2 className="font22 textInitial ">Terms Of Trade</h2>
            </div>

            <div
              className="radio-design-block mb10 text-left ml10"
              dangerouslySetInnerHTML={{
                __html: this.state.userInfo["terms_of_trade"],
              }}
            >
              {/* <label className="radio-design pt5 mb0 font16 lineHeight1">
                                <input type="radio" name="option" onClick={() => this.setState({ showTextbox: true, reason1Status: false, reason2Status: false, reason3Status: false, reasonOtherStatus: true, reasonError: "" })} />
                                <span></span>Reason not listed
                                 </label> */}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btnGreen pt10 pb10 max-WT-250"
                onClick={(e) => this.closeTrems(e)}
              >
                OK
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.DisputeModal}>
          <ModalBody>
            <div className="sectionHead mb15 mt15">
              <h2 className="font22 textInitial ">
                Please Enter the reason for disputing the trade
              </h2>
            </div>

            <div className="radio-design-block mb10 text-left ml10">
              {/* <label className="radio-design pt5 mb0 font16 lineHeight1">
                                <input type="radio" name="option" onClick={() => this.setState({ showTextbox: true, reason1Status: false, reason2Status: false, reason3Status: false, reasonOtherStatus: true, reasonError: "" })} />
                                <span></span>Reason not listed
                                 </label> */}

              <textarea
                className="form-control height75 textareaChat mt10"
                maxLength={500}
                name="Disputereason"
                value={this.state.Disputereason}
                onChange={(e) => this.handleOnChange(e)}
                placeholder="Enter your Reason here"
              />

              {this.state.DisputeReasonError ? (
                <span className="error">{this.state.DisputeReasonError}</span>
              ) : null}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btnGreen pt10 pb10 max-WT-250"
                onClick={(e) => this.DisputeTrade(e)}
              >
                Submit
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.order}>
          <ModalBody>
            <span
              className="crossBtn"
              onClick={() => this.setState({ order: false })}
            >
              <i className="fa fa-times" />
            </span>

            <div className="sectionHead">
              <h2 className="font22 textInitial ">
                While Ordering a Product:-
              </h2>
            </div>
            <div className="sectionHead">
              <h3 className="font15 textInitial ">
                Choose any of the Pick Up Delivery Services to Ship an Order
              </h3>
            </div>
            <div style={{ marginLeft: 60 }}>
              <div style={{ display: "flex", marginLeft: 20 }}>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btnGreen pt10 pb10 max-WT-300"
                    style={{ width: 150 }}
                    onClick={(e) => this.open("DHL")}
                  >
                    DHL
                  </button>
                </div>
                <div className="text-center" style={{ marginLeft: 25 }}>
                  <button
                    type="submit"
                    className="btn btnGreen pt10 pb10 max-WT-300"
                    style={{ width: 150 }}
                    onClick={(e) => this.open("UPS")}
                  >
                    UPS
                  </button>
                </div>
              </div>
              <div style={{ display: "flex", marginLeft: 20, marginTop: 15 }}>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btnGreen pt10 pb10 max-WT-300"
                    style={{ width: 150 }}
                    onClick={(e) => this.open("DELHIVERY")}
                  >
                    DELHIVERY
                  </button>
                </div>
                <div className="text-center" style={{ marginLeft: 25 }}>
                  <button
                    type="submit"
                    className="btn btnGreen pt10 pb10 max-WT-300"
                    style={{ width: 150 }}
                    onClick={(e) => this.open("FedEX")}
                  >
                    FedEX
                  </button>
                </div>
              </div>
            </div>
            <div
              className="sectionHead"
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                1) Please read the terms of the trade carefully.
              </h3>
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                2) Confirm as much details of the product while ordering with
                the vendor.
              </h3>
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                3) Always share the tracking number in the trade window once you
                order the package.
              </h3>
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                4) Make sure to record the a clear video while opening the
                product which you receive.
              </h3>
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                5) Coinbaazar Dispute Moderators may ask for a video recording
                in case of a dispute.
              </h3>
              <h3 className="font15 textInitial " style={{ width: 450 }}>
                6) If the product is damaged or different please talk to vendor
                regarding replacement, or else you can raise a dispute.
              </h3>
            </div>
          </ModalBody>
        </Modal>

        <main className="mainContent">
          <section
            className="staticContentSection commonSectionStyle"
            style={{ padding: 0 }}
          >
            <div className="container1440">
              <div className="mb15 tradeStrip">
                <div>
                  {this.state.result ? (
                    <h2 className="font18 wColor  textInitial">{`Trade ID  ${
                      this.state.result["uniqueId"]
                    }:${
                      this.state._id == this.state.result["buyerId"]
                        ? "Selling"
                        : "Buying"
                    } ${
                      this.state.result["amount_of_cryptocurrency"]
                    } (${this.state.result['currency_type']} ${this.state.youGet}) bitcoins for ${this.state.result["amount_in_currency"]} ${
                      this.state.result["currency_type"]
                    } `}</h2>
                  ) : null}
                  {this.state.result ? (
                    <p className="font14 wColor mt0">{`${
                      this.state._id == this.state.result["buyerId"]
                        ? "Buying"
                        : "Selling"
                    } from advertisement ${
                      this.state.result["addUniqueId"]
                    } with (${
                      this.state.result["payment_method"]
                    }) at the exchange rate of ${Number(
                      this.state.result["exchangeRate"]
                    ).toFixed(2)} ${
                      this.state.result["currency_type"]
                    }/BTC`}</p>
                  ) : null}
                </div>
                <div className="">
                  <strong className="font16 wColor mr5 fontfamTB">
                    Trade Status:
                  </strong>
                  <p className="font14 wColor">
                    {this.state.result
                      ? `${this.state.result["status"]}.`
                      : null}
                  </p>
                </div>
              </div>

              <form className="mb0">
                <div className="row colCenter">
                  <div className="col-md-5">
                    {this.state.tradeCancelled == undefined ? (
                      <div className="cardBox mt50 height500">
                        <div className="cardHead mb15 text-center">
                          <h4 className="textInitial">Payment Confirmation</h4>
                        </div>
                        {/* <p
                          className="cnfrmtext"
                          style={{ color: "var(--text-grey)" }}
                        >
                          After receiving payment from the buyer, you can
                          release the bitcoins held in escrow by clicking the
                          "Release BTC" button. Bitcoins cannot be reclaimed
                          once they have been released 111.
                        </p> */}
                        <div className="text-center pb20">
                          {this.state.paid_status ? (
                            <div>
                              <p
                                className="cnfrmtext"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Once you have made a payment make sure you click
                                on "I have paid" button. Otherwise the trade
                                will get timed out and gets cancelled
                                automatically and bitcoins will return to the
                                vendor.
                              </p>
                              <button
                                type="button"
                                className="btn btnGreenmax-WT-190"
                                disabled
                              >
                                Paid
                              </button>
                            </div>
                          ) : this.state.tradeDisputed ? (
                            <div>
                              <p
                                className="cnfrmtext"
                                style={{ color: "var(--text-grey)" }}
                              >
                                You can report a dispute if you think your trade
                                partner is not paying you or replying you. Some
                                payment methods might take several days to
                                confirm payment. Please open dispute if you have
                                made the payment and there is an issue from your
                                partner.
                              </p>
                              <button
                                type="button"
                                className="btn btnRed cur max-WT-190"
                                onClick={() => this.TRADEDISPUTED()}
                                disabled
                              >
                                Trade Disputed
                              </button>
                              {this.state._id !==
                              this.state.result["buyerId"] ? (
                                <div>
                                  {this.state._id !==
                                  this.state.result["buyerId"] ? (
                                    <p
                                      className="cnfrmtext"
                                      style={{ color: "var(--text-grey)" }}
                                    >
                                      After receiving payment from the buyer,
                                      you can release the bitcoins held in
                                      escrow by clicking the "Release BTC"
                                      button. Bitcoins cannot be reclaimed once
                                      they have been released.
                                    </p>
                                  ) : (
                                    <p
                                      className="mt30"
                                      style={{ color: "var(--text-grey)" }}
                                    >
                                      You can report a dispute if you think your
                                      trade partner is not paying you or
                                      replying you. Some payment methods might
                                      take several days to confirm payment.
                                      Please open dispute if you have made the
                                      payment and there is an issue from your
                                      partner.{" "}
                                    </p>
                                  )}
                                  <button
                                    type="button"
                                    className="btn btnGreen max-WT-190"
                                    onClick={() =>
                                      this.state.G2FA
                                        ? this.setState({ AuthModal: true })
                                        : this.ReleaseBTC()
                                    }
                                  >
                                    Release BTC
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          ) : this.state.tradeCompleted ? (
                            <button
                              type="button"
                              className="btn btnGreen max-WT-190"
                              disabled
                            >
                              Trade Completed
                            </button>
                          ) : this.state.Dispute ? (
                            <div>
                              <p
                                className="mt30"
                                style={{ color: "var(--text-grey)",textAlign:"center" }}
                              >
                                You can report a dispute if you think your trade
                                partner is not paying you or replying you. Some
                                payment methods might take several days to
                                confirm payment. Please open dispute if you have
                                made the payment and there is an issue from your
                                partner.{" "}
                              </p>
                              <button
                                type="button"
                                className="btn btnRed max-WT-190"
                                onClick={() =>
                                  this.setState({ DisputeModal: true })
                                }
                              >
                                Dispute
                              </button>
                            </div>
                          ) : this.state.buttonText === "I have paid" ? (
                            <div>
                              <p
                                className="cnfrmtext"
                                style={{ color: "var(--text-grey)" }}
                              >
                                Once you have made a payment make sure you click
                                on "I have paid" button. Otherwise the trade
                                will get timed out and gets cancelled
                                automatically and bitcoins will return to the
                                vendor.
                              </p>
                              <button
                                type="button"
                                className="btn btnGreen max-WT-190"
                                onClick={() => this.iHavePaid()}
                              >
                                {this.state.buttonText}
                              </button>
                            </div>
                          ) : this.state.buttonText === "Release BTC" ? (
                            this.state.test ? (
                              <div>
                                <p
                                  className=""
                                  style={{ color: "var(--text-grey)" }}
                                >
                                  After receiving payment from the buyer, you
                                  can release the bitcoins held in escrow by
                                  clicking the "Release BTC" button. Bitcoins
                                  cannot be reclaimed once they have been
                                  released.
                                </p>
                                <button
                                  type="button"
                                  className="btn btnGreen max-WT-190"
                                  onClick={() =>
                                    this.state.G2FA
                                      ? this.setState({ AuthModal: true })
                                      : this.ReleaseBTC()
                                  }
                                >
                                  {this.state.buttonText}
                                </button>
                                <p
                                  className="mt20"
                                  style={{ color: "var(--text-grey)" }}
                                >
                                  You can report a dispute if you think your
                                  trade partner is not paying you or replying
                                  you. Some payment methods might take several
                                  days to confirm payment. Please open dispute
                                  if you have made the payment and there is an
                                  issue from your partner.{" "}
                                </p>
                                <button
                                  type="button"
                                  className="btn btnRed max-WT-190 mt10"
                                  onClick={() =>
                                    this.setState({ DisputeModal: true })
                                  }
                                >
                                  Dispute
                                </button>
                              </div>
                            ) : (
                              <div>
                                <p
                                  className="cnfrmtext"
                                  style={{ color: "var(--text-grey)" }}
                                >
                                  After receiving payment from the buyer, you
                                  can release the bitcoins held in escrow by
                                  clicking the "Release BTC" button. Bitcoins
                                  cannot be reclaimed once they have been
                                  released.
                                </p>
                                <button
                                  type="button"
                                  className="btn btnGreen max-WT-190"
                                  onClick={() =>
                                    this.state.G2FA
                                      ? this.setState({ AuthModal: true })
                                      : this.ReleaseBTC()
                                  }
                                >
                                  {this.state.buttonText}
                                </button>
                              </div>
                            )
                          ) : (
                            <button
                              type="button"
                              className="btn btnGreen max-WT-190"
                            >
                              {" "}
                              <img
                                src={require("../assets/ajax-loader.gif")}
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                            </button>
                          )}
                        </div>
                        {this.state.remainingPaymentTime ? (
                          this.state.tradeCompleted ? null : (
                            <div
                              id="timerId"
                              className="cnfrmtext pb25"
                              style={{ color: "var(--text-grey)" }}
                            >
                              <span className="countCss">
                                {setTimeout(() => {
                                  this.counterFun(
                                    this.state.remainingPaymentTime,
                                    this.state.flag
                                  );
                                }, 100)}
                              </span>
                            </div>
                          )
                        ) : // <div className="ml15">

                        //     <Countdown date={Date.now() + this.state.remainingPaymentTime * 1000}
                        //         onComplete={() => {
                        //             setTimeout(() => {
                        //                 this.tradeDetail();
                        //             }, 2000);
                        //         }}
                        //     >

                        //     </Countdown>
                        // </div>

                        null}
                      </div>
                    ) : null}
                    {this.state.tradeCancelled ? (
                      <div className="cardBox mt50 height500">
                        <div className="cardHead mb15 text-center">
                          <h4 className="textInitial">Have trading Issue?</h4>
                        </div>
                        <p
                          className="cnfrmtext"
                          style={{ color: "var(--text-grey)" }}
                        >
                          You can always cancel the trade if it was started by
                          mistake, or if you don't meet the requirements
                          mentioned in the trade instructions.
                        </p>
                        <div className="text-center pb25">
                          <button
                            type="button"
                            className="btn btnRed max-WT-190"
                            disabled
                          >
                            Trade cancelled
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {/* <button type="button" className="btn btnRed pt10 pb10 max-WT-250" >Trade cancelled</button>: */}
                  </div>
                  {/* chat-section */}

                  <div className="col-md-5">
                    <div className="cardBox mt50 height500">
                      <div className="formBox">
                        <div className="tradePr mb10">
                          <div className="cardHead mb15 ">
                            {this.state.name ? (
                              <div
                              className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width:'100%'
                                }}
                              >
                                <div style={{width:'50%'}}>
                                <h4
                                  onClick={(e) =>
                                    this.viewDetail(
                                      e,
                                      this.state.opponent_user_id
                                    )
                                  }
                                >
                                  Send message to{" "}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {" "}
                                    {this.state.name}{" "}
                                  </span>
                                </h4>
                                </div>
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width:'50%'
                                    }}
                                  >
                                    <i
                                      style={{ color: this.state.activeColor }}
                                      className="dot fa fa-circle"
                                    />
                                    <h4
                                      className="justify-content-start"
                                      style={{fontSize:16}}
                                    >
                                      {" "}
                                      {this.state.activeStatus}
                                    </h4>
                                </div>
                                {/* {this.state.activeStatus.toLocaleLowerCase() ==
                                "online" ? (
                                  <div
                                    style={{
                                      width: "20%",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ color: this.state.activeColor }}
                                      className="dot fa fa-circle"
                                    />
                                    <h4
                                      className="textInitial"
                                      style={{ marginLeft: -10, marginTop: 5 }}
                                    >
                                      {" "}
                                      {this.state.activeStatus}
                                    </h4>
                                  </div>
                                ) : this.state.activeStatus.toLocaleLowerCase() ==
                                "away" ? (
                                  <div
                                    style={{
                                      width: "20%",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ color: this.state.activeColor }}
                                      className="dot fa fa-circle"
                                    />
                                    <h4
                                      className="textInitial"
                                      style={{ marginLeft: -10, marginTop: 5 }}
                                    >
                                      Away
                                    </h4>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: "20%",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ color: this.state.activeColor }}
                                      className="dot fa fa-circle"
                                    />
                                    <h4
                                      className="textInitial"
                                      style={{ marginLeft: -10, marginTop: 5 }}
                                    >
                                      Offline
                                    </h4>
                                  </div>
                                )} */}
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ marginLeft: "10px" }}
                              className="tradeAln"
                              onClick={() => this.setState({ order: true })}
                            >
                              Order a Product
                            </div>
                            <div
                              className="tradeAln"
                              onClick={() => this.tremsTrad()}
                            >
                              Terms Of Trade
                            </div>
                          </div>
                        </div>
                        <ul className="chat tradingChatList" id="chat-ul-list">
                          {this.state.populateAllChats.length !== 0 ? (
                            this.state.populateAllChats
                          ) : (
                            <p />
                          )}
                          {/* <div className="chatCommonBlock">
                                                            <p>rgrthrthrthththrgrthrthrththth rgrthrthrththth rgrthrthrththth rgrthrthrththth</p>
                                                        </div>
                                                        <div className="chatCommonBlock otherside">
                                                            <p>rgrthrthrthththrgrthrthrththth rgrthrthrththth rgrthrthrththth rgrthrthrthththrgrthrthrthththrgrthrthrththth rgrthrthrththth rgrthrthrththth rgrthrthrththth</p>
                                                        </div> */}
                        </ul>
                        {/* {this.state.loader? 
                                                <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px" }} alt="" /></span>
                                                : 
                                                <span></span>} */}

                        <div
                          className="d-flex flex-wrap uploadWithButton colcentr"
                          // style={{ height: "fit-content", minHeight: 40 }}
                        >
                          <textarea
                            className="form-control textareaChat"
                            style={{ height: 40, overflow: "hidden" }}
                            name="textmessage"
                            id="chatbox"
                            onKeyPress={(e) => {
                              if (e.which == 13) {
                                this.sendMessage(e);
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.which == 13) {
                                this.sendMessage(e);
                              } else {
                                this.handleupdateFiled(e);
                              }
                            }}
                            value={this.state.textmessage}
                            placeholder="Enter your message"
                            ref={this.firstRef}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              gap: 5,
                              marginLeft: 5,
                            }}
                          >
                            {this.state.loader ? (
                              <button
                                type="button"
                                className="btn btnGreen minWidth90 "
                                style={{ height: 40 }}
                              >
                                {" "}
                                <span>
                                  <img
                                    src={require("../assets/ajax-loader.gif")}
                                    style={{ height: "25px", width: "25px" }}
                                    alt=""
                                  />
                                </span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btnGreen minWidth90 ml-auto "
                                onClick={(e) => this.sendMessage(e)}
                                style={{ height: 40 }}
                              >
                                Send
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn btnGreen ml-auto "
                              style={{ height: 40 }}
                            >
                              {" "}
                              <i className="fas fa-upload" />{" "}
                            </button>
                          </div>
                          <input
                            type="file"
                            id="file_data1"
                            name="file_data1"
                            accept=".jpg, .jpeg, .png, .mp4, video/*"
                            className="form-control uploadBtn"
                            value={this.state.file_data1}
                            onChange={(e) => this.verify(e)}
                          />
                          {/* <span style={{marginLeft:300,backgroundColor:'red'}} >Upload</span> */}
                        </div>
                        <div className="spanpad">
                          {this.state.TextError ? (
                            <span className="error">
                              {this.state.TextError}
                            </span>
                          ) : null}
                          {this.state.showFile1 ? (
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  overflow: "clip",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span style={{ color: "green" }}>
                                  {this.state.showFile1}
                                </span>
                              </div>
                              <span
                                style={{ marginLeft: 10, cursor: "pointer" }}
                                onClick={() => this.setState({ showFile1: "" })}
                              >
                                <i className="fa fa-times" />
                              </span>
                            </div>
                          ) : null}
                        </div>

                        {/* <div className="form-group d-flex flex-wrap uploadWithButton mb25">
                                                    <div className="kycfile-uplaod">
                                                        <input type="file" id="file_data1" name="file_data1" accept=".jpg, .jpeg, .png" className="form-control" value={this.state.file_data1} onChange={(e) => this.verify(e)} />
                                                        <span className="label-control">Upload</span>
                                                        <i className="fas fa-paperclip"></i>
                                                    </div>


                                                </div> */}

                        {/* <div className="label-control">Media</div> */}
                        {/* <div className="my-carousel">
                                                        <Carousel images={images} 
                                                                    thumb={true}
                                                                    loop={true}
                                                                    autoplay={3000}/>
                                                    </div> */}
                        {/* {this.callView()} */}

                        {/* <div id="thumbnail-slider">
                    <div className="inner">
                        <ul> */}
                        {/* <Slider {...settings}>
                                                    {this.state.ImagesData ? this.state.ImagesData : null} */}
                        {/* <li>
                                <img className="thumb" src={require('../assets/images/Alert.png')}></img>
                            </li> */}

                        {/* </Slider> */}
                        {/* </ul>
                    </div>
                </div> */}
                      </div>
                      <div />
                    </div>
                  </div>
                </div>
                <div className="row colCenter">
                  <div className="col-md-10">
                    {this.state.tradeCancelled ? null : !this.state // </div> //     </div> //         <button type="button" className="btn btnRed max-WT-190" disabled>Trade cancelled</button> //     <div className="text-center pb25"> //     You can always cancel the trade if it was started by mistake, or if you don't meet the requirements mentioned in the trade instructions.</p> //     <p className="cnfrmtext"> //     </div> //         <h4 className="textInitial">Have trading Issue?</h4> //     <div className="cardHead mb15 text-center"> // <div className="cardBox mt15">
                      .tradeCompleted ? (
                      this.state.result ? (
                        this.state._id == this.state.result["buyerId"] ? (
                          <div className="cardBox mt15">
                            <div className="cardHead mb15 text-center">
                              <h4 className="textInitial">
                                Have trading Issue?
                              </h4>
                            </div>
                            <p
                              className="cnfrmtext"
                              style={{ color: "var(--text-grey)" }}
                            >
                              You can always cancel the trade if it was started
                              by mistake, or if you don't meet the requirements
                              mentioned in the trade instructions.{" "}
                            </p>
                            <div className="text-center pb25">
                              <button
                                type="button"
                                className="btn btnGreen max-WT-190"
                                onClick={() => this.setState({ Modal: true })}
                              >
                                Cancel the Purchase
                              </button>
                            </div>
                          </div>
                        ) : null
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="row colCenter">
                  <div className="col-md-10">
                    {this.state.tradeCompleted ? (
                      this.state.showFeedback ? (
                        <div className="cardBox mt35 pb20">
                          <div className="cardHead mb15">
                            {this.state.name ? (
                              <h4 className="textInitial">
                                Please Leave a feedback for {this.state.name}
                              </h4>
                            ) : null}
                          </div>
                          <div className="radio-design-block colPad">
                            <label className="radio-design  largeRadio mb0 font16 weight600 lineHeight1 dBlueColor">
                              <input
                                type="radio"
                                name="option"
                                value={this.state.feedbackPositive}
                                onChange={() =>
                                  this.setState({
                                    feedbackPositive: true,
                                    feedbackNegative: false,
                                    feedbackNeutral: false,
                                    feedbackError: "",
                                  })
                                }
                              />
                              <span />
                              Positive
                              <p className="mb0 font12 fontfamQR greyColor">
                                Positive feedback will improve your partner
                                rating
                              </p>
                            </label>
                          </div>

                          <div className="radio-design-block colPad">
                            <label className="radio-design largeRadio mb0 font16 weight600 lineHeight1 dBlueColor">
                              <input
                                type="radio"
                                name="option"
                                value={this.state.feedbackNeutral}
                                onChange={() =>
                                  this.setState({
                                    feedbackPositive: false,
                                    feedbackNegative: false,
                                    feedbackNeutral: true,
                                    feedbackError: "",
                                  })
                                }
                              />
                              <span />
                              Neutral
                              <p className="mb0 font12 fontfamQR greyColor">
                                Neutral feedback will not affect your partner
                                rating
                              </p>
                            </label>
                          </div>
                          <div className="radio-design-block colPad">
                            <label className="radio-design largeRadio mb0 font16 weight600 lineHeight1 dBlueColor">
                              <input
                                type="radio"
                                name="option"
                                value={this.state.feedbackNegative}
                                onChange={() =>
                                  this.setState({
                                    feedbackPositive: false,
                                    feedbackNegative: true,
                                    feedbackNeutral: false,
                                    feedbackError: "",
                                  })
                                }
                              />
                              <span />
                              Negative
                              <p className="mb0 font12 fontfamQR greyColor">
                                Negative feedback will decrease your partner
                                rating
                              </p>
                            </label>
                            <label className="error">
                              {this.state.feedbackError}
                            </label>
                          </div>

                          <div className="colPad pt0">
                            <textarea
                              className="form-control minHeight150"
                              name="feedback"
                              value={this.state.feedback}
                              placeholder="Enter your feedback here (optional)"
                              onChange={(e) => this.handleOnChange(e)}
                            />
                          </div>
                          <div className="text-center colPad">
                            {this.state.sendFeedback == false ? (
                              <button
                                type="button"
                                className="btn btnGreen max-WT-190"
                                onClick={(e) => this.feedback(e)}
                              >
                                Send
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btnGreen max-WT-190"
                              >
                                Send
                              </button>
                            )}
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </section>
        </main>
        <Modal
          isOpen={this.state.showImage}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
            maxWidth: "680px",
            margintop: "60px",
          }}
        >
          <div
            className="cardBox max-WT-950 center-box"
            style={{ backgroundColor: "var(--white-color)" }}
          >
            <div className="cardHead center-box pb10 text-center posRel">
              <span
                className="crossBtn"
                onClick={() => this.setState({ showImage: false })}
              >
                <i className="fa fa-times" />
              </span>

              <h4 className="font18 mt0 mb5 text-center">Image/Video</h4>
            </div>
            {/* <Slider {...setting} buttons={true}>
              {this.state.prodImg.map((item, index) => {
                return (
                  <div
                    className=""
                    key={item}
                    // style={{
                    //   borderRadius: 6,
                    //   marginRight: 24,
                    //   textAlign: "center",
                    //   width: "100%",
                    //   // minHeight: "100%",
                    //   padding: 10,
                    // }}
                  >
                    <a
  href="https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
 >
   <i className="fa fa-download" />
 </a>
                    <div
                      className="slideimg"
                      style={{ width: "100%", height: "230px" }}
                    >
                      <img
                        style={{ objectFit: "contain" }}
                        src={item}
                        alt="Image"
                      />
                    </div>
                    
                  </div>
                );
              })}
            </Slider> */}
            <div
              className="container1440 prodImg"
              style={{ backgroundColor: "var(--white-color)" }}
            >
              <Slider {...setting} buttons={true}>
                {this.state.prodImg.map((item, index) => {
                  // console.log("this.state.prodImg", this.state.prodImg, index);
                  return (
                    <div className="slideimgbox boxsize" key={item}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <a
                          href={item}
                          id="downloadImage"
                          // target="_blank"
                          // download
                          onClick={(e) => this.forceDownload(item, e)}
                        >
                          <i className="fa fa-download" />
                        </a>
                      </div>
                      <div
                        className="slideimg"
                        style={{ width: "100%", height: "100%" }}
                      >
                        {item && item.split("/").includes("image") ? (
                          <img
                            style={{ objectFit: "contain" }}
                            src={item}
                            alt="Image"
                          />
                        ) : (
                          <video width="320" height="240" controls>
                            <source src={item} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="slidetext">
              <p style={{ marginTop: "4px", color: "var(--text-grey)" }}>
                Disclaimer: The product or service images shown are for
                illustration purposes only and
                <br /> may not be an exact representation of the product.
              </p>
            </div>
          </div>
        </Modal>

        <Footer {...this.props} />
      </div>
    );
  }
}
