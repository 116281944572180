// import React, { Component } from "react"

// /* Module */
// import { ToastContainer, toast } from 'react-toastify';
// import { toast_func } from "../components/toaster";
// import 'react-toastify/dist/ReactToastify.css';
// import { ClipLoader } from 'react-spinners';
// import Pagination from "rc-pagination"

// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

// /* components */
// import Header from '../components/header';
// import Footer from '../components/footer';

// /* JSON */

// import currencyJson from "../JSON/currency.json"
// import _country from "../JSON/country.json"

// /* services */
// import { Api } from "../services/webservices"
// import { base32 } from "../services/Decrypt"

// export default class Cointab extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             tab: "buy",
//             currency: "",
//             Amount: "",
//             paymentMethod: [],
//             country: "",
//             bank: "",
//             whole_data: "",
//             whole_data1: [],
//             whole_data2: [],
//             tutorial: false,
//             tutorialinc: false

//         }
//     }
//     componentDidMount() {
//         window.scrollTo(0, 0);
//         this.setState({ loading: true })
//         this.setState({ tab: this.props.match.params.type })
//         var addr = localStorage.getItem("Address")
//         var id = localStorage.getItem("data")
//         var token = localStorage.getItem("token")
//         setTimeout(() => {
//             this.setState({ _id: id, token: token, addr: addr }, () => this.stateApis())

//         }, 300);

//         Api("trade/paymentMethodList", "GET", {}, this.state.token, this.state._id).then(
//             (resp) => {
//                 this.setState({ loading: false })

//                 try {
//                     if (resp.status === 200) {
//                         if (resp.data.responseCode === 409 || resp.data.responseCode === 410 || resp.data.responseCode === 411) {
//                             localStorage.removeItem("data");
//                             localStorage.removeItem("isLoggedin");
//                             localStorage.removeItem("data1");
//                             localStorage.removeItem("token");

//                             this.props.history.push("/")
//                         }
//                         else if (resp.data.responseCode == 200) {
//                             this.setState({ paymentMethod: resp.data.result })

//                         }
//                         else if (resp.data.responseCode == 502) {
//                             this.props.history.push("NotFound")

//                         }
//                     }
//                     else {

//                     } //toast_func("error", "Some problem occured please contact administrator")
//                 }
//                 catch (ex) {
//                     toast_func("error", "Some problem occured please contact administrator")
//                 }
//             }
//         )
//         this.loadBuyData()

//     }
//     stateApis = () => {
//         return

//         Api(`user/deposits_save/${this.state.addr}/${this.state._id}`, "GET", {}, this.state.token, this.state._id)
//         Api(`user/transferToAdminAccount/${this.state.addr}/${this.state._id}`, "GET", {}, this.state.token, this.state._id)
//     }
//     loadBuyData = (e) => {

//         var parameter1 = {
//             "type": "buy",
//         }

//         this.setState({ loading: true })
//         Api("trade/filter_trade", "POST", parameter1, this.state.token, this.state._id).then(
//             (resp) => {
//                 this.setState({ loading: false })
//                 try {
//                     if (resp.status === 200) {
//                         if (resp.data.responseCode === 200) {
//                             if (resp.data.responseCode === 409 || resp.data.responseCode === 410 || resp.data.responseCode === 411) {
//                                 localStorage.removeItem("data");
//                                 localStorage.removeItem("isLoggedin");
//                                 localStorage.removeItem("data1");
//                                 localStorage.removeItem("token");

//                                 this.props.history.push("/")

//                             }
//                             else if (resp.data.result.docs.length !== 0) {
//                                 this.setState({ whole_data1: resp.data.result.docs })

//                             }
//                             else {
//                                 //toast_func("error", "No data matched as per your search")

//                             }

//                         }
//                         else {
//                             //toast_func("error", resp.data.responseMessage)

//                         }
//                     }
//                     else {
//                         // toast_func("error", resp.data.responseMessage)

//                     }
//                 }
//                 catch (ex) {
//                     toast_func("error", "Some problem occured please contact administrator")
//                 }
//             }
//         )
//         this.setState({ loading: true })
//     }

//     loadSellData = () => {
//         var parameter2 = {
//             "type": "sell",
//         }
//         Api("trade/filter_trade", "POST", parameter2, this.state.token, this.state._id).then(
//             (resp) => {
//                 this.setState({ loading: false })

//                 try {
//                     if (resp.status == 200) {
//                         if (resp.data.responseCode === 409 || resp.data.responseCode === 410 || resp.data.responseCode === 411) {
//                             localStorage.removeItem("data");
//                             localStorage.removeItem("isLoggedin");
//                             localStorage.removeItem("data1");
//                             localStorage.removeItem("token");
//                             toast_func("error", resp.data.responseMessage)
//                             setTimeout(() => {
//                                 this.props.history.push("/")
//                             }, 1000);
//                         }
//                         else if (resp.data.responseCode == 200) {
//                             if (resp.data.result.docs.length != 0) {
//                                 this.setState({ whole_data2: resp.data.result.docs })

//                             }
//                             else {
//                                 //toast_func("error", "No data matched as per your search")

//                             }

//                         }
//                         else if (resp.data.responseCode == 502) {
//                             this.props.history.push("NotFound")

//                         }
//                         else {
//                             //toast_func("error", resp.data.responseMessage)

//                         }

//                     }
//                     else {
//                         //toast_func("error", resp.data.responseMessage)

//                     }
//                 }
//                 catch (ex) {
//                     toast_func("error", "Some problem occured please contact administrator")
//                 }
//             }
//         )
//     }
//     populateTagSell = (i) => {
//         let a = []
//         for (var k = 0; k < this.state.whole_data2[i]["add_tags"].length; k++) {

//             a.push(<span className="badge">{this.state.whole_data2[i]["add_tags"][k]}</span>)
//         }
//         return (a)
//     }
//     populateTagBuy = (i) => {

//         let a = []
//         for (var k = 0; k < this.state.whole_data1[i]["add_tags"].length; k++) {
//             a.push(<span className="badge">{this.state.whole_data1[i]["add_tags"][k]}</span>)
//         }
//         return (a)
//     }
//     Populate_currency = () => {
//         let currencyArray = []
//         for (var i = 0; i < currencyJson.length; i++) {
//             currencyArray.push(<option>{currencyJson[i].code}</option>)
//         }
//         return currencyArray;
//     }
//     Populate_country = () => {
//         let __array = []
//         for (var i = 0; i < _country.length; i++) {
//             __array.push(<option>{_country[i].name}</option>)
//         }
//         return __array;
//     }
//     Populate_paymentMethods = () => {
//         let __array = []
//         for (var i = 0; i < this.state.paymentMethod.length; i++) {
//             __array.push(<option>{this.state.paymentMethod[i].name}</option>)
//         }
//         return __array;
//     }
//     onClick_currency = (e) => {
//         e.preventDefault()
//         var value = e.target.value
//         if (value == "Select currency") {
//             this.setState({ currency: "", currencyError: "" })
//         }
//         else {
//             this.setState({ currency: value, currencyError: "" })
//         }
//     }
//     handleOnChange = (e) => {
//         e.preventDefault();
//         let type = e.target.name
//         let value = e.target.value

//         if (type == 'Amount') {

//             if (!isNaN(value) && (value !== " ")) {
//                 this.setState({ [type]: value })
//             }
//         }
//     }
//     onClick_country = (e) => {
//         e.preventDefault()
//         var value = e.target.value

//         if (value == "Select Country") this.setState({ country: "", countryError: "" })
//         else this.setState({ country: value, countryError: "" })
//     }
//     onClick_paymentBank = (e) => {
//         e.preventDefault()
//         var value = e.target.value
//         if (value == "All Online offers") this.setState({ bank: "", bankError: "" })
//         else this.setState({ bank: value, bankError: "" })

//     }
//     searchFilter = (e) => {

//         e.preventDefault()
//         this.setState({ whole_data1: "", whole_data2: "" })
//         var parameter = {
//             "type": "buy",
//             "payment_method": this.state.bank == "All Online offers" ? "" : this.state.bank,
//             "amount": this.state.Amount,
//             "location": this.state.country,
//             "currency_type": this.state.currency

//         }
//         var parameter1 = {
//             "type": "sell",
//             "payment_method": this.state.bank == "All Online offers" ? "" : this.state.bank,
//             "amount": this.state.Amount,
//             "location": this.state.country,
//             "currency_type": this.state.currency

//         }
//         this.setState({ loader1: true })
//         Api("trade/filter_trade", "POST", parameter, this.state.token, this.state._id).then(
//             (resp) => {
//                 this.setState({ loader1: false })
//                 try {
//                     if (resp.status == 200) {
//                         if (resp.data.responseCode == 200) {

//                             if (resp.data.responseCode == 409 || resp.data.responseCode == 410 || resp.data.responseCode == 411) {
//                                 localStorage.removeItem("data");
//                                 localStorage.removeItem("isLoggedin");
//                                 localStorage.removeItem("data1");
//                                 localStorage.removeItem("token");

//                                 this.props.history.push("/")

//                             }

//                             if (resp.data.responseCode == 200) {
//                                 if (resp.data.result.docs.length != 0) {

//                                     this.setState({ whole_data1: resp.data.result.docs })
//                                 }
//                                 else {
//                                     // toast_func("error", "No data matched for BUY as per your search")
//                                 }

//                             }
//                             else if (resp.data.responseCode == 502) {
//                                 this.props.history.push("NotFound")

//                             }
//                             else {
//                                 //toast_func("error", "No data matched as per your search")

//                             }

//                         }
//                         else {
//                             toast_func("error", resp.data.responseMessage)

//                         }
//                     }
//                     else {
//                         toast_func("error", resp.data.responseMessage)

//                     }
//                 }
//                 catch (ex) {
//                     toast_func("error", "Some problem occured please contact administrator")
//                 }

//             })

//         this.setState({ loader2: true })
//         Api("trade/filter_trade", "POST", parameter1, this.state.token, this.state._id).then(
//             (resp) => {
//                 this.setState({ loader2: false })
//                 try {
//                     if (resp.status == 200) {
//                         if (resp.data.responseCode == 200) {

//                             if (resp.data.responseCode == 409 || resp.data.responseCode == 410 || resp.data.responseCode == 411) {
//                                 localStorage.removeItem("data");
//                                 localStorage.removeItem("isLoggedin");
//                                 localStorage.removeItem("data1");
//                                 localStorage.removeItem("token");

//                                 this.props.history.push("/")

//                             }
//                             else if (resp.data.responseCode == 502) {
//                                 this.props.history.push("NotFound")

//                             }
//                             if (resp.data.responseCode == 200) {
//                                 if (resp.data.result.docs.length != 0) {

//                                     this.setState({ whole_data2: resp.data.result.docs })
//                                 }
//                                 else {
//                                     // toast_func("error", "No data matched for BUY as per your search")
//                                 }

//                             }
//                             else {
//                                 //toast_func("error", "No data matched as per your search")

//                             }

//                         }
//                         else {
//                             toast_func("error", resp.data.responseMessage)

//                         }
//                     }
//                     else {
//                         toast_func("error", resp.data.responseMessage)

//                     }
//                 }
//                 catch (ex) {
//                     toast_func("error", "Some problem occured please contact administrator")
//                 }

//             })

//     }

//     viewDetail = (e) => {
//         e.preventDefault();
//         var id = e.target.id
//         var encryptData = base32.encode(id)
//         this.props.history.push(`/user-details/${encryptData}`)
//     }
//     populate_view1 = () => {
//         console.log('==========populate_view1========================')
//         let table = [];
//         if (this.state.loader1 == true) {
//             return <td colspan="6" align="center"><span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px" }} alt="" /></span></td>
//         }
//         else if (this.state.whole_data1.length == 0) {

//             return <tr>
//                 <td colspan="6" align="center"> <label className="error text-center">Sorry no record found</label></td>
//             </tr>
//         }
//         // Outer loop to create parent
//         for (let i = 0; i < ((this.state.whole_data1.length < 5) ? this.state.whole_data1.length : 5); i++) {

//             let children = []
//             //Inner loop to create children
//             for (let j = 0; j <= 5; j++) {
//                 if (j == 0) {
//                     children.push(<td><a className="anchor-styling" id={`${this.state.whole_data1[i]["user_id"]}`} onClick={(e) => this.viewDetail(e)}>{`${this.state.whole_data1[i]["user_name"]}`}</a></td>)
//                 }
//                 else if (j == 1) {

//                     children.push(<td>{`${this.state.whole_data1[i]["payment_method"]}`}
//                         <p className="mb-0 tags-pay">
//                             {this.populateTagBuy(i)}
//                             {/* <span className="badge">Primary</span>
//                       <span className="badge">Primary</span> */}
//                         </p>
//                     </td>)
//                 }
//                 else if (j == 2) {
//                     if (this.state.whole_data1[i]["price_equation"] !== undefined) {
//                         var num = Number(this.state.whole_data1[i]["price_equation"])
//                         var n = num.toFixed(2);
//                         var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//                         var n2 = n.split(".")[1]
//                         children.push(<td>{`${n1 + "." + n2 + " " + this.state.whole_data1[i]["currency_type"]}`}</td>)
//                     }
//                     else children.push(<td>0</td>)
//                 }
//                 else if (j == 3) {
//                     let rap = Number(this.state.whole_data1[i]["toPay"])
//                     let tap = rap.toFixed(2);
//                     this.state.whole_data1[i]["toPay"] ?
//                         children.push(<td style={{ width: "100px" }} >{"$" + `${tap}`}</td>)
//                         :
//                         children.push(<td style={{ width: "100px" }} >$0</td>)
//                 }
//                 else if (j == 4) {
//                     children.push(<td>{`${this.state.whole_data1[i]["min_transaction_limit"] + "-" + this.state.whole_data1[i]["max_transaction_limit"] + " " + this.state.whole_data1[i]["currency_type"]}`}</td>)
//                 }
//                 else if (j == 5) {
//                     if (this.state.tab == "buy") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data1[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">BUY</a></td>)
//                     }
//                     else if (this.state.tab == "sell") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data1[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">SELL</a></td>)

//                     }
//                 }
//             }
//             //Create the parent and add the children
//             table.push(<tr>{children}</tr>)
//         }
//         return table
//     }
//     populate_view1ShowMore = () => {

//         let table = [];
//         console.log('========populate_view1ShowMore==========')
//         if (this.state.whole_data1.length == 0) {
//             return <tr>
//                 <td colspan="6" align="center"> <label className="error text-center">Sorry no record found</label></td>
//             </tr>
//         }
//         // Outer loop to create parent
//         for (let i = 0; i < this.state.whole_data1.length; i++) {
//             console.log(i, '==================')

//             let children = []
//             //Inner loop to create children
//             for (let j = 0; j <= 5; j++) {
//                 if (j == 0) {
//                     children.push(<td><a className="anchor-styling" id={`${this.state.whole_data1[i]["user_id"]}`} onClick={(e) => this.viewDetail(e)}>{`${this.state.whole_data1[i]["user_name"]}`}</a></td>)
//                 }
//                 else if (j == 1) {

//                     children.push(<td>{`${this.state.whole_data1[i]["payment_method"]}`}
//                         <p className="mb-0 tags-pay">
//                             {this.populateTagBuy(i)}
//                             {/* <span className="badge">Primary</span>
//                       <span className="badge">Primary</span> */}
//                         </p>
//                     </td>)
//                 }
//                 else if (j == 2) {
//                     if (this.state.whole_data1[i]["price_equation"] !== undefined) {
//                         var num = Number(this.state.whole_data1[i]["price_equation"])
//                         var n = num.toFixed(2);
//                         var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//                         var n2 = n.split(".")[1]

//                         children.push(<td>{`${n1 + "." + n2 + " " + this.state.whole_data1[i]["currency_type"]}`}</td>)
//                     }
//                     else children.push(<td>0</td>)
//                 }
//                 else if (j == 3) {

//                     let num = Number(this.state.whole_data1[i]["toPay"])
//                     let num1 = num.toFixed(2)
//                     this.state.whole_data1[i]["toPay"] ?
//                         children.push(<td style={{ width: "100px" }} >{"$" + `${num1}`}</td>)
//                         :
//                         children.push(<td style={{ width: "100px" }} >$0</td>)
//                 }
//                 else if (j == 4) {
//                     children.push(<td>{`${this.state.whole_data1[i]["min_transaction_limit"] + "-" + this.state.whole_data1[i]["max_transaction_limit"] + " " + this.state.whole_data1[i]["currency_type"]}`}</td>)
//                 }

//                 else if (j == 5) {
//                     if (this.state.tab == "buy") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data1[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">BUY</a></td>)
//                     }
//                     else if (this.state.tab == "sell") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data1[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">SELL</a></td>)

//                     }
//                 }
//             }
//             //Create the parent and add the children
//             table.push(<tr>{children}</tr>)
//         }
//         return table
//     }
//     populate_view2 = () => {
//         let table = [];

//         if (this.state.loader2 == true) {
//             return <td colspan="6" align="center"><span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px" }} alt="" /></span></td>
//         }
//         else if (this.state.whole_data2.length == 0) {
//             return <tr>
//                 <td colspan="6" align="center"> <label className="error text-center">Sorry no record found</label></td>
//             </tr>
//         }

//         // Outer loop to create parent
//         for (let i = 0; i < ((this.state.whole_data2.length < 5) ? this.state.whole_data2.length : 5); i++) {

//             let children = []
//             //Inner loop to create children
//             for (let j = 0; j <= 4; j++) {
//                 if (j == 0) {
//                     children.push(<td><a className="anchor-styling" id={`${this.state.whole_data2[i]["user_id"]}`} onClick={(e) => this.viewDetail(e)}>{`${this.state.whole_data2[i]["user_name"]}`}</a></td>)
//                     // children.push(<td>{`${this.state.whole_data2[i]["user_name"]}`}</td>)
//                 }
//                 else if (j == 1) {

//                     children.push(<td>{`${this.state.whole_data2[i]["payment_method"]}`}
//                         <p className="mb-0 tags-pay">
//                             {this.populateTagSell(i)}
//                             {/* <span className="badge">Primary</span>
//                       <span className="badge">Primary</span> */}
//                         </p>
//                     </td>)
//                 }
//                 else if (j == 2) {
//                     if (this.state.whole_data2[i]["price_equation"] !== undefined) {
//                         var num = Number(this.state.whole_data2[i]["price_equation"])
//                         var n = num.toFixed(2);
//                         var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//                         var n2 = n.split(".")[1]
//                         children.push(<td>{`${n1 + "." + n2 + " " + this.state.whole_data2[i]["currency_type"]}`}</td>)

//                     }
//                     else children.push(<td>0</td>)
//                 }
//                 else if (j == 3) {
//                     children.push(<td>{`${this.state.whole_data2[i]["min_transaction_limit"] + "-" + this.state.whole_data2[i]["max_transaction_limit"] + " " + this.state.whole_data2[i]["currency_type"]}`}</td>)
//                 }
//                 else if (j == 4) {
//                     if (this.state.tab == "buy") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data2[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">BUY</a></td>)
//                     }
//                     else if (this.state.tab == "sell") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data2[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">SELL</a></td>)

//                     }
//                 }
//             }
//             //Create the parent and add the children
//             table.push(<tr>{children}</tr>)
//         }
//         return table
//     }
//     populate_view2ShowMore = () => {
//         let table = []
//         if (this.state.whole_data2.length == 0) {
//             return <tr>
//                 <td colspan="6" align="center"> <label className="error text-center">Sorry no record found</label></td>
//             </tr>
//         }
//         // Outer loop to create parent
//         for (let i = 0; i < this.state.whole_data2.length; i++) {

//             let children = []
//             //Inner loop to create children
//             for (let j = 0; j <= 4; j++) {
//                 if (j == 0) {
//                     children.push(<td><a className="anchor-styling" id={`${this.state.whole_data2[i]["user_id"]}`} onClick={(e) => this.viewDetail(e)}>{`${this.state.whole_data2[i]["user_name"]}`}</a></td>)
//                 }
//                 else if (j == 1) {

//                     children.push(<td>{`${this.state.whole_data2[i]["payment_method"]}`}
//                         <p className="mb-0 tags-pay">
//                             {this.populateTagSell(i)}
//                             {/* <span className="badge">Primary</span>
//                       <span className="badge">Primary</span> */}
//                         </p>
//                     </td>)
//                 }
//                 else if (j == 2) {
//                     if (this.state.whole_data2[i]["price_equation"] !== undefined) {
//                         var num = Number(this.state.whole_data2[i]["price_equation"])
//                         var n = num.toFixed(2);
//                         var n1 = n.split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//                         var n2 = n.split(".")[1]
//                         children.push(<td>{`${n1 + "." + n2 + " " + this.state.whole_data2[i]["currency_type"]}`}</td>)

//                     }
//                     else children.push(<td>0</td>)
//                 }
//                 else if (j == 3) {
//                     children.push(<td>{`${this.state.whole_data2[i]["min_transaction_limit"] + "-" + this.state.whole_data2[i]["max_transaction_limit"] + " " + this.state.whole_data2[i]["currency_type"]}`}</td>)
//                 }
//                 else if (j == 4) {
//                     if (this.state.tab == "buy") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data2[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">BUY</a></td>)
//                     }
//                     else if (this.state.tab == "sell") {
//                         children.push(<td className="Width110 text-center"><a id={`${this.state.whole_data2[i]["_id"]}`} onClick={(e) => this.BuySellSpecific(e)} className="btn btnGray minWidth80">SELL</a></td>)

//                     }
//                 }
//             }
//             //Create the parent and add the children
//             table.push(<tr>{children}</tr>)
//         }
//         return table
//     }
//     BuySellSpecific = (e) => {
//         e.preventDefault();
//         var id = e.target.id;
//         var encryptData = base32.encode(id)

//         if (this.state.tab == "buy") {
//             this.props.history.push(`/buycoindetail/${encryptData}/buy`)
//         }
//         else if (this.state.tab == "sell") {
//             this.props.history.push(`/sellbitcoindetail/${encryptData}/sell`)
//         }
//     }

//     render() {

//         return (
//             <div>
//                 <Header {...this.props} />
//                 <ToastContainer />
//                 {this.state.loading ? <div className='loader'> <img className="" style={{ height: 100, width: 100 }} src={require('../assets/lg.gif')} /> </div>
//                     : null
//                 }
//                 <Modal isOpen={this.state.tutorial} style={{ width: "150%" }} >
//                     <div>
//                         <div className="modal-header">
//                             <h4 className="modal-title">Trade Tutorial</h4>
//                             <button type="button" onClick={() => this.setState({ tutorial: false })} className="close" data-dismiss="modal">&times;</button>
//                         </div>
//                         <div className="modal-body pt0 pb0 readTutoral">
//                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
//                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
//                             {this.state.tutorialinc ?
//                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
//                                 : null}
//                         </div>
//                         <div className="modal-footer">
//                             <div className="pt10 borderTop w-100 text-right d-flex justify-content-between flex-wrap">
//                                 <button type="button" onClick={() => this.setState({ tutorial: false })} className="btn btnGray w-50 font16 p10 pb10">Skip</button>
//                                 <button type="button" onClick={() => this.setState({ tutorialinc: true })} className="btn btnGreen w-50 font16  p10 pb10">Continue Reading</button>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal>
//                 <main className="mainContent">
//                     <section className="staticContentSection commonSectionStyle">
//                         <div className="container1440">
//                             <ul className="nav nav-tabs globalTab max-WT-600 center-box w-100" role="tablist" style={{ marginBottom: 30 }}>
//                                 <li className="nav-item  w-50">
//                                     <a className={`nav-link ${this.state.tab == "buy" ? "active" : ""} radius-TR0 radius-BR0`} onClick={() => this.setState({ tab: "buy", whole_data: "" })} data-toggle="tab" >QUICK BUY</a>
//                                 </li>
//                                 <li className="nav-item  w-50">
//                                     <a className={`nav-link ${this.state.tab == "sell" ? "active" : ""} radius-TL0 radius-BL0`} onClick={() => { this.loadSellData(); this.setState({ tab: "sell", whole_data: "" }) }} data-toggle="tab" >QUICK SELL</a>
//                                 </li>
//                             </ul>
//                             {/* <!-- Tab-Content --> */}
//                             <div className="tab-content">
//                                 <div className="tableFilter borderBottom0 pb0 mb30">
//                                     <form className="main-form">
//                                         <div className="d-flex align-items-center flex-wrap">
//                                             <div className="filterInlineField">
//                                                 <div className="input-group">
//                                                     <input type="text" className="form-control borderRight0 radius-TR0 radius-BR0" value={this.state.Amount} name="Amount" placeholder="Enter Amount" onChange={this.handleOnChange} />
//                                                     <div className="input-group-append">
//                                                         <select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0" name="currency" onChange={(e) => this.onClick_currency(e)}>
//                                                             {<option>Select currency</option>}
//                                                             {this.Populate_currency()}
//                                                         </select>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="filterInlineField">
//                                                 <select className="form-control select-style1" onChange={(e) => this.onClick_country(e)}>
//                                                     <option>Select Country</option>
//                                                     {this.Populate_country()}
//                                                 </select>
//                                             </div>
//                                             <div className="filterInlineField">
//                                                 <select className="form-control select-style1" onChange={(e) => this.onClick_paymentBank(e)}>
//                                                     <option>All Online offers</option>
//                                                     {this.Populate_paymentMethods()}
//                                                 </select>
//                                             </div>
//                                             <button className="proxiNova btn btnGreen pt10 pb10 minWidth120" onClick={(e) => this.searchFilter(e)}>Search</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                                 <div id="tab1" className={`tab-pane ${this.state.tab == "buy" ? "active" : ""} `}>
//                                     <div className="tableBox">
//                                         <div className="tableBoxHead d-flex align-items-center flex-wrap">
//                                             <h4 className="mb15">Buy Bitcoin Online</h4>
//                                             {this.state.ShowBuyMore ? <button onClick={() => this.setState({ ShowBuyMore: false })} className="showMore btn ml-auto mb15 minWidth120">Show Less...</button> :
//                                                 <button onClick={() => this.setState({ ShowBuyMore: true })} className="showMore btn ml-auto mb15 minWidth120">Show More...rrrrrrr</button>}
//                                         </div>
//                                         <div className="tableBoxBody">
//                                             <div className="table-responsive">
//                                                 <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
//                                                     <thead>
//                                                         <tr>
//                                                             <th>Buyer</th>
//                                                             <th>Payment Method</th>
//                                                             <th>Price/BTC</th>
//                                                             <th>to Pay <label> on the dollar</label></th>
//                                                             <th>Limit</th>
//                                                             <th>Action</th>
//                                                         </tr>
//                                                     </thead>

//                                                     <tbody>

//                                                         {this.state.ShowBuyMore ?
//                                                             this.populate_view1ShowMore() : this.populate_view1()}
//                                                         {/* <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Receipt required</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Online payment</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td><span>501013.92 INR</span></td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
//                                                     </tr> */}
//                                                     </tbody>

//                                                 </table>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {this.state.whole_dataTotal ?
// 										this.state.ShowBuyMore ?
// 											<Pagination className="mt10" defaultCurrent={1} total={this.state.whole_dataTotal} onChange={(value) => this._loadData(value)} />
// 											: null : null}
//                                 </div>
//                                 {/* <!-- tab1 End --> */}
//                                 <div id="tab2" className={`tab-pane ${this.state.tab == "sell" ? "active" : ""} `}>
//                                     <div className="tableBox">
//                                         <div className="tableBoxHead d-flex align-items-center flex-wrap">
//                                             <h4 className="mb15">Sell Bitcoin Online</h4>
//                                             {/* {this.state.ShowSellMore ? <button onClick={() => this.setState({ ShowSellMore: false })} className="showMore btn ml-auto mb15 minWidth120">Show Less...</button> : */}
//                                                 <button onClick={() => this.setState({ ShowSellMore: true })} className="showMore btn ml-auto mb15 minWidth120">Show More...MMMMM</button>
//                                         </div>
//                                         <div className="tableBoxBody">
//                                             <div className="table-responsive">
//                                                 <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
//                                                     <thead>
//                                                         <tr>
//                                                             <th>Seller</th>
//                                                             <th>Payment Method</th>
//                                                             <th>Price/BTC</th>
//                                                             <th>Limit</th>
//                                                             <th>Action</th>
//                                                         </tr>
//                                                     </thead>

//                                                     <tbody>

//                                                         {this.state.ShowSellMore ?
//                                                             this.populate_view2ShowMore() : this.populate_view2()}

//                                                         {/* {this.state.whole_dataTotal ?
//                                                             this.state.ShowBuyMore ?
//                                                                 <Pagination className="mt10" defaultCurrent={1} total={this.state.whole_dataTotal} onChange={(value) => this._loadData(value)} />
//                                                                 : null : null} */}
//                                                         {/* <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Receipt required</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Online payment</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">No ID required</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Receipt required</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Amit Kumar (1000+; 100%)</td>
//                                                         <td>IMPS <a className="underline ml7">Online payment</a></td>
//                                                         <td>501013.92 INR</td>
//                                                         <td>11000 - 20000 INR</td>
//                                                         <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
//                                                     </tr> */}
//                                                     </tbody>

//                                                 </table>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {this.state.whole_dataTotal2 ?
//                                     this.state.ShowSellMore ?
//                     <Pagination className="mt10" defaultCurrent={1} total={this.state.whole_dataTotal2} onChange={(value) => this._loadData2(value)} />
//                     : null : null}
//                                 </div>
//                                 {/* <!-- tab2 End --> */}
//                             </div>
//                             {/* <!-- Tab-Content Start --> */}
//                         </div>
//                     </section>
//                     {/* <!-- createAccountSection Start --> */}
//                     {/* <section className="createAccountSection bgGreen">
//                     <div className="container-fluid">
//                         <div className="createAccountBox">
//                             <span className="wColor">Getting started is easy. Create your account now.</span>
//                             <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
//                         </div>
//                     </div>
//                 </section> */}
//                 </main>

//                 <Footer {...this.props} />
//             </div>
//         )
//     }
// }
import React, { Component } from "react";

/* Module */
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { changeUTCTime } from "../components/convertTimeToUTC";
import Pagination from "rc-pagination";

/* components */
import Header from "../components/header";
import Footer from "../components/footer";

/* JSON */

import currencyJson from "../JSON/currency.json";
import _country from "../JSON/country.json";
/* services */
import { Api } from "../services/webservices";
import { base32 } from "../services/Decrypt";

let getUTCZone = "";
let converetdTime = [];

export default class Cointab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "buy",
      currency: "",
      Amount: "",
      paymentMethod: [],
      country: "",
      bank: "",
      whole_data: "",
      whole_data1: [],
      whole_data2: [],
      tutorial: false,
      tutorialinc: false,
      whole_dataTotal: [],
      whole_dataTotal2: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    this.setState({
      tab: this.props.match.params.type,
    });
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    var tab = this.props.match.params.view_type;
    this.setState({ tab: tab });
    setTimeout(() => {
      this.setState({
        _id: id,
        token: token,
        addr: addr,
      });
    }, 300);

    Api(
      "trade/paymentMethodList",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.data.responseCode == 200) {
          this.setState({
            paymentMethod: resp.data.result,
          });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        } else {
        } //toast_func("error", "Some problem occured please contact administrator")
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.loadBuySellData();
  }
  stateApis = () => {
    return;

    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  loadBuySellData = (e) => {
    var parameter1 = { type: "buy" };
    var parameter2 = { type: "sell" };

    this.setState({
      loader1: true,
      loader2: true,
    });
    Api(
      "trade/filter_trade",
      "POST",
      parameter1,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({
        loader1: false,
        loader2: false,
      });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.result.docs.length != 0) {
              console.log("===========", resp.data.result);
              this.setState({
                whole_data1: resp.data.result.docs,
                whole_dataTotal: resp.data.result.total,
              });
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          // toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
    this.setState({ loading: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter2,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.result.docs.length != 0) {
              this.setState({
                whole_data2: resp.data.result.docs,
                whole_dataTotal2: resp.data.result.total,
              });
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          //toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };
  Populate_currency = () => {
    let currencyArray = [];
    for (var i = 0; i < currencyJson.length; i++) {
      currencyArray.push(<option>{currencyJson[i].code}</option>);
    }
    return currencyArray;
  };
  Populate_country = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option>{_country[i].name}</option>);
    }
    return __array;
  };
  Populate_paymentMethods = () => {
    let __array = [];
    for (var i = 0; i < this.state.paymentMethod.length; i++) {
      __array.push(<option>{this.state.paymentMethod[i].name}</option>);
    }
    return __array;
  };
  onClick_currency = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "Select currency") {
      this.setState({
        currency: "",
        currencyError: "",
      });
    } else {
      this.setState({
        currency: value,
        currencyError: "",
      });
    }
  };
  handleOnChange = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;

    if (type == "Amount") {
      if (!isNaN(value) && value !== " ") {
        this.setState({ [type]: value });
      }
    }
  };
  onClick_country = (e) => {
    e.preventDefault();
    var value = e.target.value;

    if (value == "Select Country")
      this.setState({
        country: "",
        countryError: "",
      });
    else
      this.setState({
        country: value,
        countryError: "",
      });
  };
  onClick_paymentBank = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "All Online offers")
      this.setState({ bank: "", bankError: "" });
    else
      this.setState({
        bank: value,
        bankError: "",
      });
  };
  populateTagSell = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data2[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge">
          {this.state.whole_data2[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };

  _loadData(value) {
    var parameter = { userId: this.state._id, limit: 10, page: value };

    // this.setState({ loading: true })
    Api(
      "trade/user_ad_list",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            console.log("ddddddddddddd", resp.data.result);
            this.setState(
              {
                whole_data1: resp.data.result["docs"],
                whole_dataTotal: resp.data.result["total"],
              },
              () => {
                this.populate_view1();
              }
            );
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  }

  _loadData2(value) {
    var parameter = {
      type: "sell",
      userId: this.state._id,
      limit: 10,
      page: value,
    };

    // this.setState({ loading: true })
    Api(
      "trade/filter_trade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            console.log("ddddddddddddd", resp.data.result);
            this.setState(
              {
                whole_data2: resp.data.result["docs"],
                whole_dataTotal2: resp.data.result["total"],
              },
              () => {
                this.populate_view2();
              }
            );
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        toast_func(
          "error",
          "some problem occured please contact administrator"
        );
      }
    });
    this.stateApis();
  }

  populateTagBuy = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data1[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge">
          {this.state.whole_data1[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  searchFilter = (e) => {
    // e.preventDefault()
    this.setState({
      whole_data1: "",
      whole_data2: "",
    });
    var parameter = {
      type: "buy",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };
    var parameter1 = {
      type: "sell",
      payment_method:
        this.state.bank == "All Online offers" ? "" : this.state.bank,
      amount: this.state.Amount,
      location: this.state.country,
      currency_type: this.state.currency,
    };
    this.setState({ loader1: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            }
            if (resp.data.responseCode == 200) {
              if (resp.data.result.docs.length != 0) {
                this.setState({
                  whole_data1: resp.data.result.docs,
                });
              } else {
                // toast_func("error", "No data matched for BUY as per your search")
              }
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });

    this.setState({ loader2: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter1,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader2: false });
      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            }
            if (resp.data.responseCode == 200) {
              if (resp.data.result.docs.length != 0) {
                this.setState({
                  whole_data2: resp.data.result.docs,
                  whole_dataTotal2: resp.data.result.total,
                });
              } else {
                // toast_func("error", "No data matched for BUY as per your search")
              }
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  };

  viewDetail = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(id);
    this.props.history.push(`/user-details/${encryptData}`);
  };
  populate_view1 = () => {
    console.log(
      "==========populate_view1======   >>>>>>>>>  xxx=================="
    );
    let table = [];
    if (this.state.loader1 == true) {
      return (
        <td colspan="6" align="center">
          <span>
            <img
              src={require("../assets/ajax-loader.gif")}
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
          </span>
        </td>
      );
    } else if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found</label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (
      let i = 0;
      i <
      (this.state.whole_data1.length < 5 ? this.state.whole_data1.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.whole_data1[i]["user_id"]}`}
                onClick={(e) => this.viewDetail(e)}
              >{`${this.state.whole_data1[i]["user_name"]}`}</a>
            </td>
          );
          //children.push(<td>{`${this.state.whole_data1[i]["user_name"]}`}</td>)
        } else if (j == 1) {
          children.push(
            <td>
              {`${this.state.whole_data1[i]["payment_method"]}`}

              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(8);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          if (this.state.tab == "buy") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  BUY
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  SELL
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populate_view1ShowMore = () => {
    console.log(
      "==========populate_view1ShowMore      >>>>>>>>>  ========================",
      this.state.whole_data1
    );
    let table = [];
    if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found</label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (let i = 0; i < this.state.whole_data1.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.whole_data1[i]["user_id"]}`}
                onClick={(e) => this.viewDetail(e)}
              >{`${this.state.whole_data1[i]["user_name"]}`}</a>
            </td>
          );

          // children.push(<td>{`${this.state.whole_data1[i]["user_name"]}`}</td>)
        } else if (j == 1) {
          children.push(
            <td>
              {`${this.state.whole_data1[i]["payment_method"]}`}
              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(8);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          if (this.state.tab == "buy") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  BUY
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data1[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  SELL
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populate_view2 = () => {
    let table = [];
    if (this.state.loader2 == true) {
      return (
        <td colspan="6" align="center">
          <span>
            <img
              src={require("../assets/ajax-loader.gif")}
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
          </span>
        </td>
      );
    } else if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found</label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (
      let i = 0;
      i <
      (this.state.whole_data2.length < 5 ? this.state.whole_data2.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.whole_data2[i]["user_id"]}`}
                onClick={(e) => this.viewDetail(e)}
              >{`${this.state.whole_data2[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td>
              {`${this.state.whole_data2[i]["payment_method"]}`}
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(8);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          if (this.state.tab == "buy") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  BUY
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  SELL
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populate_view2ShowMore = () => {
    let table = [];
    if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colspan="6" align="center">
            {" "}
            <label className="error text-center">Sorry no record found</label>
          </td>
        </tr>
      );
    }
    // Outer loop to create parent
    for (let i = 0; i < this.state.whole_data2.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 4; j++) {
        if (j == 0) {
          children.push(
            <td>
              <a
                className="anchor-styling"
                id={`${this.state.whole_data2[i]["user_id"]}`}
                onClick={(e) => this.viewDetail(e)}
              >{`${this.state.whole_data2[i]["user_name"]}`}</a>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td>
              {`${this.state.whole_data2[i]["payment_method"]}`}
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
                      <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(8);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          if (this.state.tab == "buy") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  BUY
                </a>
              </td>
            );
          } else if (this.state.tab == "sell") {
            children.push(
              <td className="Width110 text-center">
                <a
                  id={`${this.state.whole_data2[i]["_id"]}`}
                  onClick={(e) => this.BuySellSpecific(e)}
                  className="btn btnGray minWidth80"
                >
                  SELL
                </a>
              </td>
            );
          }
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  BuySellSpecific = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(id);

    if (this.state.tab == "buy") {
      this.props.history.push(`/buycoindetail/${encryptData}/buy`);
    } else if (this.state.tab == "sell") {
      this.props.history.push(`/sell-bitcoins-instantly/${encryptData}`);
    }
  };
  handleCallback = (val) => {
    if (val === "true") {
      console.log("true", val);
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
    } else {
      console.log("false", val);
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Modal isOpen={this.state.tutorial} style={{ width: "150%" }}>
          <div>
            <div className="modal-header">
              <h4 className="modal-title">Trade Tutorial</h4>
              <button
                type="button"
                onClick={() =>
                  this.setState({
                    tutorial: false,
                  })
                }
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body pt0 pb0 readTutoral">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              {this.state.tutorialinc ? (
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              ) : null}
            </div>
            <div className="modal-footer">
              <div className="pt10 borderTop w-100 text-right d-flex justify-content-between flex-wrap">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      tutorial: false,
                    })
                  }
                  className="btn btnGray w-50 font16 p10 pb10"
                >
                  Skip
                </button>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      tutorialinc: true,
                    })
                  }
                  className="btn btnGreen w-50 font16  p10 pb10"
                >
                  Continue Reading
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle">
            <div className="container1440">
              <ul
                className="nav nav-tabs globalTab max-WT-600 center-box w-100 mb30"
                role="tablist"
              >
                <li className="nav-item  w-50">
                  <a
                    className={`nav-link ${
                      this.state.tab == "buy" ? "active" : ""
                    } radius-TR0 radius-BR0`}
                    onClick={() =>
                      this.setState({
                        tab: "buy",
                        whole_data: "",
                      })
                    }
                    data-toggle="tab"
                  >
                    QUICK BUY
                  </a>
                </li>
                <li className="nav-item  w-50">
                  <a
                    className={`nav-link ${
                      this.state.tab == "sell" ? "active" : ""
                    } radius-TL0 radius-BL0`}
                    onClick={() =>
                      this.setState({
                        tab: "sell",
                        whole_data: "",
                      })
                    }
                    data-toggle="tab"
                  >
                    QUICK SELL
                  </a>
                </li>
              </ul>
              {/* <!-- Tab-Content --> */}
              <div className="tab-content">
                <div className="tableFilter borderBottom0 pb0 mb30">
                  <form className="main-form">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="filterInlineField">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control borderRight0 radius-TR0 radius-BR0"
                            value={this.state.Amount}
                            name="Amount"
                            placeholder="Enter Amount"
                            onChange={this.handleOnChange}
                          />
                          <div className="input-group-append">
                            <select
                              className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0"
                              name="currency"
                              onChange={(e) => this.onClick_currency(e)}
                            >
                              <option>Select currency</option>
                              {this.Populate_currency()}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="filterInlineField">
                        <select
                          className="form-control select-style1"
                          onChange={(e) => this.onClick_country(e)}
                        >
                          <option>Select Country</option>
                          {this.Populate_country()}
                        </select>
                      </div>
                      <div className="filterInlineField">
                        <select
                          className="form-control select-style1"
                          onChange={(e) => this.onClick_paymentBank(e)}
                        >
                          <option>All Online offers</option>
                          {this.Populate_paymentMethods()}
                        </select>
                      </div>
                      <button
                        className="proxiNova btn btnGreen pt10 pb10 minWidth120"
                        onClick={(e) => this.searchFilter(e)}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  id="tab1"
                  className={`tab-pane ${
                    this.state.tab == "buy" ? "active" : ""
                  } `}
                >
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap">
                      <h4 className="mb15">Buy Bitcoin Online</h4>
                      {this.state.ShowBuyMore ? (
                        <button
                          className="showMore btn ml-auto mb15 minWidth120"
                          onClick={() =>
                            this.setState({
                              ShowBuyMore: false,
                            })
                          }
                        >
                          Show Less...
                        </button>
                      ) : (
                        <button
                          className="showMore btn ml-auto mb15 minWidth120"
                          onClick={() =>
                            this.setState({
                              ShowBuyMore: true,
                            })
                          }
                        >
                          {" "}
                          Show More...
                        </button>
                      )}
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead>
                            <tr>
                              <th>Buyer</th>
                              <th>Payment Method</th>
                              <th>Price/BTC</th>
                              <th>Limit</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.ShowBuyMore
                              ? this.populate_view1ShowMore()
                              : this.populate_view1()}
                            {/* <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Receipt required</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Online payment</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td><span>501013.92 INR</span></td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn btnGray minWidth80">BUY</a></td>
                                                    </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {this.state.whole_dataTotal ? (
                    this.state.ShowBuyMore ? (
                      <Pagination
                        className="mt10"
                        defaultCurrent={1}
                        total={this.state.whole_dataTotal}
                        onChange={(value) => this._loadData(value)}
                      />
                    ) : null
                  ) : null}
                </div>
                {/* <!-- tab1 End --> */}
                <div
                  id="tab2"
                  className={`tab-pane ${
                    this.state.tab == "sell" ? "active" : ""
                  } `}
                >
                  <div className="tableBox">
                    <div className="tableBoxHead d-flex align-items-center flex-wrap">
                      <h4 className="mb15">Sell Bitcoin Online</h4>

                      <button
                        onClick={() =>
                          this.setState({
                            ShowSellMore: true,
                          })
                        }
                        className="showMore btn ml-auto mb15 minWidth120"
                      >
                        Show More...xxxx
                      </button>
                    </div>
                    <div className="tableBoxBody">
                      <div className="table-responsive">
                        <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                          <thead>
                            <tr>
                              <th>Seller</th>
                              <th>Payment Method</th>
                              <th>Price/BTC</th>
                              <th>Limit</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.ShowSellMore
                              ? this.populate_view2ShowMore()
                              : this.populate_view2()}
                            {/* <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Receipt required</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Online payment</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">No ID required</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Receipt required</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Amit Kumar (1000+; 100%)</td>
                                                        <td>IMPS <a className="underline ml7">Online payment</a></td>
                                                        <td>501013.92 INR</td>
                                                        <td>11000 - 20000 INR</td>
                                                        <td className="Width110 text-center"><a className="btn redBtn minWidth80">SELL</a></td>
                                                    </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {this.state.whole_dataTotal2 ? (
                    this.state.ShowSellMore ? (
                      <Pagination
                        className="mt10"
                        defaultCurrent={1}
                        total={this.state.whole_dataTotal2}
                        onChange={(value) => this._loadData2(value)}
                      />
                    ) : null
                  ) : null}
                </div>
                {/* <!-- tab2 End --> */}
              </div>
              {/* <!-- Tab-Content Start --> */}
            </div>
          </section>
          {/* <!-- createAccountSection Start --> */}
          {/* <section className="createAccountSection bgGreen">
                    <div className="container-fluid">
                        <div className="createAccountBox">
                            <span className="wColor">Getting started is easy. Create your account now.</span>
                            <a className="btn btnYellow max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                        </div>
                    </div>
                </section> */}
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
