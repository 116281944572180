import React, { Component } from "react";
// import AwesomeSlider from 'react-awesome-slider';
// import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import Slider from "react-slick";

export default class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  componentDidMount = async () => {
    console.log("prop===", this.props);
    // this.setState({ images: this.props.productImage });
  };

  render() {
    const setting = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 1000,
      slidesToShow: 1,
      // slidesToScroll: 1,
      accessibility: true,
    };
    return (
      <div
        className="container1440 prodImg"
        style={{ backgroundColor: "var(--white-color)",borderRadius:12 }}
      >
        <Slider {...setting} buttons={true}>
          {this.props.productImage.map((item, index) => {
            return (
              <div className="slideimgbox boxsize" key={item._id}>
                <div
                  className="slideimg"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    src={item.imageUrl}
                    alt="Image"
                  />
                </div>
                <div className="slidetext" style={{marginTop:60}}>
                  <p style={{ color: "var(--text-grey)" }}>
                    Disclaimer:The product or service images shown are for
                    illustration purposes only and may not be an exact
                    representation of the product
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
