import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";

export default class Merchant extends Component {
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <main className="mainContent">
          <section className="staticContentSection commonSectionStyle walletSection">
            <div className="container">
              <div id="tab3">
                <div className="mb20">
                  <h4 className="dBlueColor">Salary Transactions</h4>
                </div>
                {/* <div className="cardBox">
                <div className="tableBox">
                  <div className="tableBoxHead d-flex align-items-center flex-wrap pt15">
                   
                    <div className="tableFilter2 ml-auto d-flex flex-wrap  align-items-center mb15">
                    <div className="datepickerField d-flex  align-items-center">
                        <label className='dBlueColor'>From</label>
                        <DatePicker  className="form-control radius6"
                              selected={this.state.startDate1}
                              onChange={this.handleChange1}
                              placeholderText="mm/dd/yyyy"
                              maxDate={new Date()} 
                            />
                      </div>
                      <div className="datepickerField d-flex align-items-center">
                        <label className='dBlueColor'>To</label>
                        <DatePicker  className="form-control radius6"
                              selected={this.state.startDate2}
                              onChange={this.handleChange2}
                              placeholderText="mm/dd/yyyy"
                              minDate={this.state.startDate1}
                              maxDate={new Date()} 
                            />
                      </div>
                      {this.state.transaction_H == true ? (
                      <button type="button" className="btn btnGreen minWidth80" onClick={()=>this.historyData(this.state.startDate1,this.state.startDate2)}>Search</button>
                      ):(
                        <button type="button" className="btn btnGreen minWidth80" onClick={()=>this.MGT_historyData(this.state.startDate1,this.state.startDate2)}>Search</button>
                      )}
                    </div>
                  </div>
                </div>

              

              </div> */}
                <div className="tableBox">
                  <div className="tableBoxBody">
                    <div className="table-responsive tbl">
                      <table className="table  tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                        <thead className="tbl_hd">
                          <tr>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Txn. Id</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>30/05/2022</th>
                            <td>Sanjeev</td>
                            <td>2133</td>
                            <td>#tyty</td>
                            <td>10000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer {...this.props} />
      </div>
    );
  }
}
