//Modules
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactModal } from "../services/globals";
import $ from "jquery";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
//Components
import {
  validateName,
  validateEmail,
  validatePassword,
  validateMobileNo,
} from "../utils/validation";
import { Api } from "../services/webservices";
import Header from "../components/header";
import Footer from "../components/footer";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ClipLoader } from "react-spinners";

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      emailStatus: false,
      id: "",
      url1: "",
      token: "",
      emailError1: "",
      emailError: "",
      confirm_email: "",
      emailStatus: false,
      confirm_emailStatus: false,
      statusCode: true,
      responseMessage: "",
      loading: false,
      togglePswrd: true,
      toggleCnfrmPswrd: true,
    };
  }
  componentDidMount() {
    // var check = this.props.history.location

    var searchUrl1 = this.props.history.location.search.split("&")[0];
    var id = searchUrl1.split("=")[1];
    var token = this.props.history.location.search.split("=")[2];
    var url1 = this.props.history.location.search.split("/")[0];
    this.setState({ id: id, token: token, url1: url1 }, () =>
      this.checkValidity()
    );

    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(45, 40, 60, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    var canvas = document.getElementById("myCanvas"),
      context = canvas.getContext("2d");

    make_base();
    function make_base() {
      var base_image = new Image();
      base_image.src =
        "https://res.cloudinary.com/georgia007/image/upload/v1648884436/u526u3jcezvcbtkioeqs.png";
      //   base_image.src = 'https://res.cloudinary.com/georgia007/image/upload/v1645866275/mpw85vrv8ysamjr632tj.png';
      //   base_image.src = '../assets/newImages/logo.png';
      base_image.onload = function() {
        context.drawImage(base_image, 10, 15, 80, 60);
      };
    }
  }
  checkValidity = () => {
    var parameter = { userId: this.state.id, token: this.state.token };
    this.setState({ loading: true });
    try {
      Api("user/verifyUser", "POST", parameter).then((resp) => {
        if (resp.status == 200) {
          this.setState({ loading: false });
          if (resp.data.responseCode == 200) {
            this.setState({ statusCode: true });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            this.setState({
              statusCode: false,
              responseMessage: resp.data.responseMessage,
            });
          }
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  };
  showPswrd(val) {
    if (val == "show") {
      this.setState({ togglePswrd: false });
      var x = document.getElementById("myPswrd");
      x.type = "text";
    } else {
      this.setState({ togglePswrd: true });
      var x = document.getElementById("myPswrd");
      x.type = "password";
    }
  }
  handleCnfrmPswrd(val) {
    if (val == "show") {
      this.setState({ toggleCnfrmPswrd: false });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "text";
    } else {
      this.setState({ toggleCnfrmPswrd: true });
      var y = document.getElementById("cnfrmPswrd");
      y.type = "password";
    }
  }

  _forgotPassword = (e) => {
    e.preventDefault();
    if (validatePassword(this.state.email).status) {
      this.setState({
        emailStatus: true,
        emailError: "",
      });
    } else
      this.setState({
        emailError: validatePassword(this.state.email).error,
        emailStatus: false,
      });

    if (this.state.confirm_email == this.state.email) {
      this.setState({
        confirm_emailStatus: true,
        emailError1: "",
      });
    } else
      this.setState({
        emailError1: "*Please enter password & confirm password same",
        confirm_emailStatus: false,
      });

    var parameter = {
      _id: this.state.id,
      token: this.state.token,
      password: this.state.email,
    };
    if (this.state.emailStatus && this.state.confirm_emailStatus) {
      this.setState({ loading: true });

      // fetch(`${this.state.url1}/reset-password?_id=${this.state.id}&token=${this.state.token}`,parameter)
      Api("user/resetPassword", "POST", parameter).then((resp) => {
        if (resp.status == 200) {
          this.setState({ loading: false });
          // alert(JSON.stringify(resp))
          if (resp.data.responseCode == 200) {
            toast_func("success", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 2000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "some problem occured please contact administrator"
          );
        }
      });
    }
  };
  handleUserInput = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;
    this.setState({ [type]: value });
    setTimeout(() => {
      if (type == "email") {
        if (validatePassword(this.state.email).status) {
          this.setState({
            emailStatus: true,
            emailError: "",
          });
        } else
          this.setState({
            emailError: validatePassword(this.state.email).error,
            emailStatus: false,
          });
      }
      if (type == "confirm_email") {
        if (this.state.email == this.state.confirm_email) {
          this.setState({
            confirm_emailStatus: true,
            emailError1: "",
          });
        } else
          this.setState({
            confirm_emailStatus: false,
            emailError1: "*Please enter password & confirm password same",
          });
      }
    }, 100);
  };
  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--dark-btn", "#fff");
      document.body.style.setProperty("--bg-color", "#000000ad");
    } else {
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
      document.body.style.setProperty("--dark-btn", "#3e5c85");
      document.body.style.setProperty("--bg-color", "#e9f9fc");
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} parentCallback1={this.handleCallback} />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <ToastContainer />
        <main className="mainContent">
          <section className="commonSectionStyle bgColour">
            <div className="container">
              <div className="loginbox">
                <div className="box_3">
                  <a href="/">
                    <canvas id="myCanvas" className="canvas" />
                  </a>
                  <div className="pdBox">
                    <img src={require("../assets/newImages/resetPswrd2.png")} />
                  </div>
                </div>
                <div className="box_2">
                  {this.state.statusCode ? (
                    <form>
                      <div className="formBox max-WT-400 formSpace">
                        <div>
                          <h2 className="header2">Set New Password</h2>
                        </div>
                        <label>New Password</label>
                        <div className="form-group posRel">
                          <input
                            type="password"
                            id="myPswrd"
                            className="form-control radius10"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleUserInput}
                            placeholder="Please enter new password"
                          />
                          {this.state.togglePswrd == true ? (
                            <span
                              title="Hide"
                              className="fa fa-eye-slash setPos"
                              onClick={() => this.showPswrd("show")}
                            />
                          ) : (
                            <span
                              title="Show"
                              className="fa fa-eye setPos"
                              onClick={() => this.showPswrd("hide")}
                            />
                          )}
                          <label className="error">
                            {this.state.emailError}
                          </label>
                        </div>
                        <label>Confirm Password</label>
                        <div className="form-group posRel">
                          <input
                            type="password"
                            id="cnfrmPswrd"
                            className="form-control radius10"
                            name="confirm_email"
                            value={this.state.confirm_email}
                            onChange={this.handleUserInput}
                            placeholder="Please confirm new password"
                          />
                          {this.state.toggleCnfrmPswrd == true ? (
                            <span
                              title="Hide"
                              className="fa fa-eye-slash setPos"
                              onClick={() => this.handleCnfrmPswrd("show")}
                            />
                          ) : (
                            <span
                              title="Show"
                              className="fa fa-eye setPos"
                              onClick={() => this.handleCnfrmPswrd("hide")}
                            />
                          )}
                          <label className="error">
                            {this.state.emailError1}
                          </label>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            onClick={(e) => this._forgotPassword(e)}
                            className="btn btnGrey w-100 pt10 pb10 radius10"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form className="mb0 w-100">
                      <div className="sectionHead pb10 text-center">
                        <h2 className="header2">
                          {this.state.responseMessage}
                        </h2>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>

        {/* <main className="mainContent">
                    <section className="staticContentSection commonSectionStyle">
                        <div className="container">
                            <div className="cardBox max-WT-550 center-box d-flex align-items-center minHeight350">
                                {this.state.statusCode ?
                                    <form className="mb0 w-100">
                                        <div className="sectionHead borderBottom pb10 text-center">
                                            <h2>Set New Password</h2>
                                        </div>
                                     
                                        <div className="formBox max-WT-400 center-box">
                                            <div className="form-group">
                                                <input type="password" className="form-control" name="email" value={this.state.email} onChange={this.handleUserInput} placeholder="Please enter new password" />

                                                <label className="error">{this.state.emailError}</label>

                                                <input type="password" className="form-control mt5" name="confirm_email" value={this.state.confirm_email} onChange={this.handleUserInput} placeholder="Please confirm new password" />

                                                <label className="error">{this.state.emailError1}</label>

                                            </div>
                                            <div className="text-center">
                                                <button type="submit" onClick={(e) => this._forgotPassword(e)} className="btn btnGreen w-100 pt10 pb10 radius4">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    <form className="mb0 w-100">
                                        <div className="sectionHead borderBottom pb10 text-center">
                                            <h2>{this.state.responseMessage}</h2>

                                        </div>
                                    </form>
                                }

                            </div>
                        </div>
                    </section>
                </main> */}
        <Footer {...this.props} />
      </div>
    );
  }
}
